import { Component, OnInit, Input, HostListener, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'hunch-button',
    templateUrl: 'hunch-button.component.html',
    styleUrls: ['./hunch-button.component.scss']
})
export class HunchButtonComponent implements OnInit {
    @Input() classes: string;
    @Input() text: string;
    @Input() iconV2: boolean = false;
    @Input() iconSecondV2: boolean = false;
    @Input() iconName: string;
    @Input() iconSize: string;
    @Input() iconNameSecond: string;
    @Input() iconSecondSize: string;
    @Input() disabled: boolean;
    @Input() tooltipText: string;
    @Input() tooltipPlacement: any = 'top'; // top, bottom, right, left and auto
    @Input() pathStrokeWidth: number = 1;
    @Input() testId: string;

    @Output() hunchClick = new EventEmitter<MouseEvent>();

    constructor() {}
    ngOnInit() {}

    @HostListener('click', ['$event'])
    onClick(event: MouseEvent) {
        if (!this.disabled) {
            this.hunchClick.emit(event);
        }
    }
}
