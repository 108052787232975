<!-- <button class="btn--solid btn--solid_icon mr--8 float-right bg--yellow-3" title="Toggle Google Metrics"
    (click)="toggleGoogleMetrics()"
    [ngClass]="{'off-google': !areGoogleMetricsDisplayed}">
    <ng-container
        *ngIf="areGoogleMetricsDisplayed; else regular"
        [ngTemplateOutlet]="white"></ng-container>
</button>

<button class="btn--solid btn--solid_icon mr--8 float-right bg--green-3" title="Toggle Hunch Metrics"
    (click)="toggleHunchMetrics()"
    [ngClass]="{'off-hunch': !areHunchMetricsDisplayed}">
    <ng-container
        *ngIf="areHunchMetricsDisplayed; else regular"
        [ngTemplateOutlet]="white"></ng-container>
</button>

<ng-template #white>
    <svg-icon name="Metrics" alt="" svgClass="single-icon metric-icon white"></svg-icon>
</ng-template>

<ng-template #regular>
    <svg-icon name="Metrics" alt="" svgClass="single-icon metric-icon"></svg-icon>
</ng-template> -->
<app-multi-checkbox-select [setOptions]="metricTypesOptions" [placeholder]="'Additional metrics:'" [defaultText]="'Default'"
(modelChanged)="metricTypeChanged($event)" [width]="'285px'"></app-multi-checkbox-select>
