<ng-container *ngIf="mainInfo || primaryData || additionalInfoData; else displayEmpty">
    <ng-container *ngIf="mainInfo; else displayCompound">
        <span title="{{mainInfo}}">{{mainInfo}}</span>
    </ng-container>
    
    <ng-template #displayCompound>
        <div class="compound-main font--weight_semi">
            <cell-format-display-value
            [cellDisplayValue]="primaryData"
            [metricDataType]="column.metricDataType"
            [adAccountId]="row?.adAccountId"
            ></cell-format-display-value>
        </div>
        <div class="size--6">{{additionalInfoData}}</div>
    </ng-template>
</ng-container>

<ng-template #displayEmpty>-</ng-template>



