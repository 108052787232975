import { MetricTypeEnum } from "../enums";

export const MetricTypes = [
    {
        id: MetricTypeEnum.GOOGLE_ANALYTICS,
        name: 'Google Analytics',
        icon: 'GoogleAnalyticsIcon',
    },
    {
        id: MetricTypeEnum.ADJUST,
        name: 'Adjust',
        icon: 'AdjustIcon'
    },
    {
        id: MetricTypeEnum.APPS_FLYER,
        name: 'AppsFlyer',
        icon: 'AppsFlyerIcon'
    }
]