export enum BillingEventEnum {
    APP_INSTALLS = 'APP_INSTALLS',
    CLICKS = 'CLICKS',
    IMPRESSIONS = 'IMPRESSIONS',
    LINK_CLICKS = 'LINK_CLICKS',
    OFFER_CLAIMS = 'OFFER_CLAIMS',
    PAGE_LIKES = 'PAGE_LIKES',
    POST_ENGAGEMENT = 'POST_ENGAGEMENT',
    VIDEO_VIEWS = 'VIDEO_VIEWS',
    TWO_SECOND_CONTINUOUS_VIDEO_VIEWS = 'TWO_SECOND_CONTINUOUS_VIDEO_VIEWS',
    COMPLETED_VIDEO_VIEWS = 'COMPLETED_VIDEO_VIEWS',
    VIDEO_VIEWS_15S = 'VIDEO_VIEWS_15S',
    NONE = 'NONE',
    THRUPLAY = 'THRUPLAY'
}
export enum OldBillingEventEnum {
    AppInstalls = 0,
    Clicks = 1,
    Impressions = 2,
    LinkClicks = 3,
    OfferClaims = 4,
    PageLikes = 5,
    PostEngagement = 6,
    VideoViews = 7,
    TwoSecondContinuousVideoViews = 8,
    CompletedVideoViews = 9,
    VideoViews15s = 10,
    None = 11,
    Thruplay = 12
}