import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { UserFormatPreferencesService } from '../../../../shared/services';
import { MetricDataType, MetricIntegrationType, MetricType } from '../../../../../api/models';
import { ColumnDisplayConf } from '../../../models';

@Component({
    selector: 'table-footer-summary-cell',
    templateUrl: './table-footer-summary-cell.component.html',
    styleUrls: ['../../metric-table/metric-table.component.scss', './table-footer-summary-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableFooterSummaryCellComponent implements OnInit, OnChanges {
    @Input() tableContainerHeight: number;
    @Input() column: ColumnDisplayConf; //TableColumn with ColumnDisplayConf data
    @Input() totalCount: number;
    @Input() summaryRow: any;
    @Input() isTotalCount: boolean = false;
    @Input() isSummary: boolean = false;
    @Input() isTotalCountLoading: boolean;
    @Input() isSummaryLoading: boolean;

    cellDisplayValue: any;
    MetricDataType = MetricDataType;
    MetricType = MetricType;
    MetricIntegrationType = MetricIntegrationType;
    isGoogleMetric: boolean = false;

    selectedTemlate: TemplateRef<any>;

    constructor(
        public format: UserFormatPreferencesService //
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (this.isSummary && this.summaryRow) {
            this.cellDisplayValue = this.summaryRow[this.column.key];
            this.isGoogleMetric = this.column.metricIntegrationType === MetricIntegrationType.Google || this.column.metricIntegrationType === MetricIntegrationType.GoogleAnalytics4 ;
            return;
        }
    }

    ngOnInit(): void {}
}
