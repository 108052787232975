import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
// import {  AccountProfile, Group, ConnectionType } from '@hunchads/angular-shared';
import { InsightsFilter } from 'apps/manage/src/app/api/models/api.models';
import { FiltersService } from '../../../../table-services';
import { get, InteroperabilityService, UserFormatPreferencesService } from '../../../../../shared/services';
import { PlatformEnum } from 'apps/manage/src/app/modules/shared';


@Component({
    selector: 'ad-account-filter',
    templateUrl: './ad-account-filter.component.html',
    styleUrls: ['../../table-filters.component.scss', '../table-filter-level.component.scss', './ad-account-filter.component.scss']
})
export class AdAccountFilterComponent implements OnInit {
    isAddAccountExpanded: boolean = false;
    adAccounts: any[];
    group: any;
    selectedAdAccountIds: string[] = [];
    platform: string = get('platform');

    reloadOptions: boolean = false;

    @Output() emitReloadData = new EventEmitter<any>();

    constructor(
        public userFormatPreferencesService: UserFormatPreferencesService, //
        public filtersService: FiltersService,
        private cd: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.setData();
    }

    setData() {
        this.group = get('user').accountId;
        this.adAccounts = !this.userFormatPreferencesService.platform ? this.userFormatPreferencesService.groupAdAccounts.filter(item => item.type === 3) : this.userFormatPreferencesService.groupAdAccounts;
        if (this.filtersService.adAccountInsigthsFilter) {
            this.selectedAdAccountIds = this.filtersService.adAccountInsigthsFilter.value;
        }
    }

    isChecked(adAccount: any): boolean {
        const profile_id = this.platform === PlatformEnum.SNAPCHAT ? adAccount.id : adAccount.adAccountId;
        if (!this.selectedAdAccountIds.length) return true;
        else return this.selectedAdAccountIds.indexOf(profile_id) > -1;
    }

    toggleOption(adAccount: any) {
        const profile_id = this.platform === PlatformEnum.SNAPCHAT ? adAccount.id : adAccount.adAccountId;
        if (!this.selectedAdAccountIds.length) {
            if (this.platform === PlatformEnum.SNAPCHAT) {
                let selectedIds = this.adAccounts.map(item => item.id).filter(item => item !== profile_id);
                selectedIds.forEach(item => this.selectedAdAccountIds.push(item));
            }
            else {
                let selectedIds = this.adAccounts.map(item => item.adAccountId).filter(item => item !== profile_id);
                selectedIds.forEach(item => this.selectedAdAccountIds.push(item));
            }

        } else {
            let index = this.selectedAdAccountIds.indexOf(profile_id);
            if (index > -1) this.selectedAdAccountIds.splice(index, 1);
            else this.selectedAdAccountIds.push(profile_id);
        }
        this.reloadOptions = true;
        this.setFilterState();
        setTimeout(() => {
            this.reloadOptions = false;
            this.cd.detectChanges();
            setTimeout(() => {
                this.emitReloadData.emit(true);
            }, 300);
        }, 0);
    }

    setFilterState() {
        if (!this.selectedAdAccountIds.length || this.selectedAdAccountIds.length === this.adAccounts.length) {
            delete this.filtersService.adAccountInsigthsFilter;
            this.setLocalStorageState();
        } else {
            this.filtersService.adAccountInsigthsFilter = new InsightsFilter({
                metricKey: 'adAccountId',
                operator: 9,
                value: this.selectedAdAccountIds
            });
            this.setLocalStorageState(this.filtersService.adAccountInsigthsFilter);
        }
    }

    setLocalStorageState(value?: any) {
        if (value) window.localStorage.setItem(`${this.group.id}-${this.group.name}`, JSON.stringify(value));
        else window.localStorage.removeItem(`${this.group.id}-${this.group.name}`);
    }
}
