import { HunchCampaignTypeEnum } from "../enums";

export const SnapchatCampaignTypes = [
            {
                id: HunchCampaignTypeEnum.STANDARD,
                name: 'Standard Campaign',
                icon: 'CampaignHunchStandardIcon',
            },
            {
                id: HunchCampaignTypeEnum.AUTOMATED,
                name: 'Automated Campaign',
                icon: 'CampaignHunchAutomatedIcon'
            }
]