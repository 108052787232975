import { FacebookPositionEnum, OldFacebookPositionEnum } from "../enums";

export const mapFacebookPosition: any = {
    [OldFacebookPositionEnum.feed]: FacebookPositionEnum.FEED,
    [OldFacebookPositionEnum.right_hand_column]: FacebookPositionEnum.RIGHT_HAND_COLUMN,
    [OldFacebookPositionEnum.instream_video]: FacebookPositionEnum.INSTREAM_VIDEO,
    [OldFacebookPositionEnum.video_feeds]: FacebookPositionEnum.VIDEO_FEEDS,
    [OldFacebookPositionEnum.marketplace]: FacebookPositionEnum.MARKETPLACE,
    [OldFacebookPositionEnum.story]: FacebookPositionEnum.STORY,
    [OldFacebookPositionEnum.search]: FacebookPositionEnum.SEARCH,
    // [OldFacebookPositionEnum.instant_article]: FacebookPositionEnum.FEED
}