import { ObjectiveEnum, ObjectiveEnumString } from "../enums";
import { OdaxObjectives } from "./odax-objectives.const";

export const Objectives: any[] = [
    {
        id: ObjectiveEnumString.CONVERSIONS,
        name: 'Conversions',
        icon: 'ObjectiveConversionIcon'
    },
    {
        id: ObjectiveEnumString.PRODUCT_CATALOG_SALES,
        name: 'Product Catalog Sales',
        icon: 'ObjectiveProductCatalogSalesIcon'
    },
    {
        id: ObjectiveEnumString.LINK_CLICKS,
        name: 'Link Clicks',
        icon: 'ObjectiveTrafficIcon'
    },
    {
        id: ObjectiveEnumString.LEAD_GENERATION,
        name: 'Lead Generation',
        icon: 'ObjectiveLeadGenerationIcon'
    },
    {
        id: ObjectiveEnumString.REACH,
        name: 'Reach',
        icon: 'ObjectiveReachIcon'
    },
    {
        id: ObjectiveEnumString.BRAND_AWARENESS,
        name: 'Brand Awareness',
        icon: 'ObjectiveBrandAwarenessIcon'
    },
    {
        id: ObjectiveEnumString.POST_ENGAGEMENT,
        name: 'Post Engagement',
        icon: 'ObjectivePostEngagementIcon'
    },
    {
        id: ObjectiveEnumString.APP_INSTALLS,
        name: 'App Install',
        icon: 'ObjectiveAppInstallIcon'
    },
    {
        id: ObjectiveEnumString.VIDEO_VIEWS,
        name: 'Video Views',
        icon: 'ObjectiveVideoViewsIcon'
    }
]