export enum CallToActionEnum {
    OPEN_LINK = 'OPEN_LINK',
    LIKE_PAGE = 'LIKE_PAGE',
    SHOP_NOW = 'SHOP_NOW',
    PLAY_GAME = 'PLAY_GAME',
    INSTALL_APP = 'INSTALL_APP',
    USE_APP = 'USE_APP',
    CALL = 'CALL',
    CALL_ME = 'CALL_ME',
    VIDEO_CALL = 'VIDEO_CALL',
    INSTALL_MOBILE_APP = 'INSTALL_MOBILE_APP',
    USE_MOBILE_APP = 'USE_MOBILE_APP',
    MOBILE_DOWNLOAD = 'MOBILE_DOWNLOAD',
    BOOK_TRAVEL = 'BOOK_TRAVEL',
    LISTEN_MUSIC = 'LISTEN_MUSIC',
    WATCH_VIDEO = 'WATCH_VIDEO',
    LEARN_MORE = 'LEARN_MORE',
    SIGN_UP = 'SIGN_UP',
    DOWNLOAD = 'DOWNLOAD',
    WATCH_MORE = 'WATCH_MORE',
    NO_BUTTON = 'NO_BUTTON',
    VISIT_PAGES_FEED = 'VISIT_PAGES_FEED',
    CALL_NOW = 'CALL_NOW',
    APPLY_NOW = 'APPLY_NOW',
    CONTACT = 'CONTACT',
    BUY_NOW = 'BUY_NOW',
    GET_OFFER = 'GET_OFFER',
    GET_OFFER_VIEW = 'GET_OFFER_VIEW',
    BUY_TICKETS = 'BUY_TICKETS',
    UPDATE_APP = 'UPDATE_APP',
    GET_DIRECTIONS = 'GET_DIRECTIONS',
    BUY = 'BUY',
    SEND_UPDATES = 'SEND_UPDATES',
    MESSAGE_PAGE = 'MESSAGE_PAGE',
    DONATE = 'DONATE',
    SUBSCRIBE = 'SUBSCRIBE',
    SAY_THANKS = 'SAY_THANKS',
    SELL_NOW = 'SELL_NOW',
    SHARE = 'SHARE',
    DONATE_NOW = 'DONATE_NOW',
    GET_QUOTE = 'GET_QUOTE',
    CONTACT_US = 'CONTACT_US',
    ORDER_NOW = 'ORDER_NOW',
    START_ORDER = 'START_ORDER',
    ADD_TO_CART = 'ADD_TO_CART',
    VIDEO_ANNOTATION = 'VIDEO_ANNOTATION',
    MOMENTS = 'MOMENTS',
    RECORD_NOW = 'RECORD_NOW',
    REFER_FRIENDS = 'REFER_FRIENDS',
    REQUEST_TIME = 'REQUEST_TIME',
    GET_SHOWTIMES = 'GET_SHOWTIMES',
    LISTEN_NOW = 'LISTEN_NOW',
    WOODHENGE_SUPPORT = 'WOODHENGE_SUPPORT',
    SOTTO_SUBSCRIBE = 'SOTTO_SUBSCRIBE',
    FOLLOW_USER = 'FOLLOW_USER',
    RAISE_MONEY = 'RAISE_MONEY',
    EVENT_RSVP = 'EVENT_RSVP',
    WHATSAPP_MESSAGE = 'WHATSAPP_MESSAGE',
    FOLLOW_NEWS_STORYLINE = 'FOLLOW_NEWS_STORYLINE',
    SEE_MORE = 'SEE_MORE',
    FIND_A_GROUP = 'FIND_A_GROUP',
    FIND_YOUR_GROUPS = 'FIND_YOUR_GROUPS',
    PAY_TO_ACCESS = 'PAY_TO_ACCESS',
    PURCHASE_GIFT_CARDS = 'PURCHASE_GIFT_CARDS',
    FOLLOW_PAGE = 'FOLLOW_PAGE',
    SEND_A_GIFT = 'SEND_A_GIFT',
    SWIPE_UP_SHOP = 'SWIPE_UP_SHOP',
    SWIPE_UP_PRODUCT = 'SWIPE_UP_PRODUCT',
    SEND_GIFT_MONEY = 'SEND_GIFT_MONEY',
    PLAY_GAME_ON_FACEBOOK = 'PLAY_GAME_ON_FACEBOOK',
    GET_STARTED = 'GET_STARTED',
    OPEN_INSTANT_APP = 'OPEN_INSTANT_APP',
    AUDIO_CALL = 'AUDIO_CALL',
    SEE_MENU = 'SEE_MENU',
    SAVE = 'SAVE',
    VIEW_INSTAGRAM_PROFILE = 'VIEW_INSTAGRAM_PROFILE',
    GET_PROMOTIONS = 'GET_PROMOTIONS',
    BOOK_NOW = 'BOOK_NOW'
}
export enum OldCallToActionTypeEnum {
    OPEN_LINK,
    LIKE_PAGE,
    SHOP_NOW,
    PLAY_GAME,
    INSTALL_APP,
    USE_APP,
    CALL,
    CALL_ME,
    INSTALL_MOBILE_APP,
    USE_MOBILE_APP,
    MOBILE_DOWNLOAD,
    BOOK_TRAVEL,
    LISTEN_MUSIC,
    WATCH_VIDEO,
    LEARN_MORE,
    SIGN_UP,
    DOWNLOAD,
    WATCH_MORE,
    NO_BUTTON,
    VISIT_PAGES_FEED,
    APPLY_NOW,
    BUY_NOW,
    GET_OFFER,
    GET_OFFER_VIEW,
    BUY_TICKETS,
    UPDATE_APP,
    GET_DIRECTIONS,
    BUY,
    MESSAGE_PAGE,
    DONATE,
    SUBSCRIBE,
    SAY_THANKS,
    SELL_NOW,
    SHARE,
    DONATE_NOW,
    GET_QUOTE,
    CONTACT_US,
    ORDER_NOW,
    ADD_TO_CART,
    VIDEO_ANNOTATION,
    MOMENTS,
    RECORD_NOW,
    GET_SHOWTIMES,
    LISTEN_NOW,
    EVENT_RSVP,
    WHATSAPP_MESSAGE,
    FOLLOW_NEWS_STORYLINE,
    VIEW_INSTAGRAM_PROFILE,
    VIDEO_CALL,
    CONTACT,
    START_ORDER,
    REFER_FRIENDS,
    REQUEST_TIME,
    WOODHENGE_SUPPORT,
    SOTTO_SUBSCRIBE,
    FOLLOW_USER,
    SEE_MORE,
    FIND_A_GROUP,
    FIND_YOUR_GROUPS,
    PAY_TO_ACCESS,
    PURCHASE_GIFT_CARDS,
    FOLLOW_PAGE,
    SEND_A_GIFT,
    SWIPE_UP_SHOP,
    SWIPE_UP_PRODUCT,
    SEE_MENU,
    CALL_NOW,
    SAVE
}