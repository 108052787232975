import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { get, NotifyService } from '../../modules/shared/services';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AdAccountSyncApiService {
    constructor(
        private httpClient: HttpClient, //
        private notifyService: NotifyService
    ) { }

    getAdAccountsForGroup(groupId: number): Observable<SyncStatusModel[]> {
        return this.httpClient.get<SyncStatusModel[]>(`$apiUri/manage/syncs-for-group/` + groupId).pipe(
            catchError(error => {
                this.notifyService.error('Sync status fetch failed!');
                return throwError('');
            })
        );
    }

    syncAdAccount(adAccountId: string): Observable<any> {
        return this.httpClient.post<SyncStatusModel[]>(`$apiUri/manage/start-static-sync/` + adAccountId, {}).pipe(
            catchError(error => {
                this.notifyService.error('Sync start sync failed!');
                return throwError('');
            })
        );
    }
}

export interface SyncStatusModel {
    asAccountId: string;
    adAccountName: string;
    initialSyncDone: boolean;
    isInProgress: boolean;
    errorMessage: string;

    lastMetricSyncEnd: Date | string;
    lastStaticSyncEnd: Date | string;
}
