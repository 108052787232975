import { Injectable } from '@angular/core';
import { InsightsApiService } from '../../../api/services';
import { ManageOverviewService } from '../../manage-overview/manage-overview.service';
import { CompareObjectsService } from './compare-objects.service';
import { DatePresetService } from './date-preset.service';
import { FiltersService } from './filters.service';
import { TableMainService } from './table-main.service';

@Injectable()
export class CampaignLevelService extends TableMainService {
    constructor(
        protected manageOverviewService: ManageOverviewService, //
        protected insightsApiService: InsightsApiService,
        protected datePresetService: DatePresetService,
        protected compareObjectsService: CompareObjectsService,
        protected filtersService: FiltersService
    ) {
        super(manageOverviewService, insightsApiService, datePresetService, compareObjectsService, filtersService);
    }
}
