import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
// import { HunchInputComponent } from '@hunchads/angular-shared';

@Component({
    selector: 'save-new-preset',
    templateUrl: './save-new-preset.component.html',
    styleUrls: ['../preset-columns-select.component.scss', './save-new-preset.component.scss']
})
export class SaveNewPresetComponent implements OnInit {
    presetName: FormControl = new FormControl('');
    isSaveAsNew: boolean = false;
    @Input() currentPresetName: string;
    @Input() isImmutablePreset: boolean = false;

    @Output() emitCancel = new EventEmitter<any>();
    @Output() emitSave = new EventEmitter<any>();
    @Output() emitUpdate = new EventEmitter<any>();

    @ViewChild('inputName', { static: false }) inputName: any;

    constructor() {}

    ngOnInit(): void {
        this.presetName.setValue(this.currentPresetName);
    }

    changeSaveType() {
        this.isSaveAsNew = !this.isSaveAsNew;
        if (this.isSaveAsNew) this.presetName.setValue('');
        else this.presetName.setValue(this.currentPresetName);
    }

    saveNewPreset() {
        if (this.presetName.value) {
            if (this.isSaveAsNew) this.emitSave.emit(this.presetName.value);
            else this.emitUpdate.emit(this.presetName.value);
        }
    }

    isConfirmDisabled(): boolean {
		return (this.isSaveAsNew && !this.presetName.value) || (!this.isSaveAsNew &&this.isImmutablePreset);
	}
}
