<div class="select-data-level-tabs">
    <div class="tab" [ngClass]="{'selected': selectedDataLevel === SelectedLevel.Campaign}"  (click)="changeSelectedLevel(SelectedLevel.Campaign)">
        <svg-icon name="CampaignIcon" alt=""></svg-icon>
        Campaigns
        <span class="notification-badge" *ngIf="campaignLevelSelectedItems?.length || selectedDraftIds?.length">
            {{campaignLevelSelectedItems.length + selectedDraftIds.length}} <span> selected</span>
            <a (click)="deselectLevel(SelectedLevel.Campaign, $event)" title="close">
                <svg-icon class="badge-close" name="CloseRemove" alt="" svgClass="close-icon"></svg-icon>
            </a>
        </span>
    </div>
    <div class="tab" [ngClass]="{'selected': selectedDataLevel === SelectedLevel.AdSet}" (click)="changeSelectedLevel(SelectedLevel.AdSet)">
        <svg-icon name="AdSetIcon" alt=""></svg-icon>
        Ad Sets
        <span class="notification-badge" *ngIf="adSetLevelSelectedItems.length">
            {{adSetLevelSelectedItems.length}} <span> selected</span>
            <a (click)="deselectLevel(SelectedLevel.AdSet,$event)" title="close">
                <svg-icon class="badge-close" name="CloseRemove" alt="" svgClass="close-icon"></svg-icon>
            </a>
        </span>

    </div>
    <div class="tab" [ngClass]="{'selected': selectedDataLevel === SelectedLevel.Ad}" (click)="changeSelectedLevel(SelectedLevel.Ad)">
        <svg-icon name="AdsIcon" alt=""></svg-icon>
        Ads
        <span class="notification-badge" *ngIf="adLevelSelectedItems.length">
            {{adLevelSelectedItems.length}} <span> selected</span>
            <a title="close" (click)="deselectLevel(SelectedLevel.Ad, $event)">
                <svg-icon class="badge-close" name="CloseRemove" alt="" svgClass="close-icon"></svg-icon>
            </a>
        </span>
    </div>

</div>