import { NgModule } from '@angular/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { LeftMenuModule } from '../left-menu/left-menu.module';
import { MetricTableModule } from '../metric-table/metric-table.module';

import { SharedModule } from '../shared/shared.module';
import { ManageOverviewComponent } from './manage-overview.component';
import { ReloadManageOverviewComponent } from './reload-manage-overview/reload.component';
import { LevelTabModule } from '../level-tab/level-tab.module';
import { ConnectCampaignModalComponent, EnrichAdModalComponent } from '../shared/components';



@NgModule({
  declarations: [
    ManageOverviewComponent,
    ReloadManageOverviewComponent,
    ConnectCampaignModalComponent, 
    EnrichAdModalComponent
  ],
  imports: [
    SharedModule,
    LeftMenuModule,
    LevelTabModule,
    MetricTableModule,
    AngularSvgIconModule
  ]
})
export class ManageOverviewModule { }
