import { InsightsQueryResponseObjectFE } from './api.models';
import { HunchCampaignType } from './enums';

export class FacebookCampaign extends InsightsQueryResponseObjectFE {
    constructor(value?: any) {
        super();
        if (value)
            for (const valueKey in value) {
                this[valueKey] = value[valueKey];
            }
    }

    public adAccountId: string;
    public facebookId: string;
    public lastDateSynced: Date;
    public hunchId: number;

    public automatedSyncEndTime: Date;
    public automatedSyncStatusProgress: number;
    public automatedSyncStatusSuccess: number;

    public lastFacebookInsightsDataSynced: Date;
    public hunchCampaignType: HunchCampaignType;
    public name: string;
    public status: string;
    public effectiveStatus: string;
    public objective: string;
    public spendCap: number;
    public createdTime: Date;
    public startTime: Date;
    public dailyBudget: number;
    public lifetimeBudget: number;
    public budgetRemaining: number;
    public bidStrategy: string;
    public usesCbo: boolean;
    public automatedCampaignSyncDisabled :boolean;
    public isCampaignSyncFailed: boolean;
}

export class FacebookAdSet extends InsightsQueryResponseObjectFE {
    constructor(value?: any) {
        super();
        if (value)
            for (const valueKey in value) {
                this[valueKey] = value[valueKey];
            }
    }

    public adAccountId: string;
    public facebookId: string;
    public facebookCampaignId: string;
    public lastDateSynced: string;
    public hunchId: number;
    public hunchCampaignType: HunchCampaignType;
    public name: string;
    public status: string;
    public effectiveStatus: string;
    public optimizationGoal: string;
    public billingEvent: string;
    public dailyBudget: number;
    public lifetimeBudget: number;
    public budgetRemaining: number;
    public bidAmount: number;
    public bidStrategy: string;
    public usesCbo: boolean;
    public createdTime: Date;
    public endTime: Date;
    public hunchCampaignId: number;
}

export class FacebookAd extends InsightsQueryResponseObjectFE {
    constructor(value?: any) {
        super();
        if (value)
            for (const valueKey in value) {
                this[valueKey] = value[valueKey];
            }
    }

    public adAccountId: string;
    public facebookId: string;
    public facebookCampaignId: string;
    public facebookAdSetId: string;
    public lastGoogleDataSynced: Date;
    public hunchId: number;
    public hunchCampaignType: HunchCampaignType;
    public utmId: string;
    public name: string;
    public status: string;
    public effectiveStatus: string;
    public createdTime: Date;
    public hunchCampaignId: number;
    public previewThumbnailURL:string;
}
