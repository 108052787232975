<div class="ng-option no-hover preset--footer" role="option" aria-selected="false" style="height: 39px;">
    <mat-checkbox class="mr--8" [checked]="isSaveAsNew" (change)="changeSaveType()">
        <span class="ng-option-label color--grey-6">Save As New Preset</span>
    </mat-checkbox>

    <button class="btn--solid btn--solid_grey btn--solid_icon mr--8 float-right preset-spec-icon" title="Cancel"
        style="margin-left: 108px;" (click)="emitCancel.emit()">
        <svg-icon id="svg-icon-close-1" name="CloseRemove" alt="" svgClass="single-icon">
        </svg-icon>
    </button>

    <button class="btn--solid btn--solid_green btn--solid_icon float-right preset-spec-icon" title="Confirm"
        [disabled]="isConfirmDisabled()" (click)="saveNewPreset()">
        <svg-icon id="svg-icon-checkmark-1" name="CheckMark" alt="" svgClass="single-icon" class="white"></svg-icon>
    </button>
</div>
<div class="ng-option no-hover" role="option" aria-selected="false" *ngIf="isSaveAsNew">
    <hunch-input #inputName [inputControl]="presetName" [placeholder]="'Type Preset Name'" [size]="'small'" >
    </hunch-input>
</div>