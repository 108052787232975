import { Component, OnInit } from "@angular/core";
import { SvgIconRegistryService } from "angular-svg-icon";
import {
  get,
  InteroperabilityService,
  MetaHubService,
  set,
  SnapchatHubService,
} from "./modules/shared/services";
import { UserInfo } from "@hunch-header";
import { Header } from '@hunchads/header';

export const ApplicationHeaderPlaceholderHeight: number = 48;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  showApp: boolean = true;
  title = "hunch-manage";
  user: any = get("user");
  accounts: any = get("accounts");
  ApplicationHeaderPlaceholderHeight = ApplicationHeaderPlaceholderHeight;
  hunchAppUrl: string = get("hunchAppUrl");
  hunchManageAppUrl: string = get("hunchManageAppUrl");
  hunchCatalogsAppUrl: string = get("hunchCatalogsAppUrl");
  constructor(
    private iconReg: SvgIconRegistryService, //
    private interoperabilityService: InteroperabilityService,
    private hub: SnapchatHubService,
    private metaHub: MetaHubService
  ) {
    setTimeout(() => {
      if (document.location.host === 'manage.hunchads.com') {
        (window as any).Intercom("boot", {
          app_id: "fqs4lt4u",
          name: `${this.user.firstName} ${this.user.lastName}`,
          email: this.user.email,
          user_id: this.user.id,
          user_hash: this.user.intercomVerificationToken,
          custom_launcher_selector:'#custom_intercom_launch'
        });
        (window as any).FS.identify(this.user.id, {
          displayName: `${this.user.firstName} ${this.user.lastName}`,
          email: this.user.email,
          teamId: this.user.teamId,
          team: this.user.teamName,
          isAdmin: this.user.isAdmin,
          user_hash: this.user.intercomVerificationToken
        });
      }
    }, 2000);

    setTimeout(() => {
      const header = new Header({
        container: document.getElementById('header-container')!,
        accounts: get("accounts"),
        selectedUser: get("user"),
        defaultLink: 'data-campaigns="true"',
        urls: {
          hunchManageAppUrl: this.hunchManageAppUrl,
          hunchCatalogsAppUrl: this.hunchCatalogsAppUrl,
          hunchAppUrl: this.hunchAppUrl,
        },
        onAccountChange: (accountId: string) => {
          set('user', {  ...get('user'), accountId:parseInt(accountId) });
          this.interoperabilityService.accountChanged.next(true);
        },
        onLogout: () => {
          console.log('Logout clicked');
        }
      });
    }, 0);

    this.hub.listenHubSubscriptions();
    this.metaHub.listenHubSubscriptions();
  }

  ngOnInit(): void {
    this.appendFonts();
    this.iconReg
      .loadSvg(`./assets/icons/Warning_2_Icon.svg`, "Warning 2 Icon")
      .subscribe();

    this.iconReg
      .loadSvg(`./assets/icons/Arrow_Down_S_Icon.svg`, "ArrowDownSIcon")
      .subscribe();
    this.iconReg
      .loadSvg(`./assets/icons/Arrow_Right_Icon.svg`, "ArrowRightIcon")
      .subscribe();
    this.iconReg
      .loadSvg(`./assets/icons/Close-Remove_L_Icon.svg`, "CloseRemove")
      .subscribe();
    this.iconReg
      .loadSvg(`./assets/icons/Filter_Icon.svg`, "FilterIcon")
      .subscribe();
    this.iconReg
      .loadSvg(`./assets/icons/Add_S_Icon.svg`, "AddIcon")
      .subscribe();
    this.iconReg.loadSvg(`./assets/icons/Edit_Icon.svg`, "Edit").subscribe();
    this.iconReg
      .loadSvg(`./assets/icons/Delete_Icon.svg`, "Delete")
      .subscribe();
    this.iconReg
      .loadSvg(`./assets/icons/Metrics_Icon.svg`, "Metrics")
      .subscribe();
    this.iconReg
      .loadSvg(`./assets/icons/Breakdown_Icon.svg`, "Breakdown")
      .subscribe();
    this.iconReg
      .loadSvg(`./assets/icons/Columns_Icon.svg`, "Columns")
      .subscribe();
    this.iconReg
      .loadSvg(`./assets/icons/Attribution_Icon.svg`, "Attribution")
      .subscribe();
    this.iconReg
      .loadSvg(`./assets/icons/Export_CSV_Icon.svg`, "ExportCSV")
      .subscribe();
    this.iconReg
      .loadSvg(`./assets/icons/Download_Icon.svg`, "Download")
      .subscribe();
    this.iconReg
      .loadSvg(`./assets/icons/Duplicate_Icon.svg`, "Duplicate")
      .subscribe();
    this.iconReg
      .loadSvg(`./assets/icons/Calendar_S_Icon.svg`, "CalendarS")
      .subscribe();
    this.iconReg
      .loadSvg(`./assets/icons/Disable_Sync_Icon.svg`, "DisableSync")
      .subscribe();
    this.iconReg
      .loadSvg(`./assets/icons/Search_Icon.svg`, "SearchIcon")
      .subscribe();
    this.iconReg.loadSvg(`./assets/icons/Collapse.svg`, "Collapse").subscribe();
    this.iconReg
      .loadSvg(`./assets/icons/Check_Mark_L_Icon.svg`, "CheckMark")
      .subscribe();
    this.iconReg.loadSvg(`./assets/icons/Refresh.svg`, "Refresh").subscribe();
    this.iconReg.loadSvg(`./assets/icons/Info_Icon.svg`, "Info").subscribe();

    this.iconReg
      .loadSvg(`./assets/icons/Campaign_Icon.svg`, "CampaignIcon")
      .subscribe();
    this.iconReg
      .loadSvg(`./assets/icons/Ad_Set_Icon.svg`, "AdSetIcon")
      .subscribe();
    this.iconReg.loadSvg(`./assets/icons/Ads_Icon.svg`, "AdsIcon").subscribe();

    this.iconReg
      .loadSvg(`./assets/icons/Facebook_Icon.svg`, "FacebookIcon")
      .subscribe();
    this.iconReg
      .loadSvg(`./assets/icons/Meta_Curve_Icon.svg`, "MetaCurveIcon")
      .subscribe();
    this.iconReg
      .loadSvg(`./assets/icons/Campaign_Type_Meta.svg`, "MetaIcon")
      .subscribe();
    this.iconReg
      .loadSvg(`./assets/icons/Meta_Inserted_Icon.svg`, "MetaInsertedIcon")
      .subscribe();
    this.iconReg
      .loadSvg(`./assets/icons/Insert_Icon.svg`, "InsertIcon")
      .subscribe();
    this.iconReg
      .loadSvg(`./assets/icons/Hunch_Icon.svg`, "HunchIcon")
      .subscribe();
    this.iconReg
      .loadSvg(
        `./assets/icons/Campaign_Type_Hunch_Icon.svg`,
        "CampaignHunchIcon"
      )
      .subscribe();
    this.iconReg
      .loadSvg(
        `./assets/icons/Campaign_Type_Hunch_Automated.svg`,
        "CampaignHunchAutomatedIcon"
      )
      .subscribe();
    this.iconReg
      .loadSvg(
        `./assets/icons/Campaign_Type_Hunch_Automated_Master.svg`,
        "CampaignHunchAutomatedMasterIcon"
      )
      .subscribe();
    this.iconReg
      .loadSvg(
        `./assets/icons/Campaign_Type_Hunch_Standard.svg`,
        "CampaignHunchStandardIcon"
      )
      .subscribe();
    this.iconReg
      .loadSvg(
        `./assets/icons/Campaign_Type_Hunch_PostBoosting.svg`,
        "CampaignHunchPostBoostingIcon"
      )
      .subscribe();
    this.iconReg
      .loadSvg(
        `./assets/icons/Campaign_Type_HunchSnapchat_Standard.svg`,
        "CampaignHunchSnapchatStandardIcon"
      )
      .subscribe();
    this.iconReg
      .loadSvg(`./assets/icons/Snapchat.svg`, "SnapchatIcon")
      .subscribe();
    this.iconReg
      .loadSvg(`./assets/icons/Snapchat_Only.svg`, "SnapchatOnlyIcon")
      .subscribe();
    this.iconReg
      .loadSvg(
        `./assets/icons/Metrics_Google_Analytics_Icon.svg`,
        "GoogleAnalyticsIcon"
      )
      .subscribe();
    this.iconReg
      .loadSvg(`./assets/icons/Metrics_AppsFlyer_Icon.svg`, "AppsFlyerIcon")
      .subscribe();
    this.iconReg
      .loadSvg(`./assets/icons/Metrics_Adjust_Icon.svg`, "AdjustIcon")
      .subscribe();
    this.iconReg
      .loadSvg(
        `./assets/icons/Objective_Engagement_Icon.svg`,
        "ObjectiveEngagementIcon"
      )
      .subscribe();
    this.iconReg
      .loadSvg(
        `./assets/icons/Objective_Awareness_Icon.svg`,
        "ObjectiveAwarenessIcon"
      )
      .subscribe();
    this.iconReg
      .loadSvg(
        `./assets/icons/Objective_Brand_Awareness_Icon.svg`,
        "ObjectiveBrandAwarenessIcon"
      )
      .subscribe();
    this.iconReg
      .loadSvg(
        `./assets/icons/Objective_Traffic_Icon.svg`,
        "ObjectiveTrafficIcon"
      )
      .subscribe();
    this.iconReg
      .loadSvg(`./assets/icons/Objective_Sales_Icon.svg`, "ObjectiveSalesIcon")
      .subscribe();
    this.iconReg
      .loadSvg(`./assets/icons/Objective_Leads_Icon.svg`, "ObjectiveLeadsIcon")
      .subscribe();
    this.iconReg
      .loadSvg(
        `./assets/icons/Objective_Lead_Generation_Icon.svg`,
        "ObjectiveLeadGenerationIcon"
      )
      .subscribe();
    this.iconReg
      .loadSvg(
        `./assets/icons/Objective_App_Install_Icon.svg`,
        "ObjectiveAppInstallIcon"
      )
      .subscribe();
    this.iconReg
      .loadSvg(
        `./assets/icons/Objective_Conversion_Icon.svg`,
        "ObjectiveConversionIcon"
      )
      .subscribe();
    this.iconReg
      .loadSvg(
        `./assets/icons/Objective_App_Promotion_Icon.svg`,
        "ObjectiveAppPromotionIcon"
      )
      .subscribe();
    this.iconReg
      .loadSvg(
        `./assets/icons/Objective_Post_Engagement_Icon.svg`,
        "ObjectivePostEngagementIcon"
      )
      .subscribe();
    this.iconReg
      .loadSvg(
        `./assets/icons/Objective_Product_Catalog_Sales_Icon.svg`,
        "ObjectiveProductCatalogSalesIcon"
      )
      .subscribe();
    this.iconReg
      .loadSvg(`./assets/icons/Objective_Reach_Icon.svg`, "ObjectiveReachIcon")
      .subscribe();
    this.iconReg
      .loadSvg(
        `./assets/icons/Objective_Video_Views_Icon.svg`,
        "ObjectiveVideoViewsIcon"
      )
      .subscribe();

    this.iconReg
      .loadSvg(`./assets/icons/Library_img_Icon.svg`, "ImgIcon")
      .subscribe();


    this.iconReg
      .loadSvg(`./assets/icons/Edit_New_Icon.svg`, "EditNewIcon")
      .subscribe();
    this.iconReg
      .loadSvg(`./assets/icons/Overview_Icon.svg`, "OverviewIcon")
      .subscribe();
    this.iconReg
      .loadSvg(`./assets/icons/View_Log_Icon.svg`, "ViewLogIcon")
      .subscribe();
    this.iconReg
      .loadSvg(`./assets/icons/Copy_Icon.svg`, "CopyIcon")
      .subscribe();

    // let htmlEl = document.getElementById('single-spa-application:manage');
    // htmlEl.className = 'new-app-hunch hunch-angular-module hunch-manage';
  }

  appendFonts() {
    var newStyle = document.createElement("style");
    newStyle.appendChild(
      document.createTextNode(`
				@font-face {
					font-family: 'Inter';
					src: url('./assets/fonts/Inter-SemiBold-slnt=0.ttf');
					font-weight: 600;
					font-style: normal;
				}
				@font-face {
					font-family: 'Inter';
					src: url('./assets/fonts/Inter-Regular-slnt=0.ttf');
					font-weight: 400;
					font-style: normal;
				}

				@font-face {
					font-family: 'Inter';
					src: url('./assets/fonts/Inter-Bold-slnt=0.ttf');
					font-weight: 700;
					font-style: normal;
				}

				@font-face {
					font-family: "slick";
					src: url('./assets/fonts/slick.eot');
					src: url('./assets/fonts/slick.eot?#iefix') format("embedded-opentype"), url('${get(
        "appUrl"
      )}/assets/fonts/slick.woff') format("woff"), url('${get(
        "appUrl"
      )}/assets/fonts/slick.ttf') format("truetype"), url('${get(
        "appUrl"
      )}/assets/fonts/slick.svg#slick') format("svg");
					font-weight: normal;
					font-style: normal;
				}

                @font-face {
                    font-family: 'hunch';
                    src:
                      url('./assets/fonts/hunch.ttf?hapq2w') format('truetype'),
                      url('./assets/fonts/hunch.woff?hapq2w') format('woff'),
                      url('./assets/fonts/hunch.svg?hapq2w#hunch') format('svg');
                    font-weight: normal;
                    font-style: normal;
                    font-display: block;
                  }

				`)
    );
    document.head.appendChild(newStyle);
  }

  accountChanged(user: UserInfo): void {
    set("user", user);
    this.interoperabilityService.accountChanged.next(true);
  }
}
