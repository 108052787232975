<div style="position: absolute; width: 0; height: 0;" *ngIf="isLoading">
    <div style="top: 1px; left: 1px; position: relative;" class="gradient"
        [ngStyle]="{'height': getElementHeight(tableContainer) - 1 + 'px', 'width': getElementWidth(tableContainer) - 1 + 'px'}">
    </div>
</div>

<div class="w--100 table-container-borders" #tableContainer>
    <ngx-datatable class="material hunch-datatable hunch-manage-table" style="height: 100%;" #table
        [ngClass]="{'ad-table': selectedDataLevel === CampaignStructureLevel.Ad}" [rows]="rows" [columns]="columns"
        [scrollbarV]="true" [headerHeight]="32" [rowHeight]="rowHeight" [scrollbarH]="true"
        [rowClass]="getRowClass.bind(this)" (activate)="onActivate($event)" [reorderable]="true" [swapColumns]="false"
        (reorder)="reorderedColumns($event)" [count]="rowsTotalCount" [externalPaging]="true" [offset]="pageNumber"
        (page)="loadPage($event)" (resize)="resizedColumn($event)" [externalSorting]="true" (sort)="columnSort($event)"
        [sorts]="sortField">
        <ng-container *ngFor="let column of columns; let index = index">
            <ngx-datatable-column [name]="column.name" [prop]="column.key" [sortable]="column.sortable"
                [resizeable]="column.resizable" [canAutoResize]="column.canAutoResize"
                [frozenLeft]="isFrozenLeft(column)" [minWidth]="column.minWidth" [width]="column.width"
                [draggable]="column.draggable">

                <ng-template ngx-datatable-header-template> <!-- let-column="column" is removed -->
                    <ng-container *ngIf="column">

                        <table-header-cell [column]="column" [selectedAll]="selectedAll"
                            [sortFieldProp]="sortField[0].prop" (emitSelectAll)="selectAll()"
                            (emitColumnSortCustom)="columnSortCustom($event)"></table-header-cell>

                        <table-footer-summary-cell *ngIf="column.key === 'name'; else summaryCell"
                            [tableContainerHeight]="tableContainerHeight" [column]="column"
                            [totalCount]="rowsTotalCount" [summaryRow]="summaryRowData" [isTotalCount]="true"
                            [isTotalCountLoading]="isTotalCountLoading"></table-footer-summary-cell>

                        <ng-template #summaryCell>
                            <table-footer-summary-cell [tableContainerHeight]="tableContainerHeight" [column]="column"
                                [summaryRow]="summaryRowData" [isSummary]="true"
                                [isSummaryLoading]="isSummaryLoading"></table-footer-summary-cell>
                        </ng-template>


                    </ng-container>
                </ng-template>

                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>

                    <ng-container *ngIf="row?.isLoadingItem; else tableCell">
                        <div class="w--100 h--100 truncate px--8 size--5 body-cell loading-cell"></div>
                    </ng-container>

                    <ng-template #tableCell>
                        <ng-container *ngIf="row">
                            <ng-container [ngSwitch]="column.key">

                                <ng-container *ngSwitchCase="'selectElement'">
                                    <div class="w--100 h--100 truncate pl--8 size--5 body-cell static-col flex-vertical-align">
                                        <mat-checkbox *ngIf="row?.facebookId"
                                            [checked]="selectedAll || selectedItemIds.indexOf(row.facebookId) > -1"
                                            (change)="toggleSelection(row)"></mat-checkbox>
                                        <mat-checkbox *ngIf="row?.isDraft"
                                            [checked]="selectedDraftIds.indexOf(row.id) > -1"
                                            (change)="toggleDraftSelection(row)"></mat-checkbox>
                                    </div>
                                </ng-container>

                                <ng-container *ngSwitchCase="'status'">
                                    <toggle-status-table-body-cell *ngIf="row.platform === 'Snapchat'"
                                        [selectedDataLevel]="selectedDataLevel"
                                        [changeStatus]="snapHandpickStatusChanges[row.internalId]" [row]="row"
                                        [column]="column">
                                    </toggle-status-table-body-cell>
                                    <toggle-status-table-body-cell *ngIf="row.platform === 'Meta'"
                                        [changeStatus]="metaHandpickStatusChanges[row.internalId]" [row]=" row"
                                        [selectedDataLevel]="selectedDataLevel" [row]="row" [column]="column">
                                    </toggle-status-table-body-cell>
                                    <toggle-status-table-body-cell *ngIf="!row.platform"
                                        [selectedDataLevel]="selectedDataLevel" [row]="row" [column]="column">
                                    </toggle-status-table-body-cell>
                                </ng-container>

                                <ng-container *ngSwitchCase="'automatedProgress'">
                                    <table-body-automated-progress-cell [selectedDataLevel]="selectedDataLevel"
                                        [row]="row" [column]="column">
                                    </table-body-automated-progress-cell>
                                </ng-container>

                                <ng-container *ngSwitchCase="'name'">
                                    <table-body-name-cell [row]="row" [column]="column" [columnKey]="column.key"
                                        [selectedDataLevel]="selectedDataLevel" [selectedItemIds]="selectedItemIds"
                                        (clearAllSelected)="clearSelectedItemsOnAllLevels($event)"
                                        (emitHoverAction)="cellAction = $event"></table-body-name-cell>
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    <table-body-cell [row]="row" [column]="column" [columnKey]="column.key">
                                    </table-body-cell>
                                </ng-container>

                            </ng-container>
                        </ng-container>
                    </ng-template>

                </ng-template>

            </ngx-datatable-column>
        </ng-container>
    </ngx-datatable>
</div>

<div #scrollerTableElement class="w--100 table-scroller cursor--pointer" *ngIf="showHorizontalScroll; else dummyScroll"
    (scroll)="onHorizontalScroll($event)">
    <div [ngStyle]="{'width': getTableWidth()}"></div>
</div>
<ng-template #dummyScroll>
    <div class="w--100 table-scroller cursor--pointer" (scroll)="onHorizontalScroll($event)">
        <div style="width: 150%;"></div>
    </div>
</ng-template>


<table-hover-cell-action-handler [action]="cellAction" (emitDeleteDraft)="emitDeleteDraft.emit($event)">
</table-hover-cell-action-handler>