export enum CustomEventTypeEnum {
    RATE = 'RATE',
    TUTORIAL_COMPLETION = 'TUTORIAL_COMPLETION',
    ADD_TO_CART = 'ADD_TO_CART',
    ADD_TO_WISHLIST = 'ADD_TO_WISHLIST',
    INITIATED_CHECKOUT = 'INITIATED_CHECKOUT',
    ADD_PAYMENT_INFO = 'ADD_PAYMENT_INFO',
    PURCHASE = 'PURCHASE',
    LEAD = 'LEAD',
    COMPLETE_REGISTRATION = 'COMPLETE_REGISTRATION',
    CONTENT_VIEW = 'CONTENT_VIEW',
    SEARCH = 'SEARCH',
    SERVICE_BOOKING_REQUEST = 'SERVICE_BOOKING_REQUEST',
    MESSAGING_CONVERSATION_STARTED_7D = 'MESSAGING_CONVERSATION_STARTED_7D',
    LEVEL_ACHIEVED = 'LEVEL_ACHIEVED',
    ACHIEVEMENT_UNLOCKED = 'ACHIEVEMENT_UNLOCKED',
    SPENT_CREDITS = 'SPENT_CREDITS',
    OTHER = 'OTHER',
    SUBSCRIBE = 'SUBSCRIBE',
    SUBMIT_APPLICATION = 'SUBMIT_APPLICATION',
    CUSTOMIZE_PRODUCT = 'CUSTOMIZE_PRODUCT',
    START_TRIAL = 'START_TRIAL',
    CONTACT = 'CONTACT',
    SCHEDULE = 'SCHEDULE',
    DONATE = 'DONATE'
}
export enum OldCustomEventTypeEnum {
    Rate = 0,
    TutorialCompletion = 1,
    AddToCart = 2,
    AddToWishlist = 3,
    InitiatedCheckout = 4,
    AddPaymentInfo = 5,
    Purchase = 6,
    Lead = 7,
    CompleteRegistration = 8,
    ContentView = 9,
    Search = 10,
    ServiceBookingRequest = 11,
    MessagingConversationStarted7d = 12,
    LevelAchieved = 13,
    AchievementUnlocked = 14,
    SpentCredits = 15,
    Other = 16,
    StartTrial = 17,
    CONTACT = 18
}