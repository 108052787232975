export enum MetricIntegrationType {
    None = 0,
    Facebook = 10,
    Google = 20,
    Snapchat = 30,
    Hunch = 1000,
    GoogleAnalytics4 = 40,
    AppsFlyer = 50,
}

export enum Operator {
    Sum = 10,
    Divide = 20,
    Average = 30,
    Max = 40
}

export enum MetricType {
    Static = 10,
    Metric = 20,
    Compound = 30
}

export enum MetricDataType {
    Hidden = 0,
    Boolean = 1,
    //
    String = 10,
    Integer = 20,
    Decimal = 30,
    Date = 40,
    Percent = 50,
    Currency = 60,
    Enum = 70,
    Seconds = 80
}

export enum CampaignStructureLevel {
    None = 0,
    Campaign = 10,
    AdSet = 20,
    Ad = 30
}

export enum FilterOperator {
    Equals = 1,
    NotEquals = 2,
    Contains = 3,
    DoesNotContain = 4,
    GreaterThan = 5,
    LessThan = 6,
    GreaterThanOrEquals = 7,
    LessThanOrEquals = 8,
    In = 9
}

export enum HunchCampaignType {
    Facebook = 1,
    HunchStandardDraft = 100,
    HunchStandard = 101,
    AutomatedDraft = 200,
    Automated = 201,
    PostBoosting = 202
}


export enum ProgressBarStatus {
    Rendering = 1,
    SendingToFacebook = 2,
    CampaignCreated = 3,
    split
}

export enum AutomatedCampaignSyncSuccess {
    Successfully = 1,
    SemiSuccessfully = 2,
    AllErrors = 3
}
