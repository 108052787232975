import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import { ColumnDisplayConf, EntityStatus } from '../../../models';
import { ActionResponceObject, TableActionsApiService } from '../../../../../api/services';
import { InsightsQueryResponseObjectFE, PatchFacebookCampaignFromManageCommand, CampaignStructureLevel, HunchCampaignType } from '../../../../../api/models';
import { HandpickChangeBody } from '../../../../shared/models';
import { NotifyService, createGuid, get, isIdGuid } from '../../../../shared/services';
import { HandpickChangeField, HandpickChangeStatus, ObjectType, ObjectTypeEnum, PlatformEnum } from '../../../../shared';
import { CampaignType } from '../../../../shared/enums/campaign-type.enum';

@Component({
    selector: 'toggle-status-table-body-cell',
    templateUrl: './toggle-status-table-body-cell.component.html',
    styleUrls: ['../../metric-table/metric-table.component.scss', './toggle-status-table-body-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToggleStatusTableBodyCellComponent implements OnInit, OnChanges {
    @Input() selectedDataLevel: CampaignStructureLevel;
    @Input() row: InsightsQueryResponseObjectFE;
    @Input() column: ColumnDisplayConf; //TableColumn with ColumnDisplayConf data
    @Input() set changeStatus(value: HandpickChangeStatus) {
        if (value) this.checkHandpickChanges(value);
    }
    EntityStatus = EntityStatus;

    isDraft: boolean = false;
    masterStatusDisabled: boolean = false;
    showNotification: boolean = true

    constructor(
        private cd: ChangeDetectorRef, //
        private tableActionsApiService: TableActionsApiService,
        private notifyService: NotifyService
    ) { }

    ngOnInit(): void {
        if (get('unpublishedHandpickChanges')) this.checkUnpublishedHandpickChanges();
    }

    ngOnChanges() {
        this.isDraft = false;
        this.masterStatusDisabled = false;
        if (this.row?.hunchCampaignType) {
            if (this.row.hunchCampaignType === HunchCampaignType.AutomatedDraft || this.row.hunchCampaignType === HunchCampaignType.HunchStandardDraft) {
                this.isDraft = true;
            } else if (this.row['masterStatusDisabled'] === true) this.masterStatusDisabled = true;
        };
    }

    toggleStatus() {
        this.row['isStatusChanging'] = true;
        if (this.row.platform === PlatformEnum.SNAPCHAT) {
            const body: HandpickChangeBody = this.createHandpickRequestObject();
            this.tableActionsApiService.handpickChangesSnap(body);
        }
        else if (this.row.platform === PlatformEnum.META && isIdGuid(this.row.internalId)) {
            const body: HandpickChangeBody = this.createHandpickRequestObject();
            this.tableActionsApiService.handpickChangesMeta(body);
        }
        else {
            let actionData = new PatchFacebookCampaignFromManageCommand();
            actionData.level = this.selectedDataLevel;
            actionData.manageRowItems.push({
                adAccountId: this.row.adAccountId,
                facebookId: this.row.facebookId,
                hunchCampaignType: this.row.hunchCampaignType
            });
            actionData.status = this.row.status === EntityStatus.Paused;

            this.tableActionsApiService.changeStatus(actionData).subscribe(
                (data: ActionResponceObject) => {
                    if (data?.successfullyUpdated?.length) {
                        if (this.row.status === EntityStatus.Active) {
                            this.row.status = EntityStatus.Paused;
                        } else if (this.row.status === EntityStatus.Paused) {
                            this.row.status = EntityStatus.Active;
                        }
                    }
                    delete this.row['isStatusChanging'];
                    this.cd.detectChanges();
                },
                () => {
                    delete this.row['isStatusChanging'];
                    this.cd.detectChanges();
                }
            );
        }
    }

    createHandpickRequestObject(): HandpickChangeBody {
        const requestId: string = createGuid();
        const campaignId: string = this.row.isCampaign ? this.row.internalId : this.row.internalCampaignId;
        const objectType: ObjectType = this.row.isCampaign ? ObjectTypeEnum.CAMPAIGN : this.row.isAdset ? ObjectTypeEnum.ADSET : ObjectTypeEnum.AD;
        const campaignType: string = (this.row.hunchCampaignType === HunchCampaignType.Automated && this.row.platform === PlatformEnum.SNAPCHAT) ? CampaignType.AUTOMATED : CampaignType.STANDARD;
        const value: string = this.row.status === EntityStatus.Active ? EntityStatus.Paused : EntityStatus.Active;
        const accountId: number = get('user').accountId;
        let body: HandpickChangeBody = {
            requestId,
            accountId,
            email: get('user').email,
            handpickChanges: [{
                campaignId,
                campaignType,
                objectId: this.row.internalId,
                objectType,
                fieldName: HandpickChangeField.STATUS,
                value,
                adAccountId: this.row.adAccountId,
                isAutomated: this.row.hunchCampaignType === HunchCampaignType.Automated
            }],
        }
        return body;

    }

    checkUnpublishedHandpickChanges(): void {
        if (this.row.handpickUnpublished && this.row.handpickUnpublished.status === HandpickChangeStatus.PENDING && this.row.handpickUnpublished.fieldName === HandpickChangeField.STATUS) this.row['isStatusChanging'] = true;
        if (this.row.handpickUnpublished && this.row.handpickUnpublished.status === HandpickChangeStatus.ERROR && this.row.handpickUnpublished.fieldName === HandpickChangeField.STATUS && this.showNotification) {
            this.showNotification = false;
            this.notifyService.error(`Change status failed for ${this.row.name}`);
        }
        if (!this.row.handpickUnpublished) delete this.row['isStatusChanging'];
    }

    checkHandpickChanges(message: any): void {
        if (message.Status === HandpickChangeStatus.PUBLISHED) {
            if (this.row?.status === EntityStatus.Active) {
                this.row.status = EntityStatus.Paused;
            } else if (this.row?.status === EntityStatus.Paused) {
                this.row.status = EntityStatus.Active;
            }
        }
        else if (message.Status === HandpickChangeStatus.ERROR) this.notifyService.error(`Change status failed${this.row.name}`);
        if (this.row) delete this.row['isStatusChanging'];
        this.cd.detectChanges();

    }
}