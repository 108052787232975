import { HunchCampaignTypeEnum } from "../enums";

export const MetaCampaignTypes = [
    {
        id: HunchCampaignTypeEnum.HUNCH,
        name: 'Hunch Campaigns',
        icon:'CampaignHunchIcon',
        child:[
            {
                id: HunchCampaignTypeEnum.HUNCH,
                name: 'Standard Campaign',
                icon: 'CampaignHunchStandardIcon',
                additionalId: HunchCampaignTypeEnum.STANDARD
            },
            {
                id: HunchCampaignTypeEnum.HUNCH,
                name: 'Connected Campaign',
                icon: 'MetaInsertedIcon',
                additionalId: HunchCampaignTypeEnum.CONNECTED
            },
            {
                id: HunchCampaignTypeEnum.HUNCH,
                name: 'Automated Campaign',
                icon: 'CampaignHunchAutomatedIcon',
                additionalId: HunchCampaignTypeEnum.AUTOMATED
            }
        ]
    },
    {
        id: HunchCampaignTypeEnum.META,
        name: 'Meta Campaign',
        icon:'MetaIcon'
    },
]