<!-- <button class="btn--solid btn--solid_grey btn--solid_icon mr--8 inline-block  v-a--top" [disabled]="false" (click)="startSyncAccount()" title="Sync Ad Account">
    <svg-icon class="mr--8" name="Refresh" alt="" svgClass="single-icon"></svg-icon>
    <span style="font-weight: 400 !important; position: relative; top: -2px;">Sync Ad Account</span>
</button> -->

<ng-container *ngIf="isComponentVisisble">
    <ng-select #ngSelect style="width: 155px;"
        class="select--dark select--manage-1 main-dp sync-dropdown bg--grey-1 size--5 font--weight_semi ml--32 inline-block v-a--top"
        placeholder="Sync Ad Account" [searchable]="false" [loading]="isLoadingAdAccountsData"
        [disabled]="!adAccounts.length" [items]="adAccounts" bindLabel="adAccountName" bindValue="asAccountId"
        [ngModel]="initVal" dropdownPosition="auto" (ngModelChange)="syncAccount($event)">

        <ng-template ng-label-tmp let-item="item">
            <ng-container *ngIf="areSomeAdAccountsSyncing(); else showMainStatus">
                <div class="ellipsis-shrinked">
                    <ng-container *ngTemplateOutlet="loader"></ng-container>
                </div>
            </ng-container>
            <span class="ng-value-label mr--8">Sync Ad Account</span>
        </ng-template>

        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <div class="inline-block v-a--top w--100 size--5" style="font-weight: 400 !important;">
                <div class="inline-block v-a--top truncate account-name-holder ln-hg--25" title="{{item.adAccountName}}"
                    [ngClass]="{'color-green-3': item.isInProgress}">
                    {{item.adAccountName}}
                </div>
                <div class="inline-block v-a--top float-right sync-btn" >
                    <ng-container
                        *ngTemplateOutlet="item.isInProgress === true ? loader : syncStatusItem; context: {item: item}">
                    </ng-container>
                </div>

                <div class="inline-block v-a--top float-right sync-holder ln-hg--25" *ngIf="!item.isInProgress">
                    <span>Last Sync: Static <ng-container [ngTemplateOutlet]="showDate"
                            [ngTemplateOutletContext]="{date:item.lastStaticSyncEnd}"></ng-container>
                        / Metric <ng-container [ngTemplateOutlet]="showDate"
                            [ngTemplateOutletContext]="{date:item.lastMetricSyncEnd}"></ng-container>
                    </span>

                    <ng-template #showDate let-date="date">
                        <span *ngIf="!date; else displayTimeValue"> No info </span>
                        <ng-template #displayTimeValue>
                            <span *ngIf="!isTodaysDate(date); else showTime;">{{date | date:'dd.MM.yy'}}</span>
                        </ng-template>

                        <ng-template #showTime>{{date | date:'hh:mm'}}</ng-template>
                    </ng-template>

                </div>
            </div>

        </ng-template>

    </ng-select>
</ng-container>




<ng-template #showMainStatus>
    <svg-icon class="ng-value-img" svgClass="single-icon" name="Refresh" alt=""
        *ngIf="hasUnsyncedAdAccount(); else hiddenIcon"></svg-icon>
</ng-template>

<ng-template #hiddenIcon>
    <svg-icon class="ng-value-img" svgClass="single-icon hidden-icon" name="Refresh" alt=""></svg-icon>
</ng-template>


<ng-template #loader>
    <div class="lds-ellipsis spec">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</ng-template>


<ng-template #syncStatusItem let-item="item">
    <svg-icon *ngIf="!item.initialSyncDone" name="Refresh" alt="" svgClass="single-icon"></svg-icon>
    <svg-icon *ngIf="item.initialSyncDone" name="Refresh" alt="" svgClass="single-icon hidden-icon"></svg-icon>
</ng-template>
