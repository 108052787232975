import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-multi-checkbox-select',
  templateUrl: './multi-checkbox-select.component.html',
  styleUrls: ['./multi-checkbox-select.component.scss']
})
export class MultiCheckboxSelectComponent {
  options: any = [];
  model: any = {};
  value: any = [];
  @Input() placeholder: string;
  @Input() defaultText: string;
  @Input() minwidth: any;
  @Input() width: any;
  @Input() set setOptions(value: any) {
    this.model = {};
    this.options = value;
    this.setUpModel(this.options);
  };
  @Output() modelChanged: EventEmitter<any> = new EventEmitter();
  debouncer: Subject<any> = new Subject<string>();
  constructor(){
    this.debouncer
    .pipe(debounceTime(100))
    .subscribe((value) => this.modelChanged.emit(value));
  }

  optionsChanged(item: any): void {
    if (this.model[item.id].child && !item.additionalId) {
      const value: boolean = this.model[item.id].checked;
      this.model[item.id].checkedIndeterminate = false;
      for (const property in this.model[item.id].child) {
        this.model[item.id].child[property].checked = value;
      }
    }
    else if (item.additionalId) {
      this.model[item.id].checkedIndeterminate = Object.keys(this.model[item.id].child).some((k: any) => this.model[item.id].child[k].checked);
      this.model[item.id].checked = Object.keys(this.model[item.id].child).every((k: any) => this.model[item.id].child[k].checked);
      if (this.model[item.id].checkedIndeterminate && !this.value.find((i: any) => i.id === item.id)) {
        this.value = [this.options.find((i: any) => i.id === item.id), ...this.value];
      }
    }
    this.debouncer.next(this.model);
  }

  setUpModel(options: any): void {
    options.forEach((o: any) => {
      this.model[o.id] = {
        checked: false
      }
      if (o.child) {
        this.model[o.id] = {
          ...this.model[o.id],
          child: {},
          checkedIndeterminate: false
        }
        o.child.forEach((c: any) => {
          this.model[o.id].child[c.additionalId] = { checked: false }
        });
      }
    });
  }

}
