import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { InsightsQueryResponseObjectFE, CampaignStructureLevel, HunchCampaignType, FacebookCampaign } from '../../../../../api/models';
import { LeftMenuService } from '../../../../left-menu/left-menu.service';
import { ColumnDisplayConf, TableCellNameActions } from '../../../models';
import { FiltersService } from '../../../table-services';
import { PlatformEnum } from '../../../../shared';
import { createGuid, get } from '../../../../shared/services';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'table-body-name-cell',
    templateUrl: './table-body-name-cell.component.html',
    styleUrls: ['../../metric-table/metric-table.component.scss', './table-body-name-cell.component.scss']
})
export class TableBodyNameCellComponent implements OnInit, OnChanges {
    @Input() row: InsightsQueryResponseObjectFE;
    @Input() column: ColumnDisplayConf; //TableColumn with ColumnDisplayConf data
    @Input() columnKey: string;
    @Input() selectedDataLevel: CampaignStructureLevel;
    @Input() selectedItemIds: string[];
    @Output('clearAllSelected') clearAllLevelsAllSelectedItemIds = new EventEmitter<any>();
    @Output() emitHoverAction = new EventEmitter<TableCellNameAction>();
    @Output() emitConnectFullCampaign = new EventEmitter<InsightsQueryResponseObjectFE>();

    cellDisplayValue: any;
    cellDisplayValueInfo: any;

    HunchCampaignType = HunchCampaignType;
    CampaignStructureLevel = CampaignStructureLevel;

    isFacebook: boolean;
    isHunch: boolean;
    isHunchAutomatedAds: boolean;

    hasDelete: boolean;
    hasEdit: boolean;
    hasDuplicate: boolean;
    hasLog: boolean;
    hasOverview: boolean;
    hasDisconnect: boolean;
    isAlreadyDisconnected: boolean;
    isCampaignSyncFailed: boolean;
    isDraft: boolean;
    isAd: boolean;
    hasSharebleLink: boolean;

    additionalClass: string = '';
    public platformEnum: any = PlatformEnum;
    connectFullCampaignAllow: boolean;
    masterCampaign: boolean;

    constructor(
        private filterService: FiltersService, //
        public cd: ChangeDetectorRef,
        private httpClient: HttpClient
    ) { }

    ngOnInit(): void {
        this.connectFullCampaignAllow = JSON.parse(get('connectFullCampaignAllow')).includes(get('user').teamId) || JSON.parse(get('connectFullCampaignAllow')).length === 0;
        this.masterCampaign = this.row.name.includes('{{item.');
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.row) {
            if (this.row.hunchCampaignType) {
                if (this.row.hunchCampaignType === HunchCampaignType.AutomatedDraft || this.row.hunchCampaignType === HunchCampaignType.HunchStandardDraft) {
                    this.isDraft = true;
                }
            }
            this.cellDisplayValue = this.row[this.columnKey];
            this.cellDisplayValueInfo = this.setCellDisplayValueInfo(this.row);
            if (!this.cellDisplayValue) this.cellDisplayValue = '-';
            this.setAvailableActions();
            this.isFacebook = this.row.hunchCampaignType === HunchCampaignType.Facebook;
            this.isAd = this.row.isAd;
            if (this.row.isCampaign) this.row.isCampaignSyncFailed = this.row.isCampaignSyncFailed;
        }
    }

    clickOnName() {
        if (!this.isDraft) {
            if (this.selectedDataLevel !== CampaignStructureLevel.Ad) {
                this.clearAllLevelsAllSelectedItemIds.emit(this.selectedDataLevel === CampaignStructureLevel.Campaign ? null : this.selectedDataLevel);
                this.selectedItemIds.push(this.row.facebookId);

                if (this.selectedDataLevel === CampaignStructureLevel.Campaign) LeftMenuService.leftMenuLevelChange$.next(CampaignStructureLevel.AdSet);
                else LeftMenuService.leftMenuLevelChange$.next(CampaignStructureLevel.Ad);
            }
        }
    }

    setCellDisplayValueInfo(row: InsightsQueryResponseObjectFE): string {
        const campaignType: string = row.connected ? 'connected' : (row.hunchCampaignType === HunchCampaignType.HunchStandard || row.hunchCampaignType === HunchCampaignType.HunchStandardDraft ? 'standard' : (row.hunchCampaignType === HunchCampaignType.Automated || row.hunchCampaignType === HunchCampaignType.AutomatedDraft) ? 'automated' : '');
        const isDraft: boolean = row.hunchCampaignType === HunchCampaignType.HunchStandardDraft || row.hunchCampaignType === HunchCampaignType.AutomatedDraft;
        return `Hunch ${campaignType} campaign - ${isDraft ? 'Draft' : (row.facebookId ? 'Published' : row.name.includes('{{item.') ? 'Automated parent campaign' : 'Unpublished')}`;
    }

    openAutomatedCampaignLog() {
        let actionInfo: TableCellNameAction = {
            actionType: TableCellNameActions.Log,
            pageRow: this.row
        };
        this.emitHoverAction.emit(actionInfo);
    }

    openLog(row: InsightsQueryResponseObjectFE): void {
        if (row.platform === PlatformEnum.META) {
            const urlConnect: string = `${get('metaStandardAppUrl')}/meta/connect/${row.adAccountId}/${row.internalId}/logs`;
            let urlStandard: string = `${get('metaStandardAppUrl')}/meta/standard/${row.internalId}/logs`
            const masterCampaignId: string = row.masterCampaignId;
            if (masterCampaignId) urlStandard = urlStandard + `?masterCampaignId=${masterCampaignId}`;
            location.href = row.connected ? urlConnect : urlStandard;
        } else {
            this.openAutomatedCampaignLog();
        }
    }

    onClickPreview(metaId: string): void {
        this.httpClient.get<any>(`$hunchMetaCampaignEditorApi/meta/ad/${this.row.adAccountId}/${metaId}/snapshot`)
            .subscribe(
                {
                    next: (resp: any) => {
                        const link = resp.preview_shareable_link;
                        if (link) window.open(link, '_blank');
                    },
                    error: (err: any) => {
                        console.log(err);
                    }
                })
    }

    openOverview(row: InsightsQueryResponseObjectFE): void {
        let urlOverviewStandard = `${`${get('metaStandardAppUrl')}/meta/standard/${row.masterCampaignId ? row.masterCampaignId : (row.internalCampaignId ? row.internalCampaignId : row.internalId)}/overview`}`;
        const urlOverviewConnect = `${`${get('metaStandardAppUrl')}/meta/connect/${row.adAccountId}/${row.internalCampaignId ? row.internalCampaignId : row.internalId}/overview`}`;
        location.href = row.connected ? urlOverviewConnect : urlOverviewStandard;
    }

    showDisconnectSyncAutomatedCampaignModal() {
        if (this.row.isCampaign && !(this.row as FacebookCampaign).automatedCampaignSyncDisabled) {
            let actionInfo: TableCellNameAction = {
                actionType: TableCellNameActions.Disconnect,
                pageRow: this.row,
                componentReference: this
            };
            this.emitHoverAction.emit(actionInfo);
        }
    }

    showDupliateModal() {
        let actionInfo: TableCellNameAction = {
            actionType: TableCellNameActions.Duplicate,
            pageRow: this.row
        };
        this.emitHoverAction.emit(actionInfo);
    }

    editCampaign() {
        let actionInfo: TableCellNameAction = {
            actionType: TableCellNameActions.Edit,
            pageRow: this.row
        };
        this.emitHoverAction.emit(actionInfo);
    }

    deleteDraft() {
        let actionInfo: TableCellNameAction = {
            actionType: TableCellNameActions.Delete,
            pageRow: this.row
        };
        this.emitHoverAction.emit(actionInfo);
    }

    setAvailableActions() {
        this.hasDelete = false;
        this.hasEdit = false;
        this.hasDuplicate = false;
        this.hasLog = false;
        this.hasOverview = false;
        this.hasDisconnect = false;
        this.hasSharebleLink = false;
        this.isAlreadyDisconnected = false;
        this.additionalClass = '';

        switch (this.selectedDataLevel) {
            case CampaignStructureLevel.Campaign:
                this.hasSharebleLink = false;
                if (this.isDraft) {
                    this.hasEdit = true;
                    this.hasDuplicate = this.row.platform === PlatformEnum.META ? true : false;
                    this.hasLog = this.row.platform === PlatformEnum.META && this.row.facebookId && !this.row.connected && !this.masterCampaign ? true : false;
                    this.hasOverview = this.row.platform === PlatformEnum.META && this.row.facebookId && !this.row.connected ? true : false;
                    this.hasDelete = this.row.platform === PlatformEnum.META && this.row.facebookId ? false : true;
                    this.additionalClass = 'three-actions';
                } else if (this.row.hunchCampaignType === HunchCampaignType.HunchStandard) {
                    this.hasEdit = true;
                    this.hasDuplicate = true;
                    this.additionalClass = 'two-actions';
                    this.hasLog = this.row.platform === PlatformEnum.META && !this.row.connected && !this.masterCampaign ? true : false;
                    this.hasOverview = this.row.platform === PlatformEnum.META && !this.row.connected ? true : false;
                } else if (
                    this.row.hunchCampaignType === HunchCampaignType.Automated || //
                    this.row.hunchCampaignType === HunchCampaignType.PostBoosting
                ) {
                    this.hasEdit = true;
                    this.hasDuplicate = this.row.platform === PlatformEnum.META ? true : false;
                    this.hasLog = !this.row.connected && !this.masterCampaign ? true : false;
                    this.hasOverview = this.row.platform === PlatformEnum.META && !this.row.connected ? true : false;
                    this.hasDisconnect = true;
                    if ((this.row as FacebookCampaign).automatedCampaignSyncDisabled) this.isAlreadyDisconnected = true;
                }
                break;
            case CampaignStructureLevel.AdSet:
            case CampaignStructureLevel.Ad:
                if (this.row.hunchCampaignType === HunchCampaignType.HunchStandard) {
                    this.hasEdit = true;
                    if (this.row.platform === PlatformEnum.META && this.row.facebookId) {
                        this.hasSharebleLink = true;
                        this.additionalClass = 'two-actions';
                    }
                    else this.additionalClass = 'one-actions';
                } else {
                    if (this.row.platform === PlatformEnum.META && this.row.facebookId) {
                        this.hasSharebleLink = true;
                        this.additionalClass = 'one-actions';
                    }
                    else this.additionalClass = 'no-actions';
                }

                break;
            default:
                break;
        }
    }

    async connectFullCampaign(): Promise<void> {
        let headers = new HttpHeaders()
        headers = headers.set('token', get('azureTokenMeta'));
        const standardCampaign = await firstValueFrom(this.httpClient.post<any>(`$buildPendingDocumentUrl`, {
            TeamId: get('user').teamId,
            MetaCampaignId: this.row.facebookId,//'120208631751030702'
            RequestId: createGuid(),
            AccountId: get('user').accountId
        }, { headers }));

        let body: any = {
            standardCampaign,
            requestId: createGuid(),
            accountId: `${get('user')?.accountId}`,
            email: `${get('user')?.email}`,
            campaignId: `${standardCampaign.campaign.id}`,
            catalogId: standardCampaign.campaign.general.catalog_id ? standardCampaign.campaign.general.catalog_id : null,
            adAccountId: standardCampaign.campaign.setup.ad_account_id,
            automatedCampaign: false
        };
        // console.log(body);

        const draftConnect = await firstValueFrom(this.httpClient.post<any>(`$hunchMetaCampaignEditorApi/standard-campaign-editor/draft/connect`, body));
        console.log(draftConnect ? 'success' : 'error');
    }
}

export class TableCellNameAction {
    actionType: TableCellNameActions;
    pageRow: InsightsQueryResponseObjectFE;
    componentReference?: TableBodyNameCellComponent;
}
