
export enum GeoLocationTypeEnum {
    COUNTRY = 'country',
    COUNTRY_GROUP = 'country_group',
    REGION = 'region',
    CITY = 'city',
    ZIP = 'zip',
    GEO_MARKET = 'geo_market',
    ELECTORAL_DISTRICT = 'electoral_district',
    CUSTOM_LOCATION = 'custom_location',
    NEIGHBORHOOD = 'neighborhood',
    MEDIUM_GEO_AREA = 'medium_geo_area',
    SUBCITY = 'subcity',
    PLACE = 'place',
    METRO_AREA = 'metro_area',
    SMALL_GEO_AREA = 'small_geo_area',
    SUB_NEIGHBORHOOD = 'subneighborhood'
}
export enum OldGeoLocationType {
    country = 0,
    country_group = 1,
    region = 2,
    city = 3,
    zip = 4,
    geo_market = 5,
    electoral_district = 6,
    custom_location = 7,
    neighborhood = 8,
    medium_geo_area = 9,
    subcity = 10,
    place = 11,
    metro_area = 12,
    small_geo_area = 13,
    subneighborhood = 14
}