<div class="hunch-modal-wrapper appear">

    <div class="hunch-modal-container">
        <div class="hunch-modal-header mt--20">
            <h2>Insert Ad</h2>
        </div>
        <div class="hunch-modal-body">
            <p>Insert Hunch Ads to the Advantage+ Shopping Campaign you made on Meta.
                Hunch will connect your Meta campaign when you insert an ad. </p>

            <!-- error message -->
            <div class="alert-error mb--20" *ngIf="error">
                <p><svg-icon name="Warning 2 Icon" alt="" svgClass="table-icon hunch-icon--post-boosting"
                        title="Error occured"></svg-icon> This is error message explaining error</p>
            </div>

            <h4><label for="available_campaigns">Available campaigns</label></h4>

            <ng-select #ngSelect id="available_campaigns"
                class="select--manage-1 filter-select main-dp bg--grey-1 font--weight_semi mr--8 inline-block v-a--top v3"
                placeholder="Select Campaign..." [searchable]="true" bindLabel="Name" bindValue="MetaId"
                [loading]="loadingCampaign" groupBy="type" [items]="campaignForConnect" [(ngModel)]="metaId">
                <ng-template ng-label-tmp let-item="item" let-index="index">
                    <div class="two-rows-value">
                        <div class="name">
                            {{item.Name}}
                            <!-- <span class="additional-info">
                                Page ID: {{item.Id}}
                            </span> -->
                        </div>
                    </div>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-index="index">
                    <div>{{item.Name}}
                        <!-- Campaign name with moderate char num -->
                        <span class="small campaign-info" *ngIf="!item.InternalId">
                            Meta Campaign
                        </span>
                        <span class="small campaign-info" *ngIf="item.InternalId">
                            Connected Campaign - {{item.AdInserted}} Ad{{item.AdInserted>1?'s':''}} inserted
                        </span>
                    </div>
                </ng-template>
            </ng-select>


            <div class="info-helpers mt--10 mb--20">
                <div class="helper" (click)="expand($event)">
                    <span class="helper-subject">
                        What can I use this feature for?
                    </span>
                    <span class="ng-arrow"></span>
                    <div class="helper-text">
                        You can insert ad in case that you don't want to create a competing Advantage+ Shopping
                        Campaign, but enrich the existing one with Hunch creatives instead. <br /> The best way you can employ
                        ad insertion is to replicate your best performing ad and enrich it with dynamic texts and more
                        appealing creative.
                    </div>
                </div>
                <div class="helper" (click)="expand($event)">
                    <span class="helper-subject">
                        What is the difference between Create Ad and Insert Ad?
                    </span>
                    <span class="ng-arrow"></span>
                    <div class="helper-text">
                        The only difference between Create and Insert is the destination of the ad. <br />Insert will create a
                        new ad inside an existing campaign that was created on Meta. <br />Create will create a new ad inside
                        the campaign that was created on Hunch.
                    </div>
                </div>
            </div>

            <a href="https://help.hunchads.com/en/articles/8182038-beta-insert-ad-to-meta-native-campaigns"
                class="helpcentar-article" target="_blank">Learn more about Insert Ad feature</a>

        </div>


        <div class="hunch-modal-footer d-flex-modal justify-content-between">


            <button class="btn--solid btn--solid-medium btn--solid_grey btn--solid_v3 btn--solid-medium light-text"
                (click)="closeModal()">Cancel
            </button>

            <button class="btn--solid btn--solid_green btn--solid_v3 btn--solid-medium" title="" [disabled]="!metaId"
                (click)="connectCampaign()">Insert Ad
            </button>
        </div>
        <!-- modal loader -->

        <div class="v-a--top inline-block edit-selection-loader" *ngIf="loading">
            <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>

    </div>
</div>