import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { EmptyTableComponent, MetricTableComponent, MetricTableContainerComponent, TableActionsComponent, TableBodyCellsComponent, TableFiltersComponent, TableSearchComponent } from './components';
import { AdAccountSyncOptionComponent, DeleteBatchActionComponent, EditSelectionComponent, MetricToggleButtonsComponent, PresetEditorComponent } from './components/table-actions';
import { PresetColumnsSelectComponent, PresetSelectComponent } from './components/table-actions/preset-editor';
import { SaveNewPresetComponent } from './components/table-actions/preset-editor/preset-columns-select/save-new-preset/save-new-preset.component';
import { CellFormatCompoundDisplayValueComponent, CellFormatDisplayValueComponent, TableBodyAutomatedProgressCellComponent, TableBodyNameCellComponent, TableFooterSummaryCellComponent, TableHeaderCellComponent, TableHoverCellActionHandlerComponent, ToggleStatusTableBodyCellComponent } from './components/table-body-cells';
import { DuplicateCampaignModalComponent } from './components/table-body-cells/table-hover-cell-action-handler';
import { DateRangeComponent, TableFilterLevelComponent } from './components/table-filters';
import { AdAccountFilterComponent, TableFilterLevelCreateModalComponent } from './components/table-filters/table-filter-level';
import { EnumParsePipe } from './enum-parse.pipe';

@NgModule({
  declarations: [
    MetricTableComponent,
    TableFiltersComponent,
    TableActionsComponent,
    TableBodyCellsComponent,
    PresetEditorComponent,
    ToggleStatusTableBodyCellComponent,
    TableBodyAutomatedProgressCellComponent,
    EnumParsePipe,
    DateRangeComponent,
    TableFooterSummaryCellComponent,
    TableBodyNameCellComponent,
    EditSelectionComponent,
    TableHeaderCellComponent,
    MetricToggleButtonsComponent,
    PresetColumnsSelectComponent,
    PresetSelectComponent,
    SaveNewPresetComponent,
    EmptyTableComponent,
    TableHoverCellActionHandlerComponent,
    DuplicateCampaignModalComponent,
    TableFilterLevelComponent,
    MetricTableContainerComponent,
    CellFormatDisplayValueComponent,
    CellFormatCompoundDisplayValueComponent,
    DeleteBatchActionComponent,
    AdAccountFilterComponent,
    TableFilterLevelCreateModalComponent,
    AdAccountSyncOptionComponent,
    TableSearchComponent
  ],
  imports: [
    SharedModule
  ],
  exports:[
    MetricTableComponent,
    TableFiltersComponent,
    TableActionsComponent,
    TableSearchComponent,
    MetricTableContainerComponent
  ]
})
export class MetricTableModule { }
