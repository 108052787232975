import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { ManageOverviewModule } from './modules/manage-overview/manage-overview.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { CookieInterceptor } from './modules/shared/interceptors';
import { HunchHeaderModule } from '@hunch-header'
import { AuthService, ConfigService, TokenInterceptorService } from './modules/shared/services';
import { HunchIconModule } from '@hunchads/ui-hunch';

export function loadConfig(configService: ConfigService, authService: AuthService) {
    return async (): Promise<any> => {
        await configService.loadConfig();
        await authService.loadIdentityInfo();
        await authService.loadAzureToken();
        return await configService.loadHubData();
    }
}

@NgModule({
    declarations: [
        AppComponent, //
        EmptyRouteComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HunchHeaderModule,
        AppRoutingModule, //
        HttpClientModule,
        ManageOverviewModule,
        AngularSvgIconModule.forRoot(),
        HunchIconModule
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: CookieInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
        { provide: APP_INITIALIZER, useFactory: loadConfig, deps: [ConfigService, AuthService], multi: true },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
