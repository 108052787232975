<ng-select #ngSelect
    class="select--dark select--manage-1 filter-select main-dp bg--grey-1 size--5 font--weight_semi inline-block v-a--top"
    placeholder="Filters" [searchable]="false"
    [items]="[{id:1,name:'a', disabled:true, selected:true}]" bindLabel="name" bindValue="id" [closeOnSelect]="false"
    [(ngModel)]="ngSelected" (close)="onCloseFilterDropdown()"
    [ngStyle]="{'width': (insightsFilters?.length && !expandFilters)? '120px' : '111px'}">

    <ng-template ng-label-tmp let-item="item">
        <svg-icon class="ng-value-img" svgClass="filter-icon" name="AddIcon" alt=""></svg-icon>
        <span class="ng-value-label">Filters</span>
    </ng-template>

    <ng-template ng-option-tmp let-item="item" let-index="index">
        <div style="width: 336px; max-height: 326px;">
            <ng-container [ngTemplateOutlet]="displayOptions">
            </ng-container>
        </div>
    </ng-template>

</ng-select>
<hunch-icon-pop-notification *ngIf="insightsFilters?.length && !expandFilters" [left]="-52" [top]="5" [value]="insightsFilters.length">
</hunch-icon-pop-notification>

<div class="filter-container inline-block ml--8" *ngIf="insightsFilters?.length" [ngStyle]="{'max-width': expandFilters ? 'calc(100% - 140px)' : 'calc(100% - 140px)'}">
    <div class="filter-array inline-block truncate" [ngClass]="{'expanded': expandFilters}">
        <div>
            <ng-container *ngFor="let filter of insightsFilters">
                <div class="filter-item px--8">
                    <div class="filter-item-delete cursor--pointer" (click)="removeFilter(filter)">
                        <svg-icon name="CloseRemove" alt="" svgClass="left-menu-icon close-icon"></svg-icon>
                    </div>
                    <div class="filter-item-name size--5">{{filter['displayName']}}</div>
                </div>
            </ng-container>
        </div>

    </div>
</div>
<div *ngIf="insightsFilters?.length" class="arrows inline-block cursor--pointer mt--8" (click)="toggleShowFilters()">
    <div class="icon-box inline-block" *ngIf="!expandFilters">
        <svg-icon name="Collapse" alt="" svgClass="left-menu-icon r--180"></svg-icon>
    </div>
    <div class="icon-box inline-block" *ngIf="expandFilters">
        <svg-icon name="Collapse" alt="" svgClass="left-menu-icon"></svg-icon>
    </div>
</div>

<div class="filter-option" *ngIf="selectedFilter">
    <table-filter-level-create-modal
    [selectedFilter]="selectedFilter"
    [operatorOptions]="operatorOptions"
    [insightsFilter]="insightsFilter"
    [enumOptions]="enumOptions"
    (addFilter)="addFilter()"
    (clearSelectedFilter)="clearSelectedFilter($event)"
    ></table-filter-level-create-modal>
</div>

<ng-template #displayOptions>
    <!-- <div class="ng-option" role="option" aria-selected="false" [ngClass]="{'ng-option-selected': isOptionSelected(0)}"
        (click)="changeMainFilter(0)">
        <div class="w--100 d--flex justify--content_space-between align--items_center">

            <span class="ng-option-label color--grey-6" title="Hunch Campaigns">
                <mat-radio-button class="mr--4 font--weight_semi" [checked]="isOptionSelected(0)"
                    (change)="changeMainFilter(0)">
                    Hunch Campaigns
                </mat-radio-button>
            </span>
        </div>
    </div>

    <div class="ng-option" role="option" aria-selected="false" [ngClass]="{'ng-option-selected': isOptionSelected(1)}"
        (click)="changeMainFilter(1)">
        <div class="w--100 d--flex justify--content_space-between align--items_center">

            <span class="ng-option-label color--grey-6" title="Automated Ads - Only">
                <mat-radio-button class="mr--4 font--weight_semi" [checked]="isOptionSelected(1)"
                    (change)="changeMainFilter(1)">
                    Automated Ads - Only
                </mat-radio-button>
            </span>
        </div>
    </div>

    <div class="ng-option" role="option" aria-selected="false" [ngClass]="{'ng-option-selected': isOptionSelected(2)}"
        (click)="changeMainFilter(2)">
        <div class="w--100 d--flex justify--content_space-between align--items_center">

            <span class="ng-option-label color--grey-6" title="All Campaigns">
                <mat-radio-button class="mr--4 font--weight_semi" [checked]="isOptionSelected(2)"
                    (change)="changeMainFilter(2)">
                    All Campaigns
                </mat-radio-button>
            </span>
        </div>
    </div> -->

    <div class="ng-option disabled no-hover border-bottom-0" role="option" aria-selected="false" style="padding-top: 8px !important;">
        <div class="w--100 d--flex justify--content_space-between align--items_center ">
            <hunch-input class="search" [inputControl]="filterControl" [placeholder]="'Search...'" [icon]="'SearchIcon'"
                [svg]="true" [size]="'small'">
            </hunch-input>
        </div>
    </div>

    <div class="ng-option disabled no-hover border-bottom-0" role="option" aria-selected="false">
        <div class="w--100 d--flex justify--content_space-between align--items_center no-hover">
            <span class="ng-option-label group-label">Filter by</span>
        </div>
    </div>

    <div style="max-height: 181px; overflow-y: auto;">
        <ng-container *ngIf="!filteredSearchItems; else filterResults" [ngTemplateOutlet]="selectOptions">
        </ng-container>
    </div>


</ng-template>



<ng-template #selectOptions>

    <ad-account-filter (emitReloadData)="reloadTableData()"></ad-account-filter>


    <div class="ng-option" role="option" aria-selected="false" (click)="isCampaignExpanded = !isCampaignExpanded">
        <div class="w--100 d--flex justify--content_space-between align--items_center ">
            <span class="ng-option-label color--grey-6 font--weight_semi"
                [ngClass]="{'color--green-2': isCampaignExpanded}" data-cy="filterCampaign">Campaign</span>
            <div class="icon-box inline-block mr--8">
                <svg-icon name="ArrowDownSIcon" alt="" svgClass="left-menu-icon expandable"
                    [ngClass]="{'expanded': isCampaignExpanded}"></svg-icon>
            </div>
        </div>
    </div>

    <ng-container *ngIf="isCampaignExpanded">
        <ng-container *ngFor="let filterItem of campaign">
            <div class="ng-option" role="option" aria-selected="false">
                <div class="w--100 d--flex justify--content_space-between align--items_center"
                    (click)="createFilter(filterItem, CampaignStructureLevel.Campaign)">
                    <span class="ng-option-label color--grey-6 font--weight_regular">{{filterItem.name}} (Campaign)</span>
                </div>
            </div>
        </ng-container>
    </ng-container>

    <div class="ng-option" role="option" aria-selected="false" (click)="isAdSetExpanded = !isAdSetExpanded">
        <div class="w--100 d--flex justify--content_space-between align--items_center ">
            <span class="ng-option-label color--grey-6 font--weight_semi"
                [ngClass]="{'color--green-2': isAdSetExpanded}" data-cy="filterAdset">Ad Set</span>
            <div class="icon-box inline-block mr--8">
                <svg-icon name="ArrowDownSIcon" alt="" svgClass="left-menu-icon expandable"
                    [ngClass]="{'expanded': isAdSetExpanded}"></svg-icon>
            </div>
        </div>
    </div>

    <ng-container *ngIf="isAdSetExpanded">
        <ng-container *ngFor="let filterItem of adSet">
            <div class="ng-option" role="option" aria-selected="false">
                <div class="w--100 d--flex justify--content_space-between align--items_center"
                    (click)="createFilter(filterItem, CampaignStructureLevel.AdSet)">
                    <span class="ng-option-label color--grey-6 font--weight_regular">{{filterItem.name}} (Ad Set)</span>
                </div>
            </div>
        </ng-container>
    </ng-container>

    <div class="ng-option" role="option" aria-selected="false" (click)="isAdsExpanded = !isAdsExpanded">
        <div class="w--100 d--flex justify--content_space-between align--items_center ">
            <span class="ng-option-label color--grey-6 font--weight_semi"
                [ngClass]="{'color--green-2': isAdsExpanded}" data-cy="filterAd">Ads</span>
            <div class="icon-box inline-block mr--8">
                <svg-icon name="ArrowDownSIcon" alt="" svgClass="left-menu-icon expandable"
                    [ngClass]="{'expanded': isAdsExpanded}"></svg-icon>
            </div>
        </div>
    </div>

    <ng-container *ngIf="isAdsExpanded">
        <ng-container *ngFor="let filterItem of ads">
            <div class="ng-option" role="option" aria-selected="false">
                <div class="w--100 d--flex justify--content_space-between align--items_center"
                    (click)="createFilter(filterItem, CampaignStructureLevel.Ad)">
                    <span class="ng-option-label color--grey-6 font--weight_regular">{{filterItem.name}} (Ad)</span>
                </div>
            </div>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #filterResults>
    <div *ngIf="!filteredSearchItems.length" class="ng-option" role="option" aria-selected="false">
        <div class="w--100 d--flex justify--content_space-between align--items_center ">
            <span class="ng-option-label color--grey-6 ">No search results</span>
        </div>
    </div>

    <ng-container *ngIf="filteredSearchItems.length">
        <ng-container *ngFor="let filterItem of filteredSearchItems">
            <div class="ng-option" role="option" aria-selected="false">
                <div class="w--100 d--flex justify--content_space-between align--items_center"
                    (click)="createFilter(filterItem, filterItem.campaignStructureLevels[0])">
                    <span class="ng-option-label color--grey-6 font--weight_regular"
                        title="{{filterItem.name}}">{{filterItem.name}}</span>
                </div>
            </div>
        </ng-container>
    </ng-container>

</ng-template>