import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { BrowserModule } from '@angular/platform-browser';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { HunchButtonComponent, HunchInputModule, HunchMainLoaderModule, HunchModalComponent, MultiCheckboxSelectComponent } from './components';
import { IconPopNotificationComponent } from './icon-pop-notification/icon-pop-notification.component';
import player from 'lottie-web';
import { MatRippleModule } from '@angular/material/core';
import { EnrichAdModalComponent } from './components/enrich-ad-modal/enrich-ad-modal.component';

export function playerFactory() {
    return player;
}

@NgModule({
    imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        MatRadioModule,
        MatSlideToggleModule,
        MatRippleModule,
        MatCheckboxModule,
        MatIconModule,
        BsDatepickerModule,
        NgxDatatableModule,
        AngularSvgIconModule,
        TooltipModule,
        HunchInputModule,
        HunchMainLoaderModule
    ],
    exports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        MatRadioModule,
        MatSlideToggleModule,
        MatCheckboxModule,
        MatIconModule,
        BsDatepickerModule,
        NgxDatatableModule,
        AngularSvgIconModule,
        IconPopNotificationComponent,
        TooltipModule,
        HunchInputModule,
        HunchMainLoaderModule,
        HunchModalComponent,
        HunchButtonComponent,
        MultiCheckboxSelectComponent
    ],
    declarations: [
        IconPopNotificationComponent,
        HunchModalComponent,
        HunchButtonComponent,
        MultiCheckboxSelectComponent,
    ]
})
export class SharedModule { }
