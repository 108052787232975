import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'empty-table',
    templateUrl: './empty-table.component.html',
    styleUrls: ['../metric-table/metric-table.component.scss', './empty-table.component.scss']
})
export class EmptyTableComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}

    public getElementHeight(element: HTMLElement): number {
        if (!element) return 0;
        let retVal = element.getBoundingClientRect().height;
        return retVal;
    }

    public getElementWidth(element: HTMLElement): number {
        if (!element) return 0;
        let retVal = element.getBoundingClientRect().width;
        return retVal;
    }
}
