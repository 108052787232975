import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';

@Component({
    selector: 'hunch-icon-pop-notification',
    templateUrl: './icon-pop-notification.component.html',
    styleUrls: ['./icon-pop-notification.component.scss']
})
export class IconPopNotificationComponent implements OnInit {
    @Input() left: number;
    @Input() right: number;
    @Input() top: number;
    @Input() bottom: number;

    @Input() value: number;
    @Input() additionalText: string;
    @Input() additionalTextWidth: number;

    @Input() additionalContent: TemplateRef<any>;
    @Output() clickOnBadge = new EventEmitter<any>();

    constructor() {}

    ngOnInit(): void {}
}
