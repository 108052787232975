import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FiltersService } from '../../metric-table/table-services/filters.service';
import { ManageOverviewService } from '../manage-overview.service';

@Component({
    selector: 'app-reload',
    templateUrl: './reload.component.html',
    styleUrls: ['./reload.component.scss']
})
export class ReloadManageOverviewComponent implements OnInit {
    constructor(
        private router: Router, //
        private route: ActivatedRoute,
        private manageOverviewService: ManageOverviewService,
        public filtersService: FiltersService
    ) {}

    ngOnInit(): void {
        let queryParams: QueryParams = {};

        let params: QueryParams= this.manageOverviewService.cachedParams.pop();
        if (params) {
            if (params.from && params.from.indexOf('NaN') === -1) queryParams.from = params.from;
            if (params.to && params.to.indexOf('NaN') === -1) queryParams.to = params.from;
            if (params.platform && params.platform.indexOf('NaN') === -1) queryParams.platform = params.platform;
        }

        this.filtersService.clearInsigtsFilters();
        setTimeout(() => {
            this.router.navigate(['.'], {
                relativeTo: this.route,
                queryParams
            });
        }, 400);
    }
}

export interface QueryParams {
    from?: string;
    to?: string;
    platform?:string;
}
