import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { get, NotifyService } from '../../modules/shared/services';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CampaignStructureLevel, FacebookAd, FacebookAdSet, FacebookCampaign, GetFacebookInsightsCountQuery, GetFacebookInsightsQuery, GetFacebookInsightsSummaryQuery, InsightsQueryResponseObject, InsightsQueryResponseObjectFE } from '../models';
import { UnpublishedHandpickResponse } from '../../modules/shared/models';


@Injectable({
    providedIn: 'root'
})
export class InsightsApiService {
    constructor(
        private httpClient: HttpClient, //
        private notifyService: NotifyService
    ) { }

    getInsights(queryFilter: GetFacebookInsightsQuery): Observable<InsightsQueryResponseObjectFE[]> {
        let filter: any = new GetFacebookInsightsQuery(queryFilter);
        filter['groupId'] = get('user').accountId;
        if (queryFilter.skip === undefined) filter.page = (filter.page - 1) * filter.resultsPerPage; else filter.page = queryFilter.skip;
        filter.skip = filter.page;
        delete filter.page;
        return this.httpClient.post<InsightsQueryResponseObject[]>(`$apiUri/manage/insights`, filter).pipe(
            map(x =>
                (x as Array<any>).map(item => {
                    let retVal;
                    if (queryFilter.campaignStructureLevel === CampaignStructureLevel.Campaign) {
                        retVal = new FacebookCampaign(item);
                        retVal.isCampaign = true;
                    } else if (queryFilter.campaignStructureLevel === CampaignStructureLevel.AdSet) {
                        retVal = new FacebookAdSet(item);
                        retVal.isAdset = true;
                    } else if (queryFilter.campaignStructureLevel === CampaignStructureLevel.Ad) {
                        retVal = new FacebookAd(item);
                        retVal.isAd = true;
                    }
                    let unpublishedHandpickChanges: UnpublishedHandpickResponse[] = get('unpublishedHandpickChanges');
                    if (unpublishedHandpickChanges) {
                        const handpickChange = unpublishedHandpickChanges.find((hp) => hp?.objectId?.includes(item.internalId));
                        if (handpickChange) retVal.handpickUnpublished = { fieldName: handpickChange.fieldName, status: handpickChange.status }
                    }
                    return retVal;
                })
            ),
            catchError(error => {
                this.notifyService.error('Fetch Insights failed');
                return throwError('');
            })
        );
    }

    getCount(queryFilter: GetFacebookInsightsCountQuery): Observable<{ count: number }[]> {
        let filter: any = new GetFacebookInsightsCountQuery(queryFilter);
        filter['groupId'] = get('user').accountId;
        return this.httpClient.post<{ count: number }[]>(`$apiUri/manage/insights-count`, filter).pipe(
            catchError(error => {
                this.notifyService.error('Fetch Total Count failed');
                return throwError('');
            })
        );
    }

    getSummary(queryFilter: GetFacebookInsightsSummaryQuery): Observable<any[]> {
        let filter: any = new GetFacebookInsightsSummaryQuery(queryFilter);
        filter['groupId'] = get('user').accountId;
        return this.httpClient.post<any[]>(`$apiUri/manage/insights-summary`, filter).pipe(
            catchError(error => {
                this.notifyService.error('Fetch Summary Row failed');
                return throwError('');
            })
        );
    }

    getDrafts(): Observable<InsightsQueryResponseObject[]> {
        return this.httpClient.get<InsightsQueryResponseObject[]>(`$apiUri/manage/${get('user').accountId}/drafts`).pipe(
            catchError(error => {
                this.notifyService.error('Fetch Drafts failed');
                return throwError('');
            })
        );
    }

    deleteAutomatedDraft(draftId: string) {
        return this.httpClient.delete(`$hunchAppUrl/api/AutomatedCampaigns/draft/${draftId}`).pipe(
            catchError(error => {
                this.notifyService.error('Delete Hunch Automated Draft failed');
                return throwError('');
            })
        );
    }

    deleteHunchStandardDraft(profileID: string, campaignID: string, profileFBID: string) {
        return this.httpClient.get(`$hunchAppUrl/api/facebookcampaigns/discard-pending-changes?profileID=${profileID}&campaignID=${campaignID}&profileFBID=${profileFBID}`).pipe(
            catchError(error => {
                this.notifyService.error('Delete Hunch Standard Draft failed');
                return throwError('');
            })
        );
    }

    deleteHunchMetaStandardDraft(campaignId: string) {
        let headers = new HttpHeaders()
        headers = headers.set('token', get('azureTokenMeta'));
        return this.httpClient.post(`$discardDraftUrl`, { campaignId }, { headers }).pipe(
            catchError(error => {
                this.notifyService.error('Delete Hunch Standard Draft failed');
                return throwError('');
            })
        );
    }
}
