import { Injectable } from '@angular/core';
import { BasicQuery, InsightsFilter,CampaignStructureLevel, FilterOperator } from '../../../api/models';
import { InsightsApiService } from '../../../api/services';
import { ManageOverviewService } from '../../manage-overview/manage-overview.service';
import { AdSetLevelService } from './adset-level.service';
import { CampaignLevelService } from './campaign-level.service';
import { CompareObjectsService } from './compare-objects.service';
import { DatePresetService } from './date-preset.service';
import { FiltersService } from './filters.service';
import { TableMainService } from './table-main.service';

@Injectable()
export class AdsLevelService extends TableMainService {
    constructor(
        protected manageOverviewService: ManageOverviewService, //
        protected insightsApiService: InsightsApiService,
        protected datePresetService: DatePresetService,
        protected compareObjectsService: CompareObjectsService,
        protected filtersService: FiltersService,
        private adSetLevelService: AdSetLevelService,
        private campaignLevelService: CampaignLevelService
    ) {
        super(manageOverviewService, insightsApiService, datePresetService, compareObjectsService, filtersService);
    }

    //overrides main-service function since it needs to insert upper level selected items
    protected setSelectedItemsFilterParams(basicQuery: BasicQuery) {
        if (this.campaignLevelService.selectedItemIds.length) {
            let campaignLevel = new InsightsFilter({
                metricKey: 'facebookId',
                operator: FilterOperator.In,
                value: [],
                campaignStructureLevel: CampaignStructureLevel.Campaign
            });
            this.campaignLevelService.selectedItemIds.forEach(facebookId => campaignLevel.value.push(facebookId));
            basicQuery.filters.push(campaignLevel);
        }

        if (this.adSetLevelService.selectedItemIds.length) {
            let adSetLevel = new InsightsFilter({
                metricKey: 'facebookId',
                operator: FilterOperator.In,
                value: [],
                campaignStructureLevel: CampaignStructureLevel.AdSet
            });
            this.adSetLevelService.selectedItemIds.forEach(facebookId => adSetLevel.value.push(facebookId));
            basicQuery.filters.push(adSetLevel);
        }
    }
}
