import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { UserFormatPreferencesService } from '../../../shared/services';
import { InsightsQueryResponseObjectFE, MetricDataType, MetricIntegrationType, MetricType } from '../../../../api/models';
import { ColumnDisplayConf, BoldTextCells, TextAlignRight } from '../../models';

@Component({
    selector: 'table-body-cell',
    templateUrl: './table-body-cells.component.html',
    styleUrls: ['../metric-table/metric-table.component.scss', './table-body-cells.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableBodyCellsComponent implements OnInit, OnChanges {
    @Input() row: InsightsQueryResponseObjectFE;
    @Input() column: ColumnDisplayConf; //TableColumn with ColumnDisplayConf data
    @Input() columnKey: string;

    cellDisplayValue: any;

    MetricDataType = MetricDataType;

    isBoldText: boolean = false;
    isTextAlignRight: boolean = false;
    isCompound: boolean = false;
    isGoogleMetric: boolean = false;

    constructor(
        public format: UserFormatPreferencesService //
    ) {}

    ngOnInit() {}

    ngOnChanges() {
        if (this.column.metricType === MetricType.Metric) {
            if (this.row.metrics) this.cellDisplayValue = this.row.metrics[this.columnKey];
        } else if (this.column.metricType === MetricType.Static) {
            this.cellDisplayValue = this.row[this.columnKey];
        }

        this.isBoldText = BoldTextCells[this.columnKey];
        this.isTextAlignRight = TextAlignRight.indexOf(this.column.metricDataType as MetricDataType) > -1;
        this.isCompound = this.column.metricType === MetricType.Compound;
        this.isGoogleMetric = this.column.metricIntegrationType === MetricIntegrationType.Google || this.column.metricIntegrationType === MetricIntegrationType.GoogleAnalytics4;
    }
}
