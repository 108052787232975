import { GeoLocationTypeEnum, OldGeoLocationType } from "../enums";

export const mapGeoLocation = {
    [OldGeoLocationType.country]: GeoLocationTypeEnum.COUNTRY,
    [OldGeoLocationType.country_group]: GeoLocationTypeEnum.COUNTRY_GROUP,
    [OldGeoLocationType.region]: GeoLocationTypeEnum.REGION,
    [OldGeoLocationType.city]: GeoLocationTypeEnum.CITY,
    [OldGeoLocationType.zip]: GeoLocationTypeEnum.ZIP,
    [OldGeoLocationType.geo_market]: GeoLocationTypeEnum.GEO_MARKET,
    [OldGeoLocationType.electoral_district]: GeoLocationTypeEnum.ELECTORAL_DISTRICT,
    [OldGeoLocationType.custom_location]: GeoLocationTypeEnum.CUSTOM_LOCATION,
    [OldGeoLocationType.neighborhood]: GeoLocationTypeEnum.NEIGHBORHOOD,
    [OldGeoLocationType.medium_geo_area]: GeoLocationTypeEnum.MEDIUM_GEO_AREA,
    [OldGeoLocationType.subcity]: GeoLocationTypeEnum.SUBCITY,
    [OldGeoLocationType.place]: GeoLocationTypeEnum.PLACE,
    [OldGeoLocationType.metro_area]: GeoLocationTypeEnum.METRO_AREA,
    [OldGeoLocationType.small_geo_area]: GeoLocationTypeEnum.SMALL_GEO_AREA,
    [OldGeoLocationType.subneighborhood]: GeoLocationTypeEnum.SUB_NEIGHBORHOOD,
};