export const mapForOldPatternValue = {
    '{{campaign.objective}}': '{{campaign.setup.objective}}',
    '{{campaign.setup.catalog}}': '{{campaign.general.catalog}}',
    '{{campaign.ad_account}}': '{{campaign.setup.ad_account}}',
    '{{campaign.product_catalog}}': '{{campaign.setup.catalog}}',
    '{{campaign.facebook_page}}': '{{campaign.identity.facebook_page_id}}',
    '{{campaign.instagram_account}}': '{{campaign.identity.instagram_account_id}}',
    '{{campaign.instagram_account.name}}': '{{campaign.identity.instagram_account_id}}',
    '{{campaign.facebook_page.name}}': '{{campaign.identity.facebook_page_id}}',
    '{{campaign.profile.name}}': '',
    '{{adset.targeting.include_custom_audience}}': '{{adset.targeting.audience.include_custom_audience}}', //
    '{{campaign.product_catalog.name}}': '{{campaign.setup.catalog}}',
    '{{campaign.tracking.pixel}}': '{{campaign.tracking.pixel_id}}',
    '{{campaign.tracking.fb_pixel.text}}': '{{campaign.tracking.pixel_id}}',
    '{{campaign.name}}': '{{campaign.general.name}}',
    '{{adset.product_set}}': '{{adset.products.product_set_id}}',
    '{{adset.product_set.name}}': '{{adset.products.product_set_id}}',
    '{{adset.optimization_goal}}': '{{adset.delivery.optimization_goal}}',
    '{{adset.billing_event}}': '{{adset.delivery.billing_event}}',
    '{{adset.targeting.spec.gender}}': '{{adset.targeting.demographics.genders}}',
    '{{adset.targeting.spec.age_min}}': '{{adset.targeting.demographics.age_from}}',
    '{{adset.targeting.spec.age_max}}': '{{adset.targeting.demographics.age_to}}',
    '{{adset.targeting.spec.location.include_locations}}': '{{adset.targeting.location.include_locations}}',
    '{{adset.targeting.spec.include_custom_audiences}}': '{{adset.targeting.audience.include_custom_audience}}',
    '{{adset.from_date.month}}': '{{adset.scheduling.start_time.month}}',
    '{{adset.from_date.year}}': '{{adset.scheduling.start_time.year}}',
    '{{adset.name}}': '{{adset.general.name}}',
    '{{ad.format}}': '{{ad.creative.format}}',
    '{{ad.template_name}}': '{{ad.creative.template_name}}',
    '{{ad.name}}': '{{ad.general.name}}',
    '{{adset.placement}}': '{{adset.placement.type}}',
    '{{placement}}': '{{adset.placement.type}}',
    '{{adset.targeting.spec.detailed_targeting}}': '{{adset.audience.targeting.include_detailed_targeting}}',
    '#': ''
    // '{{adset.targeting.saved_audience_name}}'???
    // '{{site_source_name}}'??
}
export const replacePatternValues = (value: string): string => {
    const keys: any = Object.keys(mapForOldPatternValue).join('|');
    const newValue: string = value.replace(new RegExp(keys, 'gi'), function (matched) {
        return mapForOldPatternValue[matched];
    });
    return newValue;
}