import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { get, NotifyService } from '../../modules/shared/services';
import { response } from '../../modules/shared/consts';
import {  PlatformType } from '../../modules/shared';
import { ColumnDisplayConf } from '../../modules/metric-table/models';
import { ManageDisplayMetricConfiguration } from '../models';

@Injectable({
    providedIn: 'root'
})
export class ManageDisplayMetricConfigurationsApiService {
    constructor(
        private httpClient: HttpClient, //
        private notifyService: NotifyService
    ) { }

    loadDataConfigurations(platform?: PlatformType): Observable<ColumnDisplayConf[]> {
        let queryParams = new HttpParams().append('platform', platform);
        return this.httpClient.get<ManageDisplayMetricConfiguration[]>(`$apiUri/manage/all-display-configurations`, { params: queryParams }).pipe(
            map(x => (x as Array<any>).map(item => new ColumnDisplayConf(item))), //.filter(x=>x.key !== 'effectiveStatus')
            catchError(error => {
                this.notifyService.error('Load Metric Display Configurations failed');
                return throwError('');
            })
        );
    }

}
