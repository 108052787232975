<!--  -->
<div #element class="hidden-element"><span *ngIf="currentTableConf">{{currentTableConf.name}}</span></div>
<ng-select #ngSelect
    class="select--dark select--manage-1 preset-options first-level bg--grey-1 size--5 font--weight_semi inline-block float-right dropdown-options"
    placeholder="Columns: " [searchable]="false" [ngModel]="currentTableConfId" style="min-width: 209px;"
    [items]="[{id:1,name:'a', disabled:true, selected:true}]" bindLabel="name" bindValue="id" [closeOnSelect]="false"
    [loading]="!currentTableConf || !tableConfigurations" (open)="openDropdown()" (close)="onClosePresetSelect()">
    <ng-template ng-label-tmp let-item="item">
        <!-- <svg-icon class="ng-value-img" svgClass="filter-icon" name="Columns" alt=""></svg-icon> -->
        <span class="ng-value-label">
            <div class="inline-block v-a--top">Columns:&nbsp;</div>
            <div *ngIf="currentTableConf" class="inline-block v-a--top truncate pr--10 preset-name-container" title="{{currentTableConf.name}}"
                [ngStyle]="{'width': (getElementWidth(element) < 70 ? 70 : getElementWidth(element)) + 'px'}">
                {{currentTableConf.name}}
            </div>
        </span>
    </ng-template>

    <ng-template ng-option-tmp let-item="item" let-index="index">

        <div style="max-height: 307px;">

            <div class="ng-option no-hover" role="option" aria-selected="false"
                style="padding: 0 !important; width: 185px; display: block;">
                <div class="w--100 justify--content_space-between align--items_center"
                    style="max-height: 150px; width: 185px; overflow: auto;">
                    <preset-select [tableConfigurations]="tableConfigurations" [currentTableConf]="currentTableConf"
                        (emitChangePreset)="changeSelectedPreset($event)"
                        (emitDeletePreset)="confirmDeletePreset = $event"></preset-select>
                </div>
            </div>

            <div class="ng-option disabled no-hover border-bottom-0" role="option" aria-selected="false">
                <div class="w--100 d--flex justify--content_space-between align--items_center">
                    <span class="ng-option-label group-label">Customize</span>
                </div>
            </div>

            <div class="ng-option" role="option" aria-selected="false">
                <div #selectColumnsOption
                    class="w--100 d--flex justify--content_space-between align--items_center spec--col--event"
                    (click)="toggleColumnSelect($event)">
                    <span class="ng-option-label color--grey-6 spec--col--event">Select</span>
                    <span class="extender-arrow spec--col--event">></span>

                    <ng-container *ngIf="areColumnsOpen && !!dataConfigurationsForSelectedLevel">

                        <div style="position: fixed;"
                            [ngStyle]="{'top': getPositionTop(selectColumnsOption) - 5 + 'px', 'left': getPositionLeft(selectColumnsOption) - 185 - 336 + 6 + 'px'}">
                            <!-- just -10 when its to the right -->
                            <preset-columns-select #presetColumnsSelectComponent [selectedDataLevel]="selectedDataLevel"
                                [dataConfigurations]="dataConfigurations" [currentTableConf]="currentTableConf"
                                (emitSelectedItemsChange)="changeSelectedColumns($event)"
                                (emitCancel)="areColumnsOpen = false" (emitSave)="saveAsNewPreset($event)"
                                (emitUpdate)="updateCurrentPreset($event)">
                            </preset-columns-select>
                        </div>
                    </ng-container>
                </div>
            </div>

            <div class="ng-option hover-green" [ngClass]="{'disabled no-hover': !currentTableConf?.hasUnsavedChanges}" role="option"
                aria-selected="false" (click)="clickOnUpdateCurrentPreset(currentTableConf?.hasUnsavedChanges)">
                <div class="w--100 d--flex justify--content_space-between align--items_center ">
                    <span class="ng-option-label"
                        [ngClass]="{'color--grey-6': !isPresetImutable() && currentTableConf?.hasUnsavedChanges}">Update
                        Current
                        Preset</span>
                </div>
            </div>

            <div class="ng-option hover-green" [ngClass]="{'disabled no-hover': !currentTableConf?.hasUnsavedChanges}" role="option"
                aria-selected="false" (click)="clickOnSaveAsNew(currentTableConf?.hasUnsavedChanges)">
                <div class="w--100 d--flex justify--content_space-between align--items_center ">
                    <span class="ng-option-label"
                        [ngClass]="{'color--grey-6': currentTableConf?.hasUnsavedChanges}">Save As New Preset</span>
                </div>
            </div>
        </div>

    </ng-template>

</ng-select>

<hunch-modal *ngIf="!!confirmDeletePreset"
    [messageHeader]="'Delete table preset?'"
    [messageBody]="'Are you sure you want to delete this table preset permanently?'" [confirmBtnText]="'Delete'"
    (emitCancel)="cancelDelete()" (emitConfirm)="deletePreset()"></hunch-modal>