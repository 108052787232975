export enum ObjectiveEnum {
    AppInstalls = 0,
    BrandAwareness = 1,
    Conversions = 2,
    EventResponses = 3,
    LeadGeneration = 4,
    LinkClicks = 5,
    LocalAwareness = 6,
    Messages = 7,
    OfferClaims = 8,
    PageLikes = 9,
    PostEngagement = 10,
    ProductCatalogSales = 11,
    Reach = 12,
    VideoViews = 13,
    StoreVisits = 14,
    MobileAppInstalls = 15,
    MobileAppEngagement = 16,
    None = 17,
    CanvasAppEngagement = 18,
}
export enum ObjectiveEnumString {
    APP_INSTALLS = 'APP_INSTALLS',
    BRAND_AWARENESS = 'BRAND_AWARENESS',
    CONVERSIONS = 'CONVERSIONS',
    LEAD_GENERATION = 'LEAD_GENERATION',
    LINK_CLICKS = 'LINK_CLICKS',
    POST_ENGAGEMENT = 'POST_ENGAGEMENT',
    PRODUCT_CATALOG_SALES = 'PRODUCT_CATALOG_SALES',
    REACH = 'REACH',
    VIDEO_VIEWS = 'VIDEO_VIEWS'
}