<div class="ng-option" role="option" aria-selected="false" (click)="isAddAccountExpanded = !isAddAccountExpanded">
    <div class="w--100 d--flex justify--content_space-between align--items_center ">
        <span class="ng-option-label color--grey-6 font--weight_semi"
            [ngClass]="{'color--green-2': isAddAccountExpanded}">Ad Account</span>
        <div class="icon-box inline-block mr--8">
            <svg-icon name="ArrowDownSIcon" alt="" svgClass="left-menu-icon expandable"
                [ngClass]="{'expanded': isAddAccountExpanded}"></svg-icon>
        </div>
    </div>
</div>

<ng-container *ngIf="isAddAccountExpanded">
    <ng-container *ngFor="let adAccount of adAccounts">
        <div class="ng-option" role="option" aria-selected="false">
            <div class="w--100 d--flex justify--content_space-between align--items_center">
                <mat-checkbox *ngIf="!reloadOptions" class="mr--8" [checked]="isChecked(adAccount)"
                    (change)="toggleOption(adAccount)">
                    <span class="ng-option-label color--grey-6 font--weight_regular">{{adAccount.name}}</span>
                </mat-checkbox>
            </div>
        </div>
    </ng-container>
</ng-container>