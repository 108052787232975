import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotifyService } from '../../modules/shared/services';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DuplicateRequest, DuplicateRequestAutomated, Group } from '../../modules/metric-table/components/table-body-cells/table-hover-cell-action-handler';

@Injectable({
    providedIn: 'root'
})
export class DuplicateApiService {

    constructor(
        private httpClient: HttpClient, //
        private notifyService: NotifyService
    ) { }

    loadGroups(): Observable<Group[]> {
        return this.httpClient.get<Group[]>(`$hunchAppUrl/api/v3/groups/catalogs-and-ad-accounts`);
    }

    duplicateAutomated(postBody: DuplicateRequestAutomated): Observable<AutomatedDuplicateResponse> {
        return this.httpClient.post<AutomatedDuplicateResponse>(`$apiUri/facebookCampaign/automated/duplicate`, postBody).pipe(
            catchError(error => {
                this.notifyService.error('Duplicate Hunch Automated Campaign failed');
                return throwError('');
            })
        );
    }

    duplicateStandard(postBody: DuplicateRequest) {
        return this.httpClient.post('$apiUri/facebookcampaign/duplicate', postBody).pipe(
            catchError(error => {
                this.notifyService.error('Duplicate Hunch Standard Campaign failed');
                return throwError('');
            })
        );
    }

    fetchFacebookCampaign(id: number) {
        return this.httpClient.get(`$apiUri/facebookCampaign/${id}`, { withCredentials: true }).pipe(
            catchError(error => {
                this.notifyService.error('META - Duplicate Hunch Standard Campaign failed');
                return throwError(() => error);
            })
        );
    }

    fetchAccountData(accountId: number) {
        return this.httpClient.get(`$hunchSettingsApiUrl/api/v2/team/accounts/${accountId}`).pipe(
            catchError(error => {
                this.notifyService.error('Fetching Hunch account failed');
                return throwError(() => error);
            })
        );
    }

    duplicateMetaCampaign(body: any) {
        return this.httpClient.post('$hunchMetaCampaignEditorApi/standard-campaign-editor/draft', body).pipe(
            catchError(error => {
                this.notifyService.error('META - Duplicate Hunch Standard Campaign failed');
                return throwError(() => error);
            })
        );
    }

    fetchCampaign(campaignId: string):Observable<any> {
        return this.httpClient.get(`$hunchMetaCampaignEditorApi/standard-campaign-editor/latest/${campaignId}`).pipe(
            catchError(error => {
                this.notifyService.error('META - Duplicate Hunch Standard Campaign failed');
                return throwError(() => error);
            })
        );
    }
}

export interface AutomatedDuplicateResponse {
    failureReason: string;
    isSuccess: boolean;
    payload: any;
}
