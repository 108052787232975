export enum EntityStatus {
    None = '',
    Active = 'ACTIVE',
    Paused = 'PAUSED',
    Deleted = 'DELETED',
    Archived = 'ARCHIVED'
}

export enum TableReloadType {
    ChangePreset = 10,
    ChangeParams = 20,
    ChangeFilters = 30,
    ReloadRows = 40,
    DuplicatedToDraft = 50,
    ClearSelectedLevel = 60,
    CustomFilter = 70
}

export enum TableCellNameActions {
    Delete = 'Delete',
    Edit = 'Edit',
    Duplicate = 'Duplicate',
    Log = 'Log',
    Disconnect = 'Disconnect',
    Overview = 'Overview'
}