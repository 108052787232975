<header>
    <span class="ico-Hunch"></span>
    <ng-select #chooseAccount class="select--dark flex--grow_1 uk-select" [items]="accounts??[]" [(ngModel)]="accountId"
        bindLabel="name" placeholder="Select..." [searchable]="true" bindValue="id" id="account"
        (change)="changeAccount()" (close)="chooseAccount.blur()">
    </ng-select>
    <ul class="top-nav">
        <!-- <li><a href=""><span class="ico-Home-Default ico-medium"></span></a></li>  will be used in later stages-->
        <li><a [href]="origin" title="Manage"><span class="ico-Manage-Default ico-medium"></span>
                <span class="txt">Manage</span> </a></li>
        <li><a [href]="hunchAppUrl+'/v2/automation'" title="Automation"><span
                    class="ico-Automation-Default ico-medium"></span><span class="txt">Automation</span></a>
        </li>
        <li><a [href]="hunchAppUrl+'/v2/creative-library/my-designs'" title="Library"><span
                    class="ico-Library-Default ico-medium"></span><span class="txt">Library</span></a></li>
        <!-- <li><a [href]="hunchAppUrl+'/v2/library/catalogs/dpa'" title="Catalogs"><span
                    class="ico-Catalogs-Default ico-medium"></span><span class="txt">Catalogs</span></a></li> -->
        <li><a [href]="hunchCatalogsAppUrl" title="Catalogs"><span class="ico-Catalogs-Default ico-medium"></span><span
                    class="txt">Catalogs</span></a></li>
    </ul>
    <ul class="profile-nav">
        <li>
            <a (click)="toggleDropbar()">{{user?.firstName}} {{user?.lastName}} <span class="ico-Profile-Default color-white"></span></a>
            <div class="dropbar">
                <ul>
                    <li><a [href]="hunchAppUrl+'/v2/settings/profile'">Settings</a></li>
                    <li><a href="mailto:support@hunchads.com">Email Support</a></li>
                    <li><a [href]="hunchAppUrl+'/account/LogOff'">Sign Out</a></li>
                </ul>
            </div>
        </li>
    </ul>
</header>