<ng-container *ngIf="!isCompound; else compound" [ngTemplateOutlet]="regularCell"></ng-container>

<ng-template #regularCell>
    <ng-container *ngIf="cellDisplayValue; else emptyCell">
        <div class="w--100 h--100 truncate px--8 size--5 body-cell" [ngClass]="{'font--weight_semi': isBoldText, 'numeric-value': isTextAlignRight}">
            <!-- {{row['page']}} -->
            <cell-format-display-value
            [cellDisplayValue]="cellDisplayValue"
            [metricDataType]="column.metricDataType"
            [adAccountId]="row?.adAccountId"
            [hideCurrency]="isGoogleMetric"
            ></cell-format-display-value>
        </div>
    </ng-container>
</ng-template>


<ng-template #emptyCell>
    <div class="w--100 h--100 truncate px--8 size--5 body-cell"
        [ngStyle]="{'text-align': isTextAlignRight ? '' : 'left'}">
        &nbsp;-&nbsp;
    </div>
</ng-template>

<ng-template #compound>
    <div class="w--100 h--100 truncate px--8 size--5 body-cell compund" [ngClass]="{'font--weight_semi': isBoldText, 'numeric-value': isTextAlignRight}">

        <!-- {{row['page']}} -->
        <cell-format-compound-display-value
        [row]="row"
        [column]="column"
        [columnKey]="column.key"
        ></cell-format-compound-display-value>
    </div>
</ng-template>
