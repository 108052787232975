import { Component, Input, OnChanges, EventEmitter, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { CampaignStructureLevel, FilterOperator, InsightsFilter } from '../../../../api/models';
import { AdSetLevelService, AdsLevelService, CampaignLevelService, FiltersService, TableMainService } from '../../table-services';
import { CampaignType, HunchCampaignTypeEnum, PlatformEnum } from '../../../shared';
import { get } from '../../../shared/services';
import { MetaCampaignTypes, Objectives, OdaxObjectives, SnapchatCampaignTypes, SnapchatObjectives } from '../../../shared/consts';


@Component({
    selector: 'table-actions',
    templateUrl: './table-actions.component.html',
    styleUrls: ['./table-actions.component.scss']
})
export class TableActionsComponent implements OnInit, OnChanges, OnDestroy {
    @Input() selectedDataLevel: CampaignStructureLevel;
    @Input('resetTableConfiguration') resetTableConfiguration: EventEmitter<any>;
    currentTableService: TableMainService;
    reloadEditComponent = { display: true, loading: false };
    objectiveOptions: any = [];
    campaignTypeOptions: any = [];
    platform: PlatformEnum;
    subs: Subscription = new Subscription();
    constructor(
        private campaignLevelService: CampaignLevelService, //
        private adSetLevelService: AdSetLevelService,
        private adsLevelService: AdsLevelService,
        private filterService: FiltersService
    ) { }

    ngOnInit(): void {
        this.resetTableConfiguration?.subscribe(() => {
            setTimeout(() => {
                const urlParams = new URLSearchParams(window.location.search);
                const platform: PlatformEnum | any = urlParams.get('platform');
                if (this.platform !== platform) {
                    this.platform = platform;
                    this.setOptions(this.platform);
                    this.filterService.resetCustomFilters();
                    
                }
            });
        });
        this.platform = get('platform');
        this.setOptions(this.platform);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedDataLevel.currentValue !== changes.selectedDataLevel.previousValue) {
            this.loadTableServiceByDataLevel();
        }
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    setOptions(platform: PlatformEnum): void {
        switch (platform) {
            case PlatformEnum.FACEBOOK:
            case PlatformEnum.META:
                this.objectiveOptions = [...OdaxObjectives, ...Objectives];
                this.campaignTypeOptions = MetaCampaignTypes;
                break;
            case PlatformEnum.SNAPCHAT:
                this.campaignTypeOptions = SnapchatCampaignTypes;
                this.objectiveOptions = SnapchatObjectives;
        }
    }

    loadTableServiceByDataLevel(): void {
        if (this.selectedDataLevel) {
            switch (this.selectedDataLevel) {
                case CampaignStructureLevel.Campaign:
                    this.currentTableService = this.campaignLevelService;
                    break;
                case CampaignStructureLevel.AdSet:
                    this.currentTableService = this.adSetLevelService;
                    break;
                case CampaignStructureLevel.Ad:
                    this.currentTableService = this.adsLevelService;
                    break;
            }
        }
    }

    objectiveChanged(value: any): void {
        let filters: any[] = [];
        let objectives: any = [];
        for (let key in value) {
            if (value[key].checked) {
                objectives.push(key);
            }
            if (value[key].child && value[key].child['AUTOMATED_SHOPPING_ADS']?.checked) {
                if(!value[key].checked){
                    filters.push(
                        new InsightsFilter({
                            metricKey: 'smartPromotionType',
                            operator: FilterOperator.Equals,
                            value: 'AUTOMATED_SHOPPING_ADS',
                            campaignStructureLevel: CampaignStructureLevel.Campaign
                        }))
                }
            }
        }
        if (objectives.length > 0) {
            filters.push(
                new InsightsFilter({
                    metricKey: 'objective',
                    operator: FilterOperator.In,
                    value: objectives,
                    campaignStructureLevel: CampaignStructureLevel.Campaign
                }))
        }
        this.filterService.addCustomFilter(filters, 'objectives');

    }

    campaignTypeChanged(value: any): void {
        let filters: any[] = [];
        let types: number[] = [];
        let connected: boolean = false;

        for (let key in value) {
            if (value[key].checked && key === HunchCampaignTypeEnum.META) {
                types.push(1);
            }
            if (value[key].checked && key === HunchCampaignTypeEnum.AUTOMATED) {
                types = [200, 201, ...types];
            }
            if (value[key].checked && key === HunchCampaignTypeEnum.STANDARD) {
                types = [100, 101, ...types];
            }
            if (value[key].child && value[key].child[HunchCampaignTypeEnum.STANDARD]?.checked) {
                types = [100, 101, ...types];
            }
            if (value[key].child && value[key].child[HunchCampaignTypeEnum.AUTOMATED]?.checked) {
                types = [200, 201, ...types];
            }
            if (value[key].child && value[key].child[HunchCampaignTypeEnum.CONNECTED]?.checked) {
                if (!value[key].child[HunchCampaignTypeEnum.STANDARD]?.checked)connected = true;
            }
        }
        if (types.length) {
            filters.push(
                new InsightsFilter({
                    metricKey: 'hunchCampaignType',
                    operator: FilterOperator.In,
                    value: types,
                    campaignStructureLevel: CampaignStructureLevel.Campaign
                }))

        }
        if (connected) {
            filters.push(
                new InsightsFilter({
                    metricKey: 'connected',
                    operator: FilterOperator.Equals,
                    value: true,
                    campaignStructureLevel: CampaignStructureLevel.Campaign
                }))
        }

        this.filterService.addCustomFilter(filters, 'campaignTypes');
    }
}