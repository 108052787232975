import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CookieInterceptor implements HttpInterceptor {
  excludeUrls: string[] = ['/negotiate'];
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let excludeCredentials: boolean = this.excludeUrls.some((url) => req.url.includes(url));
    if (!excludeCredentials) {
      req = req.clone({
        withCredentials: req.headers.get('token') ? false : true
      });
    }
    return next.handle(req);
  }
}
