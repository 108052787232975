import { Component, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TableConfigurationsApiService } from '../../../../../api/services';
import { Subscription } from 'rxjs';
import { CampaignStructureLevel } from '../../../../../api/models';
import { ApplicationHeaderPlaceholderHeight } from '../../../../../app.component';
import { ManageOverviewService } from '../../../../manage-overview/manage-overview.service';
import { ColumnDisplayConf, TableConfigurationPresetFE, TableReloadType } from '../../../models';
import { TableService } from '../../../table-services';
import { PresetColumnsSelectComponent } from './preset-columns-select/preset-columns-select.component';

@Component({
    selector: 'preset-editor',
    templateUrl: './preset-editor.component.html',
    styleUrls: ['../table-actions.component.scss', './preset-editor.component.scss']
})
export class PresetEditorComponent implements OnInit, OnDestroy {
    selectedDataLevel: CampaignStructureLevel;
    @Input('selectedDataLevel') set _selectedDataLevel(value: CampaignStructureLevel) {
        this.selectedDataLevel = value;
        if (this.currentTableConf) this.currentTableConfColumnsLevel = this.currentTableConf.getCurrentLevelColumns(this.selectedDataLevel);
        if (this.dataConfigurations) this.dataConfigurationsForSelectedLevel = this.getDataConfForSelectedLevel(this.selectedDataLevel);
    }

    tableConfigurations: TableConfigurationPresetFE[];
    currentTableConf: TableConfigurationPresetFE;
    currentTableConfColumnsLevel: ColumnDisplayConf[];
    currentTableConfId: string;
    dataConfigurations: ColumnDisplayConf[];
    dataConfigurationsForSelectedLevel: ColumnDisplayConf[];

    campaingLevelDataConfigurations: ColumnDisplayConf[];
    adSetLevelDataConfigurations: ColumnDisplayConf[];
    adLevelDataConfigurations: ColumnDisplayConf[];

    areColumnsOpen: boolean = false;

    ApplicationHeaderPlaceholderHeight = ApplicationHeaderPlaceholderHeight;
    confirmDeletePreset: TableConfigurationPresetFE;

    @ViewChild('ngSelect', { static: false }) ngSelect: NgSelectComponent;
    @ViewChild('presetColumnsSelectComponent', { static: false }) presetColumnsSelectComponent: PresetColumnsSelectComponent;

    hasTableConfigurationChangesInThisSession: boolean = false;
    subs: Subscription = new Subscription();

    constructor(
        private manageOverviewService: ManageOverviewService, //
        private tableConfigurationsApiService: TableConfigurationsApiService,
        private renderer: Renderer2
    ) {
        this.loadConfiguration();
    }

    ngOnInit(): void {}

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    loadConfiguration() {
        this.subs.add(
            this.manageOverviewService.tableConfigurations$.subscribe((data: TableConfigurationPresetFE[]) => {
                if (data) {
                    this.tableConfigurations = data;
                }
            })
        );

        this.subs.add(
            this.manageOverviewService.dataConfigurations$.subscribe((data: ColumnDisplayConf[]) => {
                if (data) {
                    this.dataConfigurations = data;
                    this.setDataConfForSelectedLevel(this.selectedDataLevel);
                    this.dataConfigurationsForSelectedLevel = this.getDataConfForSelectedLevel(this.selectedDataLevel);
                }
            })
        );

        this.subs.add(
            this.manageOverviewService.getCurrentTableConfiguration().subscribe((data: TableConfigurationPresetFE) => {
                if (data) {
                    this.currentTableConf = data;
                    this.currentTableConfId = this.currentTableConf.id;
                    this.currentTableConfColumnsLevel = this.currentTableConf.getCurrentLevelColumns(this.selectedDataLevel);
                    this.hasTableConfigurationChangesInThisSession = false;
                }
            })
        );
    }

    toggleColumnSelect(event: Event) {
        if (this.isClikedOnSelectColumnOptionElement(event)) this.areColumnsOpen = !this.areColumnsOpen;
    }

    changeSelectedColumns(value: string[]) {
        this.hasTableConfigurationChangesInThisSession = true;
        let selectedKeys = [...value];

        let currentStateKeys: string[] = this.currentTableConfColumnsLevel.map(item => item.key);
        let removedKeys: string[] = currentStateKeys.filter(key => !selectedKeys.includes(key));
        let addedKeys: string[] = selectedKeys.filter(key => !currentStateKeys.includes(key));

        this.updatePreset(removedKeys, addedKeys, CampaignStructureLevel.Campaign);
        this.updatePreset(removedKeys, addedKeys, CampaignStructureLevel.AdSet);
        this.updatePreset(removedKeys, addedKeys, CampaignStructureLevel.Ad);

        this.currentTableConf.hasUnsavedChanges = true;
    }

    updatePreset(removedKeys: string[], addedKeys: string[], level: CampaignStructureLevel) {
        let columnsThatAreNotRemoved = this.currentTableConf.getCurrentLevelColumns(level).filter(item => !removedKeys.includes(item.key));
        let additional = this.getDataConfForSelectedLevel(level)
            .filter(item => addedKeys.indexOf(item.key) > -1)
            .map(item => new ColumnDisplayConf(item));
        let levelConfigurationInCurrentTableConf = this.currentTableConf.getCurrentLevelColumns(level);
        levelConfigurationInCurrentTableConf.length = 0;
        columnsThatAreNotRemoved.concat(additional).forEach(col => levelConfigurationInCurrentTableConf.push(col));
    }

    changeSelectedPreset(selectedPreset: TableConfigurationPresetFE) {
        if (selectedPreset) {
            this.hasTableConfigurationChangesInThisSession = false;
            this.closeDropdown();
            this.manageOverviewService.setCurrentTableConfiguration(selectedPreset);
            this.reloadTableAndCurrentPage();
        }
    }

    getPositionTop(element: HTMLElement): number {
        if (!element) return 0;
        let retVal = element.getBoundingClientRect().top;
        return retVal;
    }

    getPositionLeft(element: HTMLElement): number {
        if (!element) return 0;
        let retVal = element.getBoundingClientRect().right;
        return retVal;
    }

    private isClikedOnSelectColumnOptionElement(event: Event): boolean {
        return ['extender-arrow spec--col--event', 'ng-option-label color--grey-6 spec--col--event', 'w--100 d--flex justify--content_space-between align--items_center spec--col--event'].indexOf(event.target['className']) > -1;
    }

    setDataConfForSelectedLevel(value: CampaignStructureLevel) {
        if (!this.campaingLevelDataConfigurations || !this.adSetLevelDataConfigurations || !this.adLevelDataConfigurations) {
            this.campaingLevelDataConfigurations = this.dataConfigurations.filter(item => item.campaignStructureLevels.indexOf(CampaignStructureLevel.Campaign) > -1);
            this.adSetLevelDataConfigurations = this.dataConfigurations.filter(item => item.campaignStructureLevels.indexOf(CampaignStructureLevel.AdSet) > -1);
            this.adLevelDataConfigurations = this.dataConfigurations.filter(item => item.campaignStructureLevels.indexOf(CampaignStructureLevel.Ad) > -1);
        }
    }

    getDataConfForSelectedLevel(value: CampaignStructureLevel): ColumnDisplayConf[] {
        switch (value) {
            case CampaignStructureLevel.Campaign:
                return this.campaingLevelDataConfigurations;
            case CampaignStructureLevel.AdSet:
                return this.adSetLevelDataConfigurations;
            case CampaignStructureLevel.Ad:
                return this.adLevelDataConfigurations;
            default:
                return;
        }
    }

    onClosePresetSelect() {
        if (this.hasTableConfigurationChangesInThisSession) {
            this.manageOverviewService.setCurrentTableConfiguration(this.currentTableConf);
            this.reloadTableAndCurrentPage();
            this.hasTableConfigurationChangesInThisSession = false;
        }
    }

    reloadTableAndCurrentPage() {
        setTimeout(() => {
            TableService.reloadTable$.next(TableReloadType.ChangePreset);
        });
    }

    closeDropdown() {
        this.areColumnsOpen = false;
        this.ngSelect.close();
        let elem = document.querySelector('body');
        if (elem) this.renderer.setStyle(elem, 'overflow-x', 'auto');
    }

    openDropdown() {
        this.areColumnsOpen = false;
        let elem = document.querySelector('body');
        if (elem) this.renderer.setStyle(elem, 'overflow-x', 'hidden');
    }

    isPresetImutable(): boolean {
        return this.currentTableConf.isHunchPredefined || this.currentTableConf.isDefault;
    }

    public clickOnUpdateCurrentPreset(isAllowed: boolean) {
        if (isAllowed) this.updateCurrentPreset();
    }

    public updateCurrentPreset(name?: string) {
        if (!this.isPresetImutable() && this.currentTableConf.hasUnsavedChanges) {
            this.tableConfigurationsApiService.updateConfiguration(this.currentTableConf).subscribe((data: TableConfigurationPresetFE) => {
                this.currentTableConf.hasUnsavedChanges = false;
            });
            this.closeDropdown();
            this.reloadTableAndCurrentPage();
        }
    }

    public saveAsNewPreset(name: string) {
        if (this.currentTableConf) {
            this.currentTableConf.hasUnsavedChanges = false;
            let save: TableConfigurationPresetFE = new TableConfigurationPresetFE(this.currentTableConf);
            save.isDefault = false;
            save.isHunchPredefined = false;
            save.name = name;
            this.tableConfigurationsApiService.createConfiguration(save).subscribe((savedID: string) => {
                if (savedID) {
                    let clone = new TableConfigurationPresetFE(JSON.parse(JSON.stringify(save)));
                    clone.id = savedID;
                    this.tableConfigurations.unshift(clone);
                    this.changeSelectedPreset(clone);
                }
            });
        }
    }

    public cancelDelete() {
        delete this.confirmDeletePreset;
    }

    public deletePreset() {
        let removeId = this.confirmDeletePreset.id;
        if (!this.confirmDeletePreset.isDefault && !this.confirmDeletePreset.isHunchPredefined && this.tableConfigurations?.length > 1) {
            if (this.confirmDeletePreset === this.currentTableConf) {
                let availableConfiurations = this.tableConfigurations.filter(item => item.id !== this.confirmDeletePreset.id);
                let nextTableConf = availableConfiurations.find(item => item.isDefault);
                if (!nextTableConf) nextTableConf = availableConfiurations[0];
                this.manageOverviewService.setCurrentTableConfiguration(nextTableConf);
            }
            this.tableConfigurationsApiService.deleteTableConfiguration(this.confirmDeletePreset.id).subscribe(x => {
                this.manageOverviewService.tableConfigurations$.next(this.tableConfigurations.filter(x => x.id !== removeId));
            });
            this.cancelDelete();
            this.closeDropdown();
        }
    }

    public clickOnSaveAsNew(isAllowed: boolean) {
        if (isAllowed) {
            this.areColumnsOpen = true;
            setTimeout(() => {
                if (!this.presetColumnsSelectComponent.saveNewPreset.isSaveAsNew) {
                    this.presetColumnsSelectComponent.saveNewPreset.changeSaveType();
                }
                setTimeout(() => {
                    this.presetColumnsSelectComponent.saveNewPreset.inputName.inputHtmlElement.nativeElement.focus();
                }, 0);
            }, 200);
        }
    }

    public getElementWidth(element: HTMLElement): number {
        if (!element) return 0;
        let retVal = element.getBoundingClientRect().width;
        return retVal;
    }
}
