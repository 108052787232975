export enum ConversionWindowEnum {
    ONE_DAY_CLICK = 'ONE_DAY_CLICK',
    SEVEN_DAYS_CLICK = 'SEVEN_DAYS_CLICK',
    ONE_DAY_CLICK_OR_VIEW = 'ONE_DAY_CLICK_OR_VIEW',
    SEVEN_DAYS_CLICK_OR_ONE_DAY_VIEW = 'SEVEN_DAYS_CLICK_OR_ONE_DAY_VIEW'
}
export enum OldConversionWindowEnum {
    OneDayClick = 0,
    SevenDaysClick = 1,
    OneDayClickOrView = 2,
    SevenDaysClickOrOneDayView = 3
}