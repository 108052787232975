import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { InsightsFilter } from '../../../../../../api/models';
import { ColumnDisplayConf } from '../../../../models';

@Component({
    selector: 'table-filter-level-create-modal',
    templateUrl: './table-filter-level-create-modal.component.html',
    styleUrls: ['../../table-filters.component.scss', './table-filter-level-create-modal.component.scss']
})
export class TableFilterLevelCreateModalComponent implements OnInit {
    @HostListener('document:click', ['$event']) onClickOut (event) {
        this.clickOutsidePopup(event);
    }

    @HostListener('document:keydown.enter', ['$event']) onEnterHandler(event: KeyboardEvent) {
        if (this.insightsFilter.operator && this.insightsFilter.value) this.addFilter.emit();
    }

    @Input() selectedFilter: ColumnDisplayConf;
    @Input() operatorOptions = [];
    @Input() insightsFilter: InsightsFilter;
    @Input() enumOptions = [];

    @Output() clearSelectedFilter = new EventEmitter<any>();
    @Output() addFilter = new EventEmitter<any>();

    constructor(
        private eRef: ElementRef //
    ) {}

    ngOnInit(): void {}

    private clickOutsidePopup(event: Event) {
        if (!!this.selectedFilter && !this.eRef.nativeElement.contains(event.target)) {
            if (!event.target['className'].includes('ng-')) {
                this.clearSelectedFilter.emit(true);
            }
        }
    }
}
