import { Subject } from 'rxjs';
import { InsightsQueryResponseObject, InsightsQueryResponseObjectFE } from '../../../api/models';

export class TableService {
    static reloadTable$ = new Subject<any>();
    static requestTableData$ = new Subject<any>();
}

export interface ITableQueries {
    pageData?: any;
    totalCount?: any;
    summaryRow?: any;

    metricTotalCount?: any;
    pagesNonMetricData?: any;
}

export interface ITableServiceResponse {
    pageData: InsightsQueryResponseObject[];

    totalCount: number;
    isTotalCountChanged: boolean;

    summaryRow: any;
    isSummaryRowChanged: boolean;
}

export class TableServiceResponse implements ITableServiceResponse {
    constructor(
        public pageData: InsightsQueryResponseObjectFE[] = [],

        public totalCount: number = 0,
        public isTotalCountChanged: boolean = false,

        public summaryRow: any = {},
        public isSummaryRowChanged: boolean = false,

        public isMetricSort: boolean = false,
        public metricTotalCount: number = 0,
        public pagesNonMetricData: InsightsQueryResponseObjectFE[] = [],
        public originalPageSize:number = 0
    ) {}
}
