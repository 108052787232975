import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { CampaignStructureLevel, InsightsQueryResponseObjectFE, MetricType } from '../../../../api/models';
import { ColumnDisplayConf, EmptyRowElement, FrozenLeftColumns, PageInfo, SelectColumn, TableConfigurationPresetFE, TableSortElement } from '../../models';
import { GetTableService, TableMainService, TableServiceResponse } from '../../table-services';
import { TableCellNameAction } from '../table-body-cells';
import { HandpickChangeField, HandpickChangeStatus, PlatformEnum } from '../../../shared';
import { MetaHubService, NotifyService, SnapchatHubService, get, isIdGuid } from '../../../shared/services';
import { Subscription } from 'rxjs';
import { MessageHub, UnpublishedHandpickResponse } from '../../../shared/models';

@Component({
    selector: 'metric-table',
    templateUrl: './metric-table.component.html',
    styleUrls: ['./metric-table.component.scss']
})
export class MetricTableComponent implements OnInit, OnDestroy {
    @Input() selectedDataLevel: CampaignStructureLevel;
    CampaignStructureLevel = CampaignStructureLevel;
    rowHeight: number = 48;
    @Input() tableDataService: TableMainService;
    @Input() drafts: InsightsQueryResponseObjectFE[];
    @Input('currentTableConf') set _currentTableConf(value: TableConfigurationPresetFE) {
        this.setCurrentTableConfiguration(value);
    }

    @Output() emitDeleteDraft = new EventEmitter<any>();
    @Output() updateTableConf = new EventEmitter<any>();

    currentTableConf: TableConfigurationPresetFE;

    columns: ColumnDisplayConf[];
    currentTableConfColumnsLevel: ColumnDisplayConf[];

    rows: (InsightsQueryResponseObjectFE | EmptyRowElement | any)[];

    snapHandpickStatusChanges: any = {};
    metaHandpickStatusChanges: any = {}

    cachedPages = {};
    loaderCache = {};

    rowsTotalCount: number;
    pageNumber: number = 0;
    pageSize: number;

    isLoading: boolean = true;
    isTotalCountLoading: boolean = true;
    isSummaryLoading: boolean = true;

    selectedAll: boolean = false;
    selectedItemIds: string[];
    selectedDraftIds: string[];
    selectedConnectedIds: string[];

    isInitLoad: boolean = true;
    loadFirstBatch: boolean = true;
    isReloadStateScrollTop: boolean = false;

    waitForHorizontalScroll: any;
    showHorizontalScroll: boolean = false;
    sortField: TableSortElement[];
    tableContainerHeight: number;

    summaryRowData: any;

    cellAction: TableCellNameAction;
    listener: any;

    subscription: Subscription = new Subscription();

    @ViewChild('tableContainer', { static: false }) tableContainer: ElementRef;
    @ViewChild('table', { static: false }) table: DatatableComponent;
    @ViewChild('scrollerTableElement', { static: false }) scrollerTableElement: ElementRef;

    constructor(
        private getTableService: GetTableService, //
        private renderer: Renderer2,
        private snapchatHubService: SnapchatHubService,
        private metaHubService: MetaHubService,
        private notifyService: NotifyService
    ) { }

    ngOnInit(): void {
        this.loadTableCache();
        if (this.selectedDataLevel === CampaignStructureLevel.Ad) this.rowHeight = 50;
        this.listenHandpickChanges();
        this.checkPendingHandpickChanges();
    }

    ngOnDestroy() {
        this.cacheTable();
        if (this.listener) this.listener();
        this.subscription.unsubscribe();
    }

    listenHandpickChanges(): void {

        if (get('platform') === PlatformEnum.SNAPCHAT) this.subscription.add(
            this.snapchatHubService.messageState.asObservable().subscribe((state: MessageHub) => {
                let row = this.rows.find((r) => {
                    return (r.internalId === state.message.ObjectId);
                })
                if (row && state.message.FieldName === HandpickChangeField.STATUS) this.snapHandpickStatusChanges[row.internalId] = state.message;
                if (row && (state.message.FieldName === HandpickChangeField.BID || state.message.FieldName === HandpickChangeField.BUDGET)) {
                    if (state.message.Status === HandpickChangeStatus.ERROR) this.notifyService.error(`Change ${state.message.FieldName} value of ${row.name} failed`);
                    if (state.message.Status === HandpickChangeStatus.PUBLISHED) this.notifyService.success(`Change ${state.message.FieldName} value at ${row.name} completed`)
                }
            })
        )
        else this.subscription.add(
            this.metaHubService.messageState.asObservable().subscribe((state: MessageHub) => {
                let row = this.rows.find((r) => {
                    return (r.internalId === state.message.ObjectId);
                })
                if (row && state.message.FieldName === HandpickChangeField.STATUS) this.metaHandpickStatusChanges[row.internalId] = state.message;
                if (row && (state.message.FieldName === HandpickChangeField.BID || state.message.FieldName === HandpickChangeField.BUDGET)) {
                    if (state.message.Status === HandpickChangeStatus.ERROR) this.notifyService.error(`Change ${state.message.FieldName} value of ${row.name} failed`);
                    if (state.message.Status === HandpickChangeStatus.PUBLISHED) this.notifyService.success(`Change ${state.message.FieldName} value at ${row.name} completed`)
                }
            }))
    }
    checkPendingHandpickChanges(): void {
        const platform = get('platform');
        const pendingHandpickChanges: UnpublishedHandpickResponse[] = get('unpublishedHandpickChanges')?.filter((up) => up.status === HandpickChangeStatus.PENDING);
        let pendingRequestIds: string[] = [];
        pendingHandpickChanges?.forEach((hp: UnpublishedHandpickResponse) => {
            if (!pendingRequestIds.includes(hp.requestId)) {
                pendingRequestIds.push(hp.requestId);
                platform === PlatformEnum.FACEBOOK ? this.metaHubService.registerByRequestId(hp.requestId) : this.snapchatHubService.registerByRequestId(hp.requestId);
            }
        })
    }

    setCurrentTableConfiguration(data: TableConfigurationPresetFE) {
        if (data) {
            this.currentTableConf = data;
            this.currentTableConfColumnsLevel = this.currentTableConf.getCurrentLevelColumns(this.selectedDataLevel);
            this.setTableDisplayColumns();
            setTimeout(() => {
                this.showHorizontalScroll = true;
                this.setTableHorizontalScrollListener();
            });
        }
    }

    setTableDisplayColumns() {
        let columns = [new ColumnDisplayConf(SelectColumn), ...JSON.parse(JSON.stringify(this.currentTableConfColumnsLevel))];
        this.setColumnWidths(columns);
        this.changeNameColumnWidth(columns.find((c: any) => c.key === 'name'));
        this.columns = columns;
    }

    renderRowsAgain() {
        let rows = this.rows;
        this.rows = this.createEmptyRows(this.rows.length);
        this.isLoading = true;
        setTimeout(() => {
            this.rows = [...rows];
            this.isLoading = false;
        }, 500);
    }

    clearTableComponentCache() {
        this.cachedPages = {};
        this.loaderCache = {};
    }

    loadPage(page: PageInfo, reloadPage?: boolean, isAdditionalPageLoad?: boolean) {
        if (reloadPage === true) {
            this.clearTableComponentCache();
        }
        this.resizeTableBodyHeight();
        if (!isAdditionalPageLoad) {
            this.pageNumber = page.offset;
        }

        this.pageSize = page.pageSize;

        if (page.offset !== 0 && !isAdditionalPageLoad) {
            setTimeout(() => {
                this.loadPreviousAndNextPageData(page);
            }, 0);
        }

        if (this.cachedPages[page.offset] === true) return;
        this.cachedPages[page.offset] = true;

        this.isLoading = true;
        this.loaderCache[page.offset] = false;

        let scrollToPage: number = 0;

        if (this.isInitLoad === true) {
            scrollToPage = this.tableDataService.pagePageOffset;
            this.isInitLoad = false;
        }

        if (!this.rows) this.rows = this.createEmptyRows(this.pageSize);
        if (!this.rowsTotalCount) this.rowsTotalCount = this.pageSize;

        let tableChanges: TableServiceResponse = this.tableDataService.getServiceResponse(page.offset, this.pageSize, this.selectedDataLevel, this.sortField, page.pageSize);
        if (tableChanges.isTotalCountChanged) {
            //refactor this with resetTable
            this.rows = this.createEmptyRows(this.pageSize);
            this.rowsTotalCount = this.pageSize;
            this.isTotalCountLoading = true;
        }

        if (tableChanges.isSummaryRowChanged) this.loadSummaryRow();
        if (!this.isSummaryLoading) {
            if (!this.summaryRowData && this.tableDataService.cachedSummaryRow) this.summaryRowData = this.tableDataService.cachedSummaryRow;
            if (!this.summaryRowData && !this.tableDataService.cachedSummaryRow) this.loadSummaryRow();
        }

        let checkSortResults: boolean;
        if (!this.isSortStatic()) checkSortResults = true;

        this.tableDataService.loadQueryResults(tableChanges, !this.isSortStatic(), page.offset, this.sortField[0].dir === 'asc').subscribe((res: TableServiceResponse) => {
            res.pageData.forEach(element => (element['pageNumber'] = page.offset + 1));

            this.expandTotalSizeIfSortMetricResultIsShort(checkSortResults, page, res);

            if (tableChanges.isTotalCountChanged) delete this.rowsTotalCount;

            this.loaderCache[page.offset] = true;
            this.isLoading = !this.areAllLoadersCompleted();
            if (page.offset === 0 || res.isTotalCountChanged) {
                this.isTotalCountLoading = false;
            }

            let totalRows = this.rows;

            if (res.isTotalCountChanged) {
                this.rowsTotalCount = res.totalCount;
                if (this.selectedDataLevel === CampaignStructureLevel.Campaign) this.rowsTotalCount += this.drafts.length;
                totalRows = this.createEmptyRows(this.rowsTotalCount);
                totalRows.splice(0, this.rows.length, ...this.rows);
                totalRows.splice(this.rowsTotalCount);
                this.tableDataService.cachedTowsTotalNumber = this.rowsTotalCount;
            }

            let start = page.offset * page.pageSize;
            let end = res.pageData.length;
            if (this.selectedDataLevel === CampaignStructureLevel.Campaign) start += this.drafts.length;
            totalRows.splice(start, end, ...res.pageData);
            if (this.drafts.length > 0) {
                this.injectDrafts(totalRows).then(() => {
                    this.rows = [...totalRows];
                })
            } else {
                this.rows = [...totalRows];
            }

            this.scrollToPreviousPositonBeforeTableWasDestroyed(page, scrollToPage);

            this.resizeTableBodyHeight();

            this.injectAdditionalRowsIfMetricSortIsShort(checkSortResults, page, res);

            if (page.offset === 0 || reloadPage) {
                this.loadPreviousAndNextPageData(page);
            }
        });
    }

    loadSummaryRow() {
        this.isSummaryLoading = true;
        delete this.summaryRowData;
        delete this.tableDataService.cachedSummaryRow;

        this.tableDataService.loadSummaryRow().then(res => {
            this.summaryRowData = res;
            this.isSummaryLoading = false;
        });
    }

    injectDrafts(data: any[]): Promise<boolean> {
        if (this.selectedDataLevel === CampaignStructureLevel.Campaign && this.drafts.length && data[0] !== this.drafts[0]) {
            data.splice(0, this.drafts.length, ...this.drafts);
            return Promise.resolve(true);
        } else {
            return Promise.resolve(false);
        }
    }

    isSortStatic(): boolean {
        let sortColumn = this.currentTableConfColumnsLevel.find(item => item.key === this.sortField[0].prop);
        if (sortColumn) return sortColumn.metricType === MetricType.Static || sortColumn.metricType === MetricType.Compound;
        return true;
    }

    expandTotalSizeIfSortMetricResultIsShort(checkSortResults: boolean, page: PageInfo, res: TableServiceResponse) {
        if (checkSortResults && page.offset === 0 && !res.totalCount && res.pageData?.length === 0) {
            res.totalCount = page.pageSize;
        }
    }

    injectAdditionalRowsIfMetricSortIsShort(checkSortResults: boolean, page: PageInfo, res: TableServiceResponse) {
        if (checkSortResults && page.offset === 0 && res.pageData.length === 0) {
            this.isLoading = true;
            this.tableDataService.loadAdditionalPageDataAfterSmallMetricSort().subscribe((data: InsightsQueryResponseObjectFE[]) => {
                if (data && data.length) {
                    if (this.selectedDataLevel === CampaignStructureLevel.Campaign && this.drafts?.length) this.rows = [...this.drafts, ...data];
                    else this.rows = [...data];
                } else {
                    if (this.selectedDataLevel === CampaignStructureLevel.Campaign && this.drafts?.length) this.rows = [...this.drafts];
                    else this.rows = [];
                }
                this.rowsTotalCount = this.rows.length;
                this.resizeTableBodyHeight();
                this.isLoading = false;
            });
        }
    }

    scrollToPreviousPositonBeforeTableWasDestroyed(page: PageInfo, scrollToPage: number) {
        if (this.tableDataService.scrollTop && scrollToPage) {
            setTimeout(() => {
                this.isReloadStateScrollTop = true;
                this.table.bodyComponent.scroller.parentElement.scrollTop = this.tableDataService.scrollTop;
            });
        }

        if (this.isReloadStateScrollTop === true) {
            this.loadPreviousAndNextPageData(page);
            this.isReloadStateScrollTop = false;
        }
    }

    loadPreviousAndNextPageData(page: PageInfo) {
        for (let index = 1; index < 5; index++) {
            if (page.offset > index - 1) {
                this.loadPage({ ...page, offset: page.offset - index }, false, true);
            }
            if (this.rowsTotalCount >= (page.offset + index) * this.pageSize) {
                this.loadPage({ ...page, offset: page.offset + index }, false, true);
            }
        }
    }

    setColumnWidths(columnData?: ColumnDisplayConf[]) {
        let tableColumns: ColumnDisplayConf[];

        if (!columnData) tableColumns = this.currentTableConfColumnsLevel;
        else tableColumns = columnData;

        //set width of columns to aggregated value would be at least as long as container
        if (this.tableContainer) {
            this.recalcColumnWidth(tableColumns);
        } else {
            let counter = 0;
            let interval = setInterval(() => {
                if (this.tableContainer || counter > 30) {
                    clearInterval(interval);
                    if (this.tableContainer) {
                        this.recalcColumnWidth(tableColumns);
                    }
                }
            }, 100);
        }
    }

    changeNameColumnWidth(column: any): void {
        if (column.width < 467) column.width = 467;
        if (column.minWidth < 324) column.minWidth = 324
    }

    recalcColumnWidth(tableColumns: ColumnDisplayConf[]) {
        let containerWidth = this.tableContainer.nativeElement.getBoundingClientRect().width;
        let allWidths = 0;
        tableColumns.forEach(column => {
            if (column.autoResizedFromWidth > 0) {
                column.width = column.autoResizedFromWidth;
                column.autoResizedFromWidth = 0;
            }
            allWidths += column.width;
        });
        if (containerWidth > allWidths) {
            let lastColumn = tableColumns[tableColumns.length - 1];
            if (lastColumn) {
                lastColumn.autoResizedFromWidth = tableColumns[tableColumns.length - 1].width;
                lastColumn.width += containerWidth - allWidths - 1;
            }
        }
    }

    resetTable(clearSelectedItems: boolean = true) {
        delete this.tableDataService.tableCache;
        if (this.table?.bodyComponent?.scroller?.parentElement?.scrollTop) this.table.bodyComponent.scroller.parentElement.scrollTop = 0;

        this.rows = this.createEmptyRows(this.rowsTotalCount);
        this.pageNumber = 0;
        this.isLoading = true;

        delete this.summaryRowData;
        delete this.tableDataService.cachedSummaryRow;

        //consider clearing chaced data in service here

        this.selectedAll = false;
        if (clearSelectedItems) this.selectedItemIds.length = 0;
        //load new total number

        this.loadPage(
            {
                offset: this.pageNumber,
                pageSize: this.pageSize,
                limit: undefined,
                count: this.pageNumber
            },
            true
        );
    }

    reloadDataOnPage(reloadToFirstPage?: boolean) {
        if (reloadToFirstPage) this.pageNumber = 0;
        this.rows = this.createEmptyRows(this.rowsTotalCount);
        this.loadPage(
            {
                offset: this.pageNumber,
                pageSize: this.pageSize,
                limit: undefined,
                count: this.rowsTotalCount
            },
            true
        );
    }

    cacheTable() {
        this.tableDataService.scrollTop = this.table?.bodyComponent?.scroller?.parentElement?.scrollTop;
        this.tableDataService.tableCache = this.cachedPages;
        this.tableDataService.tableCachedRows = this.rows;
        this.tableDataService.pagePageOffset = this.pageNumber;
        this.tableDataService.itemsPerPage = this.pageSize;
        this.tableDataService.sortField = this.sortField;
    }

    loadTableCache() {
        this.selectedItemIds = this.tableDataService.selectedItemIds;
        this.selectedDraftIds = this.tableDataService.selectedDraftIds;
        this.selectedConnectedIds = this.tableDataService.selectedConnectedIds;
        this.selectedAll = this.tableDataService.selectedAll;
        this.sortField = this.tableDataService.sortField;

        //if (this.tableDataService.tableCache) this.cachedPages = this.tableDataService.tableCache;
        if (this.tableDataService.tableCache) {
            if (this.tableDataService.tableCachedRows) this.rows = [...this.tableDataService.tableCachedRows];
            if (this.tableDataService.cachedTowsTotalNumber) this.rowsTotalCount = this.tableDataService.cachedTowsTotalNumber;
            if (this.tableDataService.cachedSummaryRow) {
                this.summaryRowData = this.tableDataService.cachedSummaryRow;
                this.isSummaryLoading = false;
            }
        }
    }

    createEmptyRows(length: number): any[] {
        let numberOfElements = length;
        if (this.selectedDataLevel === CampaignStructureLevel.Campaign) numberOfElements += this.drafts.length;
        let retVal = new Array<any>(numberOfElements);
        for (let index = 0; index < retVal.length; index++) {
            retVal[index] = new EmptyRowElement();
        }
        return retVal;
    }

    areAllLoadersCompleted(): boolean {
        let keys = Object.keys(this.loaderCache);
        for (let index = 0; index < keys.length; index++) {
            if (this.loaderCache[keys[index]] === false) return false;
        }
        return true;
    }

    @HostListener('window:resize', ['$event']) onResize(event: Event) {
        //this also applies when table container changes because manage overview will launche window resize on open/close drawer
        this.setColumnWidths();
        this.resizeTableBodyHeight();
    }

    public isFrozenLeft(column: ColumnDisplayConf): boolean {
        if (FrozenLeftColumns.indexOf(column.key) > -1) return true;
        return false;
    }

    public getElementHeight(element: HTMLElement): number {
        if (!element) return 0;
        let retVal = element.getBoundingClientRect().height;
        return retVal;
    }

    public getElementWidth(element: HTMLElement): number {
        if (!element) return 0;
        let retVal = element.getBoundingClientRect().width;
        return retVal;
    }

    public resizeTableBodyHeight() {
        //for custom summury row to be shown
        if (this.table && this.tableContainer) {
            this.tableContainerHeight = this.tableContainer.nativeElement.getBoundingClientRect().height;
            let tbh = this.tableContainerHeight - 48 - 60;

            let elem = this.table.element.querySelector('datatable-body');
            if (elem) this.renderer.setStyle(elem, 'max-height', tbh + 16 + 'px');
        }
    }

    public toggleSelection(row: InsightsQueryResponseObjectFE) {
        let rowId = row.facebookId;
        if (rowId) {
            if (this.selectedAll) this.selectedAll = false;
            let index = this.selectedItemIds.indexOf(rowId);
            if (index > -1) {
                this.selectedItemIds.splice(index, 1);
            } else {
                this.selectedItemIds.push(rowId);
            }
        }
        if (row.connected || !isIdGuid(row.internalId)) this.toggleConnectedSelection(row);
    }

    onActivate(event: { type: string; rowElement: ElementRef; cellElement: ElementRef; cellIndex: number; event: Event; row: InsightsQueryResponseObjectFE }) {
        if (event.type === 'click' && event.cellIndex === 0 && event.event.target['className']?.indexOf('mat-checkbox') > -1) {
            setTimeout(() => {
                let index = this.selectedItemIds.indexOf(event.row.facebookId);
                if (index > -1) this.renderer.addClass(event.rowElement, 'active');
                else this.renderer.removeClass(event.rowElement, 'active');
            }, 0);
        }
    }

    getRowClass(row: InsightsQueryResponseObjectFE) {
        let retVal = false;
        if (this?.selectedItemIds?.length && row?.facebookId) {
            if (this.selectedItemIds.indexOf(row.facebookId) > -1) retVal = true;
        }
        return {
            active: retVal
        };
    }

    public toggleDraftSelection(row: InsightsQueryResponseObjectFE) {
        let rowId = row.id;
        if (rowId) {
            let index = this.selectedDraftIds.indexOf(rowId);
            if (index > -1) {
                this.selectedDraftIds.splice(index, 1);
            } else {
                this.selectedDraftIds.push(rowId);
            }
        }
    }

    public toggleConnectedSelection(row: InsightsQueryResponseObjectFE) {
        let rowId = row.facebookId;
        if (rowId) {
            let index = this.selectedConnectedIds.indexOf(rowId);
            if (index > -1) {
                this.selectedConnectedIds.splice(index, 1);
            } else {
                this.selectedConnectedIds.push(rowId);
            }
        }
    }

    public selectAll() {
        this.selectedItemIds.length = 0;
        this.selectedAll = !this.selectedAll;
        this.tableDataService.selectedAll = this.selectedAll;
    }

    public resizedColumn(event: { column: any; newValue: number }) {
        let column = this.currentTableConfColumnsLevel.find(column => column.key === event?.column?.prop);
        if (column) {
            column.width = event.newValue < column.minWidth ? column.minWidth : event.newValue;
            this.currentTableConf.hasUnsavedChanges = true;
            this.currentTableConf.resizeAllLevelColumns(column.key, column.width);
        }
        this.updateTableConf.emit();
    }

    public reorderedColumns(res: { column: any; newValue: number; prevValue: number }) {
        let reloadColumns: boolean;
        if (res.newValue < 3) {
            reloadColumns = true;
            res.newValue = 4;
        }
        let reorderedColumn = this.currentTableConfColumnsLevel.find(column => column.key === res?.column?.prop);

        this.currentTableConfColumnsLevel.splice(res.prevValue - 1, 1);
        this.currentTableConfColumnsLevel.splice(res.newValue - 1, 0, reorderedColumn);
        this.currentTableConfColumnsLevel.forEach((col, index) => (col.sortOrder = index + 1));

        this.currentTableConf.hasUnsavedChanges = true;
        if (reloadColumns) {
            this.setTableDisplayColumns();
            this.renderRowsAgain();
        }

        this.updateTableConf.emit();
    }

    public getTableWidth(): string {
        return this.table && this.table.bodyComponent && this.table.bodyComponent.scroller ? this.table.bodyComponent.scroller.scrollWidth + 'px' : undefined;
    }

    public columnSortCustom(column: ColumnDisplayConf) {
        if (this.sortField[0].prop !== column.key) {
            this.sortField[0].prop = column.key;
            this.sortField[0].dir = 'asc';
        } else {
            if (this.sortField[0].dir === 'asc') this.sortField[0].dir = 'desc';
            else this.sortField[0].dir = 'asc';
        }

        this.sortField = [...this.sortField];
        this.tableDataService.sortField = this.sortField;

        this.tableDataService.pagePageOffset = 0;
        this.tableDataService.scrollTop = 0;
        if (this.table?.bodyComponent?.scroller?.parentElement?.scrollTop) this.table.bodyComponent.scroller.parentElement.scrollTop = 0;

        this.reloadDataOnPage(true);
    }

    public columnSort(event: { sorts: TableSortElement[]; column: any; prevValue: string; newValue: string }) {
        this.sortField[0].prop = event.sorts[0].prop;
        this.sortField[0].dir = event.sorts[0].dir;

        this.reloadDataOnPage(true);
    }

    public clearSelectedItemsOnAllLevels(level?: CampaignStructureLevel) {
        this.getTableService.clearSelectedItemsOnAllLevels(level);
    }

    public onHorizontalScroll(event: any) {
        if (!this.waitForHorizontalScroll)
            this.waitForHorizontalScroll = setTimeout(() => {
                if (this.table && this.table.bodyComponent) {
                    let elem = this.table.element.querySelector('datatable-body');
                    if (elem) elem.scrollLeft = event.srcElement.scrollLeft;
                    this.scrollerTableElement.nativeElement.scrollLeft = event.srcElement.scrollLeft;
                }
                this.waitForHorizontalScroll = null;
            }, 42);
    }

    setTableHorizontalScrollListener() {
        setTimeout(() => {
            let elem: Element = this.table.element.querySelector('datatable-body');
            if (elem) {
                this.listener = this.renderer.listen(elem, 'scroll', event => {
                    this.scrollerTableElement.nativeElement.scrollLeft = event.srcElement.scrollLeft;
                });
            }
        }, 1000);
    }
}
