import { OdaxObjectiveEnum, ObjectiveEnum } from "../enums";

export const mapOldObjectiveToNew: any = {
    [ObjectiveEnum.BrandAwareness]: OdaxObjectiveEnum.OUTCOME_AWARENESS,
    [ObjectiveEnum.Reach]: OdaxObjectiveEnum.OUTCOME_AWARENESS,
    [ObjectiveEnum.LinkClicks]: OdaxObjectiveEnum.OUTCOME_TRAFFIC,
    [ObjectiveEnum.PostEngagement]: OdaxObjectiveEnum.OUTCOME_ENGAGEMENT,
    [ObjectiveEnum.AppInstalls]: OdaxObjectiveEnum.OUTCOME_APP_PROMOTION,
    [ObjectiveEnum.VideoViews]: OdaxObjectiveEnum.OUTCOME_ENGAGEMENT,
    [ObjectiveEnum.LeadGeneration]: OdaxObjectiveEnum.OUTCOME_LEADS,
    [ObjectiveEnum.Messages]: OdaxObjectiveEnum.OUTCOME_LEADS,
    [ObjectiveEnum.Conversions]: OdaxObjectiveEnum.OUTCOME_SALES,
    [ObjectiveEnum.ProductCatalogSales]: OdaxObjectiveEnum.OUTCOME_SALES,
    [ObjectiveEnum.StoreVisits]: OdaxObjectiveEnum.OUTCOME_AWARENESS,
}