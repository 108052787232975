import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { InsightsQueryResponseObjectFE, AutomatedCampaignSyncSuccess, CampaignStructureLevel, ProgressBarStatus } from "../../../../../api/models";
import { ColumnDisplayConf } from "../../../models";
import { AnimationOptions } from 'ngx-lottie';
import { get } from "../../../../shared/services";

@Component({
    selector: 'table-body-automated-progress-cell',
    templateUrl: './table-body-automated-progress-cell.component.html',
    styleUrls: ['./table-body-automated-progress-cell.component.scss']
})
export class TableBodyAutomatedProgressCellComponent implements OnInit, OnChanges {
    @Input() selectedDataLevel: CampaignStructureLevel;
    @Input() row: InsightsQueryResponseObjectFE;
    @Input() column: ColumnDisplayConf; //TableColumn with ColumnDisplayConf data
    @Input() columnKey: string;

    cellDisplayValue: any;

    automatedSyncEndTime: string;
    automatedSyncStatusProgress: ProgressBarStatus;
    dateString: string;
    timeString: string;

    automatedSyncStatusSuccess: AutomatedCampaignSyncSuccess;
    mainLoaderAnimation: AnimationOptions = { path: `${get('apiUrl')}/assets/hunch-icons/animations/main_loader_animation.json` };

    constructor(

    ) {}

    ngOnInit() {
      this.automatedSyncEndTime = this.row['automatedSyncEndTime'];
      this.automatedSyncStatusProgress = this.row['automatedSyncStatusProgress'];
      this.automatedSyncStatusSuccess = this.row['automatedSyncStatusSuccess'];

      this.formatDate();
    }

    private formatDate() {
       if (this.automatedSyncEndTime) {
          let date = this.automatedSyncEndTime.split('T')[0].split('-');
          let time = this.automatedSyncEndTime.split('T')[1].split(':');
          this.dateString = `${date[2]}/${date[1]}/${date[0]}`;
          this.timeString = `${time[0]}:${time[1]}`
        }
    }

    ngOnChanges() {
      this.cellDisplayValue = this.row[this.columnKey];
    }
}
