import { Component, EventEmitter, Output } from '@angular/core';
import { createGuid, get } from '../../services';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { ConnectCampaign } from '../../models';

@Component({
  selector: 'app-connect-campaign-modal',
  templateUrl: './connect-campaign-modal.component.html',
  styleUrls: ['./connect-campaign-modal.component.scss']
})
export class ConnectCampaignModalComponent {
  @Output() modalClosed: EventEmitter<boolean> = new EventEmitter();
  campaignForConnect: ConnectCampaign[];
  metaId: any;
  loading: boolean;
  loadingCampaign: boolean;
  error: string;
  constructor(private httpClient: HttpClient) {
    this.getCampaigns();
  }
  closeModal(): void {
    this.modalClosed.emit(true);
  }

  expand(event: any) {
    const className = 'selected';
    if (event.target.classList.contains(className)) {
      event.target.classList.remove(className);
    } else {
      event.target.classList.add(className);
    }
  }

  async connectCampaign(): Promise<any> {
    this.error = '';
    this.loading = true;
    const campaign: ConnectCampaign = this.campaignForConnect.find((c: any) => c.MetaId === this.metaId);
    if (campaign.Connected) location.href = `${get('metaStandardAppUrl')}/meta/connect/${campaign.AdAccountId}/${this.metaId}/setup/campaign`;
    try {
      let headers = new HttpHeaders()
      headers = headers.set('token', get('azureTokenMeta'));
      await this.connectCampaignPublisher(campaign, headers);
      await this.connectCampaignInsights(campaign, headers);
    } catch {
      this.error = 'error'
    }
    this.loading = false;
    if (!this.error) {
      location.href = `${get('metaStandardAppUrl')}/meta/connect/${campaign.AdAccountId}/${this.metaId}/setup/campaign`;
    }
  }
  async connectCampaignPublisher(campaign: ConnectCampaign, headers: any): Promise<any> {
    const request: any = {
      teamId: get('user').teamId,
      accountId: get('user').accountId,
      requestId: createGuid(),
      campaignId: campaign.MetaId,
      adSetId: campaign.AdSets[0].MetaId
    }
    return firstValueFrom(this.httpClient.post('$publisherConnectCampaignUrl',
      request, { headers }
    ))
  }


  async connectCampaignInsights(campaign: any, headers: any): Promise<any> {
    return firstValueFrom(this.httpClient.post('$insightConnectCampaignUrl',
      { campaignId: campaign.MetaId, teamId: get('user').teamId, accountId: get('user').accountId }, { headers }
    ))
  }

  getCampaigns(): void {
    this.loadingCampaign = true;
    let headers = new HttpHeaders();
    headers = headers.set('token', get('azureTokenMeta'));
    this.httpClient.post<ConnectCampaign[]>('$connectCampaignUrl',
      {
        adAccountIds: get('adAccountIds')
      }, { headers }
    ).subscribe({
      next: (resp: ConnectCampaign[]) => {
        this.campaignForConnect = resp.map((campaign: ConnectCampaign) => {
          let adsLength: number = 0;
          campaign.AdSets.forEach((as: any) => {
            as.Ads.forEach((ad: any) => {
              if (ad.InternalId) adsLength += 1;
            });
          });
          return {
            AdInserted: adsLength, ...campaign
          }
        });;
        this.loadingCampaign = false;
      },
      error: (err: any) => {
        console.log(err);
        this.loadingCampaign = false;
      }
    })
  }
}
