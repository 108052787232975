import { Injectable } from '@angular/core';
import * as toastr from 'toastr';
@Injectable({
    providedIn:'root'
})
export class NotifyService {
    constructor() {  
        toastr.options.closeButton = true;
        toastr.options.timeOut = 5000;
        toastr.options.extendedTimeOut = 2000;
        toastr.options.progressBar = true;
    }

    success(message: string) {
        toastr.success(message, 'Success');
    }

    error(message: string) {
        toastr.error(message, 'Error');
    }

    warning(message: string) {
        toastr.warning(message, 'Warning');
    }

    info(message) {
        toastr.info(message, 'Info');
    }
}
