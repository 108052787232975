import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'enum'
})
export class EnumParsePipe implements PipeTransform {

	transform(value: string): string {
		if(!value) return '';
		if(typeof value === 'string'){
			return value.replace(/_/g,' ');
		} else {
			console.log('Enum is not a string! ', value);
			return '';
		}
	}

}
