import { GenerateId } from '../../modules/metric-table/models/column-display-conf.model';
import { CampaignStructureLevel, FilterOperator, HunchCampaignType } from './enums';

export class BasicQuery {
    constructor(value?: any) {
        const classKeys = Object.keys(this);
        if (value)
            for (const valueKey in value) {
                if (classKeys.includes(valueKey)) this[valueKey] = value[valueKey];
            }
    }

    public adAccountIds: string[] = [];
    public filterOnAccountId: boolean = true;
    public campaignStructureLevel: CampaignStructureLevel = CampaignStructureLevel.Campaign;
    public filters: InsightsFilter[] = [];
    public customfilters?: InsightsFilter[] = [];
    public from: Date | string = new Date(new Date().getDate() - 14);
    public to: Date | string = new Date();
    public platform?: string = '';
}

export class GetFacebookInsightsCountQuery extends BasicQuery {
    constructor(value?: any) {
        super();
        const classKeys = Object.keys(this);
        if (value)
            for (const valueKey in value) {
                if (classKeys.includes(valueKey) || valueKey === 'sortField') this[valueKey] = value[valueKey];
            }
    }

    public sortField: SortField = new SortField();
}

export class GetFacebookInsightsSummaryQuery extends BasicQuery {
    constructor(value?: any) {
        super();
        const classKeys = Object.keys(this);
        if (value)
            for (const valueKey in value) {
                if (classKeys.includes(valueKey)) this[valueKey] = value[valueKey];
            }
    }

    public metricKeys: string[] = [];
}

export class GetFacebookInsightsQuery extends BasicQuery {
    constructor(value?: any) {
        super();
        const classKeys = Object.keys(this);
        if (value)
            for (const valueKey in value) {
                if (classKeys.includes(valueKey)) this[valueKey] = value[valueKey];
            }
    }

    public sortField: SortField = new SortField();
    public metricKeys: string[] = [];
    public page: number = 1;
    public resultsPerPage: number = 30;
    public getRemainingItemsForMetricSort: boolean = false;
    public skip?: number;
}

export class InsightsQueryResponseObject {
    public id: string = GenerateId();

    public name: string;
    public status: string;
    public effectiveStatus: string;

    public facebookId: string;
    public hunchId: number;
    public platform?: string;
    public documentId?: string;

    public hunchCampaignType: HunchCampaignType;
    public adAccountId: string;

    public metrics: any = {};

    public previewThumbnailURL: string = '';

    public masterStatusDisabled?: boolean; //ad set and ads
    public isCampaignSyncFailed?: boolean; //campaign level
    public connected?: boolean; //campaign level


    constructor() { }

    static fromObject(item: any) {
        return Object.assign(new InsightsQueryResponseObject(), item);
    }
}

export class InsightsQueryResponseObjectFE extends InsightsQueryResponseObject {
    constructor(value?: any) {
        super();
        if (value)
            for (const valueKey in value) {
                this[valueKey] = value[valueKey];
            }
    }

    isCampaign: boolean = false;
    isAdset: boolean = false;
    isAd: boolean = false;
    isDraft: boolean = false;

    isLoadingItem: boolean = false;

    previewThumbnailURL: string = '';
    internalId?: string;
    internalCampaignId?: string;
    handpickUnpublished?: handpickUnpublishedData;
    masterCampaignId?:string;
}
export class handpickUnpublishedData {
    fieldName: string;
    status: string
}

export class InsightsFilter {
    constructor(value?: any) {
        const classKeys = Object.keys(this);
        if (value)
            for (const valueKey in value) {
                if (classKeys.includes(valueKey)) this[valueKey] = value[valueKey];
            }
    }
    metricKey: string = null;
    operator: FilterOperator = null;
    value: any = null;
    campaignStructureLevel: CampaignStructureLevel = null;
}

export class SortField {
    constructor(public sortKey: string = 'createdTime', public isAscending: boolean = false) { }
}
