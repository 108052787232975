import { ChangeDetectionStrategy, Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { UserFormatPreferencesService } from '../../../../shared/services';
import { MetricDataType } from '../../../../../api/models';

@Component({
    selector: 'cell-format-display-value',
    templateUrl: './cell-format-display-value.component.html',
    styleUrls: ['./cell-format-display-value.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CellFormatDisplayValueComponent {
    @Input() cellDisplayValue: any;
    @Input() metricDataType: MetricDataType;
    @Input() adAccountId: string;
    @Input() isSummaryRow: boolean = false;
    @Input() hideCurrency: boolean = false;

    MetricDataType = MetricDataType;
    currencySymbol: string;

    constructor(public format: UserFormatPreferencesService) {}

    ngOnChanges(changes: SimpleChanges): void {
        this.setCurrencyIfMetricDataTypeIsCurrency();
    }

    setCurrencyIfMetricDataTypeIsCurrency() {
        if (this.metricDataType === MetricDataType.Currency) {
            if (this.format.areAllProfilesWithSameCurrency) this.currencySymbol = this.format.userPreferences.defaultCurrencySymbol;
            else {
                if (this.adAccountId) {
                    this.currencySymbol = this.format.currencyByAdAccount.get(this.adAccountId);
                } else if (this.isSummaryRow) this.currencySymbol = '';
            }
            if (this.hideCurrency) this.currencySymbol = '';
        }
        if (this.metricDataType === MetricDataType.Seconds) {
            let seconds = parseInt(this.cellDisplayValue + '');
            let minutes = Math.floor(seconds / 60);
            let hours = Math.floor(minutes / 60);
            let days = Math.floor(hours / 24);
            seconds -= minutes * 60;
            minutes -= hours * 60;
            hours -= days * 24;
            let retVal = '';
            if (days) retVal += parseInt(days + '') + 'd ';
            if (days || hours) retVal += parseInt(hours + '') + 'h ';
            if (days || hours || minutes) retVal += parseInt(minutes + '') + 'm ';
            retVal += parseInt(seconds + '') + 's';
            this.cellDisplayValue = retVal;
        }
    }
}
