import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { CampaignStructureLevel, MetricIntegrationType } from '../../../../../api/models';
import { ManageOverviewService } from '../../../../manage-overview/manage-overview.service';
import { ColumnDisplayConf, TableConfigurationPresetFE, TableReloadType } from '../../../models';
import { TableService } from '../../../table-services/table-service.model';
import { MetricTypes } from '../../../../shared/consts';
import { MetricTypeEnum } from '../../../../shared';


@Component({
    selector: 'metric-toggle-buttons',
    templateUrl: './metric-toggle-buttons.component.html',
    styleUrls: ['../table-actions.component.scss', './metric-toggle-buttons.component.scss']
})
export class MetricToggleButtonsComponent implements OnInit, OnDestroy, OnChanges {
    metricTypesOptions: any[] = MetricTypes;
    @Input() selectedDataLevel: CampaignStructureLevel;
    subs: Subscription = new Subscription();
    currentTableConf: TableConfigurationPresetFE;
    dataConfigurations: ColumnDisplayConf[];
    dataConfigurationsForSelectedLevel: ColumnDisplayConf[];
    currentValueOfMetrics: any;
    areHunchMetricsDisplayed: boolean;
    areGoogleMetricsDisplayed: boolean;
    areAppsFlyerMetricsDisplayed: boolean;

    constructor(
        private manageOverviewService: ManageOverviewService //
    ) { }

    ngOnInit(): void {
        this.watchConfigurationChange();
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.setButtonValues();
        if (this.selectedDataLevel && this.dataConfigurations) this.dataConfigurationsForSelectedLevel = this.getDataConfForSelectedLevel(this.selectedDataLevel);
    }

    setButtonValues() {
        if (this.currentTableConf && this.selectedDataLevel) {
            this.areHunchMetricsDisplayed = this.currentTableConf.doesSelectedDataLevelContainHunchMetrics(this.selectedDataLevel);
            this.areGoogleMetricsDisplayed = this.currentTableConf.doesSelectedDataLevelContainGoogleMetrics(this.selectedDataLevel);
            this.areAppsFlyerMetricsDisplayed = this.currentTableConf.doesSelectedDataLevelContainAppsFlyerMetrics(this.selectedDataLevel);
        }
    }

    toggleGoogleMetrics() {
        if (this.currentTableConf) {
            this.areGoogleMetricsDisplayed = !this.areGoogleMetricsDisplayed;
            let adLevel = this.currentTableConf.getCurrentLevelColumns(CampaignStructureLevel.Ad);
            let adSetLevel = this.currentTableConf.getCurrentLevelColumns(CampaignStructureLevel.AdSet);
            let campaignLevel = this.currentTableConf.getCurrentLevelColumns(CampaignStructureLevel.Campaign);
            if (this.areGoogleMetricsDisplayed) {
                let googleMetrics = this.dataConfigurationsForSelectedLevel.filter(item => item.metricIntegrationType === MetricIntegrationType.Google || item.metricIntegrationType === MetricIntegrationType.GoogleAnalytics4);
                googleMetrics.forEach(x =>{
                    adLevel.push(new ColumnDisplayConf(x));
                    adSetLevel.push(new ColumnDisplayConf(x));
                    campaignLevel.push(new ColumnDisplayConf(x));
                } );
            } else {
                let presetItems = [...this.currentTableConf.getCurrentLevelColumns(this.selectedDataLevel)];
                let withoutGoogle = presetItems.filter(item => item.metricIntegrationType !== MetricIntegrationType.Google && item.metricIntegrationType !== MetricIntegrationType.GoogleAnalytics4);
                let currentLevel = this.currentTableConf.getCurrentLevelColumns(this.selectedDataLevel);
                adLevel.length = 0;
                adSetLevel.length = 0;
                campaignLevel.length = 0;
                withoutGoogle.forEach(x =>{
                    adLevel.push(new ColumnDisplayConf(x));
                    adSetLevel.push(new ColumnDisplayConf(x));
                    campaignLevel.push(new ColumnDisplayConf(x));
                } );
            }
            this.currentTableConf.hasUnsavedChanges = true;
            this.manageOverviewService.setCurrentTableConfiguration(this.currentTableConf);
            TableService.reloadTable$.next(TableReloadType.ChangePreset);
        }
    }

    toggleHunchMetrics() {
        if (this.currentTableConf) {
            this.areHunchMetricsDisplayed = !this.areHunchMetricsDisplayed;
            let adLevel = this.currentTableConf.getCurrentLevelColumns(CampaignStructureLevel.Ad);
            let adSetLevel = this.currentTableConf.getCurrentLevelColumns(CampaignStructureLevel.AdSet);
            let campaignLevel = this.currentTableConf.getCurrentLevelColumns(CampaignStructureLevel.Campaign);
            if (this.areHunchMetricsDisplayed) {
                let hunchMetrics = this.dataConfigurationsForSelectedLevel.filter(item => item.metricIntegrationType === MetricIntegrationType.Hunch);
                hunchMetrics.forEach(x =>{
                    adLevel.push(new ColumnDisplayConf(x));
                    adSetLevel.push(new ColumnDisplayConf(x));
                    campaignLevel.push(new ColumnDisplayConf(x));
                } );
            } else {
                let presetItems = [...this.currentTableConf.getCurrentLevelColumns(this.selectedDataLevel)];
                let withoutHunch = presetItems.filter(item => item.metricIntegrationType !== MetricIntegrationType.Hunch);
                adLevel.length = 0;
                adSetLevel.length = 0;
                campaignLevel.length = 0;
                withoutHunch.forEach(x =>{
                    adLevel.push(new ColumnDisplayConf(x));
                    adSetLevel.push(new ColumnDisplayConf(x));
                    campaignLevel.push(new ColumnDisplayConf(x));
                } );
            }
            this.currentTableConf.hasUnsavedChanges = true;
            this.manageOverviewService.setCurrentTableConfiguration(this.currentTableConf);
            TableService.reloadTable$.next(TableReloadType.ChangePreset);
        }
    }

    toggleAppsFlyerMetrics() {
        if (this.currentTableConf) {
            this.areAppsFlyerMetricsDisplayed = !this.areAppsFlyerMetricsDisplayed;
            let adLevel = this.currentTableConf.getCurrentLevelColumns(CampaignStructureLevel.Ad);
            let adSetLevel = this.currentTableConf.getCurrentLevelColumns(CampaignStructureLevel.AdSet);
            let campaignLevel = this.currentTableConf.getCurrentLevelColumns(CampaignStructureLevel.Campaign);
            if (this.areAppsFlyerMetricsDisplayed) {
                let appsFlyerMetrics = this.dataConfigurationsForSelectedLevel.filter(item => item.metricIntegrationType === MetricIntegrationType.AppsFlyer);
                appsFlyerMetrics.forEach(x =>{
                    adLevel.push(new ColumnDisplayConf(x));
                    adSetLevel.push(new ColumnDisplayConf(x));
                    campaignLevel.push(new ColumnDisplayConf(x));
                } );
            } else {
                let presetItems = [...this.currentTableConf.getCurrentLevelColumns(this.selectedDataLevel)];
                let withoutHAppsFlyer = presetItems.filter(item => item.metricIntegrationType !== MetricIntegrationType.AppsFlyer);
                adLevel.length = 0;
                adSetLevel.length = 0;
                campaignLevel.length = 0;
                withoutHAppsFlyer.forEach(x =>{
                    adLevel.push(new ColumnDisplayConf(x));
                    adSetLevel.push(new ColumnDisplayConf(x));
                    campaignLevel.push(new ColumnDisplayConf(x));
                } );
            }
            this.currentTableConf.hasUnsavedChanges = true;
            this.manageOverviewService.setCurrentTableConfiguration(this.currentTableConf);
            TableService.reloadTable$.next(TableReloadType.ChangePreset);
        }
    }

    watchConfigurationChange() {
        this.subs.add(
            this.manageOverviewService.getCurrentTableConfiguration().subscribe((data: TableConfigurationPresetFE) => {
                if (data) {
                    this.currentTableConf = data;
                    this.setButtonValues();
                }
            })
        );

        this.subs.add(
            this.manageOverviewService.dataConfigurations$.subscribe((data: ColumnDisplayConf[]) => {
                if (data) {
                    this.dataConfigurations = data;
                    if (this.selectedDataLevel) this.dataConfigurationsForSelectedLevel = this.getDataConfForSelectedLevel(this.selectedDataLevel);
                }
            })
        );
    }

    getDataConfForSelectedLevel(value: CampaignStructureLevel): ColumnDisplayConf[] {
        switch (value) {
            case CampaignStructureLevel.Campaign:
                return this.dataConfigurations.filter(item => item.campaignStructureLevels.indexOf(CampaignStructureLevel.Campaign) > -1);
            case CampaignStructureLevel.AdSet:
                return this.dataConfigurations.filter(item => item.campaignStructureLevels.indexOf(CampaignStructureLevel.AdSet) > -1);
            case CampaignStructureLevel.Ad:
                return this.dataConfigurations.filter(item => item.campaignStructureLevels.indexOf(CampaignStructureLevel.Ad) > -1);
            default:
                return;
        }
    }
    metricTypeChanged(value: any): void {
        if (!this.currentValueOfMetrics) {
            this.currentValueOfMetrics = JSON.parse(JSON.stringify(value));
            Object.keys(this.currentValueOfMetrics).forEach( (el:any) => this.currentValueOfMetrics[el].checked = false);
        }
        if (value[MetricTypeEnum.GOOGLE_ANALYTICS].checked !== this.currentValueOfMetrics[MetricTypeEnum.GOOGLE_ANALYTICS]?.checked) {
            this.currentValueOfMetrics[MetricTypeEnum.GOOGLE_ANALYTICS] = { checked: value[MetricTypeEnum.GOOGLE_ANALYTICS].checked };
            this.toggleGoogleMetrics();
        }
        if (value[MetricTypeEnum.ADJUST].checked !== this.currentValueOfMetrics[MetricTypeEnum.ADJUST]?.checked) {
            this.currentValueOfMetrics[MetricTypeEnum.ADJUST] = { checked: value[MetricTypeEnum.ADJUST].checked };
            this.toggleHunchMetrics();
        }
        if (value[MetricTypeEnum.APPS_FLYER].checked !== this.currentValueOfMetrics[MetricTypeEnum.APPS_FLYER]?.checked) {
            this.currentValueOfMetrics[MetricTypeEnum.APPS_FLYER] = { checked: value[MetricTypeEnum.APPS_FLYER].checked };
            this.toggleAppsFlyerMetrics();
        }
    }
}
