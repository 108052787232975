import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { CampaignStructureLevel } from '../../../../api/models';
import { MetricTypes } from '../../../shared/consts';


@Component({
    selector: 'table-filters',
    templateUrl: './table-filters.component.html',
    styleUrls: ['./table-filters.component.scss']
})
export class TableFiltersComponent implements OnInit {
    @Input() selectedDataLevel: CampaignStructureLevel;
    @Input('resetTableConfiguration') resetTableConfiguration: EventEmitter<any>;
    constructor() {}
    ngOnInit() {}
}
