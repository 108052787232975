import { Component, Input } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
    selector: 'hunch-main-loader',
    templateUrl: './hunch-main-loader.component.html',
    styleUrls: ['./hunch-main-loader.component.scss']
})
export class HunchMainLoaderComponent {
    @Input() defaultLoading: AnimationOptions;
    @Input() width: number = 80;
    @Input() height: number = 80;
}
