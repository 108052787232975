import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { FormControl, FormControlName, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'hunch-input',
    templateUrl: 'hunch-input.component.html',
    styleUrls: ['hunch-input.component.scss'],
    host: { '[class.ng-invalid]': 'inputInvalid' } // based on: https://stackoverflow.com/a/34643330/1677187
})
export class HunchInputComponent implements OnInit, OnDestroy {
    @Input() id: string;
    @Input() type: string = 'text';
    @Input() icon: string;
    @Input() iconV2: boolean = false;
    @Input() variant: string;
    @Input() svg: boolean;
    @Input() size: string;
    @Input() placeholder: string;
    @Input() value: any;
    @Input() loading: boolean;
    @Input() disabled: boolean;
    @Input() isSuccess: boolean;
    @Input() isCondition: boolean;
    @Input() isAutoFocus: boolean;
    @Input() inputControl: FormControl;
    @Input() parent: FormGroup = new FormGroup({}) || null;
    @Input() inputControlName: any;
    @Input() isNumeric: boolean;
    @Input() testId: string;

    @Output() blurOrEnter = new EventEmitter<any>();

    @ViewChild('inputHtmlElement', { static: false })
    set inputHtmlElement(element: ElementRef<HTMLInputElement>) {
        if(element && this.isAutoFocus) {
            element.nativeElement.focus()
        }
    }

    inputInvalid: boolean = false;
    subscriptions = new Subscription();

    constructor() {
        if (!this.id) {
            this.id = `${Math.random().toString().substr(2)}${new Date().getTime()}`;
        }
    }

    ngOnInit() {
        if (this.inputControl) {
            this.subscriptions.add(this.inputControl.valueChanges.pipe(debounceTime(100)).subscribe(value => this.valueChanged(value)));
        }
        this.valueChanged();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    valueChanged(value?: string) {
        if (this.isNumeric && value && this.doesValueContainCharacters(value)) {
            this.inputControl.setValue(value.replace(/[^0-9.]/g, ''));
        }
        this.inputInvalid = this.inputControl?.invalid;
    }

    doesValueContainCharacters(value: string) {
        return !(value.replace(/[^0-9.]/g, '') === value);
    }

    getFirstError() {
        if (!this.inputControl || !this.inputControl.errors || this.inputControl.valid) {
            return null;
        }

        let errorObject = this.inputControl.errors;
        for (let key of Object.keys(errorObject)) {
            if (key === 'required') {
                return 'This field is required.';
            }
            if (key === 'max') {
                return 'Maximum value is ' + errorObject[key].max + '.';
            }
            if (key === 'min') {
                return 'Minimum value is ' + errorObject[key].min + '.';
            }

            return errorObject[key];
        }

        return null;
    }

    confirmedValue() {
        this.blurOrEnter.emit(event || null);
    }
}
