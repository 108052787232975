import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, of } from 'rxjs';
import { EnrichAdDetails } from 'apps/manage/src/app/api/models';
import { get } from '../../services';

@Component({
  selector: 'app-enrich-ad-modal',
  templateUrl: './enrich-ad-modal.component.html',
  styleUrls: ['./enrich-ad-modal.component.scss', '../connect-campaign-modal/connect-campaign-modal.component.scss']
})
export class EnrichAdModalComponent {
  @Output() enrichModalClosed: EventEmitter<boolean> = new EventEmitter();
  loading: boolean;
  adId: string;
  imageTemplates: any[] = [];
  catalogName: string;
  ad: EnrichAdDetails = null;
  templateId: string;
  error: boolean;
  adUpdated: boolean;
  errorMessages: string;
  updateFail: boolean;

  constructor(
    private httpClient: HttpClient
  ) { }

  closeModal(): void {
    this.enrichModalClosed.emit(true);
  }

  async getAd(adId: string): Promise<void> {
    this.loading = true;
    await this.getAdDetails(adId);
    this.getHunchCatalogData(this.ad.productSetId);
  }
  async getAdDetails(adId: string): Promise<any> {
    try {
      const ad: any = await firstValueFrom(this.httpClient.get(`$hunchMetaCampaignEditorApi/meta/ad/${adId}/ad-details`));
      if (!ad.ad_data.creative.object_story_spec.template_data) {
        this.error = true;
        this.errorMessages = 'Enrich ad with Hunch template feature does not support collection ad format. Please select single image/video or carousel ad.'
        this.adId = null;
        this.loading = false;
        return;
      }
      this.ad = new EnrichAdDetails(ad);
      this.error = false;
      return ad;
    } catch (error) {
      this.error = true;
      this.errorMessages = 'Could not find an ad with this ID. Please check the ID and try again.'
      this.adId = null;
      this.loading = false;
    }

    throw new Error('Failed to fetch ad details from all provided ad accounts.');
  }

  async getHunchCatalogData(productSetId: string): Promise<void> {
    try {
      const resp: any = await firstValueFrom(
        this.httpClient.get(
          `$hunchMetaCampaignEditorApi/catalogs/${get('user')?.accountId}/${productSetId}/META/hunch-catalog-data-by-product-set-id`,
        )
      );

      if (resp) {
        // If we have a response, use the data from it
        this.imageTemplates = resp?.imageTemplates ? resp.imageTemplates : [];
        this.catalogName = resp?.name;
        this.loading = false;
      } else {
        // If no response it indicate is old catalog and need to try in sql db
        this.getCatalogData(productSetId);
      }
    } catch (error) {
      // In case of error, fall back to the old method
      console.error('Error getting Hunch catalog data:', error);
      this.getCatalogData(productSetId);
    }
  }

  getCatalogData(productSetId: string): void {
    this.httpClient.get(`$hunchAppUrl/api/v2/facebookcatalogs/${productSetId}/light-data-by-product-set-id`, { withCredentials: true })
      .subscribe({
        next: (resp: any) => {
          this.imageTemplates = resp?.imageTemplates ? resp.imageTemplates : [];
          this.catalogName = resp?.name
          this.loading = false;
        },
        error: (error: any) => {
          console.error('Error getting catalog data:', error);
          this.imageTemplates = [];
          this.loading = false;
        }
      });
  }

  updateAd(ad: EnrichAdDetails, templateId: string): void {
    this.loading = true;
    ad.adData.creative.object_story_spec.template_data.preferred_image_tags = [templateId.toString()];
    // ad.adData.creative.object_story_spec.video_data.additional_image_index = "0";
    const body = {
      oldCreativeId: ad.oldCreativeId,
      adId: ad.adData.id,
      templateId,
      ad: ad.adData
    };
    this.httpClient.put<any>(`$hunchMetaCampaignEditorApi/meta/ad/${ad.adData.account_id}/update-ad`, body)
      .subscribe({
        next: (resp: any) => {
          if (resp.errorCode) {
            this.updateFail = true;
            this.errorMessages = resp.error;
          }
          else this.adUpdated = true;
          this.loading = false;
        },
        error: (error: any) => {
          this.updateFail = true;
          this.loading = false;
          console.error('Error updating ad:', error);
        }
      });
  }

  previewAd(ad: EnrichAdDetails): void {
    this.httpClient.get<any>(`$hunchMetaCampaignEditorApi/meta/ad/${ad.adData.account_id}/${ad.adData.id}/snapshot`)
      .subscribe(
        {
          next: (resp: any) => {
            const link = resp.preview_shareable_link;
            if (link) window.open(link, '_blank');
            this.closeModal();
          },
          error: (err: any) => {
            console.log(err);
          }
        })
  }
}
