<ng-container *ngIf="cellDisplayValue; else emptyCell">

    <div class="static-col w--100  h--100 px--8 size--5 body-cell font--weight_semi is-ad-{{isAd}}">
        <div class="h-100 item-type">
            <div class="cercle-container">
                <ng-container [ngSwitch]="row.hunchCampaignType"
                    *ngIf="row.platform !== platformEnum.SNAPCHAT && !row.connected && !masterCampaign">
                    <svg-icon *ngSwitchCase="HunchCampaignType.Facebook" title="Meta Campaign" name="MetaIcon" alt=""
                        svgClass="table-icon meta-icon"></svg-icon>
                    <svg-icon *ngSwitchCase="HunchCampaignType.HunchStandard" title="Hunch Standard Campaign"
                        name="CampaignHunchStandardIcon" alt="" svgClass="table-icon hunch-icon"></svg-icon>
                    <svg-icon *ngSwitchCase="HunchCampaignType.HunchStandardDraft" title="Hunch Standard Campaign DRAFT"
                        name="CampaignHunchStandardIcon" alt="" svgClass="table-icon hunch-icon"
                        class="draft-icon"></svg-icon>
                    <svg-icon *ngSwitchCase="HunchCampaignType.Automated" title="Hunch Automated Campaign"
                        name="CampaignHunchAutomatedIcon" alt="" svgClass="table-icon"></svg-icon>
                    <svg-icon *ngSwitchCase="HunchCampaignType.AutomatedDraft" title="Hunch Automated Campaign DRAFT"
                        name="CampaignHunchAutomatedIcon" alt="" svgClass="table-icon" class="draft-icon"></svg-icon>
                    <svg-icon *ngSwitchCase="HunchCampaignType.PostBoosting"
                        title="Hunch Automated Campaign - Post Boosting" name="CampaignHunchPostBoostingIcon" alt=""
                        svgClass="table-icon"></svg-icon>
                </ng-container>
                <ng-container *ngIf=" row.platform !== platformEnum.SNAPCHAT && masterCampaign">
                    <svg-icon title="Hunch Automated Campaign" name="CampaignHunchAutomatedMasterIcon" alt=""
                        svgClass="table-icon"
                        [ngClass]="{'draft-icon': row.hunchCampaignType === HunchCampaignType.AutomatedDraft}"></svg-icon>
                </ng-container>
                <ng-container *ngIf="row.platform !== platformEnum.SNAPCHAT && row.connected">
                    <svg-icon title="Inseted Hunch Ad into Meta Campaign" name="MetaInsertedIcon" alt=""
                        svgClass="table-icon meta-icon"
                        [ngClass]="{'draft-icon': row.hunchCampaignType === HunchCampaignType.HunchStandardDraft}"></svg-icon>
                </ng-container>
                <ng-container [ngSwitch]="row.hunchCampaignType" *ngIf="row.platform === platformEnum.SNAPCHAT">
                    <svg-icon *ngSwitchCase="HunchCampaignType.Automated" title="Hunch Automated Campaign"
                        name="CampaignHunchAutomatedIcon" alt="" svgClass="table-icon"></svg-icon>
                    <svg-icon *ngSwitchCase="HunchCampaignType.HunchStandard" title="Hunch Standard Campaign"
                        name="CampaignHunchSnapchatStandardIcon" alt="" svgClass="table-icon"></svg-icon>
                </ng-container>
            </div>
            <div class="hidden-container" *ngIf="isCampaignSyncFailed">
                <svg-icon name="Warning 2 Icon" alt="" svgClass="table-icon hunch-icon--post-boosting"
                    title="Last Sync Pending"></svg-icon>
            </div>
        </div>
        <div class="ad-thumbnail" *ngIf="isAd">
            <ng-container *ngIf="row.previewThumbnailURL; else noImage">
                <img src="{{row.previewThumbnailURL}}" alt="">
            </ng-container>
            <ng-template #noImage>
                <svg-icon name="ImgIcon" alt="" svgClass="no-image-preview"></svg-icon>
            </ng-template>

        </div>
        <ng-container *ngIf="!isFacebook; else justName">
            <div class="h--100 hover-actions {{additionalClass}} show-thumb-{{isAd}} any-case">
                <div class="h--100 truncate">
                    <span title="{{cellDisplayValue}}" class="cursor--pointer" (click)="clickOnName()"
                        [ngClass]="{'link--hoverQ': selectedDataLevel !== CampaignStructureLevel.Ad && !isDraft}">
                        <div class="camp-name">{{cellDisplayValue}}</div>
                        <span class="small info">{{cellDisplayValueInfo}}</span>
                        <span class="small hover-show">
                            <span *ngIf="hasEdit" class="mr--4 icon-hover cursor--pointer" (click)="editCampaign()"
                                title="Edit" data-cy="campaignEdit">
                                <svg-icon name="EditNewIcon" alt="" svgClass="table-icon"></svg-icon> Edit
                            </span>
                            <span *ngIf="hasOverview && row.platform !== platformEnum.SNAPCHAT"
                                class="mr--4 icon-hover cursor--pointer" title="Overview" data-cy=""
                                (click)="openOverview(row)">
                                <svg-icon name="OverviewIcon" alt="" svgClass="table-icon"></svg-icon> Overview
                            </span>
                            <span
                                *ngIf="hasDuplicate && row.platform !== platformEnum.SNAPCHAT && !row.masterCampaignId && (row.adAccountId != '' || row.hunchCampaignType === HunchCampaignType.Automated || row.hunchCampaignType === HunchCampaignType.AutomatedDraft )"
                                class="mr--4 icon-hover cursor--pointer" (click)="showDupliateModal()"
                                title="Duplicate">
                                <svg-icon name="CopyIcon" alt="" svgClass="table-icon"></svg-icon> Duplicate
                            </span> <span *ngIf="hasLog && row.platform !== platformEnum.SNAPCHAT  "
                                class="mr--4 icon-hover cursor--pointer" (click)="openLog(row)" title="View log">
                                <svg-icon name="ViewLogIcon" alt="" svgClass="table-icon"></svg-icon> View log
                            </span>
                            <span *ngIf="hasSharebleLink && selectedDataLevel === CampaignStructureLevel.Ad"
                                class="mr--4 icon-hover cursor--pointer" (click)="onClickPreview(row.facebookId)"
                                title="View Ad">
                                <svg-icon name="MetaIcon" alt="" svgClass="table-icon"></svg-icon> Preview ad on meta
                            </span>
                            <span
                                *ngIf="hasDisconnect && row.platform !== platformEnum.SNAPCHAT&& row.platform !== platformEnum.META"
                                class="mr--4 {{isAlreadyDisconnected? '' : 'icon-hover'}} cursor--pointer"
                                (click)="showDisconnectSyncAutomatedCampaignModal()" title="Disconnect">
                                <svg-icon name="DisableSync" alt="" svgClass="table-icon"></svg-icon> Disconnect
                            </span>

                            <span
                                *ngIf="hasDelete && row.platform !== platformEnum.SNAPCHAT && row.platform !== platformEnum.META"
                                class="mr--4 icon-hover cursor--pointer" (click)="deleteDraft()" title="Delete">
                                <svg-icon name="Delete" alt="" svgClass="table-icon"></svg-icon> Delete
                            </span>
                        </span>
                    </span>
                </div>
                <!-- <div class="h--100 hover-show">
                    <span *ngIf="hasLog && row.platform !== platformEnum.SNAPCHAT && row.platform !== platformEnum.META" class="ml--4 mr--4 icon-hover cursor--pointer" (click)="openAutomatedCampaignLog()" title="Open log">
                        <svg-icon name="ExportCSV" alt="" svgClass="table-icon"></svg-icon>
                    </span>
                    <span *ngIf="hasDisconnect && row.platform !== platformEnum.SNAPCHAT&& row.platform !== platformEnum.META" class="ml--4 mr--4 {{isAlreadyDisconnected? '' : 'icon-hover'}} cursor--pointer" (click)="showDisconnectSyncAutomatedCampaignModal()" title="Disconnect">
                        <svg-icon name="DisableSync" alt="" svgClass="table-icon"></svg-icon>
                    </span>
                    <span *ngIf="hasDuplicate && row.platform !== platformEnum.SNAPCHAT" class="ml--4 mr--4 icon-hover cursor--pointer" (click)="showDupliateModal()" title="Duplicate">
                        <svg-icon name="Duplicate" alt="" svgClass="table-icon"></svg-icon>
                    </span>
                    <span *ngIf="hasEdit" class="ml--4 mr--4 icon-hover cursor--pointer" (click)="editCampaign()" title="Edit" data-cy="campaignEdit">
                        <svg-icon name="Edit" alt="" svgClass="table-icon"></svg-icon>
                    </span>
                    <span *ngIf="hasDelete && row.platform !== platformEnum.SNAPCHAT&& row.platform !== platformEnum.META" class="ml--4 mr--4 icon-hover cursor--pointer" (click)="deleteDraft()" title="Delete">
                        <svg-icon name="Delete" alt="" svgClass="table-icon"></svg-icon>
                    </span>
                </div> -->
            </div>
        </ng-container>


    </div>


</ng-container>

<ng-template #emptyCell>
    <div class="w--100 h--100 truncate px--8 size--5 body-cell">
        &nbsp;-&nbsp;
    </div>
</ng-template>
<ng-template #justName>
    <div class="h--100 hover-actions {{additionalClass}} show-thumb-{{isAd}} any-case"
        *ngIf="selectedDataLevel === CampaignStructureLevel.Campaign && connectFullCampaignAllow">
        <div class="h--100 truncate">
            <span title="{{cellDisplayValue}}" class="cursor--pointer" (click)="clickOnName()"
                [ngClass]="{'link--hoverQ': selectedDataLevel === CampaignStructureLevel.Campaign}">
                <div class="camp-name">{{cellDisplayValue}}</div>
                <span class="small info">Meta campaign - Published</span>
                <span class="small hover-show">
                    <span class="mr--4 icon-hover cursor--pointer" (click)="connectFullCampaign()" title="Connect "
                        data-cy="campaignConnect">
                        <svg-icon name="EditNewIcon" alt="" svgClass="table-icon"></svg-icon> Connect
                    </span>
                </span>
            </span>
        </div>
    </div>
    <div class="h--100 fb-name truncate"
        *ngIf="selectedDataLevel !== CampaignStructureLevel.Campaign || !connectFullCampaignAllow">
        <div class="camp-name link--hover cursor--pointer" (click)="clickOnName()">{{cellDisplayValue}}</div>
        <span class="small info">Meta campaign - Published</span>
    </div>
</ng-template>