export enum SnapchateObjectiveEnum {
    APP_INSTALL = 'APP_INSTALL',
    APP_CONVERSION = 'APP_CONVERSION',
    BRAND_AWARENESS = 'BRAND_AWARENESS',
    PROMOTE_PLACES = 'PROMOTE_PLACES',
    WEB_VIEW = 'WEB_VIEW',
    APP_REENGAGEMENT = 'APP_REENGAGEMENT',
    ENGAGEMENT = 'ENGAGEMENT',
    VIDEO_VIEW = 'VIDEO_VIEW',
    LEAD_GENERATION = 'LEAD_GENERATION',
    WEB_CONVERSION = 'WEB_CONVERSION',
    CATALOG_SALES = 'CATALOG_SALES'
}