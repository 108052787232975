import { CallToActionEnum, OldCallToActionTypeEnum } from "../enums";

export const mapCallToAction: any = {
    [OldCallToActionTypeEnum.OPEN_LINK]: CallToActionEnum.OPEN_LINK,
    [OldCallToActionTypeEnum.LIKE_PAGE]: CallToActionEnum.LIKE_PAGE,
    [OldCallToActionTypeEnum.SHOP_NOW]: CallToActionEnum.SHOP_NOW,
    [OldCallToActionTypeEnum.PLAY_GAME]: CallToActionEnum.PLAY_GAME,
    [OldCallToActionTypeEnum.INSTALL_APP]: CallToActionEnum.INSTALL_APP,
    [OldCallToActionTypeEnum.USE_APP]: CallToActionEnum.USE_APP,
    [OldCallToActionTypeEnum.CALL]: CallToActionEnum.CALL,
    [OldCallToActionTypeEnum.CALL_ME]: CallToActionEnum.CALL_ME,
    [OldCallToActionTypeEnum.VIDEO_CALL]: CallToActionEnum.VIDEO_CALL,
    [OldCallToActionTypeEnum.INSTALL_MOBILE_APP]: CallToActionEnum.INSTALL_MOBILE_APP,
    [OldCallToActionTypeEnum.USE_MOBILE_APP]: CallToActionEnum.USE_MOBILE_APP,
    [OldCallToActionTypeEnum.MOBILE_DOWNLOAD]: CallToActionEnum.MOBILE_DOWNLOAD,
    [OldCallToActionTypeEnum.BOOK_TRAVEL]: CallToActionEnum.BOOK_TRAVEL,
    [OldCallToActionTypeEnum.LISTEN_MUSIC]: CallToActionEnum.LISTEN_MUSIC,
    [OldCallToActionTypeEnum.WATCH_VIDEO]: CallToActionEnum.WATCH_VIDEO,
    [OldCallToActionTypeEnum.LEARN_MORE]: CallToActionEnum.LEARN_MORE,
    [OldCallToActionTypeEnum.SIGN_UP]: CallToActionEnum.SIGN_UP,
    [OldCallToActionTypeEnum.DOWNLOAD]: CallToActionEnum.DOWNLOAD,
    [OldCallToActionTypeEnum.WATCH_MORE]: CallToActionEnum.WATCH_MORE,
    [OldCallToActionTypeEnum.NO_BUTTON]: CallToActionEnum.NO_BUTTON,
    [OldCallToActionTypeEnum.VISIT_PAGES_FEED]: CallToActionEnum.VISIT_PAGES_FEED,
    [OldCallToActionTypeEnum.CALL_NOW]: CallToActionEnum.CALL_NOW,
    [OldCallToActionTypeEnum.APPLY_NOW]: CallToActionEnum.APPLY_NOW,
    [OldCallToActionTypeEnum.CONTACT]: CallToActionEnum.CONTACT,
    [OldCallToActionTypeEnum.BUY_NOW]: CallToActionEnum.BUY_NOW,
    [OldCallToActionTypeEnum.GET_OFFER]: CallToActionEnum.GET_OFFER,
    [OldCallToActionTypeEnum.GET_OFFER_VIEW]: CallToActionEnum.GET_OFFER_VIEW,
    [OldCallToActionTypeEnum.BUY_TICKETS]: CallToActionEnum.BUY_TICKETS,
    [OldCallToActionTypeEnum.UPDATE_APP]: CallToActionEnum.UPDATE_APP,
    [OldCallToActionTypeEnum.GET_DIRECTIONS]: CallToActionEnum.GET_DIRECTIONS,
    [OldCallToActionTypeEnum.BUY]: CallToActionEnum.BUY,
    [OldCallToActionTypeEnum.MESSAGE_PAGE]: CallToActionEnum.MESSAGE_PAGE,
    [OldCallToActionTypeEnum.DONATE]: CallToActionEnum.DONATE,
    [OldCallToActionTypeEnum.SUBSCRIBE]: CallToActionEnum.SUBSCRIBE,
    [OldCallToActionTypeEnum.SAY_THANKS]: CallToActionEnum.SAY_THANKS,
    [OldCallToActionTypeEnum.SELL_NOW]: CallToActionEnum.SELL_NOW,
    [OldCallToActionTypeEnum.SHARE]: CallToActionEnum.SHARE,
    [OldCallToActionTypeEnum.DONATE_NOW]: CallToActionEnum.DONATE_NOW,
    [OldCallToActionTypeEnum.GET_QUOTE]: CallToActionEnum.GET_QUOTE,
    [OldCallToActionTypeEnum.CONTACT_US]: CallToActionEnum.CONTACT_US,
    [OldCallToActionTypeEnum.ORDER_NOW]: CallToActionEnum.ORDER_NOW,
    [OldCallToActionTypeEnum.START_ORDER]: CallToActionEnum.START_ORDER,
    [OldCallToActionTypeEnum.ADD_TO_CART]: CallToActionEnum.ADD_TO_CART,
    [OldCallToActionTypeEnum.VIDEO_ANNOTATION]: CallToActionEnum.VIDEO_ANNOTATION,
    [OldCallToActionTypeEnum.MOMENTS]: CallToActionEnum.MOMENTS,
    [OldCallToActionTypeEnum.RECORD_NOW]: CallToActionEnum.RECORD_NOW,
    [OldCallToActionTypeEnum.REFER_FRIENDS]: CallToActionEnum.REFER_FRIENDS,
    [OldCallToActionTypeEnum.REQUEST_TIME]: CallToActionEnum.REQUEST_TIME,
    [OldCallToActionTypeEnum.GET_SHOWTIMES]: CallToActionEnum.GET_SHOWTIMES,
    [OldCallToActionTypeEnum.LISTEN_NOW]: CallToActionEnum.LISTEN_NOW,
    [OldCallToActionTypeEnum.WOODHENGE_SUPPORT]: CallToActionEnum.WOODHENGE_SUPPORT,
    [OldCallToActionTypeEnum.SOTTO_SUBSCRIBE]: CallToActionEnum.SOTTO_SUBSCRIBE,
    [OldCallToActionTypeEnum.FOLLOW_USER]: CallToActionEnum.FOLLOW_USER,
    [OldCallToActionTypeEnum.EVENT_RSVP]: CallToActionEnum.EVENT_RSVP,
    [OldCallToActionTypeEnum.WHATSAPP_MESSAGE]: CallToActionEnum.WHATSAPP_MESSAGE,
    [OldCallToActionTypeEnum.FOLLOW_NEWS_STORYLINE]: CallToActionEnum.FOLLOW_NEWS_STORYLINE,
    [OldCallToActionTypeEnum.SEE_MORE]: CallToActionEnum.SEE_MORE,
    [OldCallToActionTypeEnum.FIND_A_GROUP]: CallToActionEnum.FIND_A_GROUP,
    [OldCallToActionTypeEnum.FIND_YOUR_GROUPS]: CallToActionEnum.FIND_YOUR_GROUPS,
    [OldCallToActionTypeEnum.PAY_TO_ACCESS]: CallToActionEnum.PAY_TO_ACCESS,
    [OldCallToActionTypeEnum.PURCHASE_GIFT_CARDS]: CallToActionEnum.PURCHASE_GIFT_CARDS,
    [OldCallToActionTypeEnum.FOLLOW_PAGE]: CallToActionEnum.FOLLOW_PAGE,
    [OldCallToActionTypeEnum.SEND_A_GIFT]: CallToActionEnum.SEND_A_GIFT,
    [OldCallToActionTypeEnum.SWIPE_UP_SHOP]: CallToActionEnum.SWIPE_UP_SHOP,
    [OldCallToActionTypeEnum.SWIPE_UP_PRODUCT]: CallToActionEnum.SWIPE_UP_PRODUCT,
    [OldCallToActionTypeEnum.SEE_MENU]: CallToActionEnum.SEE_MENU,
    [OldCallToActionTypeEnum.SAVE]: CallToActionEnum.SAVE,
    [OldCallToActionTypeEnum.VIEW_INSTAGRAM_PROFILE]: CallToActionEnum.VIEW_INSTAGRAM_PROFILE
}