import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FacebookCampaign, HunchCampaignType, InsightsQueryResponseObjectFE } from '../../../../../../api/models';
import { createGuid, get, set } from '../../../../../shared/services';
import { Subscription, firstValueFrom } from 'rxjs';
import { TableReloadType } from '../../../../models';
import { TableService } from '../../../../table-services';
import { DuplicateApiService, AutomatedDuplicateResponse } from '../../../../../../api/services';
import { OdaxObjectiveEnum, PlatformEnum } from '../../../../../shared/enums';
import { Liquid } from 'liquidjs';
import { mapAdFormat, mapAudienceNetworkPosition, mapBidType, mapBillingEvent, mapCallToAction, mapConversionWindow, mapEventType, mapFacebookPosition, mapForOldPatternValue, mapGeoLocation, mapInstagramPosition, mapMessengerPosition, mapOldObjectiveToNew, mapOptimizationGoal, mapProductAudienceSpec, mapTargetingType, optimizationGoalByObjective, replacePatternValues } from '../../../../../../modules/shared/consts';
import * as path from 'path';
@Component({
    selector: 'manage-duplicate-campaign-modal',
    templateUrl: './duplicate-campaign-modal.component.html',
    styleUrls: ['../table-hover-cell-action-handler.component.scss', './duplicate-campaign-modal.component.scss']
})
export class DuplicateCampaignModalComponent implements OnInit, OnDestroy {
    @Input() pageRow: InsightsQueryResponseObjectFE | any;
    @Output() emitValue: any = new EventEmitter<DuplicateRequest | DuplicateRequestAutomated | string>();
    groupFormControl: FormControl = new FormControl();
    acconutFormControl: FormControl = new FormControl();
    dpaCatalogFormControl: FormControl = new FormControl();
    automatedCatalogFormControl: FormControl = new FormControl();
    loading: boolean = true;
    groups: Group[];
    accounts: Profile[];
    catalogs: Catalog[];

    isAutomated: boolean;
    isDpa: boolean;
    isHuncStandard: boolean;
    shouldShow: boolean = true;

    subs: Subscription = new Subscription();
    newCampaign: any;

    constructor(
        private duplicateApiService: DuplicateApiService //
    ) { }

    ngOnInit(): void {
        this.setState();
        this.loadData();
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    submitForm() {
        if (this.isFormValid()) this.postDuplicate();
    }

    async loadData() {
        const data = await firstValueFrom(this.duplicateApiService.loadGroups());
        if (data) this.groups = data;
        else this.groups = [];
        if (this.pageRow.platform === PlatformEnum.META) {
            this.newCampaign = await this.loadCampaign();
            if (this.newCampaign) {
                this.isAutomated = this.newCampaign.standardCampaign.campaign.general.automated_campaign
                if (this.isAutomated) this.pageRow.automatedCatalogId = this.newCampaign.catalogId;
                this.isDpa = this.newCampaign.standardCampaign.campaign.setup.objective === OdaxObjectiveEnum.OUTCOME_SALES && this.newCampaign.standardCampaign.campaign.general.catalog_id;
                if (this.isDpa) this.pageRow.catalogId = this.newCampaign.catalogId;
            }
        } else {
            this.isDpa = (this.pageRow as FacebookCampaign).objective === 'PRODUCT_CATALOG_SALES';
        }
        this.preloadData();
        this.setFormValueChangesDetection();

    }

    async loadCampaign(): Promise<any> {
        const campaignId = this.pageRow.internalId;
        return firstValueFrom(this.duplicateApiService.fetchCampaign(campaignId));
    }

    setState() {
        if (this.pageRow) {
            this.isAutomated = this.pageRow.hunchCampaignType === HunchCampaignType.Automated || this.pageRow.hunchCampaignType === HunchCampaignType.AutomatedDraft;
            this.isHuncStandard = this.pageRow.hunchCampaignType === HunchCampaignType.HunchStandard || this.pageRow.hunchCampaignType === HunchCampaignType.HunchStandardDraft;
        }
    }

    setFormValueChangesDetection() {
        this.subs.add(
            this.groupFormControl.valueChanges.subscribe((groupId: number) => {
                let selectedGroup = this.groups.find(group => group.id === groupId);
                if (selectedGroup) {
                    this.accounts = selectedGroup.profiles.filter(prof => prof.type === 3);
                    if (this.isAutomated) this.catalogs = selectedGroup.groupAutomatedCatalogs;
                    if (this.isDpa) this.catalogs = selectedGroup.groupCatalogs;
                }
            })
        );
    }

    isFormValid(): boolean {
        if (!this.groupFormControl.value) return false;
        if (!this.acconutFormControl.value) return false;
        if (this.isAutomated && !this.automatedCatalogFormControl.value) return false;
        if (this.isDpa && !this.dpaCatalogFormControl.value) return false;
        return true;
    }

    postDuplicate(): void {
        let retVal: DuplicateRequest | DuplicateRequestAutomated;
        if (this.isAutomated) {
            retVal = {
                isAutomated: true,
                campaignId: this.pageRow.hunchId,
                catalogId: this.automatedCatalogFormControl.value,
                profileId: this.acconutFormControl.value,
                accountId: this.groupFormControl.value
            };
        } else {
            retVal = {
                isAutomated: false,
                destinationCatalogId: this.dpaCatalogFormControl.value,
                destinationGroupId: this.groupFormControl.value,
                destinationProfileId: this.acconutFormControl.value,
                facebookID: this.pageRow.facebookId,
                id: this.pageRow.hunchId,
                profileId: this.pageRow.adAccountId
            };
        }
        if (this.isAutomated) {
            if (this.pageRow.platform === PlatformEnum.META) {
                const masterCampaign: boolean = this.pageRow.name?.includes('{{item.')
                this.duplicateAutomatedMeta(masterCampaign);
            } else {
                this.duplicateApiService.duplicateAutomated(retVal as DuplicateRequestAutomated).subscribe((data: AutomatedDuplicateResponse) => {
                    TableService.reloadTable$.next(TableReloadType.DuplicatedToDraft);
                    // --!!!!! add notification
                    // this.emitValue.emit(retVal);
                });
            }
        } else {
            if (this.pageRow.platform === PlatformEnum.META) {
                this.duplicateStandardMeta();
                return;
            } else {
                this.duplicateStandard();
                return;
            }
        }

    }

    duplicateStandard(): void {
        const campaignId = this.pageRow.hunchId;
        this.duplicateApiService.fetchFacebookCampaign(campaignId).subscribe({
            next: (oldCampaign: any) => {
                this.mapOldToNewCampaign(oldCampaign)
                    .then((newDuplicatedCampaign) => {
                        if (newDuplicatedCampaign.adAccountId !== this.acconutFormControl.value) {
                            newDuplicatedCampaign.adAccountId = this.acconutFormControl.value;
                            newDuplicatedCampaign.standardCampaign.campaign.setup.ad_account_id = this.acconutFormControl.value;;
                        }
                        this.duplicateApiService.duplicateMetaCampaign(newDuplicatedCampaign).subscribe({
                            next: (draft: any) => {
                                TableService.reloadTable$.next(TableReloadType.DuplicatedToDraft);
                                this.emitValue.emit(draft.PartitionKey);
                            },
                            error: (err) => console.log(err)
                        });
                    });
            },
            error: (err) => console.log(err)
        });
    }

    duplicateStandardMeta(): void {
        const duplicatedCampaign = this.mapDuplicate(this.newCampaign);
        this.duplicateApiService.duplicateMetaCampaign(duplicatedCampaign).subscribe({
            next: (draft: any) => {
                TableService.reloadTable$.next(TableReloadType.DuplicatedToDraft);
                this.emitValue.emit(draft.PartitionKey);
            },
            error: (err) => console.log(err)
        });
    }


    duplicateAutomatedMeta(masterCampaign?: boolean): void {
        const duplicatedCampaign = this.mapDuplicateAutomated(this.newCampaign, false, masterCampaign);
        this.duplicateApiService.duplicateMetaCampaign(duplicatedCampaign).subscribe({
            next: (draft: any) => {
                TableService.reloadTable$.next(TableReloadType.DuplicatedToDraft);
                this.emitValue.emit(draft.PartitionKey);
            },
            error: (err) => console.log(err)
        });
    }

    mapDuplicate(campaign: any, useForJest?: boolean): any {
        const campaignId = useForJest ? '' : createGuid();
        let adSetCntTmp: number = 0;
        let adCntTmp: number = 0;
        let differentAccount = false;
        ////////////////////////////////////////
        campaign.standardCampaign.campaign.general.name = campaign.standardCampaign.campaign.general.name_pattern = `Copy of ${campaign.standardCampaign.campaign.general.name}`;
        //////////////////////////////////////// 
        campaign.campaignId = campaignId;
        campaign.standardCampaign.campaign.id = campaignId;
        campaign.requestId = useForJest ? '' : createGuid();
        const currentVersion = campaign.standardCampaign?.campaign?.version;
        if (!currentVersion || currentVersion < 4) campaign.standardCampaign.campaign.version = 4;

        if (campaign.adAccountId !== this.acconutFormControl.value) {
            differentAccount = true;
            campaign.adAccountId = this.acconutFormControl.value;
            campaign.standardCampaign.campaign.setup.ad_account_id = this.acconutFormControl.value;
            campaign.standardCampaign.campaign.tracking.pixel_id = null;
        }

        campaign.errors = [];

        campaign.standardCampaign.adsets.forEach((adset: any) => {
            const oldAdsetId = adset.adSetId;
            adset.adSetId = useForJest ? `${adSetCntTmp++}` : createGuid();

            if (!useForJest) {
                const currentDate = new Date();
                currentDate.setHours(currentDate.getHours() + 2);
                const endTime = new Date(currentDate).setHours(currentDate.getHours() + 1);
                if (adset.scheduling.start_time && (currentDate > new Date(adset.scheduling.start_time))) {
                    adset.scheduling.start_time = new Date(currentDate).toISOString();
                }
                if (adset.scheduling.end_time && (new Date() > new Date(adset.scheduling.end_time))) {
                    adset.scheduling.end_time = new Date(endTime).toISOString();
                }
                if (differentAccount && adset.targeting?.audience) {
                    adset.targeting.audience.include_custom_audience = []
                    adset.targeting.audience.exclude_custom_audience = []
                    adset.targeting.audience.saved_audience = false;
                    adset.targeting.audience.saved_audience_id = null;
                    adset.targeting.audience.lookalike_expansion = false;
                    adset.targeting.audience.enable_exclude_custom_audience = false;
                }

            }
            campaign.standardCampaign.ads
                .filter((ad: any) => ad.adSetId === oldAdsetId)
                .forEach((ad: any) => {
                    ad.adId = useForJest ? `${adCntTmp++}` : createGuid();
                    ad.creative.creativeId = useForJest ? '' : createGuid();
                    ad.adSetId = adset.adSetId;
                    if (differentAccount) ad.tracking.website.pixel_id = null;
                });
        });
        return campaign;
    }

    mapDuplicateAutomated(campaign: any, useForJest?: boolean, masterCampaign?: boolean): any {
        const campaignId = useForJest ? '' : createGuid();

        let adSetCntTmp: number = 0;
        let adCntTmp: number = 0;
        let differentAccount = false;
        ////////////////////////////////////////
        campaign.standardCampaign.campaign.general.name = campaign.standardCampaign.campaign.general.name_pattern = `Copy of ${campaign.standardCampaign.campaign.general.name}`;
        //////////////////////////////////////// 
        masterCampaign ? campaign.standardCampaign.campaign.masterCampaignId = campaignId : campaign.standardCampaign.campaign.id = campaignId;
        campaign.campaignId = campaignId;
        campaign.requestId = useForJest ? '' : createGuid();
        const currentVersion = campaign.standardCampaign?.campaign?.version;
        if (!currentVersion || currentVersion < 4) campaign.standardCampaign.campaign.version = 4;

        if (campaign.adAccountId !== this.acconutFormControl.value) {
            differentAccount = true;
            campaign.adAccountId = this.acconutFormControl.value;
            campaign.standardCampaign.campaign.setup.ad_account_id = this.acconutFormControl.value;
            campaign.standardCampaign.campaign.tracking.pixel_id = null;
        }
        campaign.errors = [];

        campaign.standardCampaign.adsets.forEach((adset: any) => {
            const oldAdsetId = adset.masterAdSetId;
            adset.masterAdSetId = useForJest ? `${adSetCntTmp++}` : createGuid();

            if (!useForJest) {
                const currentDate = new Date();
                currentDate.setHours(currentDate.getHours() + 2);
                const endTime = new Date(currentDate).setHours(currentDate.getHours() + 1);
                if (adset.scheduling.start_time && (currentDate > new Date(adset.scheduling.start_time))) {
                    adset.scheduling.start_time = new Date(currentDate).toISOString();
                }
                if (adset.scheduling.end_time && (new Date() > new Date(adset.scheduling.end_time))) {
                    adset.scheduling.end_time = new Date(endTime).toISOString();
                }
                if (differentAccount && adset.targeting?.audience) {
                    adset.targeting.audience.include_custom_audience = []
                    adset.targeting.audience.exclude_custom_audience = []
                    adset.targeting.audience.saved_audience = false;
                    adset.targeting.audience.saved_audience_id = null;
                    adset.targeting.audience.lookalike_expansion = false;
                    adset.targeting.audience.enable_exclude_custom_audience = false;
                }
            }
            campaign.standardCampaign.ads
                .filter((ad: any) => ad.masterAdSetId === oldAdsetId)
                .forEach((ad: any) => {
                    ad.masterAdId = useForJest ? `${adCntTmp++}` : createGuid();
                    ad.masterAdSetId = adset.masterAdSetId;
                    if (differentAccount) ad.tracking.website.pixel_id = null;
                });
        });
        return campaign;
    }

    async mapOldToNewCampaign(oldCampaign: any, useForJest?: boolean): Promise<any> {
        let adSetCntTmp = 0;
        let adCntTmp = 0;
        return new Promise((resolve, _) => {
            const liquidPath = !useForJest ? './assets/liquids' : path.join(__dirname, '../../../../../../../assets/liquids');
            const engine = new Liquid({
                root: liquidPath
            });

            let changedCatalog = false;
            const chosenCatalog = this.dpaCatalogFormControl.value;
            if (!useForJest && oldCampaign.facebookCatalogId) {
                let existingCatalog = this.catalogs?.find(catalog => catalog.id === oldCampaign.facebookCatalogId)?.facebookId;
                oldCampaign.facebookCatalogId = chosenCatalog;
                if (existingCatalog !== chosenCatalog) changedCatalog = true;
            }

            oldCampaign["campaignId"] = useForJest ? "" : createGuid();
            oldCampaign["requestId"] = useForJest ? "" : createGuid();

            oldCampaign["accountId"] = get('user')?.accountId;

            oldCampaign.campaign.budgetOptimization.bid = mapBidType[oldCampaign.campaign.budgetOptimization.bid];

            oldCampaign.campaign.tracking.trackingPattern = replacePatternValues(oldCampaign.campaign.tracking.trackingPattern);
            oldCampaign.campaign.general.name = replacePatternValues(oldCampaign.campaign.general.name);

            oldCampaign["objective"] = mapOldObjectiveToNew[oldCampaign["objective"]];
            oldCampaign.adSets.forEach((adSet: any) => {
                adSet["adSetId"] = useForJest ? `${adSetCntTmp++}` : createGuid();
                adSet.bidding.bidOptions = mapBidType[adSet.bidding.bidOptions];

                adSet.general.name = replacePatternValues(adSet.general.name);


                if (!useForJest) {
                    const currentDate = new Date();
                    currentDate.setHours(currentDate.getHours() + 2);
                    adSet.budgetAndSchedule.adSetStartDateTime = new Date(currentDate).toISOString();
                    adSet.budgetAndSchedule.adSetEndDateTime = null;
                }

                // changing productSetId to null if the catalog has been changed
                adSet.productSetId = changedCatalog ? "" : adSet.productSetId;

                adSet.bidding.optimizationGoal = mapOptimizationGoal[adSet.bidding.optimizationGoal];
                if (!adSet.bidding.optimizationGoal)
                    adSet.bidding.optimizationGoal = optimizationGoalByObjective[oldCampaign.objective];

                adSet.bidding.billingEvent = mapBillingEvent[adSet.bidding.billingEvent];
                adSet.bidding.conversionEvent = mapEventType[adSet.bidding.conversionEvent];
                adSet.bidding.conversionWindow = mapConversionWindow[adSet.bidding.conversionWindow];

                if (adSet.targeting.targetingSpec) {
                    if (adSet.targeting.targetingSpec?.targetingType === 0)
                        adSet.targeting.targetingSpec.retargeting.eventsPreset = mapProductAudienceSpec[adSet.targeting.targetingSpec.retargeting.eventsPreset];

                    adSet.targeting.targetingSpec.detailedTargeting?.forEach((element: any) => {
                        element.type = mapTargetingType[element.type];
                    });
                    adSet.targeting.targetingSpec.detailedTargetingExclude?.forEach((element: any) => {
                        element.type = mapTargetingType[element.type];
                    });
                    adSet.targeting.targetingSpec.detailedTargetingNarrow?.forEach((elementArr: any) => {
                        elementArr?.forEach((element: any) => {
                            element.type = mapTargetingType[element.type];
                        })
                    });
                    adSet.targeting.targetingSpec.location?.excludeLocations?.forEach((element: any) => {
                        element.type = mapGeoLocation[element.type];
                    });
                    adSet.targeting.targetingSpec.location?.includeLocations?.forEach((element: any) => {
                        element.type = mapGeoLocation[element.type];
                        element.typeText = mapGeoLocation[element.type];
                    });
                }
                if (adSet.placement.placementType === 1) {
                    adSet.placement.facebookPositions = adSet.placement.facebookPositions.filter((facebookPositionIndex: any) => facebookPositionIndex !== 2).map((facebookPositionIndex: any) => mapFacebookPosition[facebookPositionIndex]);
                    adSet.placement.instagramPositions = adSet.placement.instagramPositions.map((instagramPositionIndex: any) => mapInstagramPosition[instagramPositionIndex]);
                    adSet.placement.messengerPositions = adSet.placement.messengerPositions.map((messengerPositionIndex: any) => mapMessengerPosition[messengerPositionIndex]);
                    adSet.placement.audienceNetworkPositions = adSet.placement.audienceNetworkPositions.map((audiencePositionIndex: any) => mapAudienceNetworkPosition[audiencePositionIndex]);
                }
                // saving for ad //
                oldCampaign.ads.filter((ad: any) => ad.general.adSets[0] === adSet.tempId)
                    .forEach((ad: any) => {
                        ad["adId"] = useForJest ? `${adCntTmp++}` : createGuid();
                        ad["adSetId"] = adSet["adSetId"];
                        ad.adFormat.format = mapAdFormat[ad.adFormat.format];
                        ad["creativeId"] = useForJest ? "" : createGuid();
                        ad.general.name = replacePatternValues(ad.general.name);

                        if (ad.adFormat.singleFormat) {
                            ad.adFormat.singleFormat.callToAction = mapCallToAction[ad.adFormat.singleFormat.callToAction];
                            if (changedCatalog) {
                                ad.adFormat.singleFormat.productSetId = null;
                                ad.adFormat.singleFormat.templateId = null;
                            }
                        }
                        if (ad.adFormat.carouselFormat) {
                            if (ad.adFormat.carouselFormat.staticData)
                                ad.adFormat.carouselFormat.staticData.callToAction = mapCallToAction[ad.adFormat.carouselFormat.staticData.callToAction];
                            else if (ad.adFormat.carouselFormat.dynamicData) {
                                ad.adFormat.carouselFormat.dynamicData.callToAction = mapCallToAction[ad.adFormat.carouselFormat.dynamicData.callToAction];
                                if (changedCatalog) {
                                    ad.adFormat.carouselFormat.dynamicData.templateId = null;
                                    ad.adFormat.carouselFormat.dynamicData.productSetId = null;
                                }
                            }
                        }
                        if (ad.adFormat.collection) {
                            ad.adFormat.collection.callToAction = mapCallToAction[ad.adFormat.collection?.callToAction];
                            if (changedCatalog) {
                                ad.adFormat.collection.productSetId = null;
                                ad.adFormat.collection.templateId = null;
                            }
                        }
                    });
            });
            let result: any;
            // oldCampaign = JSON.parse(JSON.stringify(oldCampaign).replace('\\n', ''));
            oldCampaign = JSON.parse(this.escapeSpecialChars(JSON.stringify(oldCampaign)));
            engine.parseFile('new-campaign.liquid').then((liquid: any) => {
                engine.render(liquid, oldCampaign).then((newCampaign: any) => {
                    const trailingComma = /\,(?!\s*?[\{\[\"\'\w])/g;
                    newCampaign = newCampaign.replace(trailingComma, '');
                    result = JSON.parse(newCampaign);
                    resolve(result)
                });
            }).catch((err) => {
                resolve('result')
                console.log(err)
            });
        })
    }

    escapeSpecialChars(proba: string) {
        return proba.replace(/\\n/g, "\\\\n")
            .replace(/\\&/g, "\\\\&")
            // .replace(/\\"/g, "'")
            .replace(/\\r/g, "\\\\r")
            .replace(/\\t/g, "\\\\t")
            .replace(/\\b/g, "\\\\b")
            .replace(/\\f/g, "\\\\f");
    };

    preloadData() {
        let selectedGroup = this.groups.find(gr => gr.id === get('user').accountId);
        if (selectedGroup) {
            this.groupFormControl.patchValue(selectedGroup.id);

            this.accounts = selectedGroup.profiles.filter(prof => prof.type === 3);
            let selectedAdAccount = selectedGroup.profiles.find(prof => prof.profile_id === this.pageRow.adAccountId);
            if (selectedAdAccount) {
                this.acconutFormControl.patchValue(selectedAdAccount.profile_id);
            }

            if (this.isAutomated || this.isDpa || this.pageRow.platform === PlatformEnum.META) {
                let selectedCatalog: Catalog | undefined;
                if (this.isAutomated) {
                    this.catalogs = selectedGroup.groupAutomatedCatalogs;
                    selectedCatalog = selectedGroup.groupAutomatedCatalogs.find(item => item.id == this.pageRow['automatedCatalogId']);
                    if (selectedCatalog) {
                        this.automatedCatalogFormControl.patchValue(selectedCatalog.id);
                    }
                }
                if (this.isDpa) {
                    this.catalogs = selectedGroup.groupCatalogs;
                    selectedCatalog = selectedGroup.groupCatalogs.find(item => item.facebookId == this.pageRow['catalogId']);
                    if (selectedCatalog) {
                        this.dpaCatalogFormControl.patchValue(selectedCatalog.facebookId);
                    }
                }
            }
        }
        this.loading = false;
    }
}

export interface DuplicateRequest {
    isAutomated: boolean;
    destinationCatalogId: string;
    destinationGroupId: number;
    destinationProfileId: string; //selected ad account
    facebookID: string;
    id: number;
    profileId: string; //ad account id of campaign that is beeing duplicated
}

export interface DuplicateRequestAutomated {
    isAutomated: boolean;
    campaignId: number; //hunchId
    catalogId: number; //selected catalog id
    profileId: string; //selected account id
    accountId: string; //selected account / group id
}

export interface Group {
    id: number;
    name: string;
    profiles: Profile[];
    groupAutomatedCatalogs: Catalog[];
    groupCatalogs: Catalog[];
}

export interface Profile {
    connected: boolean;
    connection: any;
    currency: string;
    currency_symbol: string;
    description: string;
    id: number;
    name: string;
    profile_id: string;
    type: number;
}

export interface Catalog {
    facebookId: string;
    id: number;
    name: string;
}
