import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { get, set } from "./global.service";
import { forkJoin, switchMap } from "rxjs";

@Injectable({ providedIn: 'root' })
export class ConfigService {
    token: string;
    constructor(
        private httpClient: HttpClient //
    ) {
    }

    loadConfig(): Promise<void> {
        return new Promise((resolve) => {
            const baseUrl = '.';
            this.httpClient.get(`${baseUrl}/assets/JSONs/app.config.json`).subscribe((resp: any) => {
                Object.keys(resp).forEach((key) => set(key, resp[key]));
                set('baseUrl', baseUrl);
                resolve();
            }, err => {
                resolve();
            });
        });
    }

    loadHubData(): Promise<void> {
        return new Promise((resolve) => {
            const requests = [
                this.httpClient.post(`${get('hubSignalrUrl')}`, null, { headers: { "Access-Control-Allow-Origin": "*" } }),
                this.httpClient.post(`${get('metaHubSignalrUrl')}`, null, { headers: { "Access-Control-Allow-Origin": "*", token: get('azureTokenMeta') } })
            ];
            forkJoin(requests).subscribe({
                next: ([snap, meta]: any) => {
                    set('snapSignalRUrl', snap.url);
                    set('snapSignalRAccessToken', snap.accessToken);
                    set('metaSignalRUrl', meta.url);
                    set('metaSignalRAccessToken', meta.accessToken);
                    resolve();
                },
                error: (err: any) => {
                    console.log(err);
                    resolve();
                }
            })
        })
    }
}
