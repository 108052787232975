import { DefaultSortColumnName, DefaultSortDirection } from "./table-format-data";

export interface PageInfo {
    offset: number;
    pageSize: number;
    limit: number;
    count: number;
}

export class TableSortElement {
    constructor(
        public dir: string = DefaultSortDirection, //
        public prop: string = DefaultSortColumnName
    ) {}
}

export class EmptyRowElement {
    constructor(
        public isLoadingItem: boolean = true //
    ) {}
}
