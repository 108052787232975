<span [ngSwitch]="metricDataType">
    <span *ngSwitchCase="MetricDataType.Currency" title="{{currencySymbol}}{{cellDisplayValue | number : format.numberPipeFormatDecimal}}">
        {{currencySymbol}}{{cellDisplayValue | number : format.numberPipeFormatDecimal}}
    </span>
    <span *ngSwitchCase="MetricDataType.Decimal" title="{{cellDisplayValue | number : format.numberPipeFormatDecimal}}">
        {{cellDisplayValue | number : format.numberPipeFormatDecimal}}
    </span>
    <span *ngSwitchCase="MetricDataType.Percent" title="{{cellDisplayValue | number : format.numberPipeFormatDecimal}}">
        {{cellDisplayValue | number : format.numberPipeFormatDecimal}}%
    </span>
    <span *ngSwitchCase="MetricDataType.Integer" title="{{cellDisplayValue | number : '1.0-0'}}">
        {{cellDisplayValue | number : '1.0-0'}}
    </span>
    <span *ngSwitchCase="MetricDataType.Enum" title="{{cellDisplayValue | enum | titlecase }}">
        {{cellDisplayValue | enum | titlecase }}
    </span>
    <span *ngSwitchCase="MetricDataType.Date" title="{{cellDisplayValue | date : format.userPreferences.dateFormat  }}">
        {{cellDisplayValue | date : format.userPreferences.dateFormat }}
    </span>
    <span *ngSwitchCase="MetricDataType.Seconds" title="{{cellDisplayValue }}">
        {{cellDisplayValue}}
    </span>
    <span *ngSwitchDefault title="{{cellDisplayValue}}">
        {{cellDisplayValue}}
    </span>
</span>