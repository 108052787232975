import { NgModule } from '@angular/core';

import { LeftMenuComponent } from './left-menu.component';
import { SharedModule } from '../shared/shared.module';
import { AngularSvgIconModule } from 'angular-svg-icon';



@NgModule({
  declarations: [
    LeftMenuComponent
  ],
  imports: [
    SharedModule,
    AngularSvgIconModule
  ],
  exports:[
    LeftMenuComponent
  ]
})
export class LeftMenuModule { }
