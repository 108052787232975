import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { InsightsApiService, TableActionsApiService } from '../../../../../api/services';
import { InsightsQueryResponseObjectFE, HunchCampaignType, FacebookAd, FacebookAdSet, FacebookCampaign } from '../../../../../api/models';
import { ApplicationHeaderPlaceholderHeight } from '../../../../../app.component';
import { TableCellNameActions } from '../../../models';
import { DuplicateCampaignModalComponent, DuplicateRequest, DuplicateRequestAutomated } from './duplicate-campaign-modal/duplicate-campaign-modal.component';
import { TableCellNameAction } from '../table-body-name-cell/table-body-name-cell.component';
import { get } from '../../../../shared/services';
import { PlatformEnum } from '../../../../shared';

@Component({
    selector: 'table-hover-cell-action-handler',
    templateUrl: './table-hover-cell-action-handler.component.html',
    styleUrls: ['./table-hover-cell-action-handler.component.scss']
})
export class TableHoverCellActionHandlerComponent implements OnInit, OnChanges {
    @Input() action: TableCellNameAction;
    @ViewChild(DuplicateCampaignModalComponent, { static: false }) duplicateModal: DuplicateCampaignModalComponent;
    isResolved: boolean = false;
    ApplicationHeaderPlaceholderHeight = ApplicationHeaderPlaceholderHeight;
    TableCellNameActions = TableCellNameActions;

    @Output() emitDeleteDraft = new EventEmitter<any>();

    constructor(
        private router: Router, //
        private insightsApiService: InsightsApiService,
        private tableActionsApiService: TableActionsApiService
    ) { }

    ngOnInit(): void { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.action.previousValue !== changes.action.currentValue) {
            this.resolveHoverAction();
        }
    }

    resolveHoverAction() {
        switch (this.action.actionType) {
            case TableCellNameActions.Delete:
                this.isResolved = false;
                break;
            case TableCellNameActions.Edit:
                this.navigateToEditPage(this.action.pageRow);
                break;
            case TableCellNameActions.Log:
                let url = `${get('hunchAppUrl')}/v2/automated-campaign-log/${this.action.pageRow.hunchId}`;
                window.open(url, '_blank');

                break;
            case TableCellNameActions.Duplicate:
                this.isResolved = false;
                break;
            case TableCellNameActions.Disconnect:
                this.isResolved = false;
                break;
            case TableCellNameActions.Overview:
                let urlOverview = `${`${get('metaStandardAppUrl')}/meta/standard/${this.action.pageRow.internalCampaignId}/overview`}`;
                window.open(urlOverview, '_blank');
                break;

            default:
                break;
        }
    }

    navigateToEditPage(row: InsightsQueryResponseObjectFE): string {
        if (row.platform === PlatformEnum.SNAPCHAT) {
            this.navigateToSnapchatEditPage(row);
            return;
        }
        if (row.platform === PlatformEnum.META) {
            this.navigateToMetaEditPage(row);
            return;
        }
        let url = '';
        let queryParams: string = '?';

        switch (row.hunchCampaignType) {
            case HunchCampaignType.Automated:
            case HunchCampaignType.AutomatedDraft:
                url = `automated/${row.hunchId}`;
                break;
            case HunchCampaignType.HunchStandard:
            case HunchCampaignType.HunchStandardDraft:
                if (row.isCampaign || row.isDraft) {
                    url = row.hunchId + '';
                } else if (row.isAdset) {
                    url = (row as FacebookAdSet).hunchCampaignId + '';
                    queryParams += queryParams + `adset=${row.hunchId}&`;
                } else if (row.isAd) {
                    url = (row as FacebookAd).hunchCampaignId + '';
                    queryParams += queryParams + `ad=${row.hunchId}&`;
                }
                break;
            case HunchCampaignType.PostBoosting:
                url = `post-boosting/${row.hunchId}`;
                break;
            default:
                break;
        }
        location.href = `${get('hunchAppUrl')}/v2/campaign-create/${url}${queryParams}`;
        return '';
    }

    navigateToSnapchatEditPage(row: InsightsQueryResponseObjectFE): string {
        let url = '';
        switch (row.hunchCampaignType) {
            case HunchCampaignType.Automated:
                url = `${get('snapchatAutomatedAppUrl')}/snapchat/automated/${row.internalId}/setup/campaign`;
                break;
            case HunchCampaignType.HunchStandard:
                if (row.isCampaign) {
                    url = `${get('snapchatStandardAppUrl')}/snapchat/standard/${row.internalId}/setup/campaign`;
                } else if (row.isAdset) {
                    url = `${get('snapchatStandardAppUrl')}/snapchat/standard/${row.internalCampaignId}/ad-set/${row.internalId}`;
                } else if (row.isAd) {
                    url = `${get('snapchatStandardAppUrl')}/snapchat/standard/${row.internalCampaignId}/ad/${row.internalId}`;
                }
                break;
            default:
                break;
        }
        location.href = url;
        return '';
    }

    navigateToMetaEditPage(row: InsightsQueryResponseObjectFE): void {
        let url = '';
        switch (row.hunchCampaignType) {
            case HunchCampaignType.Automated:
            case HunchCampaignType.AutomatedDraft:
            case HunchCampaignType.HunchStandard:
            case HunchCampaignType.HunchStandardDraft:
                if (row.connected) {
                    url = `${get('metaStandardAppUrl')}/meta/connect/${row.adAccountId}/${row.internalId}/setup/campaign`;
                } else if (row.isCampaign) {
                    url = `${get('metaStandardAppUrl')}/meta/standard/${row.masterCampaignId ? row.masterCampaignId : row.internalId}/setup/campaign`;
                } else if (row.isAdset) {
                    url = `${get('metaStandardAppUrl')}/meta/standard/${row.internalCampaignId}/ad-set/${row.internalId}`;
                } else if (row.isAd) {
                    url = `${get('metaStandardAppUrl')}/meta/standard/${row.internalCampaignId}/ad/${row.internalId}`;
                }
                break;
            default:
                break;
        }
        location.href = url;
    }

    cancelAction() {
        this.isResolved = true;
    }

    confirmAction() {
        if (this.action.actionType === TableCellNameActions.Disconnect) this.disconnectCampaign();
        if (this.action.actionType === TableCellNameActions.Delete) this.deleteDraft();
        //edit and log are done through rerouting
        this.isResolved = true;
    }
    confirmCustom($event: any): void {
        if (this.action.actionType === TableCellNameActions.Duplicate) {
            if (this.duplicateModal) this.duplicateModal.submitForm();
        }
    }

    isConfirmDuplicateDisabled(): boolean {
        if (!this.duplicateModal) return true;
        else return !this.duplicateModal.isFormValid();
    }

    deleteDraft() {
        if (this.action.pageRow.hunchCampaignType === HunchCampaignType.AutomatedDraft) {
            this.insightsApiService.deleteAutomatedDraft(this.action.pageRow.hunchId + '').subscribe(() => {
                this.emitDeleteDraft.emit(this.action.pageRow);
            });
        } else if (this.action.pageRow.hunchCampaignType === HunchCampaignType.HunchStandardDraft) {
            if (this.action.pageRow.platform === PlatformEnum.META) {
                this.insightsApiService.deleteHunchMetaStandardDraft(this.action.pageRow.internalId).subscribe(() => {
                    this.emitDeleteDraft.emit(this.action.pageRow);
                });
            } else {
                this.insightsApiService.deleteHunchStandardDraft(null, this.action.pageRow.hunchId + '', this.action.pageRow.facebookId).subscribe(() => {
                    this.emitDeleteDraft.emit(this.action.pageRow);
                });
            }

        }
    }

    disconnectCampaign() {
        if (this.action.pageRow.hunchCampaignType === HunchCampaignType.Automated || this.action.pageRow.hunchCampaignType === HunchCampaignType.PostBoosting) {
            this.tableActionsApiService.disconnectAutomated(this.action.pageRow.hunchId + '').subscribe(() => {
                (this.action.pageRow as FacebookCampaign).automatedCampaignSyncDisabled = true;
                this.action.componentReference.setAvailableActions();
                this.action.componentReference.cd.detectChanges();
            });
        }
    }

    postDuplicate(value: DuplicateRequest | DuplicateRequestAutomated) {
        location.href = `${get('metaStandardAppUrl')}/meta/standard/${value}/setup/campaign?duplicate=true`;
    }
}
