import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LottieModule } from 'ngx-lottie';
import { HunchMainLoaderComponent } from './hunch-main-loader.component';

export function playerFactory() {
    return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

@NgModule({
  declarations: [HunchMainLoaderComponent],
  imports: [
    CommonModule,
    LottieModule.forRoot({ player: playerFactory as any })
  ],
  exports: [HunchMainLoaderComponent]
})
export class HunchMainLoaderModule { }

