export class EnrichAdDetails {
    adSetName: string;
    name: string
    campaignName: string;
    productSetId: string;
    adAccountId?: string;
    adData: any;
    oldCreativeId: string;

    constructor(value?: any) {
        this.adSetName = value.ad_set_name;
        this.name = value.ad_data.name;
        this.campaignName = value.campaign_name;
        this.productSetId = value.product_set_id ? value.product_set_id : value.ad_data.creative.product_set_id;
        this.adData = value.ad_data;
        this.oldCreativeId = value.old_creative_id;
    }
}
