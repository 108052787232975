export enum BidTypeEnum {
    LOWEST_COST_WITHOUT_CAP = 'LOWEST_COST_WITHOUT_CAP',
    LOWEST_COST_WITH_BID_CAP = 'LOWEST_COST_WITH_BID_CAP',
    COST_CAP = 'COST_CAP',
    LOWEST_COST_WITH_MIN_ROAS = 'LOWEST_COST_WITH_MIN_ROAS',
    TARGET_COST = 'TARGET_COST'// remove from Marketing API v9
}
export enum OldBidTypeEnum {
    LOWEST_COST_WITHOUT_CAP,
    LOWEST_COST_WITH_BID_CAP,
    TARGET_COST,
    COST_CAP,
    LOWEST_COST_WITH_MIN_ROAS
}