import { OdaxObjectiveEnum } from "../enums";

export const OdaxObjectives: any[] = [
    {
        id: OdaxObjectiveEnum.OUTCOME_SALES,
        name: 'Sales',
        icon: 'ObjectiveSalesIcon',
        child: [
            {
                id: OdaxObjectiveEnum.OUTCOME_SALES,
                name: 'Manual Sales campaign',
                additionalId: OdaxObjectiveEnum.OUTCOME_SALES
            },
            {
                id: OdaxObjectiveEnum.OUTCOME_SALES,
                name: 'Advantage+ Shopping campaign',
                additionalId: 'AUTOMATED_SHOPPING_ADS'
            }
        ]
    },
    {
        id: OdaxObjectiveEnum.OUTCOME_TRAFFIC,
        name: 'Traffic',
        icon: 'ObjectiveTrafficIcon'
    },
    {
        id: OdaxObjectiveEnum.OUTCOME_AWARENESS,
        name: 'Awareness',
        icon: 'ObjectiveAwarenessIcon'
    },
    {
        id: OdaxObjectiveEnum.OUTCOME_LEADS,
        name: 'Leads',
        icon: 'ObjectiveLeadGenerationIcon'
    }
]