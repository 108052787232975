import { Injectable } from '@angular/core';
import { BaseService, HubNetCore } from '@hunchads/angular-signalr';
import { get } from './global.service';
import { Subject } from 'rxjs';
import { MessageHub } from '../models';
import { MetaHubCommands, MetaHubEvents } from '../consts';


@Injectable({
    providedIn: 'root'
})
export class MetaHubService extends BaseService<MetaHub> {
    public messageState: Subject<MessageHub> = new Subject();
    constructor(
        metaHub: MetaHub //
    ) {
        super(metaHub);
    }

    registerByRequestId(requestId: string): void {
        this.hub.invoke(MetaHubCommands.registerByRequestId, null, requestId);
    }

    unRegisterByRequestId(requestId: string): void {
        this.hub.invoke(MetaHubCommands.unRegisterByRequstId, null, requestId);
    }

    listenHubSubscriptions(): void {
        this.hub.subscribe(MetaHubEvents.handpickChangesReady, (message) => {
            this.messageState.next(new MessageHub({ message: JSON.parse(message), type: MetaHubEvents.handpickChangesReady }))
        });

        this.hub.subscribe(MetaHubEvents.handpickChangeNotification, (message) => {
            console.log(MetaHubEvents.handpickChangeNotification, message);
            this.messageState.next(new MessageHub({ message: JSON.parse(message), type: MetaHubEvents.handpickChangeNotification }))
        });
    }
}

@Injectable({
    providedIn: 'root'
})
export class MetaHub extends HubNetCore {
    constructor() {
        super('metahub', get('metaSignalRUrl'))
    }

    override getToken() {
        return get('metaSignalRAccessToken');
    }
}