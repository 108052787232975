import { OldOptimizationGoal, OptimizationGoalEnum } from "../enums";

export const mapOptimizationGoal: any = {
    [OldOptimizationGoal.None]: OptimizationGoalEnum.NONE,
    [OldOptimizationGoal.AppInstalls]: OptimizationGoalEnum.APP_INSTALLS,
    [OldOptimizationGoal.BrandAwareness]: OptimizationGoalEnum.BRAND_AWARENESS,
    [OldOptimizationGoal.AdRecallLift]: OptimizationGoalEnum.AD_RECALL_LIFT,
    [OldOptimizationGoal.Clicks]: OptimizationGoalEnum.CLICKS,
    [OldOptimizationGoal.EngagedUsers]: OptimizationGoalEnum.ENGAGED_USERS,
    [OldOptimizationGoal.EventResponses]: OptimizationGoalEnum.EVENT_RESPONSES,
    [OldOptimizationGoal.Impressions]: OptimizationGoalEnum.IMPRESSIONS,
    [OldOptimizationGoal.LeadGeneration]: OptimizationGoalEnum.LEAD_GENERATION,
    [OldOptimizationGoal.LinkClicks]: OptimizationGoalEnum.LINK_CLICKS,
    [OldOptimizationGoal.OfferClaims]: OptimizationGoalEnum.OFFER_CLAIMS,
    [OldOptimizationGoal.OffsiteConversions]: OptimizationGoalEnum.OFFSITE_CONVERSIONS,
    [OldOptimizationGoal.PageEngagement]: OptimizationGoalEnum.PAGE_ENGAGEMENT,
    [OldOptimizationGoal.PageLikes]: OptimizationGoalEnum.PAGE_LIKES,
    [OldOptimizationGoal.PostEngagement]: OptimizationGoalEnum.POST_ENGAGEMENT,
    [OldOptimizationGoal.Reach]: OptimizationGoalEnum.REACH,
    [OldOptimizationGoal.SocialImpressions]: OptimizationGoalEnum.SOCIAL_IMPRESSIONS,
    [OldOptimizationGoal.VideoViews]: OptimizationGoalEnum.VIDEO_VIEWS,
    [OldOptimizationGoal.AppDownloads]: OptimizationGoalEnum.APP_DOWNLOADS,
    [OldOptimizationGoal.LandingPageViews]: OptimizationGoalEnum.LANDING_PAGE_VIEWS,
    [OldOptimizationGoal.Value]: OptimizationGoalEnum.VALUE,
    [OldOptimizationGoal.Replies]: OptimizationGoalEnum.REPLIES,
    [OldOptimizationGoal.TwoSecondContinuousVideoViews]: OptimizationGoalEnum.TWO_SECOND_CONTINUOUS_VIDEO_VIEWS,
    [OldOptimizationGoal.Thruplay]: OptimizationGoalEnum.THRUPLAY,
    [OldOptimizationGoal.OnsiteConversions]: OptimizationGoalEnum.ONSITE_CONVERSIONS,
    [OldOptimizationGoal.OfflineConversions]: OptimizationGoalEnum.OFFLINE_CONVERSIONS,
    [OldOptimizationGoal.Conversations]: OptimizationGoalEnum.CONVERSATIONS,
    [OldOptimizationGoal.VisitInstagramProfile]: OptimizationGoalEnum.VISIT_INSTAGRAM_PROFILE,
    [OldOptimizationGoal.IncrementalOffsiteConversions]: OptimizationGoalEnum.INCREMENTAL_OFFSITE_CONVERSIONS,
    [OldOptimizationGoal.EngagedReach]: OptimizationGoalEnum.ENGAGED_REACH,
    [OldOptimizationGoal.AppInstallsAndOffsiteConversions]: OptimizationGoalEnum.APP_INSTALLS_AND_OFFSITE_CONVERSIONS,
    [OldOptimizationGoal.STORE_VISITS]: OptimizationGoalEnum.STORE_VISITS,
    [OldOptimizationGoal.QUALITY_CALL]: OptimizationGoalEnum.QUALITY_CALL,
}