import { Injectable } from '@angular/core';
import { get } from '../../shared/services';
import { InsightsFilter, FilterOperator, HunchCampaignType } from '../../../api/models';
import { TableReloadType } from '../models/enums';
import { TableService } from './table-service.model';

@Injectable({
    providedIn: 'root'
})
export class FiltersService {
    insightsFilters: InsightsFilter[] = [];
    customFilters: any = {
        search: [],
        objectives: [],
        campaignTypes: []
    };
    adAccountInsigthsFilter: InsightsFilter;
    sessionStorage = new Map<string, string>();

    mainFilterOptions: InsightsFilter[] = [
        new InsightsFilter({
            metricKey: 'hunchCampaignType',
            operator: FilterOperator.In,
            value: [HunchCampaignType.HunchStandard, HunchCampaignType.HunchStandardDraft, HunchCampaignType.Automated, HunchCampaignType.AutomatedDraft, HunchCampaignType.PostBoosting]
        }),
        new InsightsFilter({
            metricKey: 'hunchCampaignType',
            operator: FilterOperator.In,
            value: [HunchCampaignType.Automated, HunchCampaignType.PostBoosting]
        }),
        new InsightsFilter({
            metricKey: 'hunchCampaignType',
            operator: FilterOperator.In,
            value: [HunchCampaignType.Facebook, HunchCampaignType.HunchStandardDraft, HunchCampaignType.HunchStandard, HunchCampaignType.Automated, HunchCampaignType.AutomatedDraft, HunchCampaignType.PostBoosting]
        })
    ];
    mainFilter: InsightsFilter = this.mainFilterOptions[0];

    constructor() { }

    addFilter(item: InsightsFilter) {
        this.insightsFilters.push(item);
        TableService.reloadTable$.next(TableReloadType.ChangeFilters);
    }

    addCustomFilter(items: InsightsFilter[], type: string) {
        this.customFilters[type] = items;
        TableService.reloadTable$.next(TableReloadType.CustomFilter);
    }

    resetCustomFilters(): void {
        this.customFilters = {
            search: [],
            objectives: [],
            campaignTypes: []
        };
    }

    removeFilter(item: InsightsFilter) {
        let index = this.insightsFilters.indexOf(item);
        if (index > -1) {
            this.insightsFilters.splice(index, 1);
            TableService.reloadTable$.next(TableReloadType.ChangeFilters);
        }
    }

    getStorageKey(): string {
        return `groupId-${get('user').accountId}-filters`;
    }

    setInsigtsFilterFromParamsOrLoadFromSession(paramsString: string): string | null {
        if (paramsString) {
            let params = JSON.parse(paramsString);
            if (params && params.length) {
                params.forEach((param: any) => {
                    this.insightsFilters.push(param);
                });
            }
        } else {
            this.setInsigtsFilterFromSesstion();
        }

        if (this.insightsFilters?.length) return JSON.stringify(this.insightsFilters);
        else return null;
    }

    setInsigtsFilterFromSesstion() {
        let persistedFilters: InsightsFilter[] | undefined;
        let persistedFiltersString: string | undefined = this.sessionStorage.get(this.getStorageKey());
        if (persistedFiltersString) persistedFilters = JSON.parse(persistedFiltersString);
        if (persistedFilters?.length) this.insightsFilters = persistedFilters;
    }

    clearInsigtsFilters() {
        this.insightsFilters.length = 0;
    }
}
