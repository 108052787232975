<div class="w--100 filter-option--header px--16 truncate">
    <div class="icon-box inline-block mr--16 cursor--pointer" (click)="clearSelectedFilter.emit()">
        <svg-icon name="ArrowRightIcon" alt="" svgClass="left-menu-icon r--180"></svg-icon>
    </div>
    {{selectedFilter.name}}
</div>
<div class="w--100 filter-option-body px--16 py--16">
    <div class="w--1/2 inline-block pr--8">
        <ng-select class="select--dark" [items]="operatorOptions" bindLabel="name" data-cy="selectOperator" placeholder="Select..."
            bindValue="id" [(ngModel)]="insightsFilter.operator" [searchable]="false" bindValue="id"> </ng-select>
    </div>

    <div class="w--1/2 inline-block pl--8">
        <ng-select *ngIf="enumOptions.length; else hunchInput" class="select--dark" [items]="enumOptions"
            bindLabel="name" placeholder="Select..." [searchable]="false" bindValue="id"
            [(ngModel)]="insightsFilter.value"> </ng-select>

        <ng-template #hunchInput>
            <!-- <hunch-input class="search" [value]="insightsFilter.value" [placeholder]="'Filter...'" [size]="'small'">
            </hunch-input> -->
            <div class="input--wrapper input--wrapper_small">
                <div class="input--inner">
                    <input type="text" data-cy="inputField" placeholder="Input" [(ngModel)]="insightsFilter.value">
                </div>
            </div>
        </ng-template>

    </div>

    <div class="w--100 pt--20 v-a--top">

        <hunch-button [classes]="'btn--solid btn--solid_grey light-text'" [text]="'Cancel'"
            (hunchClick)="clearSelectedFilter.emit()">
        </hunch-button>

        <div class="float-right inline-block">
            <hunch-button [classes]="'btn--solid btn--solid_green'" [text]="'Apply'" (hunchClick)="addFilter.emit()"
                [disabled]="!insightsFilter.operator || !insightsFilter.value">
            </hunch-button>
        </div>

    </div>
</div>