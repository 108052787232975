import { DetailedTargetingTypeEnum, OldDetailedTargetingType } from "../enums";

export const mapTargetingType = {
    [OldDetailedTargetingType.interests]: DetailedTargetingTypeEnum.interests,
    [OldDetailedTargetingType.behaviors]: DetailedTargetingTypeEnum.behaviors,
    [OldDetailedTargetingType.work_employers]: DetailedTargetingTypeEnum.work_employers,
    [OldDetailedTargetingType.education_schools]: DetailedTargetingTypeEnum.education_schools,
    [OldDetailedTargetingType.adgroup_id]: DetailedTargetingTypeEnum.adgroup_id,
    [OldDetailedTargetingType.genders]: DetailedTargetingTypeEnum.genders,
    [OldDetailedTargetingType.age_min]: DetailedTargetingTypeEnum.age_min,
    [OldDetailedTargetingType.age_max]: DetailedTargetingTypeEnum.age_max,
    [OldDetailedTargetingType.country_groups]: DetailedTargetingTypeEnum.country_groups,
    [OldDetailedTargetingType.countries]: DetailedTargetingTypeEnum.countries,
    [OldDetailedTargetingType.country]: DetailedTargetingTypeEnum.country,
    [OldDetailedTargetingType.cities]: DetailedTargetingTypeEnum.cities,
    [OldDetailedTargetingType.radius]: DetailedTargetingTypeEnum.radius,
    [OldDetailedTargetingType.regions]: DetailedTargetingTypeEnum.regions,
    [OldDetailedTargetingType.zips]: DetailedTargetingTypeEnum.zips,
    [OldDetailedTargetingType.keywords]: DetailedTargetingTypeEnum.keywords,
    [OldDetailedTargetingType.education_majors]: DetailedTargetingTypeEnum.education_majors,
    [OldDetailedTargetingType.work_positions]: DetailedTargetingTypeEnum.work_positions,
    [OldDetailedTargetingType.relationship_statuses]: DetailedTargetingTypeEnum.relationship_statuses,
    [OldDetailedTargetingType.interested_in]: DetailedTargetingTypeEnum.interested_in,
    [OldDetailedTargetingType.locales]: DetailedTargetingTypeEnum.locales,
    [OldDetailedTargetingType.user_adclusters]: DetailedTargetingTypeEnum.user_adclusters,
    [OldDetailedTargetingType.excluded_user_adclusters]: DetailedTargetingTypeEnum.excluded_user_adclusters,
    [OldDetailedTargetingType.conjunctive_user_adclusters]: DetailedTargetingTypeEnum.conjunctive_user_adclusters,
    [OldDetailedTargetingType.custom_audiences]: DetailedTargetingTypeEnum.custom_audiences,
    [OldDetailedTargetingType.excluded_custom_audiences]: DetailedTargetingTypeEnum.excluded_custom_audiences,
    [OldDetailedTargetingType.college_years]: DetailedTargetingTypeEnum.college_years,
    [OldDetailedTargetingType.education_statuses]: DetailedTargetingTypeEnum.education_statuses,
    [OldDetailedTargetingType.connections]: DetailedTargetingTypeEnum.connections,
    [OldDetailedTargetingType.excluded_connections]: DetailedTargetingTypeEnum.excluded_connections,
    [OldDetailedTargetingType.friends_of_connections]: DetailedTargetingTypeEnum.friends_of_connections,
    [OldDetailedTargetingType.user_event]: DetailedTargetingTypeEnum.user_event,
    [OldDetailedTargetingType.dynamic_audience_ids]: DetailedTargetingTypeEnum.dynamic_audience_ids,
    [OldDetailedTargetingType.excluded_dynamic_audience_ids]: DetailedTargetingTypeEnum.excluded_dynamic_audience_ids,
    [OldDetailedTargetingType.rtb_flag]: DetailedTargetingTypeEnum.rtb_flag,
    [OldDetailedTargetingType.site_category]: DetailedTargetingTypeEnum.site_category,
    [OldDetailedTargetingType.geo_locations]: DetailedTargetingTypeEnum.geo_locations,
    [OldDetailedTargetingType.excluded_geo_locations]: DetailedTargetingTypeEnum.excluded_geo_locations,
    [OldDetailedTargetingType.timezones]: DetailedTargetingTypeEnum.timezones,
    [OldDetailedTargetingType.place_page_set_ids]: DetailedTargetingTypeEnum.place_page_set_ids,
    [OldDetailedTargetingType.page_types]: DetailedTargetingTypeEnum.page_types,
    [OldDetailedTargetingType.publisher_platforms]: DetailedTargetingTypeEnum.publisher_platforms,
    [OldDetailedTargetingType.effective_publisher_platforms]: DetailedTargetingTypeEnum.effective_publisher_platforms,
    [OldDetailedTargetingType.facebook_positions]: DetailedTargetingTypeEnum.facebook_positions,
    [OldDetailedTargetingType.effective_facebook_positions]: DetailedTargetingTypeEnum.effective_facebook_positions,
    [OldDetailedTargetingType.instagram_positions]: DetailedTargetingTypeEnum.instagram_positions,
    [OldDetailedTargetingType.effective_instagram_positions]: DetailedTargetingTypeEnum.effective_instagram_positions,
    [OldDetailedTargetingType.messenger_positions]: DetailedTargetingTypeEnum.messenger_positions,
    [OldDetailedTargetingType.effective_messenger_positions]: DetailedTargetingTypeEnum.effective_messenger_positions,
    [OldDetailedTargetingType.device_platforms]: DetailedTargetingTypeEnum.device_platforms,
    [OldDetailedTargetingType.effective_device_platforms]: DetailedTargetingTypeEnum.effective_device_platforms,
    [OldDetailedTargetingType.audience_network_positions]: DetailedTargetingTypeEnum.audience_network_positions,
    [OldDetailedTargetingType.industries]: DetailedTargetingTypeEnum.industries,
    [OldDetailedTargetingType.family_statuses]: DetailedTargetingTypeEnum.family_statuses,
    [OldDetailedTargetingType.life_events]: DetailedTargetingTypeEnum.life_events,
    [OldDetailedTargetingType.politics]: DetailedTargetingTypeEnum.politics,
    [OldDetailedTargetingType.income]: DetailedTargetingTypeEnum.income,
    [OldDetailedTargetingType.user_device]: DetailedTargetingTypeEnum.user_device,
    [OldDetailedTargetingType.user_os]: DetailedTargetingTypeEnum.user_os
};