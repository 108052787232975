<div class="hunch-modal-wrapper appear">
    <div class="hunch-modal-container">
        <div class="hunch-modal-header mt--20">
            <h2>Enrich ad creative with Hunch template</h2>
        </div>
        <div class="hunch-modal-body">
            <ng-container *ngIf="!adUpdated && !updateFail">
                <p>Use Hunch catalog and enrich creative for ad created on Meta. </p>
                <!-- error message -->
                <ng-container *ngIf="!ad">
                    <h4><label for="ad_id">Ad Id</label></h4>
                    <input class="modal-text-input" #elementInput type="text" placeholder="Input" [(ngModel)]="adId"
                        [disabled]="ad && !error">
                </ng-container>

                <div class="alert-error mb--20" *ngIf="error">
                    <p><svg-icon name="Warning 2 Icon" alt="" svgClass="table-icon hunch-icon--post-boosting"
                            title="Error occured"></svg-icon>{{errorMessages}}</p>
                </div>
            </ng-container>

            <div *ngIf="ad && !adUpdated && !updateFail" class="uk-margin-medium-top">
                <div class="selected-ad">
                    <svg-icon name="AdsIcon" alt=""></svg-icon>
                    <div class="ad-details">
                        <span class="medium-text">{{ad.name}}</span>
                        <div class="small-text">
                            {{ad.campaignName}} / {{ad.adSetName}}
                        </div>
                    </div>
                </div>
                <p *ngIf="catalogName" class="small-text mb--20">Ad using Hunch catalog {{catalogName}}</p>

                <h4><label for="template">Select template</label></h4>
                <ng-select #chooseTemplate [(ngModel)]="templateId"
                    class="uk-select uk-margin-small-right uk-select-2rows" placeholder="Select Hunch template"
                    [items]="imageTemplates" [searchable]="true" [clearable]="false" bindValue="id"
                    (close)="chooseTemplate.blur()">
                    <ng-template ng-label-tmp let-item="item">
                        <div class="two-rows-value">
                            <div class="name">
                                {{item.name}} <br><span class="additional-info" *ngIf="item.width&&item.heigth">
                                    {{item.width}}x{{item.heigth}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index">
                        <div class="two-rows-value">{{item.name}}<span class="additional-info"
                                *ngIf="item.width&&item.heigth">
                                {{item.width}}x{{item.heigth}}</span></div>
                    </ng-template>
                </ng-select>
            </div>

            <!-- success message screen ------------------------------------------------------------------------->
            <div class="result text--center">
                <br>
                <svg-icon *ngIf="adUpdated" name="CheckMark" alt="" svgClass="confirmation-icon"></svg-icon>
                <br><br>
                <p *ngIf="adUpdated">
                    You have successfully enriched ad <br>
                    {{ad.name}} <br>
                    with Hunch template.
                </p>
                <p *ngIf="updateFail">
                    You have unsuccessfully try to enriched ad <br>
                    {{ad.name}} <br>
                    with Hunch template. <br>
                    {{errorMessages}}
                </p>
                <br><br>
            </div>
        </div>
        <div class="hunch-modal-footer d-flex-modal justify-content-between">
            <button class="btn--solid btn--solid-medium btn--solid_grey btn--solid_v3 btn--solid-medium light-text"
                (click)="closeModal()"> {{adUpdated||updateFail ? 'Close' : 'Cancel'}}
            </button>

            <button class="btn--solid btn--solid_green btn--solid_v3 btn--solid-medium" *ngIf="!ad" [disabled]="!adId"
                (click)="getAd(adId)">Select template
            </button>
            <button class="btn--solid btn--solid_green btn--solid_v3 btn--solid-medium"
                *ngIf="ad && !adUpdated && !updateFail" [disabled]="!templateId "
                (click)="updateAd(ad, templateId)">Enrich Ad
            </button>

            <button class="btn--solid btn--solid_green btn--solid_v3 btn--solid-medium" *ngIf="adUpdated"
                [disabled]="!templateId " (click)="previewAd(ad)">Preview ad on Meta
            </button>
        </div>
        <!-- modal loader -->
        <div class="v-a--top inline-block edit-selection-loader" *ngIf="loading">
            <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
</div>