import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AdAccountSyncApiService, SyncStatusModel } from '../../../../../api/services';
import { get, InteroperabilityService } from '../../../../shared/services';
// import { Group } from '@hunchads/angular-shared';

@Component({
    selector: 'ad-account-sync-option',
    templateUrl: './ad-account-sync-option.component.html',
    styleUrls: ['./ad-account-sync-option.component.scss']
})
export class AdAccountSyncOptionComponent implements OnInit {
    accountId: any;
    adAccounts: SyncStatusModel[];
    isLoadingAdAccountsData = true;
    initVal: any;
    subscription: Subscription = new Subscription();
    reloadFetchData: SyncStatusModel = {
        asAccountId: null,
        adAccountName: 'Fetching Ad Account Sync Data Failed',
        initialSyncDone: false,
        isInProgress: false,
        errorMessage: '',
        lastMetricSyncEnd: null,
        lastStaticSyncEnd: null
    };

    isComponentVisisble: boolean;

    constructor(
        private adAccountSyncApiService: AdAccountSyncApiService, //
        private interoperabilityService: InteroperabilityService
    ) { }

    ngOnInit(): void {
        this.isComponentAvailable();
        this.setNoAdAccountsLoaded();
        this.subscription.add(this.interoperabilityService.$accountChanged.subscribe((data: any) => {
            this.accountId = get('user').accountId;
            this.loadAdAccountData(true)
        }));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    setAdAccounts(data: SyncStatusModel[]) {
        this.adAccounts = data;
        if (this.adAccounts?.length) {
            this.isLoadingAdAccountsData = false;
        } else this.setNoAdAccountsLoaded();
    }

    setNoAdAccountsLoaded() {
        this.adAccounts = [
            {
                asAccountId: null,
                adAccountName: '',
                initialSyncDone: true,
                isInProgress: false,
                errorMessage: '',
                lastMetricSyncEnd: null,
                lastStaticSyncEnd: null
            }
        ];
        this.adAccounts[0]['disabled'] = false;
        this.initVal = this.adAccounts[0].asAccountId;
    }

    loadAdAccountData(isInitLoad: boolean = false) {
        this.adAccountSyncApiService.getAdAccountsForGroup(this.accountId).subscribe(
            (data: SyncStatusModel[]) => {
                this.setAdAccounts(data);
                if (isInitLoad) this.checkSyncIfAdAccountsHaveInProgress();
            },
            () => {
                this.completeSyncWithError();
            }
        );
    }

    syncAccount(option: string) {


        if (!option) {
            this.isLoadingAdAccountsData = true;
            this.loadAdAccountData();
        } else {
            let clickedOption = this.adAccounts.find(item => item.asAccountId === option);
            if (clickedOption && !clickedOption.isInProgress) {
                clickedOption.isInProgress = true;
                this.adAccountSyncApiService.syncAdAccount(clickedOption.asAccountId).subscribe(
                    () => {
                        setTimeout(() => {
                            this.checkSync();
                        }, 10000);
                    },
                    () => {
                        setTimeout(() => {
                            this.checkSync();
                        }, 10000);
                    }
                );
            }
        }
    }

    checkSync() {
        this.adAccountSyncApiService.getAdAccountsForGroup(this.accountId).subscribe(
            (data: SyncStatusModel[]) => {
                if (data?.length) {
                    this.setAdAccounts(data);
                    this.checkSyncIfAdAccountsHaveInProgress();
                } else this.completeSyncWithError();
            },
            () => {
                this.completeSyncWithError();
            }
        );
    }
    checkSyncIfAdAccountsHaveInProgress() {
        if (this.adAccounts.filter(item => item.isInProgress)?.length)
            setTimeout(() => {
                this.checkSync();
            }, 10000);
    }

    completeSyncWithError() {
        this.setReloadDataOptions();
        this.isLoadingAdAccountsData = false;
    }

    areSomeAdAccountsSyncing(): boolean {
        if (this.adAccounts?.length) {
            let retVal = false;
            this.adAccounts.forEach(item => {
                if (item.isInProgress) retVal = true;
            });
            return retVal;
        }
        return false;
    }

    hasUnsyncedAdAccount(): boolean {
        if (this.adAccounts?.length) {
            let retVal = false;
            this.adAccounts.forEach(item => {
                if (item.initialSyncDone === false) retVal = true;
            });
            return retVal;
        }

        return false;
    }

    setReloadDataOptions() {
        this.adAccounts = [JSON.parse(JSON.stringify(this.reloadFetchData))];
        this.adAccounts[0].asAccountId = null;
    }

    isComponentAvailable() {
        let user: any = get('user');
        if (user) {
            let email: string = user['email'];
            if (email) {
                if (email.indexOf('hunchads') > -1 || email.indexOf('gorillas') > -1
                    || email.indexOf('kiwi.com') > -1 || email.indexOf('indigital.marketing') > -1
                    || email.indexOf('digicelgroup.com') > -1 || email.indexOf('vacansoleil.com') > -1 || email.indexOf('planeta') > -1) this.isComponentVisisble = true;
            }
        }
        if (!this.isComponentVisisble) {
            if (get('user').teamId == 616 || get('user').teamId == 644)
                this.isComponentVisisble = true;
        }
    }

    isTodaysDate(someDate: Date): boolean {
        if (typeof someDate === 'string') someDate = new Date(someDate);
        if (!someDate || typeof someDate !== 'object') return false;
        const today = new Date();
        return someDate.getDate() == today.getDate() && someDate.getMonth() == today.getMonth() && someDate.getFullYear() == today.getFullYear();
    }
}
