export enum CampaignType {
    AUTOMATED = 'AutomatedCampaign',
    STANDARD = 'StandardCampaign'
}

export enum HunchCampaignTypeEnum {
    META = 'META',
    STANDARD = 'STANDARD',
    AUTOMATED = 'AUTOMATED',
    CONNECTED = 'CONNECTED',
    HUNCH = 'HUNCH',
}