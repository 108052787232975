import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";

import { get } from "../services";

Injectable({
  providedIn: 'root'
})

export class TokenInterceptorService implements HttpInterceptor {
  private readonly prefixChar = '$';
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = get('token');
    const processed = this.processUrl(request.url);
    const azureToken = request.headers.get('token') ? request.headers.get('token') : get('azureToken');
    if (token) {
      request = request.clone({
        setHeaders: {
          'x-hunch-identity': token,
          'Authorization': 'Bearer ' + azureToken,
          'Ocp-Apim-Subscription-Key': get('apiSubscriptionKey')
        },
        url: processed
      });
    }
    return next.handle(request);
  }

  private processUrl(url: string): string {
    if (url.startsWith(this.prefixChar)) {
      const splitted: string[] = url.split('/');
      const prefix: string = splitted[0] ? splitted[0] : '';
      const configKey: string = prefix.substr(1);
      const origin = get(configKey);

      if (!origin) {
        throw new Error(`There is no origin for prefix. ${url}`);
      }

      const paths = url.replace(prefix, origin);

      return `${paths}`;
    }
    return url;
  }
}