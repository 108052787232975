import { Component, EventEmitter, Input, Output, Renderer2, TemplateRef } from '@angular/core';
import { ApplicationHeaderPlaceholderHeight } from '../../app.component';
import { CampaignStructureLevel } from '../../api/models';
import { Subscription } from 'rxjs';
import { AdSetLevelService, AdsLevelService, CampaignLevelService, TableService } from '../metric-table/table-services';
import { TableReloadType } from '../metric-table/models';
import { LevelTabService } from './level-tab.service';

@Component({
  selector: 'app-level-tab',
  templateUrl: './level-tab.component.html',
  styleUrls: ['./level-tab.component.scss'],
  providers: [LevelTabService]
})
export class LevelTabComponent {
  @Input() rightContent: TemplateRef<any>;
  @Input('resetTableConfiguration') resetTableConfiguration: EventEmitter<any>;
  ApplicationHeaderPlaceholderHeight = ApplicationHeaderPlaceholderHeight;

  showLeftMenu = true;
  SelectedLevel = CampaignStructureLevel;
  @Input() selectedDataLevel: CampaignStructureLevel = CampaignStructureLevel.Campaign;

  @Output() resizedContainer = new EventEmitter<any>();
  @Output() changeLevel = new EventEmitter<any>();

  adLevelSelectedItems: string[];
  adSetLevelSelectedItems: string[];
  campaignLevelSelectedItems: string[];
  selectedDraftIds: string[];

  notificationPosition = {
    isHidden: false,
    right: 0,
    top: 0,
    expanded: {
      right: -200,
      top: -1
    },
    subtracted: {
      right: -14,
      top: -7
    }
  };

  subs: Subscription = new Subscription();

  constructor(
    private adsLevelService: AdsLevelService, //
    private adSetLevelService: AdSetLevelService,
    private campaignLevelService: CampaignLevelService,
    private renderer: Renderer2
  ) {
    this.adLevelSelectedItems = this.adsLevelService.selectedItemIds;
    this.adSetLevelSelectedItems = this.adSetLevelService.selectedItemIds;
    this.campaignLevelSelectedItems = this.campaignLevelService.selectedItemIds;
    this.selectedDraftIds = this.campaignLevelService.selectedDraftIds;
  }

  ngOnInit(): void {
    this.subscribeLevelChanges();
    this.resetTableConfiguration?.subscribe(() => {
      this.changePlatform();
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  getElementHeight(element: HTMLElement): number {
    if (!element) return 0;
    let retVal = element.getBoundingClientRect().height;
    return retVal;
  }

  changeSelectedLevel(value: CampaignStructureLevel) {
    this.selectedDataLevel = value;
    this.changeLevel.emit(value);
  }

  toggleDrawer() {
    this.showLeftMenu = !this.showLeftMenu;
    if (this.showLeftMenu) {
      this.notificationPosition.top = this.notificationPosition.expanded.top;
      this.notificationPosition.right = this.notificationPosition.expanded.right;
    } else {
      this.notificationPosition.top = this.notificationPosition.subtracted.top;
      this.notificationPosition.right = this.notificationPosition.subtracted.right;
    }
    this.notificationPosition.isHidden = true;
    setTimeout(() => {
      this.notificationPosition.isHidden = false;
    }, 250);
    this.resizedContainer.emit();
    this.showLeftMenuLagAnimation();
  }

  hasAdditionalText(): string {
    return this.showLeftMenu ? 'selected' : undefined;
  }

  hoverColor(element: HTMLElement) {
    this.renderer.setStyle(element, 'background-color', '#e6e6e6');
  }

  unhoverColor(element: HTMLElement) {
    this.renderer.setStyle(element, 'background-color', '');
  }

  deselectLevel(level: CampaignStructureLevel, event?: Event) {
    if (this.showLeftMenu) {
      event.stopPropagation();
      switch (level) {
        case CampaignStructureLevel.Campaign:
          this.campaignLevelSelectedItems.length = 0;
          this.selectedDraftIds.length = 0;
          break;
        case CampaignStructureLevel.AdSet:
          this.adSetLevelSelectedItems.length = 0;
          break;
        case CampaignStructureLevel.Ad:
          this.adLevelSelectedItems.length = 0;
          break;
      }
      TableService.reloadTable$.next(TableReloadType.ClearSelectedLevel);
    }
  }

  changePlatform(): void {
    this.campaignLevelSelectedItems.length = 0;
    this.selectedDraftIds.length = 0;
    this.adSetLevelSelectedItems.length = 0;
    this.adLevelSelectedItems.length = 0;
    // this.unhoverColor(element);
  }

  subscribeLevelChanges() {
    LevelTabService.levelTabChange$.subscribe((data: CampaignStructureLevel) => {
      switch (data) {
        case CampaignStructureLevel.Campaign:
          this.changeSelectedLevel(data);
          break;
        case CampaignStructureLevel.AdSet:
          this.changeSelectedLevel(data);
          break;
        case CampaignStructureLevel.Ad:
          this.changeSelectedLevel(data);
          break;
      }
    });
  }

  showText: boolean = true;
  showLeftMenuLagAnimation() {
    if (this.showLeftMenu === true) {
      setTimeout(() => {
        this.showText = true;
      }, 300);
    } else this.showText = false;
    return '';
  }
}

