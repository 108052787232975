<div [formGroup]="parent" class="input--wrapper" [class]="{ 'input--wrapper_icon': icon, 'input--wrapper_small': size === 'small', 'input--wrapper__condition': isCondition, 'input--wrapper__x-small': size === 'x-small', 'input--wrapper_disabled': inputControl?.disabled || disabled, 'input--wrapper_loading': loading, 'input--wrapper_cl': variant === 'cl' }">
    <div class="input--inner">
        <div *ngIf="icon" class="input--icon" [ngStyle]="{ 'height': (size === 'small' ? '23px' : '35px !important') }">
            <span *ngIf="!svg" [class]="{ small_font_size: icon.length !== 1 }">{{ icon }}</span>
            <span *ngIf="svg">
                <mat-icon *ngIf="iconV2; else iconV1" [svgIcon]="icon" class="hunch--icon hunch--icon__md"></mat-icon>
                <ng-template #iconV1>
                    <svg-icon [name]="icon" alt="icon"></svg-icon>
                </ng-template>
            </span>
        </div>
        <input #inputHtmlElement *ngIf="inputControlName" [type]="type"[id]="id" [formControlName]="inputControlName" [placeholder]="placeholder || ''" class="truncate" [attr.data-cy]="testId" (keydown.enter)="confirmedValue()" (blur)="confirmedValue()" />
        <input #inputHtmlElement *ngIf="inputControl" [type]="type" name="" [id]="id" [formControl]="inputControl" [placeholder]="placeholder || ''" [ngClass]="{'green':isSuccess}" class="truncate" [attr.data-cy]="testId" (keydown.enter)="confirmedValue()" (blur)="confirmedValue()" />
        <input #inputHtmlElement *ngIf="!inputControl && !inputControlName" [type]="type" name="" [id]="id" [(ngModel)]="value" [ngClass]="{'green':isSuccess}" [placeholder]="placeholder || ''" class="truncate" [attr.data-cy]="testId" (keydown.enter)="confirmedValue()" (blur)="confirmedValue()" />
    </div>
    <span *ngIf="!loading">
        <mat-icon *ngIf="iconV2; else iconSecondV1" svgIcon="warning2" class="hunch--icon input--warning" [tooltip]="getFirstError()" [containerClass]="'white--tooltip'" [placement]="'top'"></mat-icon>
        <ng-template #iconSecondV1>
            <svg-icon class="input--warning" name="Warning 2 Icon" alt="" [tooltip]="getFirstError()" [containerClass]="'white--tooltip'" [placement]="'top'"></svg-icon>
        </ng-template>
    </span>
    <img class="input--loading" src="/images/Rolling-0.9s-177px.svg" alt="" />
</div>
