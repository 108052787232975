<!-- <div class="w--100 h--100">
    <left-menu [rightContent]="content" [selectedDataLevel]="selectedDataLevel" (resizedContainer)="resizedContainer()"
        (changeLevel)="changeSelectedLevel($event)" [resetTableConfiguration]="resetTableConfiguration"></left-menu>
</div> -->
<!-- <ng-template> -->
<div class="w--100 h--100 manage-wrapper">
    <div class="horizontal-block tabs fb-button-container pl--24">
        <div class="tabs-container clearfix">
            <ng-container *ngIf="multiPlatform">
                <button class="btn--tab" [ngClass]="{'active':p.id == platform}" *ngFor="let p of platforms"
                    (click)="goToPlatformManage(p.id)">
                    <svg-icon title="Meta Campaigns" name="{{p.name}}Icon" alt=""
                        svgClass="table-icon meta-icon"></svg-icon>
                    {{p.name}}</button>
            </ng-container>
            <hunch-button  [classes]="'btn--solid btn--solid_facebook btn_insert_ad'" [text]="'Enrich Ad'" (hunchClick)="enrichAd()" 
            *ngIf="platform === platformEnum.FACEBOOK && enrichAdAllow"
            (hunchClick)="enrichAd()">
            </hunch-button>
            <hunch-button [classes]="'btn--solid btn--solid_facebook btn_insert_ad'" [text]="'Insert Ad'"
                [iconName]="'InsertIcon'" (hunchClick)="connectToCampaign()"
                *ngIf="platform === platformEnum.FACEBOOK && connectCampaignAllow">
            </hunch-button>
            <hunch-button [classes]="'btn--solid btn--solid_facebook'" [text]="'Create Campaign'"
                [iconName]="'MetaCurveIcon'" (hunchClick)="goToCampaignCreate()"
                *ngIf="platform === platformEnum.FACEBOOK">
            </hunch-button>
            <hunch-button [classes]="'btn--solid btn--solid_snapchat'" [text]="'Create Standard Campaign'"
                [iconName]="'SnapchatIcon'" (hunchClick)="goToSnapchatStandard()"
                *ngIf="platform === platformEnum.SNAPCHAT">
            </hunch-button>
            <hunch-button [classes]="'btn--solid btn--solid_snapchat'" [text]="'Create Automated Campaign'"
                [iconName]="'SnapchatIcon'" (hunchClick)="goToSnapchatAutomated()"
                *ngIf="platform === platformEnum.SNAPCHAT">
            </hunch-button>
        </div>
    </div>
    <div class="w--100" style="height: calc(100% - 44px);">
        
        <!-- loader for filters - should be shown when switching between snapchat and meta tabs while the filters are not loaded yet -->

        <div class="loading-filters" *ngIf="!showFiltering">
                <div class="v-a--top inline-block edit-selection-loader">
                    <div class="lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        <div class="w--100 pl--24 pr--16" *ngIf="showFiltering">
            <div class="horizontal-block filters pt--16 pb--40 h--44_px">
                <table-filters [resetTableConfiguration]="resetTableConfiguration"
                    [selectedDataLevel]="selectedDataLevel"></table-filters>
            </div>
            <div class="horizontal-block pb--20">
                <table-actions [selectedDataLevel]="selectedDataLevel"
                    [resetTableConfiguration]="resetTableConfiguration"></table-actions>
            </div>
        </div>
        <div class="w--100 pl--24" style="height: calc(100% - 140px);">
            <app-level-tab [selectedDataLevel]="selectedDataLevel" (resizedContainer)="resizedContainer()"
                (changeLevel)="changeSelectedLevel($event)"
                [resetTableConfiguration]="resetTableConfiguration"></app-level-tab>
            <ng-container [ngSwitch]="selectedDataLevel">
                <ng-container *ngSwitchCase="SelectedLevel.Campaign">
                    <metric-table-container [selectedDataLevel]="selectedDataLevel"
                        [resetTableConfiguration]="resetTableConfiguration"></metric-table-container>
                </ng-container>
                <ng-container *ngSwitchCase="SelectedLevel.AdSet">
                    <metric-table-container [selectedDataLevel]="selectedDataLevel"
                        [resetTableConfiguration]="resetTableConfiguration"></metric-table-container>
                </ng-container>
                <ng-container *ngSwitchCase="SelectedLevel.Ad">
                    <metric-table-container [selectedDataLevel]="selectedDataLevel"
                        [resetTableConfiguration]="resetTableConfiguration"></metric-table-container>
                </ng-container>
                <ng-container *ngSwitchDefault>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>
<!-- </ng-template> -->
<app-connect-campaign-modal (modalClosed)="showConnectCampaignModal = false"
    *ngIf="showConnectCampaignModal"></app-connect-campaign-modal>
<app-enrich-ad-modal (enrichModalClosed)="showEnrichAdModal = false" 
*ngIf="showEnrichAdModal"></app-enrich-ad-modal>