import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ManageOverviewService } from '../../manage-overview/manage-overview.service';
import { QueryParams } from '../components/table-filters';
import { FiltersService } from './filters.service';

@Injectable()
export class DatePresetService {
    private currentDatePreset: DatePresetType;

    currentDate: Date = new Date(this.getJustDateString(new Date()));
    datePresets: DatePresetType[] = [
        { id: 11, name: 'Today', dateRange: [this.currentDate, this.currentDate] },
        { id: 12, name: 'Yesterday', dateRange: [this.getDateBeforeDays(1), this.getDateBeforeDays(1)] },
        { id: 13, name: 'Last 2 Days', dateRange: [this.getDateBeforeDays(2), this.getDateBeforeDays(1)] },
        { id: 14, name: 'Last 3 Days', dateRange: [this.getDateBeforeDays(3), this.getDateBeforeDays(1)] },
        { id: 15, name: 'Last 7 Days', dateRange: [this.getDateBeforeDays(7), this.getDateBeforeDays(1)] },
        { id: 16, name: 'Last 14 Days', dateRange: [this.getDateBeforeDays(14), this.getDateBeforeDays(1)] },
        { id: 17, name: 'Last 30 Days', dateRange: [this.getDateBeforeDays(30), this.getDateBeforeDays(1)] },
        { id: 18, name: 'Last 60 Days', dateRange: [this.getDateBeforeDays(60), this.getDateBeforeDays(1)] },
        { id: 19, name: 'Last Month', dateRange: [this.getFirstDayOfPreviousMonth(), this.getLastDayOfPreviousMonth()] },
        { id: 20, name: 'This Week', dateRange: [this.getDateBeforeDays(this.currentDate.getDay() ? this.currentDate.getDay() - 1 : 7), new Date()] },
        { id: 21, name: 'This Month', dateRange: [this.getDateBeforeDays(this.currentDate.getDate() - 1), new Date()] },
        // { id: 22, name: 'Lifetime', dateRange: [new Date('1971-01-01'), this.currentDate] },
        { id: 22, name: 'Last 365 Days', dateRange: [this.getDateBeforeOneYear(), this.currentDate] },
        { id: 23, name: 'Custom', dateRange: [] }
    ];

    loadedQueryParamsOnGroupInitJSON: string;

    constructor(
        private route: ActivatedRoute, //
        private router: Router,
        private manageOverviewService: ManageOverviewService,
        private filtersService: FiltersService
    ) {
        this.loadedQueryParamsOnGroupInitJSON = this.setInsigtsFilters();
        this.setPresetByUrlParam(this.route.snapshot.queryParams.from, this.route.snapshot.queryParams.to);
    }

    getDateBeforeDays(numberOfDays: number): Date {
        let date = new Date(this.currentDate);
        return new Date(date.setDate(date.getDate() - numberOfDays));
    }

    getNumberOfDaysInMonth(year: number, month: number) {
        return new Date(year, month + 1, 0).getDate();
    }

    getFirstDayOfPreviousMonth() {
        let retVal = new Date();
        retVal.setDate(1);
        retVal.setMonth(retVal.getMonth() - 1);
        return retVal;
    }

    getLastDayOfPreviousMonth() {
        let retVal = new Date();
        retVal.setDate(0);
        return retVal;
    }

    getDateBeforeOneYear(): Date {
        let date = new Date();
        date.setFullYear(new Date().getFullYear() - 1);
        return date;
    }

    setCurrentDatePreset(preset: DatePresetType) {
        this.currentDatePreset = preset;
        let currentDatePresetValue = preset.dateRange;
        let params = this.route.snapshot.queryParams.insightsFilters;
        let queryParams: QueryParams = {
            from: `lifetime`,
            to: ``,
            level: `${this.manageOverviewService.getCurrentLevel()}`
        };

        let platform = this.route.snapshot.queryParams.platform;
        if (platform) queryParams.platform = platform;
        if (params) queryParams.insightsFilters = params;
        if (this.loadedQueryParamsOnGroupInitJSON) {
            queryParams.insightsFilters = this.loadedQueryParamsOnGroupInitJSON;
            delete this.loadedQueryParamsOnGroupInitJSON;
        }

        queryParams.from = `${this.getJustDateString(currentDatePresetValue[0])}`;
        queryParams.to = `${this.getJustDateString(currentDatePresetValue[1])}`;

        this.router.navigate(['.'], {
            relativeTo: this.route,
            queryParams: queryParams
        });

    }

    getCurrentDatePreset(): DatePresetType {
        return this.currentDatePreset;
    }

    private setNumberFormatStringFromMonthOrDay(numberValue: number, isMonth: boolean): string {
        if (isMonth == true) numberValue += 1;
        if (numberValue < 10) {
            return '0' + numberValue;
        } else return numberValue + '';
    }

    public getPureDateStringISO(date: Date, isFrom: boolean): string {
        return date.getFullYear() + '-' + this.setNumberFormatStringFromMonthOrDay(date.getMonth(), true) + '-' + this.setNumberFormatStringFromMonthOrDay(date.getDate(), false) + 'T' + (isFrom ? '00:00:00.000' : '23:59:59.000') + 'Z';
    }

    public getJustDateString(date: Date): string {
        return date.getFullYear() + '-' + this.setNumberFormatStringFromMonthOrDay(date.getMonth(), true) + '-' + this.setNumberFormatStringFromMonthOrDay(date.getDate(), false);
    }

    setPresetByUrlParam(from: string, to: string) {
        if (from === 'lifetime') {
            this.setCurrentDatePreset(this.datePresets[11]);
        } else if (from && to) {
            let dateRange = [new Date(from), new Date(to)];

            for (let datePreset of this.datePresets) {
                if (datePreset.dateRange.length) {
                    if (dateRange[0].getTime() === datePreset.dateRange[0].getTime() && dateRange[1].getTime() === datePreset.dateRange[1].getTime()) {
                        this.setCurrentDatePreset(datePreset);
                        break;
                    }
                }
            }

            if (!this.currentDatePreset) {
                this.datePresets[12].dateRange = dateRange;
                this.setCurrentDatePreset(this.datePresets[12]);
            }
        } else {
            this.setCurrentDatePreset(this.datePresets[1]);
        }
    }

    setInsigtsFilters(): string {
        return this.filtersService.setInsigtsFilterFromParamsOrLoadFromSession(this.route.snapshot.queryParams.insightsFilters);
    }
}

export class DatePresetType {
    id: number;
    name: string;
    dateRange: Date[];
}
