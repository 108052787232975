import { APP_BASE_HREF } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { ManageOverviewComponent } from './modules/manage-overview/manage-overview.component';
import { ReloadManageOverviewComponent } from './modules/manage-overview/reload-manage-overview/reload.component';

const routes: Routes = [
    { path: '', component: ManageOverviewComponent },
    { path: 'reload', component: ReloadManageOverviewComponent },
    { path: '**', component: EmptyRouteComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [{ provide: APP_BASE_HREF, useValue: '' }]
})
export class AppRoutingModule {}
