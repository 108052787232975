<div class="hunch-modal-wrapper" *ngIf="wrapperShow" [ngClass]="{'appear': isAppeared, 'v-2': isV2}">

    <div class="hunch-modal-container">
        <div class="hunch-modal-header">
            <h2 class="modal-title h2 {{headerClass}}" title="{{messageHeader}}">
                {{messageHeader}}
            </h2>
        </div>
        <div *ngIf="isV2" class="divider"></div>
        <ng-container *ngTemplateOutlet="customTemplate"></ng-container>
        <div *ngIf="messageBody" class="hunch-modal-body" title="{{messageBody}}">
            {{messageBody}}
        </div>
        <div *ngIf="isV2" class="divider"></div>
        <div class="hunch-modal-footer d-flex-modal " [ngStyle]="{'display': hideButtonGrid ? 'none' : ''}"
            [ngClass]="{'justify-content-between': justifySaveCancel, 'flex-end': flexEnd}">


            <button class="btn--solid btn--solid_grey light-text {{cancelBtnClass}}" (click)="closeModal(false)"
                title="{{cancelBtnText}}">{{cancelBtnText}}
            </button>

            <button class="btn--solid btn--solid_green {{confirmBtnClass}}" (click)="closeModal(true)"
                [disabled]="isConfirmDisabled" title="{{confirmBtnText}}">{{confirmBtnText}}
            </button>
        </div>
    </div>
</div>
