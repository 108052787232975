import { CampaignStructureLevel, MetricIntegrationType } from '../../../api/models';
import { ColumnDisplayConf } from './column-display-conf.model';

export class TableConfigurationPreset {
    constructor() {}

    id: string = Math.floor(Math.random() * 100000000000000000) + '';
    name: string = '';
    lastUsed: Date = new Date();
    isDefault: boolean = false;
    isHunchPredefined: boolean = false;

    campaign: ColumnDisplayConf[] = [];
    adSet: ColumnDisplayConf[] = [];
    ads: ColumnDisplayConf[] = [];
    groupId?:any;

    allDataConfigurations: ColumnDisplayConf[] = [];

    static fromObject(item: any) {
        return Object.assign(new TableConfigurationPreset(), item);
    }
}

export class TableConfigurationPresetFE extends TableConfigurationPreset {
    hasUnsavedChanges: boolean = false;

    constructor(value?: any) {
        super();
        const classKeys = Object.keys(this);
        if (value)
            for (const valueKey in value) {
                if (classKeys.includes(valueKey)) this[valueKey] = value[valueKey];
            }
    }

    static fromObject(item: any) {
        return Object.assign(new TableConfigurationPresetFE(), item);
    }

    public getCurrentLevelColumns(selectedDataLevel: CampaignStructureLevel): ColumnDisplayConf[] {
        if (selectedDataLevel) {
            switch (selectedDataLevel) {
                case CampaignStructureLevel.Campaign:
                    return this.campaign;
                case CampaignStructureLevel.AdSet:
                    return this.adSet;
                case CampaignStructureLevel.Ad:
                    return this.ads;
            }
        }
        return null;
    }

    public doesSelectedDataLevelContainHunchMetrics(selectedDataLevel: CampaignStructureLevel): boolean {
        if (selectedDataLevel) {
            switch (selectedDataLevel) {
                case CampaignStructureLevel.Campaign:
                    return !!this.campaign.find(item => item.metricIntegrationType === MetricIntegrationType.Hunch);
                case CampaignStructureLevel.AdSet:
                    return !!this.adSet.find(item => item.metricIntegrationType === MetricIntegrationType.Hunch);
                case CampaignStructureLevel.Ad:
                    return !!this.ads.find(item => item.metricIntegrationType === MetricIntegrationType.Hunch);
            }
        }
        return false;
    }

    public doesSelectedDataLevelContainAppsFlyerMetrics(selectedDataLevel: CampaignStructureLevel): boolean {
        if (selectedDataLevel) {
            switch (selectedDataLevel) {
                case CampaignStructureLevel.Campaign:
                    return !!this.campaign.find(item => item.metricIntegrationType === MetricIntegrationType.AppsFlyer);
                case CampaignStructureLevel.AdSet:
                    return !!this.adSet.find(item => item.metricIntegrationType === MetricIntegrationType.AppsFlyer);
                case CampaignStructureLevel.Ad:
                    return !!this.ads.find(item => item.metricIntegrationType === MetricIntegrationType.AppsFlyer);
            }
        }
        return false;
    }

    public doesSelectedDataLevelContainGoogleMetrics(selectedDataLevel: CampaignStructureLevel): boolean {
        if (selectedDataLevel) {
            switch (selectedDataLevel) {
                case CampaignStructureLevel.Campaign:
                    return !!this.campaign.find(item => item.metricIntegrationType === MetricIntegrationType.Google || item.metricIntegrationType === MetricIntegrationType.GoogleAnalytics4);
                case CampaignStructureLevel.AdSet:
                    return !!this.adSet.find(item => item.metricIntegrationType === MetricIntegrationType.Google || item.metricIntegrationType === MetricIntegrationType.GoogleAnalytics4);
                case CampaignStructureLevel.Ad:
                    return !!this.ads.find(item => item.metricIntegrationType === MetricIntegrationType.Google || item.metricIntegrationType === MetricIntegrationType.GoogleAnalytics4);
            }
        }
        return false;
    }

    public setMandatoryColumnsOrder(level: ColumnDisplayConf[]) {
        //PresetStaticActions.unshiftByKey(level, 'effectiveStatus');
        PresetStaticActions.unshiftByKey(level, 'name');
        PresetStaticActions.unshiftByKey(level, 'status');
    }

    public addMissingMandatoryFields(level: ColumnDisplayConf[]) {
        //PresetStaticActions.addDisplayColumnIfMissing(level, this.allDataConfigurations, 'effectiveStatus');
        PresetStaticActions.addDisplayColumnIfMissing(level, this.allDataConfigurations, 'status');
        PresetStaticActions.addDisplayColumnIfMissing(level, this.allDataConfigurations, 'name');
    }

    public setSortOrderByArrayOrder(level: ColumnDisplayConf[]) {
        level.forEach((x, index) => {
            if (x.key !== 'selectElement') x.sortOrder = index + 1;
        });
    }

    public sortPresetColumnsBySortOrder() {
        this.campaign = this.campaign.sort((a, b) =>  Number(a.sortOrder) - Number(b.sortOrder));
        this.adSet = this.adSet.sort((a, b) => a.sortOrder - b.sortOrder);
        this.ads = this.ads.sort((a, b) => a.sortOrder - b.sortOrder);
    }

    public resizeAllLevelColumns(columnKey:string, size:number){
        this.resizeColumns(columnKey, size, this.campaign);
        this.resizeColumns(columnKey, size, this.adSet);
        this.resizeColumns(columnKey, size, this.ads);
    }

    private resizeColumns(columnKey:string, size:number, array:ColumnDisplayConf[]){
        let column = array.find(item=>item.key === columnKey);
        if(column) column.width = size;
    }
}

export class PresetStaticActions {
    static unshiftByKey(level: any[], key: string) {
        let element = level.find(x => x.key === key);
        if (element) {
            let elementIndex = level.indexOf(element);
            level.splice(elementIndex, 1);
            level.unshift(element);
        }
    }

    static addDisplayColumnIfMissing(level: any[], allDataConfigurations: ColumnDisplayConf[], key: string) {
        let status = level.find(x => x.key === key);
        if (!status) {
            let defStatus = allDataConfigurations.find(x => x.key === key);
            if (defStatus) level.push(defStatus);
        }
    }
}
