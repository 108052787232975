export enum OptimizationGoalEnum {
    NONE = 'NONE',
    APP_INSTALLS = 'APP_INSTALLS',
    BRAND_AWARENESS = 'BRAND_AWARENESS',
    AD_RECALL_LIFT = 'AD_RECALL_LIFT',
    CLICKS = 'CLICKS',
    ENGAGED_USERS = 'ENGAGED_USERS',
    EVENT_RESPONSES = 'EVENT_RESPONSES',
    IMPRESSIONS = 'IMPRESSIONS',
    LEAD_GENERATION = 'LEAD_GENERATION',
    LINK_CLICKS = 'LINK_CLICKS',
    OFFER_CLAIMS = 'OFFER_CLAIMS',
    OFFSITE_CONVERSIONS = 'OFFSITE_CONVERSIONS',
    PAGE_ENGAGEMENT = 'PAGE_ENGAGEMENT',
    PAGE_LIKES = 'PAGE_LIKES',
    POST_ENGAGEMENT = 'POST_ENGAGEMENT',
    REACH = 'REACH',
    SOCIAL_IMPRESSIONS = 'SOCIAL_IMPRESSIONS',
    VIDEO_VIEWS = 'VIDEO_VIEWS',
    APP_DOWNLOADS = 'APP_DOWNLOADS',
    LANDING_PAGE_VIEWS = 'LANDING_PAGE_VIEWS',
    VALUE = 'VALUE',
    REPLIES = 'REPLIES',
    TWO_SECOND_CONTINUOUS_VIDEO_VIEWS = 'TWO_SECOND_CONTINUOUS_VIDEO_VIEWS',
    THRUPLAY = 'THRUPLAY',
    ONSITE_CONVERSIONS = 'ONSITE_CONVERSIONS',
    OFFLINE_CONVERSIONS = 'OFFLINE_CONVERSIONS',
    CONVERSATIONS = 'CONVERSATIONS',
    VISIT_INSTAGRAM_PROFILE = 'VISIT_INSTAGRAM_PROFILE',
    INCREMENTAL_OFFSITE_CONVERSIONS = 'INCREMENTAL_OFFSITE_CONVERSIONS',
    ENGAGED_REACH = 'ENGAGED_REACH',
    APP_INSTALLS_AND_OFFSITE_CONVERSIONS = 'APP_INSTALLS_AND_OFFSITE_CONVERSIONS',
    STORE_VISITS = 'STORE_VISITS',
    QUALITY_CALL = 'QUALITY_CALL'
}
export enum OldOptimizationGoal {
    None = 0,
    AppInstalls = 1,
    BrandAwareness = 2,
    AdRecallLift = 3,
    Clicks = 4,
    EngagedUsers = 5,
    EventResponses = 6,
    Impressions = 7,
    LeadGeneration = 8,
    LinkClicks = 9,
    OfferClaims = 10,
    OffsiteConversions = 11,
    PageEngagement = 12,
    PageLikes = 13,
    PostEngagement = 14,
    Reach = 15,
    SocialImpressions = 16,
    VideoViews = 17,
    AppDownloads = 18,
    LandingPageViews = 19,
    Value = 20,
    Replies = 21,
    TwoSecondContinuousVideoViews = 22,
    Thruplay = 23,
    OnsiteConversions = 24,
    OfflineConversions = 25,
    Conversations = 26,
    VisitInstagramProfile = 27,
    IncrementalOffsiteConversions = 28,
    EngagedReach = 29,
    AppInstallsAndOffsiteConversions = 30,
    STORE_VISITS = 31,
    QUALITY_CALL = 32,
    QUALITY_LEAD = 33,
    DERIVED_EVENTS = 34,
    IN_APP_VALUE = 35,
    MESSAGING_PURCHASE_CONVERSION = 36,
    MESSAGING_APPOINTMENT_CONVERSION = 37
}