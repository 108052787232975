<div style="width: 402px;" *ngIf="!loading else loader">
    <div class="row-name" title="{{pageRow.name}}" [style.margin-bottom]="'0px'">
        {{pageRow.name}}
    </div>
    <ng-container>
        <div>
            <!-- <ng-container *ngIf="isAutomated">
                <div class="pb--10 text-12">Please select account you want to duplicate campaign to</div>
                <div class="pb--20">
                    <ng-select class="select--dark" [items]="groups" [loading]="!groups" [formControl]="groupFormControl"
                        bindLabel="name" bindValue="id" placeholder="Select..." [searchable]="true"> </ng-select>
                </div>
            </ng-container> -->
            <ng-container *ngIf="shouldShow">
                <div class="pb--10 font--weight_semi text-12">Ad Account</div>
                <div class="pb--20">
                    <ng-select class="select--dark" [items]="accounts" [loading]="!groups"
                        [formControl]="acconutFormControl" bindLabel="name" placeholder="Select..." [searchable]="true"
                        bindValue="profile_id">
                        <ng-template ng-label-tmp let-item="item">
                            <svg-icon class="ng-value-img fb-icon" name="FacebookIcon" alt=""></svg-icon>
                            <span class="ng-value-label">{{ item.name }}</span>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                            <div class="ng-option-img_facebook">
                                <svg-icon class="ng-option-img fb-icon" name="FacebookIcon" alt=""></svg-icon>
                            </div>
                            <div class="ng-option-value_facebook">
                                <div class="value_facebook-title">{{ item.name }}</div>
                                <div class="value_facebook-value">Account number: {{item.profile_id}}</div>
                            </div>
                        </ng-template>
                    </ng-select>
                </div></ng-container>
        </div>
        <!-- <ng-container *ngIf="isAutomated">
            <div class="pb--10 font--weight_semi text-12">Catalog</div>
            <div>
                <ng-select class="select--dark" [items]="catalogs" [loading]="!groups"
                    [formControl]="automatedCatalogFormControl" bindLabel="name" placeholder="Select..."
                    [searchable]="true" bindValue="id">
                    <ng-template ng-label-tmp let-item="item">
                        <svg-icon class="ng-value-img" name="HunchIcon" alt=""></svg-icon>
                        <span class="ng-value-label">{{ item.name }}</span>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                        <div class="ng-option-img_facebook">
                            <svg-icon class="ng-option-img" name="HunchIcon" alt=""></svg-icon>
                        </div>
                        <div class="ng-option-value_facebook">
                            <div class="value_facebook-title">{{ item.name }}</div>
                            <div class="value_facebook-value">
                                <span>Hunch Automated Catalog</span>
                            </div>
                        </div>
                    </ng-template>
                </ng-select>
            </div>
        </ng-container> -->
        <ng-container *ngIf="isDpa">
            <div class="pb--10 font--weight_semi text-12">Catalog</div>
            <div>
                <ng-select class="select--dark" [items]="catalogs" [loading]="!groups"
                    [formControl]="dpaCatalogFormControl" bindLabel="name" placeholder="Select..." [searchable]="true"
                    bindValue="facebookId">
                    <ng-template ng-label-tmp let-item="item">
                        <svg-icon class="ng-value-img" name="HunchIcon" alt=""></svg-icon>
                        <span class="ng-value-label">{{ item.name }}</span>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                        <div class="ng-option-img_facebook">
                            <svg-icon class="ng-option-img" name="HunchIcon" alt=""></svg-icon>
                        </div>
                        <div class="ng-option-value_facebook">
                            <div class="value_facebook-title">{{ item.name }}</div>
                            <div class="value_facebook-value">
                                <span>{{item.facebookId}}</span>
                            </div>
                        </div>
                    </ng-template>
                </ng-select>
            </div>
        </ng-container>
    </ng-container>
</div>

<ng-template #loader>
loading...
</ng-template>
