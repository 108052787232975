import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FacebookAdSet, FacebookCampaign,  InsightsQueryResponseObjectFE } from '../../../../../api/models';
import { ColumnDisplayConf } from '../../../models';

@Component({
    selector: 'cell-format-compound-display-value',
    templateUrl: './cell-format-compound-display-value.component.html',
    styleUrls: ['./cell-format-compound-display-value.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CellFormatCompoundDisplayValueComponent implements OnChanges {
    @Input() row: InsightsQueryResponseObjectFE;
    @Input() column: ColumnDisplayConf;
    @Input() columnKey: string;

    primaryData;
    additionalInfoData: string;

    mainInfo: string;

    constructor() {}

    ngOnChanges(changes: SimpleChanges): void {
        delete this.primaryData;
        delete this.additionalInfoData;
        delete this.mainInfo;

        let data:FacebookCampaign | FacebookAdSet = this.row as FacebookCampaign | FacebookAdSet;
        if (this.columnKey === 'budget') {
            if (this.row.isCampaign) {
                if(data.lifetimeBudget || data.dailyBudget) this.setBudgetValues(data)
                else this.mainInfo = 'Using ad set budget';
            }
            if (this.row.isAdset) {
                if (data.usesCbo) this.mainInfo = 'Using Campaign budget';
                else this.setBudgetValues(data);
            }
        }
    }

    setBudgetValues(data: FacebookCampaign | FacebookAdSet) {
        if (!data.dailyBudget && !data.lifetimeBudget) this.mainInfo = '-';
        else {
            if (data.dailyBudget) {
                this.primaryData = data.dailyBudget;
                this.additionalInfoData = 'Daily';
            }
            if (data.lifetimeBudget) {
                this.primaryData = data.lifetimeBudget;
                this.additionalInfoData = 'Lifetime';
            }
        }
    }
}
