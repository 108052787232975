<div class="w--100 h--100 truncate pl--8 size--5 font--weight_semi cursor--pointer header-cell noselect"

    style="height: 32px;" title="{{column.name}}" [ngClass]="{'draggable': column.draggable,
    'hunch-integration-type-fill-color': column.metricIntegrationType === MetricIntegrationType.Hunch,
    'google-integration-type-fill-color': column.metricIntegrationType === MetricIntegrationType.Google || column.metricIntegrationType === MetricIntegrationType.GoogleAnalytics4,
    'sorted': isSorted}">
    <ng-container [ngSwitch]="column.key">
        <!-- <ng-container *ngSwitchCase="'selectElement'">
            <mat-checkbox [ngModel]="selectedAll" (change)="emitSelectAll.emit()"></mat-checkbox>
        </ng-container> -->
        <ng-container *ngSwitchCase="'status'">
        </ng-container>

        <ng-container *ngSwitchCase="'effectiveStatus'">
            <div class="header-main truncate" (click)="emitColumnSortCustom.emit(column)">Status</div>
            <div class="header-sub truncate">{{ column.name }}</div>
        </ng-container>
        
        <svg-icon *ngIf="column.metricIntegrationType === MetricIntegrationType.Hunch" name="AdjustIcon" alt="" class="mr--4" svgClass="left-menu-icon menu-icon"></svg-icon>
        <svg-icon *ngIf="column.metricIntegrationType === MetricIntegrationType.AppsFlyer" name="AppsFlyerIcon" alt="" class="mr--4" svgClass="left-menu-icon menu-icon"></svg-icon>
        <svg-icon *ngIf="column.metricIntegrationType === MetricIntegrationType.Google || column.metricIntegrationType === MetricIntegrationType.GoogleAnalytics4" name="GoogleAnalyticsIcon" alt="" class="mr--4" svgClass="left-menu-icon menu-icon"></svg-icon>

        <ng-container *ngSwitchDefault>
            <span [ngClass]="{'draggable': column.draggable}" (click)="emitColumnSortCustom.emit(column)" title="{{column.description}}">{{ column.name }}</span>
        </ng-container>
    </ng-container>
</div>