<div class="w--100 v-a--top d--flex">
    <div class="inline-block w--100 flex--dyn-w">
        <app-table-search [resetTableConfiguration]="resetTableConfiguration"></app-table-search>

    </div>
    <!-- <table-filter-level style="width: calc(100% - 230px);"  class="inline-block" [resetTableConfiguration]="resetTableConfiguration"></table-filter-level> -->

    <div class="flex--auto-w">
        <ad-account-sync-option class=" v-a--top"></ad-account-sync-option>
    </div>

    <div class="flex--auto-w ml--8">
        <metric-toggle-buttons [selectedDataLevel]="selectedDataLevel"></metric-toggle-buttons>
    </div>
    <div class="flex--auto-w">
        <preset-editor class=" v-a--top" [selectedDataLevel]="selectedDataLevel"></preset-editor>
    </div>

    <div class="flex--auto-w">
        <date-range class="inline-block"></date-range>
    </div>


</div>