import { CustomEventTypeEnum, OldCustomEventTypeEnum } from "../enums";

export const mapEventType: any = {
    [OldCustomEventTypeEnum.Rate]: CustomEventTypeEnum.RATE,
    [OldCustomEventTypeEnum.TutorialCompletion]: CustomEventTypeEnum.TUTORIAL_COMPLETION,
    [OldCustomEventTypeEnum.AddToCart]: CustomEventTypeEnum.ADD_TO_CART,
    [OldCustomEventTypeEnum.AddToWishlist]: CustomEventTypeEnum.ADD_TO_WISHLIST,
    [OldCustomEventTypeEnum.InitiatedCheckout]: CustomEventTypeEnum.INITIATED_CHECKOUT,
    [OldCustomEventTypeEnum.AddPaymentInfo]: CustomEventTypeEnum.ADD_PAYMENT_INFO,
    [OldCustomEventTypeEnum.Purchase]: CustomEventTypeEnum.PURCHASE,
    [OldCustomEventTypeEnum.Lead]: CustomEventTypeEnum.LEAD,
    [OldCustomEventTypeEnum.CompleteRegistration]: CustomEventTypeEnum.COMPLETE_REGISTRATION,
    [OldCustomEventTypeEnum.ContentView]: CustomEventTypeEnum.CONTENT_VIEW,
    [OldCustomEventTypeEnum.Search]: CustomEventTypeEnum.SEARCH,
    [OldCustomEventTypeEnum.ServiceBookingRequest]: CustomEventTypeEnum.SERVICE_BOOKING_REQUEST,
    [OldCustomEventTypeEnum.MessagingConversationStarted7d]: CustomEventTypeEnum.MESSAGING_CONVERSATION_STARTED_7D,
    [OldCustomEventTypeEnum.LevelAchieved]: CustomEventTypeEnum.LEVEL_ACHIEVED,
    [OldCustomEventTypeEnum.AchievementUnlocked]: CustomEventTypeEnum.ACHIEVEMENT_UNLOCKED,
    [OldCustomEventTypeEnum.SpentCredits]: CustomEventTypeEnum.SPENT_CREDITS,
    [OldCustomEventTypeEnum.Other]: CustomEventTypeEnum.OTHER,
    [OldCustomEventTypeEnum.StartTrial]: CustomEventTypeEnum.START_TRIAL,
    [OldCustomEventTypeEnum.CONTACT]: CustomEventTypeEnum.CONTACT
}