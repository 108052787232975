import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { BsDaterangepickerInlineConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Subscription } from 'rxjs';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { enGbLocale } from 'ngx-bootstrap/locale';
import { ApplicationHeaderPlaceholderHeight } from '../../../../../app.component';
import { TableService, DatePresetService, DatePresetType  } from '../../../table-services';
import { TableReloadType } from '../../../models';
defineLocale('en-gb', enGbLocale);

@Component({
    selector: 'date-range',
    templateUrl: './date-range.component.html',
    styleUrls: ['./date-range.component.scss']
})
export class DateRangeComponent implements OnInit, OnDestroy {
    selectedPresetId = new FormControl();
    currentPresetId: number;

    ApplicationHeaderPlaceholderHeight = ApplicationHeaderPlaceholderHeight;

    currentDate = new Date();
    maxDate = this.setMaxDate();
    datePresets: DatePresetType[] = this.datePresetService.datePresets;

    blockBsValueChange: boolean = true;
    bsRangeValue: Date[];

    subs: Subscription = new Subscription();

    as: BsDaterangepickerInlineConfig;
    @ViewChild(NgSelectComponent, { static: false }) dropdownElement: NgSelectComponent;

    constructor(
        private datePresetService: DatePresetService, //
        private localeService: BsLocaleService
    ) {
        this.localeService.use('en-gb');
    }

    ngOnInit(): void {
        this.bsRangeValue = this.datePresetService.getCurrentDatePreset().dateRange;
        this.selectedPresetId.setValue(this.datePresetService.getCurrentDatePreset().id);
        this.currentPresetId = this.datePresetService.getCurrentDatePreset().id;

        this.subs.add(
            this.selectedPresetId.valueChanges.subscribe((value: number) => {
                this.changeDatePreset(value);
            })
        );
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    bsValueChange(event: Date[]) {
        if (this.blockBsValueChange === false) {
            this.bsRangeValue = event;
            this.selectedPresetId.patchValue(23);
        }
        this.blockBsValueChange = false;
    }

    changeDatePreset(selectedId: number) {
        switch (selectedId) {
            case 11:
                this.bsRangeValue = this.datePresets[0].dateRange;
                break;
            case 12:
                this.bsRangeValue = this.datePresets[1].dateRange;
                break;
            case 13:
                this.bsRangeValue = this.datePresets[2].dateRange;
                break;
            case 14:
                this.bsRangeValue = this.datePresets[3].dateRange;
                break;
            case 15:
                this.bsRangeValue = this.datePresets[4].dateRange;
                break;
            case 16:
                this.bsRangeValue = this.datePresets[5].dateRange;
                break;
            case 17:
                this.bsRangeValue = this.datePresets[6].dateRange;
                break;
            case 18:
                this.bsRangeValue = this.datePresets[7].dateRange;
                break;
            case 19:
                this.bsRangeValue = this.datePresets[8].dateRange;
                break;
            case 20:
                this.bsRangeValue = this.datePresets[9].dateRange;
                break;
            case 21:
                this.bsRangeValue = this.datePresets[10].dateRange;
                break;
            case 22:
                this.bsRangeValue = this.datePresets[11].dateRange;
                break;
            case 23:
                this.datePresets[12].dateRange = this.bsRangeValue;
                break;
            default:
                break;
        }
        this.blockBsValueChange = true;
    }

    closeDatepicker(isChangeConfirmed: boolean, ngSelect?: NgSelectComponent) {
        if (isChangeConfirmed) {
            this.updateTimePreset();
        } else {
            this.selectedPresetId.patchValue(this.currentPresetId);
        }
        if (ngSelect) ngSelect.close();
    }

    updateTimePreset() {
        this.currentPresetId = this.selectedPresetId.value;
        this.datePresetService.setCurrentDatePreset(this.datePresets.find(pres => pres.id === this.currentPresetId));
        TableService.reloadTable$.next(TableReloadType.ChangeParams);
    }

    blockEvent(event: Event) {
        event.stopPropagation();
    }

    setMaxDate(): Date {
        let today = new Date();
        let tommorow = new Date();
        tommorow.setDate(today.getDate() + 1);
        return tommorow;
    }

    changeValue(event: Event, itemId: number) {
        if (itemId !== 23) {
            this.currentPresetId = itemId;
            this.selectedPresetId.patchValue(itemId);
            this.closeDatepicker(true, this.dropdownElement);
        }
    }
}
