export enum FacebookPositionEnum {
    FEED = 'feed',
    MARKETPLACE = 'marketplace',
    VIDEO_FEEDS = 'video_feeds',
    RIGHT_HAND_COLUMN = 'right_hand_column',
    STORY = 'story',
    FACEBOOK_REELS = 'facebook_reels',
    ADS_ON_FACEBOOK_REELS = 'facebook_reels_overlay',
    BUSINESS_EXPLORE = 'business_explore', // only for FE disabled on Meta and not in api doc
    INSTREAM_VIDEO = 'instream_video',
    SEARCH = 'search'
}
export enum OldFacebookPositionEnum {
    feed,
    right_hand_column,
    instant_article,
    instream_video,
    video_feeds,
    marketplace,
    story,
    search
}