import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MetaHubService, NotifyService, SnapchatHubService, get } from '../../modules/shared/services';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PatchFacebookCampaignFromManageCommand } from '../models/table-action-request.model';
import { EditSelectionComponent } from '../../modules/metric-table/components/table-actions';
import { HandpickChangeBody, MessageHub, UnpublishedHandpickResponse } from '../../modules/shared/models';
import { PlatformEnum } from '../../modules/shared';


@Injectable({
    providedIn: 'root'
})
export class TableActionsApiService {
    private endpointUriActions = `${get('apiUri')}/manage/campaign`;
    private endpointUriDisconnect = `${get('apiUri')}/manage/disable-aa-sync/`;

    constructor(
        private httpClient: HttpClient, //
        private notifyService: NotifyService,
        private snapchatHubService: SnapchatHubService,
        private metaHubService: MetaHubService
    ) { }

    changeBid(postObj: PatchFacebookCampaignFromManageCommand, component: EditSelectionComponent): Observable<any> {
        return this.httpClient.patch<ActionResponceObject>(this.endpointUriActions, postObj).pipe(
            map(res => {
                if (res?.failedToUpdate?.length) {
                    if (res.failedToUpdate.length === postObj.manageRowItems.length) this.notifyService.error('Change bid failed');
                    else this.notifyService.error('Change bid partially failed');
                }
                if (!res?.successfullyUpdated?.length) this.notifyService.error('Change bid failed');
                component.clearLoaderAndReloadMetricTable();
                return res;
            }),
            catchError(error => {
                component.clearLoaderAndReloadMetricTable();
                this.notifyService.error('Change bid failed');
                return throwError('');
            })
        );
    }

    changeBudget(postObj: PatchFacebookCampaignFromManageCommand, component: EditSelectionComponent): Observable<any> {
        return this.httpClient.patch<ActionResponceObject>(this.endpointUriActions, postObj).pipe(
            map(res => {
                if (res?.failedToUpdate?.length) {
                    if (res.failedToUpdate.length === postObj.manageRowItems.length) this.notifyService.error('Change budget failed');
                    else this.notifyService.error('Change budget partially failed');
                }
                if (!res?.successfullyUpdated?.length) this.notifyService.error('Change budget failed');
                component.clearLoaderAndReloadMetricTable();
                return res;
            }),
            catchError(error => {
                component.clearLoaderAndReloadMetricTable();
                this.notifyService.error('Change budget failed');
                return throwError('');
            })
        );
    }

    changeStatus(postObj: PatchFacebookCampaignFromManageCommand): Observable<any> {
        return this.httpClient.patch<ActionResponceObject>(this.endpointUriActions, postObj).pipe(
            map(res => {
                if (res?.failedToUpdate?.length) {
                    this.notifyService.error('Change status failed');
                }
                if (!res?.successfullyUpdated?.length) this.notifyService.error('Change status failed');
                return res;
            }),
            catchError(error => {
                this.notifyService.error('Change status failed');
                return throwError('');
            })
        );
    }

    handpickChangesSnap(body: HandpickChangeBody) {
        this.snapchatHubService.registerByRequestId(body.requestId);
        this.httpClient.post<HandpickChangeBody>(`$hunchSnapchatCampaignEditorApi/inline-handpick-changes`, body).subscribe((resp: any) => {
        });
    }

    handpickChangesMeta(body: HandpickChangeBody) {
        this.metaHubService.registerByRequestId(body.requestId);
        this.httpClient.post<HandpickChangeBody>('$hunchMetaCampaignEditorApi/inline-handpick-changes', body).subscribe((resp: any) => {
        });
    }

    getUnpublishedHandpickChanges(platform: PlatformEnum): Observable<UnpublishedHandpickResponse[]> {
        if (platform === PlatformEnum.SNAPCHAT) return this.httpClient.get<UnpublishedHandpickResponse[]>(`$hunchSnapchatCampaignEditorApi/inline-handpick-changes/unpublished`)
        if (platform === PlatformEnum.FACEBOOK) return this.httpClient.get<UnpublishedHandpickResponse[]>(`$hunchMetaCampaignEditorApi/inline-handpick-changes/unpublished`)
    }

    disconnectAutomated(hunchId: string) {
        return this.httpClient.post(this.endpointUriDisconnect + hunchId, {}).pipe(
            catchError(error => {
                this.notifyService.error('Disconnect failed');
                return throwError('');
            })
        );
    }
}

export class ActionResponceObject {
    public successfullyUpdated: string[];
    public failedToUpdate: string[];
}
