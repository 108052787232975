<div class="w--100 h--100 truncate pl--8 pr--8 size--5 font--weight_semi noselect footer-cell bg--white"
    style="position: absolute;"
    [ngStyle]="{'top': tableContainerHeight - 60 + 'px', 'border-right': column.key === 'selectElement' ? '0' : '' }">

    <ng-container *ngIf="isTotalCount; else checkIfMetric" [ngTemplateOutlet]="total"></ng-container>

    <ng-template #checkIfMetric>
        <ng-container *ngIf="column.metricType === MetricType.Metric" [ngTemplateOutlet]="metric">
        </ng-container>
    </ng-template>

</div>

<ng-template #metric>
    <div class="w--100 h--100" [ngClass]="{ 'hunch-integration-type-color': column.metricIntegrationType === MetricIntegrationType.Hunch,
    'google-integration-type-color': column.metricIntegrationType === MetricIntegrationType.Google || column.metricIntegrationType === MetricIntegrationType.GoogleAnalytics4}">
        <ng-container *ngIf="!isSummaryLoading; else loader1" [ngTemplateOutlet]="data"></ng-container>
    </div>
</ng-template>

<ng-template #data>
    <div class="compound-main font--weight_semi mt--10">
        <cell-format-display-value  *ngIf="cellDisplayValue; else noData"
        [cellDisplayValue]="cellDisplayValue"
        [metricDataType]="column.metricDataType"
        [isSummaryRow]="true"
        [hideCurrency]="isGoogleMetric"
        ></cell-format-display-value>
    </div>
    <div class="size--6 truncate" title="Total {{column.name}}">Total {{column.name}}</div>

    <ng-template #noData>-</ng-template>
</ng-template>

<ng-template #loader1>
    <div class="lds-ellipsis" style="margin-right: 27px; vertical-align: top; top: 10px;">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</ng-template>



<ng-template #total>
    Total: <span
        *ngIf="!isTotalCountLoading && !(totalCount === undefined || totalCount === null); else loader2">{{totalCount}}</span>
</ng-template>

<ng-template #loader2>
    &nbsp;&nbsp;&nbsp;<div class="lds-ellipsis" style="vertical-align: top; top: 10px;">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</ng-template>