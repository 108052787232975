import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class CompareObjectsService {

	constructor() { }

	areObjectsEqual(obj1: any, obj2: any): boolean {
		let retVal = true;

		if(!obj1 && !!obj2 || !!obj1 && !obj2) return false;
		if(!obj1 && !obj2) return true;

		const object1Keys = Object.keys(obj1);
		const object2Keys = Object.keys(obj2);

		if (object1Keys.length === object2Keys.length) {

			const uniqueElement = object1Keys.filter(a => !object2Keys.includes(a));
			if (uniqueElement.length === 0) {

				for (let field in obj1) {
					if (Object.prototype.toString.call(obj1[field]) === '[object Object]') {
						if (!this.areObjectsEqual(obj1[field], obj2[field])) {
							retVal = false;
							break;
						}
					} else if (Array.isArray(obj1[field])) {
						if (!this.areArraysEqual(obj1[field], obj2[field])) {
							retVal = false;
							break;
						}
					} else if (obj1[field] !== Object(obj1[field])) {
						if (obj1[field] !== obj2[field]) {
							retVal = false;
							break;
						}
					}
				}
			} else retVal = false;

		} else {
			retVal = false;
		}

		return retVal;
	}

	private areArraysEqual(obj1: any[], obj2: any[]): boolean {

		if(!obj1 && !!obj2 || !!obj1 && !obj2) return false;
		if(!obj1 && !obj2) return true;

		let sort1 =  [...obj1].sort();
		let sort2 =  [...obj2].sort();

		let retVal = true;
		if (sort1.length !== sort2.length) {
			retVal = false;
		} else {
			for (let index = 0; index < sort1.length; index++) {
				if (!this.areArrayValuesEqual(sort1[index], sort2[index])) {
					retVal = false;
					break;
				}
			}
		}

		return retVal;
	}

	private areArrayValuesEqual(obj1: any, obj2: any): boolean {
		let retVal = true;

		if(!obj1 && !!obj2 || !!obj1 && !obj2) return false;
		if(!obj1 && !obj2) return true;

		if (obj1 !== Object(obj1) && obj2 !== Object(obj2)) {
			if (obj1 !== obj2) {
				retVal = false;
			}
		} else if (Object.prototype.toString.call(obj1) === '[object Object]' && Object.prototype.toString.call(obj2) === '[object Object]') {
			if (!this.areObjectsEqual(obj1, obj2)) {
				retVal = false;
			}
		} else if (Array.isArray(obj1) || Array.isArray(obj2)) {
			if (!this.areArraysEqual(obj1, obj2)) {
				retVal = false;
			}
		} else {
			retVal = false;
		}

		return retVal;
	}
}

