import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { CampaignStructureLevel, InsightsFilter } from '../../../../api/models';
import { FiltersService, TableService } from '../../table-services';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs';

@Component({
  selector: 'app-table-search',
  templateUrl: './table-search.component.html',
  styleUrls: ['./table-search.component.scss'],
})
export class TableSearchComponent implements OnInit {
  @Input('resetTableConfiguration') resetTableConfiguration: EventEmitter<any>;
  searchControl: FormControl = new FormControl();
  constructor(private filtersService: FiltersService) { }

  ngOnInit(): void {
    this.searchControl.valueChanges.pipe(debounceTime(300)).subscribe((value: string) => {
      let filters = [];
      if (value) {
        filters = [
          new InsightsFilter({
            metricKey: 'name',
            operator: 3,
            value: value,
            campaignStructureLevel: CampaignStructureLevel.Campaign
          }),
          new InsightsFilter({
            metricKey: 'facebookId',
            operator: 3,
            value: value,
            campaignStructureLevel: CampaignStructureLevel.Campaign
          }),
          new InsightsFilter({
            metricKey: 'internalId',
            operator: 3,
            value: value,
            campaignStructureLevel: CampaignStructureLevel.Campaign
          })
        ];
      } 
      this.filtersService.addCustomFilter(filters, 'search');
    });
    this.resetTableConfiguration.subscribe(() => {
      this.searchControl.setValue(null);
    })
  }
}
