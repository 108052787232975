import { CampaignStructureLevel, MetricDataType, MetricIntegrationType, MetricType } from '../../../api/models';
import { ColumnDisplayConf } from './column-display-conf.model';

export const FrozenLeftColumns: string[] = [
    'selectElement', //
    'name',
    'status',
    'effectiveStatus'
];

export const DefaultSortColumnName: string = 'createdTime';
export const DefaultSortDirection: string = 'desc';

export const BoldTextCells = {
    name: true
};

export const TextAlignRight = [
    MetricDataType.Decimal, //
    MetricDataType.Integer,
    MetricDataType.Percent,
    MetricDataType.Currency,
    MetricDataType.Date,
    MetricDataType.Seconds
];

export const SelectColumn: ColumnDisplayConf = new ColumnDisplayConf({
    key: 'selectElement',
    name: '',
    description: '',
    campaignStructureLevels: [CampaignStructureLevel.Campaign, CampaignStructureLevel.AdSet, CampaignStructureLevel.Ad],
    metricDataType: MetricDataType.Boolean,
    metricIntegrationType: MetricIntegrationType.None,
    metricType: MetricType.Static,

    sortable: false,
    resizable: false,
    canAutoResize: false,
    draggable: false,
    minWidth: 32,
    width: 32,
    sortOrder: 0
});
