export enum RetargetingAudianceEnum {
    VIEWED_OR_ADDED = 'VIEWED_OR_ADDED',
    ADDED = 'ADDED',
    UPSELL_PRODUCTS = 'UPSELL_PRODUCTS',
    CROSS_SELL_PRODUCTS = 'CROSS_SELL_PRODUCTS',
    CUSTOM = 'CUSTOM'
}
export enum EventsPreset {
    ViewOrAddToCartButNotPurchase = 0,
    AddToCartButNotPurchase = 1,
    Upsell = 2,
    CrossSell = 3,
    Custom = -1
}