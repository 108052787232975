<div class="column-select-container py--5" [ngClass]="{'extend': saveNewPreset?.isSaveAsNew}">
    <div class="ng-option no-hover border-bottom-0" role="option" aria-selected="false">
        <div class="w--100 d--flex justify--content_space-between align--items_center ">
            <hunch-input class="search" [inputControl]="filterControl" [placeholder]="'Search...'" [icon]="'SearchIcon'"
                [svg]="true" [size]="'small'">
            </hunch-input>
        </div>
    </div>

    <div class="ng-option disabled no-hover border-bottom-0" role="option" aria-selected="false">
        <div class="w--100 d--flex justify--content_space-between align--items_center ">
            <span class="ng-option-label size--6 color--green-2" style="font-weight: 600 !important;">Customize
                columns</span>
        </div>
    </div>

    <div class="scroll-container">
        <ng-container *ngIf="!filteredSearchItems; else searchRes" [ngTemplateOutlet]="allOptions"></ng-container>
    </div>
    <save-new-preset #saveNewPreset [currentPresetName]="currentTableConf.name"
        [isImmutablePreset]="currentTableConf.isDefault || currentTableConf.isHunchPredefined"
        (emitCancel)="emitCancel.emit()" (emitSave)="emitSave.emit($event)"
        (emitUpdate)="emitUpdate.emit($event)"></save-new-preset>

    <!-- style="height: 82px;" -->
</div>

<ng-template #allOptions>
    <div *ngFor="let item of dataConfigurationsForSelectedLevel" class="ng-option" role="option" aria-selected="false">
        <div class="w--100 d--flex align--items_center ">
            <mat-checkbox class="mr--8" [checked]="columnsInPreset.indexOf(item.key) > -1"
                (change)="toggleOption(item.key)">
                <span class="ng-option-label color--grey-6">
                    <svg-icon *ngIf="(item.metricIntegrationType === MetricIntegrationType.Hunch)"
                        title="Adjust Metrics" name="AdjustIcon" alt=""></svg-icon>
                    <svg-icon
                        *ngIf="(item.metricIntegrationType === MetricIntegrationType.Google || item.metricIntegrationType === MetricIntegrationType.GoogleAnalytics4)"
                        title="Google Analytics" name="GoogleAnalyticsIcon" alt=""></svg-icon>
                    <svg-icon *ngIf="(item.metricIntegrationType === MetricIntegrationType.AppsFlyer)"
                        title="AppsFlyer Metrics" name="AppsFlyerIcon" alt=""></svg-icon>
                    {{item.name}}</span>
            </mat-checkbox>
        </div>
    </div>
</ng-template>



<ng-template #searchRes>
    <ng-container *ngIf="filteredSearchItems.length; else noRes">
        <div *ngFor="let item of filteredSearchItems" class="ng-option" role="option" aria-selected="false">
            <div class="w--100 d--flex align--items_center ">
                <mat-checkbox class="mr--8" [checked]="columnsInPreset.indexOf(item.key) > -1"
                    (change)="toggleOption(item.key)">
                    <span class="ng-option-label color--grey-6">
                        <svg-icon *ngIf="(item.metricIntegrationType === MetricIntegrationType.Hunch)"
                            title="Adjust Metrics" name="AdjustIcon" alt=""></svg-icon>
                        <svg-icon
                            *ngIf="(item.metricIntegrationType === MetricIntegrationType.Google || item.metricIntegrationType === MetricIntegrationType.GoogleAnalytics4)"
                            title="Google Analytics" name="GoogleAnalyticsIcon" alt=""></svg-icon>
                        <svg-icon *ngIf="(item.metricIntegrationType === MetricIntegrationType.AppsFlyer)"
                            title="AppsFlyer Metrics" name="AppsFlyerIcon" alt=""></svg-icon>
                        {{item.name}}</span>
                </mat-checkbox>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #noRes>
    <div class="ng-option" role="option" aria-selected="false">
        <div class="w--100 d--flex justify--content_space-between align--items_center ">
            <span class="ng-option-label color--grey-6 ">No search results</span>
        </div>
    </div>
</ng-template>