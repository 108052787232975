import { Injectable } from '@angular/core';
import { BaseService, HubNetCore } from '@hunchads/angular-signalr';
import { get } from './global.service';
import { Subject } from 'rxjs';
import { MessageHub } from '../models';
import { SnapchatHubCommands, SnapchatHubEvents } from '../consts';

@Injectable({
    providedIn: 'root'
})
export class SnapchatHubService extends BaseService<SnapchatHub> {
    public messageState: Subject<MessageHub> = new Subject();
    constructor(
        snapchatHub: SnapchatHub //
    ) {
        super(snapchatHub);
    }

    registerByRequestId(requestId: string): void {
        this.hub.invoke(SnapchatHubCommands.registerByRequestId, null, requestId);
    }

    unRegisterByRequestId(requestId: string): void {
        this.hub.invoke(SnapchatHubCommands.unRegisterByRequstId, null, requestId);
    }

    listenHubSubscriptions(): void {
        this.hub.subscribe(SnapchatHubEvents.handpickChangesReady, (message) => {
            this.messageState.next(new MessageHub({ message: JSON.parse(message), type: SnapchatHubEvents.handpickChangesReady }))
        });

        this.hub.subscribe(SnapchatHubEvents.handpickChangeNotification, (message) => {
            console.log(SnapchatHubEvents.handpickChangeNotification, message);
            this.messageState.next(new MessageHub({ message: JSON.parse(message), type: SnapchatHubEvents.handpickChangeNotification }))
        });
    }
}

@Injectable({
    providedIn: 'root'
})
export class SnapchatHub extends HubNetCore {
    constructor() {
        super('snapchathub', get('snapSignalRUrl'))
    }

    override getToken() {
        return get('snapSignalRAccessToken');
    }
}