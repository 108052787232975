import { PlatformEnum } from "../enums";

export const platforms = [
    {
        id: PlatformEnum.FACEBOOK,
        name: 'Meta'
    },
    {
        id: PlatformEnum.SNAPCHAT,
        name: 'Snapchat'
    }
]