import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HunchInputComponent } from './hunch-input.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MatIconModule, MatIconRegistry } from "@angular/material/icon";

@NgModule({
  providers: [
    MatIconRegistry
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularSvgIconModule,
    TooltipModule,
    MatIconModule
  ],
  exports: [HunchInputComponent],
  declarations: [HunchInputComponent]
})
export class HunchInputModule { }

