import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { UserInfo, Account } from '../../models';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() hunchAppUrl: string;
  @Input() hunchManageAppUrl: string;
  @Input() hunchCatalogsAppUrl: string;
  @Input() user: UserInfo;
  @Input() accounts: Account[];
  @Output() accountChanged: EventEmitter<UserInfo> = new EventEmitter();
  accountId: number;
  origin: string = location.origin;
  constructor(private httpClient: HttpClient) { }
  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
      const dropbar = document.querySelector('.dropbar');
      const profileLink = document.querySelector('.profile-nav li a');
      if (dropbar && profileLink && !profileLink.contains(event.target as Node)) {
          dropbar.classList.remove('visible');
      }
  }
  ngOnInit(): void {
    this.accountId = this.user?.accountId;
  }

  changeAccount(): void {
    this.httpClient.put('$hunchAppUrl/api/v3/users/me/account', { accountId: this.accountId }, { withCredentials: true })
      .subscribe({
        next: (resp: any) => {
          this.user.accountId = this.accounts.find((g: Account) => g.id === this.accountId).id;
          this.accountChanged.emit(this.user);

        },
        error: (err: any) => {
          console.log(err);
        }
      });
  }

  toggleDropbar() :void{
    const dropbar = document.querySelector('.dropbar');
    if (dropbar) {
        dropbar.classList.toggle('visible');
    }
}
}
