export enum AdFormatTypeEnum {
    SINGLE = 'SINGLE',
    CAROUSEL = 'CAROUSEL',
    EXISTING = 'EXISTING',
    COLLECTION = 'COLLECTION'
}
export enum OldAdFormatTypeEnum {
    SingleAd = 0,
    CarouselAd = 1,
    Existing = 2,
    Collection = 3
}