import { CampaignStructureLevel, HunchCampaignType } from './enums';

export class PatchFacebookCampaignFromManageCommand {
    constructor() {}
    public level: CampaignStructureLevel;
    public manageRowItems: ManageRowItem[] = [];
    public status?: boolean;
    public dailyBudgetValue?: number;
    public lifetimeBudgetValue?: number;
    public bidValue?: number;
}

export class ManageRowItem {
    constructor() {}
    public adAccountId: string;
    public facebookId: string;
    public hunchCampaignType: HunchCampaignType;
}
