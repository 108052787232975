<div class="w--100 h--100" #tableContainer>
    <div style="position: relative; width: 0; height: 0;">
        <div style="width: 1800px; top: 1px; left: 1px; position: relative;" class="gradient" [ngStyle]="{'height': getElementHeight(tableContainer) - 1 + 'px',
        'width': getElementWidth(tableContainer) - 1 + 'px'}"></div>
    </div>

    <div class="dummy-table-borders dummy-table-summary" style="position: absolute;" 
    [ngStyle]="{'width': getElementWidth(tableContainer) - 1 + 'px'}"></div>

    <div class="skeleton-table-caontainer" style="overflow: hidden; position: absolute;" [ngStyle]="{'height': getElementHeight(tableContainer) - 1 + 'px',
    'width': getElementWidth(tableContainer) - 1 + 'px'}">
        <div class="w-100 dummy-table-borders dummy-table-header"></div>

        <div class="w-100 dummy-table-borders dummy-table-row"></div>
        <div class="w-100 dummy-table-borders dummy-table-row even"></div>
        <div class="w-100 dummy-table-borders dummy-table-row"></div>
        <div class="w-100 dummy-table-borders dummy-table-row even"></div>
        <div class="w-100 dummy-table-borders dummy-table-row"></div>
        <div class="w-100 dummy-table-borders dummy-table-row even"></div>
        <div class="w-100 dummy-table-borders dummy-table-row"></div>
        <div class="w-100 dummy-table-borders dummy-table-row even"></div>
        <div class="w-100 dummy-table-borders dummy-table-row"></div>
        <div class="w-100 dummy-table-borders dummy-table-row even"></div>
        <div class="w-100 dummy-table-borders dummy-table-row"></div>
        <div class="w-100 dummy-table-borders dummy-table-row even"></div>
        <div class="w-100 dummy-table-borders dummy-table-row"></div>
        <div class="w-100 dummy-table-borders dummy-table-row even"></div>
        <div class="w-100 dummy-table-borders dummy-table-row"></div>
        <div class="w-100 dummy-table-borders dummy-table-row even"></div>
        <div class="w-100 dummy-table-borders dummy-table-row"></div>
        <div class="w-100 dummy-table-borders dummy-table-row even"></div>
        <div class="w-100 dummy-table-borders dummy-table-row"></div>
        <div class="w-100 dummy-table-borders dummy-table-row even"></div>
        <div class="w-100 dummy-table-borders dummy-table-row"></div>
        <div class="w-100 dummy-table-borders dummy-table-row even"></div>
        <div class="w-100 dummy-table-borders dummy-table-row"></div>
        <div class="w-100 dummy-table-borders dummy-table-row even"></div>
        <div class="w-100 dummy-table-borders dummy-table-row"></div>
        <div class="w-100 dummy-table-borders dummy-table-row even"></div>
        <div class="w-100 dummy-table-borders dummy-table-row"></div>
        <div class="w-100 dummy-table-borders dummy-table-row even"></div>
        <div class="w-100 dummy-table-borders dummy-table-row"></div>
        <div class="w-100 dummy-table-borders dummy-table-row even"></div>
        <div class="w-100 dummy-table-borders dummy-table-row"></div>
        <div class="w-100 dummy-table-borders dummy-table-row even"></div>
        <div class="w-100 dummy-table-borders dummy-table-row"></div>
        <div class="w-100 dummy-table-borders dummy-table-row even"></div>
        <div class="w-100 dummy-table-borders dummy-table-row"></div>
        <div class="w-100 dummy-table-borders dummy-table-row even"></div>
        <div class="w-100 dummy-table-borders dummy-table-row"></div>
        <div class="w-100 dummy-table-borders dummy-table-row even"></div>
        <div class="w-100 dummy-table-borders dummy-table-row"></div>
        <div class="w-100 dummy-table-borders dummy-table-row even"></div>
        <div class="w-100 dummy-table-borders dummy-table-row"></div>
        <div class="w-100 dummy-table-borders dummy-table-row even"></div>
        <div class="w-100 dummy-table-borders dummy-table-row"></div>
        <div class="w-100 dummy-table-borders dummy-table-row even"></div>

    </div>
</div>