<div class="w--100 h--100 truncate pl--8 size--5 body-cell static-col" title="{{row?.status | enum | titlecase }}">
    <ng-container *ngIf="row?.status">
        <ng-container *ngIf="!row['isStatusChanging'] else loader">
            <mat-slide-toggle class="w--85" [checked]="row.status === EntityStatus.Active"
                [disabled]="row.status === EntityStatus.Deleted || row.status === EntityStatus.Archived || isDraft || masterStatusDisabled || !row?.facebookId"
                (toggleChange)="toggleStatus()"></mat-slide-toggle>
        </ng-container>
    </ng-container>
</div>

<ng-template #loader>
    <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</ng-template>