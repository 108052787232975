<ng-select #ngSelect *ngIf="options"
    class="custom-multi-checkbox select--dark select--manage-1 preset-options first-level bg--grey-1 size--5 font--weight_semi inline-block mr--8 dropdown-options"
    placeholder="{{placeholder + (value.length===0? defaultText?' '+defaultText:'None':'')}}" [searchable]="false" [ngStyle]="{'width':width,'min-width': minwidth}"
    [multiple]="true" [items]="options" [closeOnSelect]="false" [(ngModel)]="value">
    <ng-template ng-label-tmp let-item="item">
        <span class="ng-value-label" *ngIf="item.name === value[0]?.name">
            <div class="inline-block v-a--top">{{placeholder}}&nbsp;</div>
            <div class="inline-block v-a--top truncate pr--10 preset-name-container">
                {{value[0]?.name}} {{value.length>1?'+'+(value.length-1):''}}
            </div>
        </span>
    </ng-template>

    <ng-template ng-option-tmp let-item="item" let-index="index">
        <div style="max-height: 430px;">
            <div class="ng-option iconified w--100 d--block" style="min-width: 185px;">
                <div class="w--100">
                    <span class="ng-option-label d--block w-100">
                        <mat-checkbox class="mr--8 w--100"
                            [ngClass]="{'mat-checkbox-indeterminate': model[item.id]?.checkedIndeterminate && !model[item.id]?.checked}"
                            [(ngModel)]="model[item.id].checked" [ngModelOptions]="{standalone: true}"
                            (ngModelChange)="optionsChanged(item)">
                            <span class="ng-option-label">
                                <span>
                                    <svg-icon title="" [name]="item.icon" alt=""></svg-icon>{{item.name}}
                                </span>
                            </span>
                        </mat-checkbox>
                    </span>
                </div>
            </div>
            <ng-container *ngIf="item.child">
                <div class="ng-option ng-option-child iconified w--100 d--block" style="min-width: 185px;"
                    *ngFor="let c of item.child">
                    <div class="w--100">
                        <span class="ng-option-label d--block w-100">
                            <mat-checkbox class="mr--8 w--100"
                                [(ngModel)]="model[item.id].child[c.additionalId].checked"
                                [ngModelOptions]="{standalone: true}" (ngModelChange)="optionsChanged(c)">
                                <span class="ng-option-label">
                                    <span>
                                        <svg-icon title="" [name]="c.icon" alt=""></svg-icon>{{c.name}}
                                    </span>
                                </span>
                            </mat-checkbox>
                        </span>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-template>
</ng-select>