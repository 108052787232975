
<ng-container *ngIf="!isResolved && action">

    <hunch-modal *ngIf="action.actionType === TableCellNameActions.Disconnect"
    [messageHeader]="'Disable Sync'"
    [headerClass]="'color--green-2'"
    [confirmBtnText]="'Disable'"
    [confirmBtnClass]="'bg--green-3'"
    [customTemplate]="disableSync"
    (emitCancel)="cancelAction()" (emitConfirm)="confirmAction()"></hunch-modal>

    <hunch-modal *ngIf="action.actionType === TableCellNameActions.Delete"
    [messageHeader]="'Please confirm!'"
    [messageBody]="'Are you sure you want to delete this draft?'"
    [confirmBtnText]="'Delete'"
    (emitCancel)="cancelAction()" (emitConfirm)="confirmAction()"></hunch-modal>

    <hunch-modal *ngIf="action.actionType === TableCellNameActions.Duplicate"
    [messageHeader]="'Duplicate Campaign'"
    [headerClass]="'color--green-2'"
    [messageBody]="''"
    [confirmBtnText]="'Duplicate'"
    [confirmBtnClass]="'bg--green-3'"
    [customTemplate]="duplicateContent"
    [isConfirmDisabled]="isConfirmDuplicateDisabled()"
    [custom]="'duplicate'"
    (emitCancel)="cancelAction()" (emitCustom)="confirmCustom($event)"></hunch-modal>

</ng-container>

<ng-template #duplicateContent>
    <manage-duplicate-campaign-modal
    [pageRow]="action.pageRow"
    (emitValue)="postDuplicate($event)"
    ></manage-duplicate-campaign-modal>
</ng-template>

<ng-template #disableSync>
    <div class="row-name" title="{{action.pageRow.name}}">
        {{action.pageRow.name}}
    </div>
    <div class="text-12">Are you sure you want to disable sync for this automated campaign?</div>
</ng-template>

