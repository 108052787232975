<ng-select
    class="select--dark select--manage-1 select--manage-select-left select--radio bg--grey-1 size--5 select--quick-range font--weight_semi ml--8 inline-block"
    style="width: 209px;" #ngSelect [formControl]="selectedPresetId" [items]="datePresets" bindLabel="name"
    bindValue="id" [searchable]="false" [closeOnSelect]="false" [loading]="false" (close)="closeDatepicker(false)">

    <ng-template ng-label-tmp let-item="item">
        <span class="ng-value-label" style="padding-left: 0 !important;">
            <svg-icon class="ng-value-img" svgClass="filter-icon" name="CalendarS" alt=""></svg-icon>
            <span *ngIf="item.id == 23 && bsRangeValue?.length > 1; else presetName">
                {{bsRangeValue[0] | date}}&nbsp;-&nbsp;{{bsRangeValue[1] | date}}
            </span>
            <ng-template #presetName>
                <span>{{ item.name }}</span>
            </ng-template>
        </span>
    </ng-template>

    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
        <div class="select--radio-checkbox" [ngClass]="{ 'select--radio-checkbox_selected': item$.selected }"  (click)="changeValue($event, item.id)"></div>
        <div class="select--radio-option truncate"  (click)="changeValue($event, item.id)">{{ item.name }}</div>
        <ng-container *ngIf="index === 0">
            <div class="float-right inline-block v-a--top modal-container" (click)="blockEvent($event)">
                <div class="date-modal" [ngStyle]="{'top': ApplicationHeaderPlaceholderHeight + 79 + 'px'}">
                    <bs-daterangepicker-inline [bsValue]="bsRangeValue"
                        [bsConfig]="{ showWeekNumbers:false, showPreviousMonth: true }" [maxDate]="maxDate"
                        (bsValueChange)="bsValueChange($event)"></bs-daterangepicker-inline>

                    <div class="w--100" style="height: 45px;"></div>

                    <div class="inline-block pl--12 w--50 v-a--top">
                        <div *ngIf="bsRangeValue?.length > 1">
                            <div>{{bsRangeValue[0] | date}}&nbsp;-&nbsp;{{bsRangeValue[1] | date}}</div>
                            <div class="size--6">Time zone, {{currentDate | date : 'OOOO'}}</div>
                        </div>
                    </div>

                    <div class="inline-block pl--12 w--50">
                        <button class="btn--solid btn--solid_green size--5 font--weight_semi mr--12 float-right text-light"
                            [disabled]="selectedPresetId.value === currentPresetId && selectedPresetId.value !== 23" (click)="closeDatepicker(true, ngSelect)">Update</button>
                        <button class="btn--solid btn--solid_grey size--5 font--weight_semi mr--8 float-right text-light"
                            (click)="closeDatepicker(false)">Cancel</button>
                    </div>
                </div>
            </div>
        </ng-container>

    </ng-template>

</ng-select>