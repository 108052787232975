import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { BasicQuery, GetFacebookInsightsCountQuery, GetFacebookInsightsQuery, GetFacebookInsightsSummaryQuery, InsightsFilter, InsightsQueryResponseObject, InsightsQueryResponseObjectFE, SortField } from '../../../api/models';
import { InsightsApiService } from '../../../api/services';
import { ManageOverviewService } from '../../manage-overview/manage-overview.service';
import { DatePresetService, DatePresetType } from './date-preset.service';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompareObjectsService } from './compare-objects.service';
import { FiltersService } from './filters.service';
import { ITableQueries, TableService, TableServiceResponse } from './table-service.model';
import { CampaignStructureLevel, MetricType } from '../../../api/models';
import { ColumnDisplayConf, DefaultSortColumnName, DefaultSortDirection, TableConfigurationPresetFE, TableSortElement } from '../models';
import { get } from '../../shared/services';


@Injectable()
export class TableMainService extends TableService {
    protected adAccountIds: string[];

    filters: any;

    tableConfiguration: TableConfigurationPresetFE;

    pagePageOffset: any;
    scrollTop: number;
    tableCache: any;
    tableCachedRows: any[];
    cachedTowsTotalNumber: number;
    cachedSummaryRow: any;
    selectedItemIds: string[] = [];
    selectedDraftIds: string[] = [];
    selectedConnectedIds: string[] = [];
    selectedAll: boolean;
    itemsPerPage: number;
    sortField: TableSortElement[] = [new TableSortElement()];

    queryCount: GetFacebookInsightsCountQuery;
    public queryData: GetFacebookInsightsQuery;
    querySummary: GetFacebookInsightsSummaryQuery;

    tableData: any[];

    subs: Subscription = new Subscription();

    constructor(
        protected manageOverviewService: ManageOverviewService, //
        protected insightsApiService: InsightsApiService,
        protected datePresetService: DatePresetService,
        protected compareObjectsService: CompareObjectsService,
        protected filtersService: FiltersService
    ) {
        super();
        this.adAccountIds = [get('user').accountId];

        this.subs.add(
            this.manageOverviewService.getCurrentTableConfiguration().subscribe(data => {
                this.adAccountIds = [get('user').accountId];
                this.tableConfiguration = data;
            })
        );
    }

    firstPageWithOverlapingData: number;
    metricTotalCount: number;
    nonMetricTotalCount: number;

    loadQueryResults(response: TableServiceResponse, hasMetricSort: boolean, pageNumber: number, isAscending: boolean): Observable<TableServiceResponse> {
        return forkJoin(this.prepareForJoinRequests(response, hasMetricSort, pageNumber, isAscending)).pipe(
            map((res: ITableQueries) => {
                if (pageNumber === 0) {
                    delete this.firstPageWithOverlapingData;
                    delete this.metricTotalCount;
                    delete this.nonMetricTotalCount;
                }

                response.pageData = res.pageData;
                response.totalCount = res.totalCount;

                if (response.isTotalCountChanged) this.cachedTowsTotalNumber = response.totalCount;

                if (hasMetricSort) {
                    if (pageNumber === 0) {
                        this.metricTotalCount = res.metricTotalCount;
                        this.nonMetricTotalCount = this.cachedTowsTotalNumber - this.metricTotalCount;
                        this.firstPageWithOverlapingData = isAscending ? Math.floor(this.nonMetricTotalCount / response.originalPageSize) : Math.floor(res.metricTotalCount / response.originalPageSize);
                    }
                    if ((this.firstPageWithOverlapingData === 0 && pageNumber === 0) || pageNumber === this.firstPageWithOverlapingData) {
                        if (!res.pageData) res.pageData = [];
                        if (!res.pagesNonMetricData) res.pagesNonMetricData = [];
                        let mergeData;
                        if (isAscending) mergeData = [...res.pagesNonMetricData, ...res.pageData];
                        else mergeData = [...res.pageData, ...res.pagesNonMetricData];
                        mergeData.splice(response.originalPageSize);
                        response.pageData = mergeData;
                    } else if (pageNumber > this.firstPageWithOverlapingData) {
                        if (isAscending) response.pageData = res.pageData;
                        else response.pageData = res.pagesNonMetricData;
                    } else if (pageNumber < this.firstPageWithOverlapingData) {
                        if (isAscending) response.pageData = res.pagesNonMetricData;
                        else response.pageData = res.pageData;
                    }
                }

                return response;
            })
        );
    }

    loadSummaryRow(): Promise<any> {
        this.querySummary.platform = this.manageOverviewService.platform;
        return new Promise(resolve => {
            this.insightsApiService.getSummary(this.querySummary).subscribe(res => {
                let summaryRow = {};
                if (res?.length) summaryRow = res[0];
                this.cachedSummaryRow = summaryRow;
                resolve(summaryRow);
            });
        });
    }

    protected prepareForJoinRequests(response: TableServiceResponse, hasMetricSort: boolean, pageNumber: number, isAscending: boolean): any {
        let retVal: ITableQueries = {};
        this.prepareForJoinRequestsRegular(response, retVal);
        if (hasMetricSort) {
            this.prepareForJoinRequestsMetricSort(retVal, pageNumber, response, isAscending);
        }
        return retVal;
    }

    private prepareForJoinRequestsMetricSort(retVal: ITableQueries, pageNumber: number, response: TableServiceResponse, isAscending: boolean) {
        if (pageNumber === 0) {
            retVal.metricTotalCount = this.insightsApiService.getCount(this.queryCount).pipe(map((item: { count: number }[]) => item[0]?.count));
        }
        if (pageNumber === 0) {
            let queryDataForNonMetric = new GetFacebookInsightsQuery(this.queryData);
            queryDataForNonMetric.getRemainingItemsForMetricSort = true;
            queryDataForNonMetric.skip = 0;
            queryDataForNonMetric.resultsPerPage = response.originalPageSize;
            retVal.pagesNonMetricData = this.insightsApiService.getInsights(queryDataForNonMetric);
        } else if (pageNumber === this.firstPageWithOverlapingData) {
            if (isAscending) {
                let queryDataForMetric = new GetFacebookInsightsQuery(this.queryData);
                queryDataForMetric.skip = 0;
                queryDataForMetric.resultsPerPage = response.originalPageSize;
                retVal.pageData = this.insightsApiService.getInsights(queryDataForMetric);

                let queryDataForNonMetric = new GetFacebookInsightsQuery(this.queryData);
                queryDataForNonMetric.getRemainingItemsForMetricSort = true;
                queryDataForNonMetric.skip = pageNumber * response.originalPageSize;
                queryDataForNonMetric.resultsPerPage = response.originalPageSize;
                retVal.pagesNonMetricData = this.insightsApiService.getInsights(queryDataForNonMetric);
            } else {
                let queryDataForNonMetric = new GetFacebookInsightsQuery(this.queryData);
                queryDataForNonMetric.getRemainingItemsForMetricSort = true;
                queryDataForNonMetric.skip = 0;
                queryDataForNonMetric.resultsPerPage = response.originalPageSize;
                retVal.pagesNonMetricData = this.insightsApiService.getInsights(queryDataForNonMetric);
            }

        } else if (pageNumber > this.firstPageWithOverlapingData) {
            if (isAscending) {
                let queryDataForMetric = new GetFacebookInsightsQuery(this.queryData);

                if (this.nonMetricTotalCount > pageNumber * response.originalPageSize) queryDataForMetric.skip = 0;
                else queryDataForMetric.skip = pageNumber * response.originalPageSize - this.nonMetricTotalCount;

                queryDataForMetric.resultsPerPage = response.originalPageSize;
                retVal.pageData = this.insightsApiService.getInsights(queryDataForMetric);
            } else {
                let queryDataForNonMetric = new GetFacebookInsightsQuery(this.queryData);
                queryDataForNonMetric.getRemainingItemsForMetricSort = true;

                if (this.metricTotalCount > pageNumber * response.originalPageSize) queryDataForNonMetric.skip = 0;
                else queryDataForNonMetric.skip = pageNumber * response.originalPageSize - this.metricTotalCount;

                queryDataForNonMetric.resultsPerPage = response.originalPageSize;
                retVal.pagesNonMetricData = this.insightsApiService.getInsights(queryDataForNonMetric);

                delete retVal.pageData;
            }
        } else if (pageNumber < this.firstPageWithOverlapingData) {
            if (isAscending) {
                let queryDataForNonMetric = new GetFacebookInsightsQuery(this.queryData);
                queryDataForNonMetric.getRemainingItemsForMetricSort = true;

                if (this.metricTotalCount > pageNumber * response.originalPageSize) queryDataForNonMetric.skip = 0;
                else queryDataForNonMetric.skip = pageNumber * response.originalPageSize;

                queryDataForNonMetric.resultsPerPage = response.originalPageSize;
                retVal.pagesNonMetricData = this.insightsApiService.getInsights(queryDataForNonMetric);

                delete retVal.pageData;
            } else {
                let queryDataForMetric = new GetFacebookInsightsQuery(this.queryData);

                if (this.nonMetricTotalCount > pageNumber * response.originalPageSize) queryDataForMetric.skip = 0;
                else queryDataForMetric.skip = pageNumber * response.originalPageSize;

                queryDataForMetric.resultsPerPage = response.originalPageSize;
                retVal.pageData = this.insightsApiService.getInsights(queryDataForMetric);
            }
        }
    }

    private prepareForJoinRequestsRegular(response: TableServiceResponse, retVal: ITableQueries) {
        retVal.pageData = this.insightsApiService.getInsights(this.queryData);
        if (response.isTotalCountChanged) {
            retVal.totalCount = this.insightsApiService.getCount(this.setCountwithOutSort(this.queryCount)).pipe(map((item: { count: number }[]) => item[0]?.count));
        }
    }

    loadAdditionalPageDataAfterSmallMetricSort(): Observable<InsightsQueryResponseObjectFE[]> {
        let additionalQuerySortedByName = new GetFacebookInsightsQuery(this.queryData);
        additionalQuerySortedByName.sortField = new SortField();
        additionalQuerySortedByName.page = 1;
        additionalQuerySortedByName.resultsPerPage = 30;
        return this.insightsApiService.getInsights(additionalQuerySortedByName);
    }

    getServiceResponse(pageNum: number, resultsPerPage: number, currentLevel: CampaignStructureLevel, sort: TableSortElement[], originalPageSize: number): TableServiceResponse {
        let response = new TableServiceResponse();
        response.originalPageSize = originalPageSize;

        let basicQuery = this.setBasicQueryParameters(currentLevel);

        let queryCount = this.setCountQueryParams(basicQuery, sort);
        let querySummary = this.setSummaryQueryParams(basicQuery, currentLevel);
        let queryData = this.setDataQueryParams(querySummary, pageNum, resultsPerPage, sort, currentLevel);
        queryData.platform = this.manageOverviewService.platform;

        let oldQueryCount: GetFacebookInsightsCountQuery | undefined;
        let newQueryCount: GetFacebookInsightsCountQuery | undefined;
        if (this.queryCount) {
            newQueryCount = new GetFacebookInsightsCountQuery(queryCount);
            oldQueryCount = new GetFacebookInsightsCountQuery(this.queryCount);
            if (oldQueryCount.sortField && queryCount.sortField) {
                oldQueryCount.sortField.isAscending = queryCount.sortField.isAscending;
            }
        }

        response.isTotalCountChanged = !this.compareObjectsService.areObjectsEqual(oldQueryCount, newQueryCount ?? queryCount);
        response.isSummaryRowChanged = !this.compareObjectsService.areObjectsEqual(this.querySummary, querySummary);

        if (pageNum === 0) console.log('isTotalCountChanged: ', response.isTotalCountChanged, ' - isSummaryRowChanged: ', response.isSummaryRowChanged);

        this.queryData = queryData;
        if (response.isTotalCountChanged) this.queryCount = queryCount;
        if (response.isSummaryRowChanged) this.querySummary = querySummary;

        return response;
    }

    public setBasicQueryParameters(currentLevel: CampaignStructureLevel): BasicQuery {
        const basicQuery = new BasicQuery();
        basicQuery.adAccountIds = this.adAccountIds;
        basicQuery.campaignStructureLevel = currentLevel;
        this.setFilterParams(basicQuery, currentLevel);
        return basicQuery;
    }

    protected setFilterParams(basicQuery: BasicQuery, currentLevel: CampaignStructureLevel) {
        basicQuery.filters = [ ...this.filtersService.insightsFilters];
        basicQuery.customfilters = this.filtersService.customFilters;

        this.setSelectedItemsFilterParams(basicQuery);
        if (this.filtersService.customFilters.campaignTypes.length === 0) this.setMainFilter(basicQuery, currentLevel);
        this.setQueryDateParams(basicQuery);

        if (this.filtersService.adAccountInsigthsFilter) {
            this.filtersService.adAccountInsigthsFilter.campaignStructureLevel = currentLevel;
            basicQuery.filters.push(this.filtersService.adAccountInsigthsFilter);
        }

        //this is temporary soulution until switch for archived campaigns is added in UI (for now we hide them)
        if (!basicQuery.filters.find(item => item.metricKey === 'status')) {
            let activePaused = new InsightsFilter({
                metricKey: 'status',
                operator: 2,
                value: 'ARCHIVED',
                campaignStructureLevel: currentLevel
            });
            basicQuery.filters.push(activePaused);
        }
    }

    //will be overriden in adset and ad level service
    protected setSelectedItemsFilterParams(basicQuery: BasicQuery) { }

    protected setMainFilter(basicQuery: BasicQuery, currentLevel: CampaignStructureLevel) {
        let mainFilter = new InsightsFilter(this.filtersService.mainFilter);
        mainFilter.campaignStructureLevel = currentLevel;
        basicQuery.filters.push(mainFilter);
    }

    protected setCountQueryParams(basicQuery: BasicQuery, sort: TableSortElement[]): GetFacebookInsightsCountQuery {
        let queryCount = new GetFacebookInsightsCountQuery(basicQuery);
        this.setSortQueryParam(queryCount, sort);
        return queryCount;
    }

    setCountwithOutSort(query: GetFacebookInsightsCountQuery): GetFacebookInsightsCountQuery {
        let retVal: any = new GetFacebookInsightsCountQuery(query);
        retVal.platform = this.manageOverviewService.platform;
        delete retVal.sortField;
        return retVal;
    }

    protected setSummaryQueryParams(basicQuery: BasicQuery, currentLevel: CampaignStructureLevel): GetFacebookInsightsSummaryQuery {
        let querySummary = new GetFacebookInsightsSummaryQuery(basicQuery);
        this.setMetricQueryParams(querySummary, currentLevel);
        return querySummary;
    }

    protected setQueryDateParams(basicQuery: BasicQuery) {
        let dateParams: DatePresetType = this.datePresetService.getCurrentDatePreset();
        if (dateParams.dateRange.length) {
            basicQuery.from = this.datePresetService.getPureDateStringISO(dateParams.dateRange[0], true);
            basicQuery.to = this.datePresetService.getPureDateStringISO(dateParams.dateRange[1], false);
        }
    }

    protected setMetricQueryParams(query: GetFacebookInsightsQuery | GetFacebookInsightsSummaryQuery, currentLevel: CampaignStructureLevel) {
        //will accept only GetFacebookInsightsSummaryQuery when model is updated
        if (this.tableConfiguration) query.metricKeys = ColumnDisplayConf.getMetricKeysForColumns(this.tableConfiguration.getCurrentLevelColumns(currentLevel));
    }

    protected setDataQueryParams(querySummary: GetFacebookInsightsSummaryQuery, pageNum: number, resultsPerPage: number, sort: TableSortElement[], currentLevel: CampaignStructureLevel): GetFacebookInsightsQuery {
        let retVal = new GetFacebookInsightsQuery(querySummary);
        retVal.page = pageNum + 1;
        retVal.resultsPerPage = resultsPerPage;
        this.setSortQueryParam(retVal, sort);
        this.setMetricQueryParams(retVal, currentLevel);
        return retVal;
    }

    protected setSortQueryParam(queryData: GetFacebookInsightsCountQuery | GetFacebookInsightsQuery, sort: TableSortElement[]) {
        if (sort?.length) {
            queryData.sortField.sortKey = sort[0].prop;
            queryData.sortField.isAscending = sort[0].dir === 'asc';
        } else {
            queryData.sortField.sortKey = DefaultSortColumnName;
            queryData.sortField.isAscending = DefaultSortDirection === 'asc';
        }
    }

    getTotalCount(): Observable<any> {
        return this.insightsApiService.getCount(this.setCountwithOutSort(this.queryCount)).pipe(map((item: { count: number }[]) => item[0]?.count));
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
