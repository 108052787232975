<!-- <button class="btn--solid btn--solid_grey size--5 font--weight_semi mr--8" title="Duplicate"
    [disabled]="!(currentTableService?.selectedItemIds?.length)">
    <svg-icon name="Duplicate" class="mr--8" alt="" svgClass="button-text-icon"></svg-icon> Duplicate
</button> -->
<div class="v-a--top inline-block edit-selection-loader" *ngIf="reloadEditComponent.loading">
    <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>

<!-- edit campaign will not be used for now -->
<!-- <edit-selection *ngIf="reloadEditComponent.display" class="inline-block v-a--top"
    [ngStyle]="{'opacity': reloadEditComponent.loading ? '0' : '1'}" [reloadEditComponent]="reloadEditComponent"
    [selectedDataLevel]="selectedDataLevel" [currentTableService]="currentTableService"></edit-selection> -->

<!-- <delete-batch-action [currentTableService]="currentTableService" [selectedDataLevel]="selectedDataLevel"
    class="inline-block v-a--top"></delete-batch-action> -->

<app-multi-checkbox-select [setOptions]="campaignTypeOptions" [placeholder]="'Campaign type:'" [defaultText]="'Default'" (modelChanged)="campaignTypeChanged($event)" [width]="'275px'"></app-multi-checkbox-select>
<app-multi-checkbox-select [setOptions]="objectiveOptions" [placeholder]="'Campaign objective:'" [defaultText]="'Default'"  (modelChanged)="objectiveChanged($event)" [width]="'320px'"></app-multi-checkbox-select>

<table-filter-level class="inline-block"
    [resetTableConfiguration]="resetTableConfiguration"></table-filter-level>

<!-- <ad-account-sync-option class="inline-block v-a--top"></ad-account-sync-option> -->

<!-- <button class="btn--solid btn--solid_grey btn--solid_icon float-right" title="Download">
    <svg-icon name="Download" alt="" svgClass="single-icon"></svg-icon>
</button>

<button class="btn--solid btn--solid_grey btn--solid_icon mr--8 float-right" title="Export CSV">
    <svg-icon name="ExportCSV" alt="" svgClass="single-icon"></svg-icon>
</button> -->

<!-- <button class="btn--solid btn--solid_grey size--5 font--weight_semi mr--8 float-right" style="width: 121px;" title="Breakdown">
    <svg-icon name="Breakdown" class="mr--8" alt="" svgClass="button-text-icon"></svg-icon> Breakdown
</button>

<button class="btn--solid btn--solid_grey size--5 font--weight_semi mr--8 float-right" style="width: 136px;" title="Attribution">
    <svg-icon name="Attribution" class="mr--8" alt="" svgClass="button-text-icon"></svg-icon> Attribution
</button> -->

<!-- <preset-editor class=" v-a--top float-right" [selectedDataLevel]="selectedDataLevel"></preset-editor> -->

<!-- <metric-toggle-buttons [selectedDataLevel]="selectedDataLevel"
    class="inline-block v-a--top float-right"></metric-toggle-buttons> -->