
export enum DetailedTargetingTypeEnum {
    interests = 'interests',
    behaviors = 'behaviors',
    work_employers = 'work_employers',
    education_schools = 'education_schools',
    adgroup_id = 'adgroup_id',
    genders = 'genders',
    age_min = 'age_min',
    age_max = 'age_max',
    country_groups = 'country_groups',
    countries = 'countries',
    country = 'country',
    cities = 'cities',
    radius = 'radius',
    regions = 'regions',
    zips = 'zips',
    keywords = 'keywords',
    education_majors = 'education_majors',
    work_positions = 'work_positions',
    relationship_statuses = 'relationship_statuses',
    interested_in = 'interested_in',
    locales = 'locales',
    user_adclusters = 'user_adclusters',
    excluded_user_adclusters = 'excluded_user_adclusters',
    conjunctive_user_adclusters = 'conjunctive_user_adclusters',
    custom_audiences = 'custom_audiences',
    excluded_custom_audiences = 'excluded_custom_audiences',
    college_years = 'college_years',
    education_statuses = 'education_statuses',
    connections = 'connections',
    excluded_connections = 'excluded_connections',
    friends_of_connections = 'friends_of_connections',
    user_event = 'user_event',
    dynamic_audience_ids = 'dynamic_audience_ids',
    excluded_dynamic_audience_ids = 'excluded_dynamic_audience_ids',
    rtb_flag = 'rtb_flag',
    site_category = 'site_category',
    geo_locations = 'geo_locations',
    excluded_geo_locations = 'excluded_geo_locations',
    timezones = 'timezones',
    place_page_set_ids = 'place_page_set_ids',
    page_types = 'page_types',
    publisher_platforms = 'publisher_platforms',
    effective_publisher_platforms = 'effective_publisher_platforms',
    facebook_positions = 'facebook_positions',
    effective_facebook_positions = 'effective_facebook_positions',
    instagram_positions = 'instagram_positions',
    effective_instagram_positions = 'effective_instagram_positions',
    messenger_positions = 'messenger_positions',
    effective_messenger_positions = 'effective_messenger_positions',
    device_platforms = 'device_platforms',
    effective_device_platforms = 'effective_device_platforms',
    audience_network_positions = 'audience_network_positions',
    industries = 'industries',
    family_statuses = 'family_statuses',
    life_events = 'life_events',
    politics = 'politics',
    income = 'income',
    user_device = 'user_device',
    user_os = 'user_os'
}
export enum OldDetailedTargetingType {
    interests,
    behaviors,
    work_employers,
    education_schools,
    adgroup_id,
    genders,
    age_min,
    age_max,
    country_groups,
    countries,
    country,
    cities,
    radius,
    regions,
    zips,
    keywords,
    education_majors,
    work_positions,
    relationship_statuses,
    interested_in,
    locales,
    user_adclusters,
    excluded_user_adclusters,
    conjunctive_user_adclusters,
    custom_audiences,
    excluded_custom_audiences,
    college_years,
    education_statuses,
    connections,
    excluded_connections,
    friends_of_connections,
    user_event,
    dynamic_audience_ids,
    excluded_dynamic_audience_ids,
    rtb_flag,
    site_category,
    geo_locations,
    excluded_geo_locations,
    timezones,
    place_page_set_ids,
    page_types,
    publisher_platforms,
    effective_publisher_platforms,
    facebook_positions,
    effective_facebook_positions,
    instagram_positions,
    effective_instagram_positions,
    messenger_positions,
    effective_messenger_positions,
    device_platforms,
    effective_device_platforms,
    audience_network_positions,
    industries,
    family_statuses,
    life_events,
    politics,
    income,
    user_device,
    user_os
}