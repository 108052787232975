var globals: any = {};

export const set = (key: string, value: any) => {
    globals[key] = value;
}

export const get = (key: string) => {
    return globals[key];
}

export const getAll = () => {
    return globals;
}

export const createGuid = () => {
    return [
        Math.random().toString(16).slice(2, 10),
        Math.random().toString(16).slice(2, 6),
        (Math.random() * .0625 /* 0x.1 */ + .25 /* 0x.4 */).toString(16).slice(2, 6),
        (Math.random() * .25 /* 0x.4 */ + .5 /* 0x.8 */).toString(16).slice(2, 6),
        Math.random().toString(16).slice(2, 14)].join('-');
}

export const isIdGuid = (id: string) => {
    const guidPattern = /^[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12}$/i;
    return guidPattern.test(id);
}