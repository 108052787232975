import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { IdentityInfo } from "../models";
import { get, set } from "./global.service";

@Injectable({ providedIn: 'root' })
export class AuthService {
    token: string;
    constructor(
        private httpClient: HttpClient //
    ) { }

    loadIdentityInfo(): Promise<void> {
        return new Promise((resolve) => {
            this.httpClient.get<IdentityInfo>(`${get('hunchAppUrl')}/api/v2/identity-connect/info`, { withCredentials: true })
                .subscribe({
                    next: (resp: IdentityInfo) => {
                        this.token = resp.token;
                        set('user', resp.user);
                        set('accounts', resp.accounts);
                        set('token', resp.token);
                        set('snapchatEnabled', resp.snapchatEnabled);
                        resolve();
                    },
                    error: (err: any) => {
                        console.error(err);
                        window.location.assign(get('hunchAppUrl'));
                        resolve();
                    }
                })
        })
    };

    async loadAzureToken(): Promise<void> {
        await this.loadAzureTokenMeta();
        return new Promise((resolve) => {
            this.httpClient.get<any>(`${get('hunchAppUrl')}/api/v1/authorizations/azuread/token`, { withCredentials: true })
                .subscribe({
                    next: (resp: any) => {
                        set('azureToken', resp.accessToken);
                        setTimeout(() => {
                            this.loadAzureToken();
                        }, resp.expiresIn * 1000);
                        resolve();
                    },
                    error: (err: any) => {
                        console.error(err);
                        resolve();
                    }
                })
        })
    };

    loadAzureTokenMeta(): Promise<void> {
        return new Promise((resolve) => {
            this.httpClient.get<any>(`${get('hunchAppUrl')}/api/v1/authorizations/azuread/token?scope=meta`, { withCredentials: true })
                .subscribe({
                    next: (resp: any) => {
                        set('azureTokenMeta', resp.accessToken);
                        setTimeout(() => {
                            this.loadAzureTokenMeta();
                        }, resp.expiresIn * 1000);
                        resolve();
                    },
                    error: (err: any) => {
                        console.error(err);
                        resolve();
                    }
                })
        })
    };
}