import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TableConfigurationPresetFE } from '../../../../models';

@Component({
    selector: 'preset-select',
    templateUrl: './preset-select.component.html',
    styleUrls: ['./preset-select.component.scss']
})
export class PresetSelectComponent implements OnInit {
    @Input() tableConfigurations: TableConfigurationPresetFE[];
    @Input() currentTableConf: TableConfigurationPresetFE;

    @Output() emitChangePreset = new EventEmitter<TableConfigurationPresetFE>();
    @Output() emitDeletePreset = new EventEmitter<TableConfigurationPresetFE>();

    constructor() {}

    ngOnInit(): void {}

    changeSelectedPreset(selectedPreset: TableConfigurationPresetFE, event: Event) {
        if (event.target['className'] !== 'ng-value-img remove-preset') {
            if (selectedPreset) {
                this.placeSelectedPresetToTheFrontOfTheArray(selectedPreset);
                this.emitChangePreset.emit(selectedPreset);
            }
        }
    }

    placeSelectedPresetToTheFrontOfTheArray(selectedPreset: TableConfigurationPresetFE) {
        let index = this.tableConfigurations.indexOf(selectedPreset);
        this.tableConfigurations.splice(index, 1);
        this.tableConfigurations.unshift(selectedPreset);
        //change the date last used also
    }

    deletePreset(conf: TableConfigurationPresetFE, event: Event) {
        event.stopPropagation();
        if (!conf.isDefault && !conf.isHunchPredefined) {
            if (this.tableConfigurations.length > 1) {
                // if (conf === this.currentTableConf) {
                //     this.emitChangePreset.emit(this.tableConfigurations.find(item => item.id !== conf.id));
                // }
                this.emitDeletePreset.emit(conf);
            }
        }
    }
}
