import { CampaignStructureLevel, FilterOperator, MetricDataType, MetricIntegrationType, MetricType } from './enums';

export class ManageDisplayMetricConfiguration {
    constructor(value?: any) {
        const keys = Object.keys(this);
        if (value)
            for (const key in value) {
                if (keys.includes(key)) this[key] = value[key];
            }
    }

    key: string = '';
    name: string = '';
    description: string = '';
    metricIntegrationType: MetricIntegrationType = MetricIntegrationType.None;

    campaignStructureLevels: CampaignStructureLevel[] = [CampaignStructureLevel.None];
    metricType: MetricType = MetricType.Static;
    metricDataType: MetricDataType = MetricDataType.Hidden;

    allowedMetricFilterOperators: FilterOperator[] = [];
    enumValues: any = {};

    minWidth: number = 80;
    sortable: boolean = true;
    resizable: boolean = true;
    canAutoResize: boolean = true;
    draggable: boolean = true;
    width: number = 160;
    sortOrder: number = 1000;

    requiredStaticFields:string[] = [];

    static fromObject(item: any): ManageDisplayMetricConfiguration {
        return Object.assign(new ManageDisplayMetricConfiguration(), item);
    }
}
