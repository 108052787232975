export enum InstagramPositionEnum {
    FEED = 'stream',
    STORY = 'story',
    EXPLORE = 'explore',
    REELS = 'reels',
    EXPLORE_HOME = 'explore_home',
    PROFILE_FEED = 'profile_feed',
    IG_SEARCH = 'ig_search'
}
export enum OldInstagramPositionEnum {
    stream,
    story,
    explore,
    reels
}