import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { get, NotifyService } from '../../modules/shared/services';
import { PlatformEnum, PlatformType } from '../../modules/shared';
import { ColumnDisplayConf, TableConfigurationPreset, TableConfigurationPresetFE } from '../../modules/metric-table/models';

@Injectable({
    providedIn: 'root'
})
export class TableConfigurationsApiService {
    private endpointUri = `${get('apiUri')}/manage/manageTablePreset`;

    constructor(
        private httpClient: HttpClient, //
        private notifyService: NotifyService
    ) { }

    loadTableConfigurations(platform?: PlatformType): Observable<any[]> {
        let params = new HttpParams().append('groupId', get('user').accountId + '').append('platform', platform);
        return this.httpClient.get<TableConfigurationPreset[]>(`${this.endpointUri}`, { params }).pipe(
            catchError(error => {
                this.notifyService.error('Load Table Presets failed');
                return throwError('');
            })
        );
    }

    deleteTableConfiguration(presetId: string): Observable<any> {
        return this.httpClient.delete(`${this.endpointUri}/${presetId}`).pipe(
            catchError(error => {
                this.notifyService.error('Table Preset delete failed');
                return throwError('');
            })
        );
    }

    createConfiguration(preset: TableConfigurationPresetFE): Observable<string> {
        preset['groupId'] = get('user').accountId;
        this.revertLastColumnsSize(preset);
        return this.httpClient.post<string>(`${this.endpointUri}`, preset).pipe(
            catchError(error => {
                this.notifyService.error('Table Preset save failed');
                return throwError('');
            })
        );
    }

    updateConfiguration(preset: TableConfigurationPresetFE): Observable<any> {
        this.revertLastColumnsSize(preset);
        return this.httpClient.put(`${this.endpointUri}`, preset).pipe(
            catchError(error => {
                this.notifyService.warning('Table Preset update unsaved');
                return throwError('');
            })
        );
    }

    convertToFrontEndModel(item: TableConfigurationPreset, allDisplayConfigurations: ColumnDisplayConf[]): TableConfigurationPresetFE {
        let retVal: TableConfigurationPresetFE = new TableConfigurationPresetFE(item);
        retVal.allDataConfigurations = allDisplayConfigurations;

        retVal.sortPresetColumnsBySortOrder();

        retVal.addMissingMandatoryFields(retVal.campaign);
        retVal.setMandatoryColumnsOrder(retVal.campaign);
        retVal.setSortOrderByArrayOrder(retVal.campaign);

        retVal.addMissingMandatoryFields(retVal.adSet);
        retVal.setMandatoryColumnsOrder(retVal.adSet);
        retVal.setSortOrderByArrayOrder(retVal.adSet);

        retVal.addMissingMandatoryFields(retVal.ads);
        retVal.setMandatoryColumnsOrder(retVal.ads);
        retVal.setSortOrderByArrayOrder(retVal.ads);
        return retVal;
    }

    revertLastColumnsSize(preset: TableConfigurationPreset) {
        if (preset.campaign[preset.campaign.length - 1].autoResizedFromWidth) preset.campaign[preset.campaign.length - 1].width = preset.campaign[preset.campaign.length - 1].autoResizedFromWidth;
        if (preset.adSet[preset.adSet.length - 1].autoResizedFromWidth) preset.adSet[preset.adSet.length - 1].width = preset.adSet[preset.adSet.length - 1].autoResizedFromWidth;
        if (preset.ads[preset.ads.length - 1].autoResizedFromWidth) preset.ads[preset.ads.length - 1].width = preset.ads[preset.ads.length - 1].autoResizedFromWidth;
    }
}
