import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { InsightsFilter, CampaignStructureLevel, FilterOperator } from '../../../../../api/models';
import { ManageOverviewService } from '../../../../manage-overview/manage-overview.service';
import { ColumnDisplayConf, TableReloadType } from '../../../models';
import { FiltersService, TableService } from '../../../table-services';

@Component({
    selector: 'table-filter-level',
    templateUrl: './table-filter-level.component.html',
    styleUrls: ['../table-filters.component.scss', './table-filter-level.component.scss']
})
export class TableFilterLevelComponent implements OnInit {
    @Input('resetTableConfiguration') resetTableConfiguration: EventEmitter<any>;
    ngSelected = 1;

    isCampaignExpanded: boolean = false;
    isAdSetExpanded: boolean = false;
    isAdsExpanded: boolean = false;

    campaign: ColumnDisplayConf[];
    adSet: ColumnDisplayConf[];
    ads: ColumnDisplayConf[];
    allConfigs: ColumnDisplayConf[];
    searchFilterOptions: ColumnDisplayConf[];

    selectedFilter: ColumnDisplayConf;
    insightsFilter: InsightsFilter;

    CampaignStructureLevel = CampaignStructureLevel;
    FilterOperator = FilterOperator;

    options = [
        { id: FilterOperator.Equals, name: 'Equals' },
        { id: FilterOperator.NotEquals, name: 'NotEquals' },
        { id: FilterOperator.Contains, name: 'Contains' },
        { id: FilterOperator.DoesNotContain, name: 'DoesNotContain' },
        { id: FilterOperator.GreaterThan, name: 'GreaterThan' },
        { id: FilterOperator.LessThan, name: 'LessThan' },
        { id: FilterOperator.GreaterThanOrEquals, name: 'GreaterThanOrEquals' },
        { id: FilterOperator.LessThanOrEquals, name: 'LessThanOrEquals' },
        { id: FilterOperator.In, name: 'In' }
    ];
    operatorOptions = [];
    enumOptions = [];
    insightsFilters: InsightsFilter[];
    expandFilters: boolean = true;

    subs: Subscription = new Subscription();

    @ViewChild('ngSelect', { static: false }) ngSelect: NgSelectComponent;

    constructor(
        private manageOverviewService: ManageOverviewService, //
        public filtersService: FiltersService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.insightsFilters = this.filtersService.insightsFilters;
    }

    ngOnInit(): void {
        this.subscribeFilterChanges();
        this.subs.add(
            this.manageOverviewService.dataConfigurations$.subscribe((data: ColumnDisplayConf[]) => {
                if (data) {
                    this.setFilterOptions(data);
                    this.setSearchFilterOptions();
                    this.allConfigs = data;
                }
            })
        );

        this.subs.add(
            this.resetTableConfiguration.subscribe(() => {
                this.filtersService.clearInsigtsFilters();
                this.insightsFilters = this.filtersService.insightsFilters;
                this.filtersService.adAccountInsigthsFilter = null;
            })
        );
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    setFilterOptions(baseConf: ColumnDisplayConf[]) {
        this.campaign = baseConf.filter(item => item.campaignStructureLevels.indexOf(CampaignStructureLevel.Campaign) > -1);
        this.adSet = baseConf.filter(item => item.campaignStructureLevels.indexOf(CampaignStructureLevel.AdSet) > -1);
        this.ads = baseConf.filter(item => item.campaignStructureLevels.indexOf(CampaignStructureLevel.Ad) > -1);
    }

    setSearchFilterOptions() {
        let camp = this.campaign.map(item => new ColumnDisplayConf(item));
        camp.forEach(item => {
            item.campaignStructureLevels = [CampaignStructureLevel.Campaign];
            item.name = item.name + ' (Campaign)';
        });
        let adSet = this.adSet.map(item => new ColumnDisplayConf(item));
        adSet.forEach(item => {
            item.campaignStructureLevels = [CampaignStructureLevel.AdSet];
            item.name = item.name + ' (AdSet)';
        });
        let ad = this.ads.map(item => new ColumnDisplayConf(item));
        ad.forEach(item => {
            item.campaignStructureLevels = [CampaignStructureLevel.Ad];
            item.name = item.name + ' (Ad)';
        });
        this.searchFilterOptions = [...camp, ...adSet, ...ad];
        this.searchFilterOptions.forEach(item => (item['searchParam'] = `${item.name} ${item.key} ${item.description}`.toLowerCase()));
    }

    createFilter(filterItem: ColumnDisplayConf, campaignStructureLevel: CampaignStructureLevel) {
        setTimeout(() => {
            this.selectedFilter = filterItem; //due to close on click outside
        }, 100);
        this.insightsFilter = new InsightsFilter({
            metricKey: filterItem.key,
            operator: null,
            value: null,
            campaignStructureLevel: campaignStructureLevel
        });
        if (filterItem.allowedMetricFilterOperators?.length) {
            this.operatorOptions = this.options.filter(item => filterItem.allowedMetricFilterOperators.includes(item.id));
            if (this.operatorOptions.length === 1) this.insightsFilter.operator = this.operatorOptions[0].id;
        } else this.operatorOptions = [];
        if (!!filterItem.enumValues) {
            let keys: string[] = Object.keys(filterItem.enumValues);
            let enumOptions = keys.map(key => {
                return { id: key, name: filterItem.enumValues[key] };
            });
            this.enumOptions = enumOptions;
        } else this.enumOptions = [];
        this.ngSelect.close();
        delete this.filteredSearchItems;
    }

    onCloseFilterDropdown() {
        this.filterControl.patchValue('');
    }

    clearSelectedFilter(closeAfter?: boolean) {
        delete this.selectedFilter;
        delete this.insightsFilter;
        this.operatorOptions = [];
        this.enumOptions = [];
        if (!closeAfter) this.ngSelect.open();
        else this.ngSelect.close();
    }

    addFilter() {
        if (this.insightsFilter.value && this.insightsFilter.operator) {
            if (this.selectedFilter.metricDataType > 19 && this.selectedFilter.metricDataType < 61) {
                if (this.selectedFilter.metricDataType > 20) {
                    this.insightsFilter.value = parseFloat(this.insightsFilter.value);
                } else {
                    this.insightsFilter.value = parseInt(this.insightsFilter.value);
                }
            }
            this.filtersService.addFilter(this.insightsFilter);
            this.addFieldNames();
        }
        this.clearSelectedFilter(true);
        this.perserveFilterStateInLocalStorage();
        this.updateFilterQueryParams();
    }

    toggleShowFilters() {
        this.expandFilters = !this.expandFilters;
        if (this.expandFilters) {
            this.addFieldNames();
        }
    }

    addFieldNames() {
        this.insightsFilters.forEach(filter => {
            filter['displayName'] = this.getMetricNameBasedOnFilter(filter);
        });
    }

    private getMetricNameBasedOnFilter(filter: InsightsFilter): string {
        let retVal = '';
        let config: ColumnDisplayConf = this.allConfigs.find(item => item.key === filter.metricKey);
        if (config) {
            retVal += config.name;

            let campaignStructureLevelName = '';
            switch (filter.campaignStructureLevel) {
                case CampaignStructureLevel.Campaign:
                    campaignStructureLevelName = 'Campaign';
                    break;
                case CampaignStructureLevel.AdSet:
                    campaignStructureLevelName = 'AdSet';
                    break;
                case CampaignStructureLevel.Ad:
                    campaignStructureLevelName = 'Ad';
                    break;
            }
            let operationType = '';
            switch (filter.operator) {
                case this.options[0].id:
                    operationType = '=';
                    break;
                case this.options[1].id:
                    operationType = '!=';
                    break;
                case this.options[2].id:
                    operationType = '=';
                    break;
                case this.options[3].id:
                    operationType = '!=';
                    break;
                case this.options[4].id:
                    operationType = '>';
                    break;
                case this.options[5].id:
                    operationType = '<';
                    break;
                case this.options[6].id:
                    operationType = '=>';
                    break;
                case this.options[7].id:
                    operationType = '<=';
                    break;
                case this.options[8].id:
                    operationType = '';
                    break;
            }
            retVal += ` (${campaignStructureLevelName}) ${operationType} ${filter.value}`;
        }

        return retVal;
    }

    removeFilter(filter: InsightsFilter) {
        this.filtersService.removeFilter(filter);
        if (!this.insightsFilters.length) this.expandFilters = false;
        this.perserveFilterStateInLocalStorage();
        this.updateFilterQueryParams();
    }

    changeMainFilter(index: number) {
        this.filtersService.mainFilter = this.filtersService.mainFilterOptions[index];
        this.reloadTableData();
    }

    reloadTableData() {
        TableService.reloadTable$.next(TableReloadType.ChangeFilters);
        this.ngSelect.close();
    }

    isOptionSelected(index: number): boolean {
        return this.filtersService.mainFilter === this.filtersService.mainFilterOptions[index];
    }

    filterControl: FormControl = new FormControl();
    filteredSearchItems: ColumnDisplayConf[];

    subscribeFilterChanges() {
        this.subs.add(
            this.filterControl.valueChanges.pipe(debounceTime(300)).subscribe((data: string) => {
                if (data.trim()) {
                    let searchParams: string[] = this.filterControl.value.trim().split(' ');
                    this.filteredSearchItems = this.searchFilterOptions.filter(item => {
                        return searchParams.filter(param => item['searchParam'].includes(param.toLowerCase())).length === searchParams.length;
                    });
                } else {
                    delete this.filteredSearchItems;
                }
            })
        );
    }

    perserveFilterStateInLocalStorage() {
        this.filtersService.sessionStorage.set(this.filtersService.getStorageKey(), JSON.stringify(this.filtersService.insightsFilters));
    }

    updateFilterQueryParams() {
        let from = this.route.snapshot.queryParamMap.get('from');
        let to = this.route.snapshot.queryParamMap.get('to');
        let level = this.route.snapshot.queryParamMap.get('level');
        let queryParams: QueryParams = {
            from: from,
            to: to,
            level: level
        };

        let insightsFilters = '';
        if (this.filtersService.insightsFilters.length) {
            insightsFilters = JSON.stringify(this.filtersService.insightsFilters);
            queryParams.insightsFilters = insightsFilters;
        }

        this.router.navigate(['.'], {
            relativeTo: this.route,
            queryParams: queryParams
        });
    }
}

export interface QueryParams {
    from?: any;
    to?: any;
    level?: any;
    platform?: any;
    insightsFilters?: any;
}
