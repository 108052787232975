import { CampaignStructureLevel, FilterOperator, MetricDataType, MetricIntegrationType, MetricType } from '../../../api/models';
import {ManageDisplayMetricConfiguration} from '../../../api/models/manage-display-metric-configuration.model'
export class ColumnDisplayConf extends ManageDisplayMetricConfiguration {
    constructor(value?: any) {
        super();
        const classKeys = Object.keys(this);
        if (value)
            for (const valueKey in value) {
                if (classKeys.includes(valueKey)) this[valueKey] = value[valueKey];
            }
    }

    key: string = '';
    name: string = '';
    description: string = '';
    metricIntegrationType: MetricIntegrationType = MetricIntegrationType.None;

    campaignStructureLevels: CampaignStructureLevel[] = [CampaignStructureLevel.None];
    metricType: MetricType = MetricType.Static;
    metricDataType: MetricDataType = MetricDataType.Hidden;

    allowedMetricFilterOperators: FilterOperator[] = [];
    enumValues: any = {};

    minWidth: number = 80;
    sortable: boolean = true;
    resizable: boolean = true;
    canAutoResize: boolean = true;
    draggable: boolean = true;
    width: number = 160;
    sortOrder: number = 1000;

    autoResizedFromWidth?: number = 0;

    static fromObject(item: any): ColumnDisplayConf {
        return Object.assign(new ColumnDisplayConf(), item);
    }

    static getMetricKeysForColumns(columns: ColumnDisplayConf[]): string[] {
        if (!columns) return [];
        return columns.filter(item => item.metricType === MetricType.Metric).map(item => item.key);
    }
}



export function GenerateId(): string {
    return Math.floor(Math.random() * 100000000000000000) + '';
}


