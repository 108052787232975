import { SnapchateObjectiveEnum } from "../enums";

export const SnapchatObjectives: any[] = [
    {
        id: SnapchateObjectiveEnum.WEB_CONVERSION,
        name: 'Website Conversion'
    },
    {
        id: SnapchateObjectiveEnum.CATALOG_SALES,
        name: 'Catalog Sales'
    },
]