import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MetricIntegrationType } from '../../../../../api/models';
import { ColumnDisplayConf, TableSortElement } from '../../../models';

@Component({
    selector: 'table-header-cell',
    templateUrl: './table-header-cell.component.html',
    styleUrls: ['../../metric-table/metric-table.component.scss', './table-header-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableHeaderCellComponent implements OnInit {
    @Input() column: ColumnDisplayConf; //TableColumn with ColumnDisplayConf data
    @Input() selectedAll: boolean;
    isSorted: boolean;
    @Input('sortFieldProp') set _sortFieldProp(value: string) {
        this.isSorted = value === this.column.key;
    }

    @Output() emitSelectAll = new EventEmitter<any>();
    @Output() emitColumnSortCustom = new EventEmitter<any>();

    MetricIntegrationType = MetricIntegrationType;

    constructor() {}

    ngOnInit(): void {}
}
