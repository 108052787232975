import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CampaignStructureLevel, MetricIntegrationType } from 'apps/manage/src/app/api/models/enums';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ColumnDisplayConf, TableConfigurationPresetFE } from '../../../../models';
import { SaveNewPresetComponent } from './save-new-preset/save-new-preset.component';

@Component({
    selector: 'preset-columns-select',
    templateUrl: './preset-columns-select.component.html',
    styleUrls: ['./preset-columns-select.component.scss']
})
export class PresetColumnsSelectComponent implements OnInit, OnDestroy {
    @Input() selectedDataLevel: CampaignStructureLevel;
    @Input() dataConfigurations: ColumnDisplayConf[];
    @Input() currentTableConf: TableConfigurationPresetFE;

    @Output() emitSelectedItemsChange = new EventEmitter<any>();
    @Output() emitCancel = new EventEmitter<any>();
    @Output() emitSave = new EventEmitter<any>();
    @Output() emitUpdate = new EventEmitter<any>();

    @ViewChild('saveNewPreset', { static: false }) saveNewPreset: SaveNewPresetComponent;

    dataConfigurationsForSelectedLevel: ColumnDisplayConf[];
    filterControl: FormControl = new FormControl();
    filteredSearchItems: ColumnDisplayConf[];

    columnsInPreset: string[] = [];

    MetricIntegrationType = MetricIntegrationType;

    subs: Subscription = new Subscription();

    constructor() {}

    ngOnInit(): void {  
        this.dataConfigurationsForSelectedLevel = this.getDataConfForSelectedLevel(this.selectedDataLevel);
        this.sortOptionsByNameAndGroup();
        this.columnsInPreset = this.getSelectedColumnsKeysForCurrentLevel(this.currentTableConf, this.selectedDataLevel);
        this.subscribeFilterChanges();
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    getDataConfForSelectedLevel(value: CampaignStructureLevel): ColumnDisplayConf[] {
        let searchThrough: ColumnDisplayConf[] = this.dataConfigurations.filter(item => !(['name', 'status'].indexOf(item.key) > -1)); //'effectiveStatus'
        switch (value) {
            case CampaignStructureLevel.Campaign:
                return searchThrough.filter(item => item.campaignStructureLevels.indexOf(CampaignStructureLevel.Campaign) > -1);
            case CampaignStructureLevel.AdSet:
                return searchThrough.filter(item => item.campaignStructureLevels.indexOf(CampaignStructureLevel.AdSet) > -1);
            case CampaignStructureLevel.Ad:
                return searchThrough.filter(item => item.campaignStructureLevels.indexOf(CampaignStructureLevel.Ad) > -1);
            default:
                //error
                return;
        }
    }

    getSelectedColumnsKeysForCurrentLevel(preset: TableConfigurationPresetFE, selectedDataLevel: CampaignStructureLevel): string[] {
        return this.getSelectedColumnsKeysForConfigurationsArray(preset.getCurrentLevelColumns(selectedDataLevel));
    }

    getSelectedColumnsKeysForConfigurationsArray(columns: ColumnDisplayConf[]): string[] {
        return columns.map(item => item.key);
    }

    toggleOption(key: string) {
        let index = this.columnsInPreset.indexOf(key);
        if (index > -1) {
            this.columnsInPreset.splice(index, 1);
        } else {
            this.columnsInPreset.push(key);
        }
        this.emitSelectedItemsChange.emit(this.columnsInPreset);
    }

    subscribeFilterChanges() {
        this.subs.add(
            this.filterControl.valueChanges.pipe(debounceTime(300)).subscribe((data: string) => {
                if (data.trim()) {
                    let searchParams: string[] = this.filterControl.value.trim().split(' ');
                    this.filteredSearchItems = this.dataConfigurationsForSelectedLevel.filter(item => {
                        return searchParams.filter(param => item.name.toLowerCase().includes(param.toLowerCase())).length === searchParams.length;
                    });
                } else {
                    delete this.filteredSearchItems;
                }
            })
        );
    }

    sortOptionsByNameAndGroup() {
        let all = [...this.dataConfigurationsForSelectedLevel];
        let fb = all.filter(item => item.metricIntegrationType === MetricIntegrationType.Facebook);
        this.sortByName(fb);
        let hunch = all.filter(item => item.metricIntegrationType === MetricIntegrationType.Hunch);
        this.sortByName(hunch);
        let google = all.filter(item => item.metricIntegrationType === MetricIntegrationType.Google || item.metricIntegrationType === MetricIntegrationType.GoogleAnalytics4);
        this.sortByName(google);
        let apps_flyer = all.filter(item => item.metricIntegrationType === MetricIntegrationType.AppsFlyer);
        this.sortByName(apps_flyer);
        this.dataConfigurationsForSelectedLevel = fb.concat(hunch).concat(google).concat(apps_flyer);
    }

    sortByName(items: any[]) {
        items.sort(function (a, b) {
            var nameA = a.name.toUpperCase();
            var nameB = b.name.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
    }
}
