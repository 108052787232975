<div class="automated--status--cell body-cell">
  <div class="automated--status--cell-icon">
    <!-- warning3 - zuta ikonica -->
    <div *ngIf="automatedSyncStatusProgress === 2 || automatedSyncStatusProgress === 1; else loadedTemp">
      <div class="">
        <hunch-main-loader [defaultLoading]="mainLoaderAnimation" [width]="24" [height]="24"></hunch-main-loader>
      </div>
    </div>
    <ng-template #loadedTemp>
      <div *ngIf="automatedSyncStatusSuccess === 1" class="automated--status--cell-icon-checked">
        <mat-icon svgIcon="checkMark:white" class="hunch--icon hunch--icon__md"></mat-icon>
      </div>
      <mat-icon *ngIf="automatedSyncStatusSuccess === 2" svgIcon="warning3" class="hunch--icon"></mat-icon>
      <mat-icon *ngIf="automatedSyncStatusSuccess === 3" svgIcon="warning1" class="hunch--icon"></mat-icon>
    </ng-template>
  </div>
  <div *ngIf="automatedSyncStatusProgress === 3;" class="automated--status--cell-info">
    <div class="automated--status--cell-title">Sync Completed</div>
    <div class="automated--status--cell-date">{{dateString}} at {{timeString}}</div>
  </div>
  <div *ngIf="automatedSyncStatusProgress === 2 || automatedSyncStatusProgress === 1" class="automated--status--cell-info">
    <div *ngIf="automatedSyncStatusProgress === 1" class="automated--status--cell-title">Rendering</div>
    <div *ngIf="automatedSyncStatusProgress === 2" class="automated--status--cell-title">Sending to FB</div>
  </div>
</div>
