import { Component, EventEmitter, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { CampaignStructureLevel, InsightsQueryResponseObject } from '../../../../api/models';
import { DefaultSortColumnName, DefaultSortDirection, TableConfigurationPresetFE, TableReloadType } from '../../models';
import { MetricTableComponent } from '../metric-table/metric-table.component';
import { IManageInitData, ManageOverviewService } from '../../../manage-overview/manage-overview.service';
import { GetTableService, TableService, TableMainService } from '../../table-services';
import { TableConfigurationsApiService } from '../../../../api/services';


@Component({
    selector: 'metric-table-container',
    templateUrl: './metric-table-container.component.html',
    styleUrls: ['./metric-table-container.component.scss']
})
export class MetricTableContainerComponent implements OnInit, OnDestroy {
    @Input('selectedDataLevel') selectedDataLevel: CampaignStructureLevel;
    @Input('resetTableConfiguration') resetTableConfiguration: EventEmitter<any>;
    currentTableConf: TableConfigurationPresetFE;
    tableDataService: TableMainService;
    drafts: any[];

    hasPresetChangesWaitingForSave: boolean = false;
    isPresetSaveInProgress: boolean = false;
    loading: boolean;

    @ViewChild('metricTable', { static: false }) metricTable: MetricTableComponent;

    subs: Subscription = new Subscription();

    constructor(
        private manageOverviewService: ManageOverviewService, //
        private getTableService: GetTableService,
        private tableConfigurationsApiService: TableConfigurationsApiService
    ) { }

    ngOnInit(): void {
        this.loadDrafts();
        this.loadTableServiceByDataLevel();
        this.subscribeToConfigurationChange();
        this.subscribeToPageReload();
        this.dispatchTableDataOnRequest();
        this.resetTableConfiguration?.subscribe(() => {
            this.loading = true;
            this.currentTableConf = null;
            setTimeout(() => {
                this.tableDataService.queryCount = null;
                this.tableDataService.tableCachedRows = [];
                this.tableDataService.setBasicQueryParameters(this.selectedDataLevel);
                this.tableDataService.cachedTowsTotalNumber = null;
                this.loading = false;
            });
        });
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    private loadDrafts() {
        this.subs.add(
            this.manageOverviewService.drafts.subscribe((data: InsightsQueryResponseObject[]) => {
                this.drafts = data;
            })
        );
    }

    private loadTableServiceByDataLevel() {
        if (this.selectedDataLevel) {
            this.tableDataService = this.getTableService.getCurrentLevel(this.selectedDataLevel);
        }
    }

    private subscribeToConfigurationChange() {
        this.subs.add(
            this.manageOverviewService.getCurrentTableConfiguration().subscribe((data: TableConfigurationPresetFE) => {
                if (data) {
                    let referenceCheck = this.currentTableConf;
                    this.currentTableConf = data;
                    this.tableDataService.tableConfiguration = this.currentTableConf;
                    //editing preset does not trigger chnage in metric table component so it has to be updated through parent
                    if (this.metricTable && referenceCheck === data) this.metricTable.setCurrentTableConfiguration(data);
                }
            })
        );
    }

    private dispatchTableDataOnRequest() {
        this.subs.add(
            TableService.requestTableData$.subscribe(() => {
                if (this.metricTable) this.tableDataService.tableData = this.metricTable.rows;
                else this.tableDataService.tableData = [];
            })
        );
    }

    private subscribeToPageReload() {
        this.subs.add(
            TableService.reloadTable$.subscribe((data: TableReloadType) => {
                if (this.metricTable) {
                    switch (data) {
                        case TableReloadType.ChangePreset:
                            this.metricTable.loadFirstBatch = true;
                            this.metricTable.sortField = [{ dir: DefaultSortDirection, prop: DefaultSortColumnName }];
                            this.metricTable.resetTable();
                            break;
                        case TableReloadType.ChangeParams:
                        case TableReloadType.ChangeFilters:
                        case TableReloadType.CustomFilter:
                            this.metricTable.loadFirstBatch = true;
                            this.metricTable.resetTable();
                            break;
                        case TableReloadType.ReloadRows:
                            this.metricTable.renderRowsAgain();
                            break;
                        case TableReloadType.DuplicatedToDraft:
                            this.loadDraftsAgain();
                            break;
                        case TableReloadType.ClearSelectedLevel:
                            this.metricTable.resetTable(false);
                            break;
                        default:
                            break;
                    }
                }
            })
        );
    }

    deleteTableDraft(draft: any) {
        let deletedDraft = this.drafts.find(item => item.hunchId === draft.hunchId);
        if (deletedDraft) this.drafts.splice(this.drafts.indexOf(deletedDraft), 1);
        this.metricTable.rowsTotalCount -= 1;
        this.metricTable.rows.splice(this.metricTable.rows.indexOf(draft), 1);
        this.metricTable.renderRowsAgain();
    }

    loadDraftsAgain() {
        delete this.drafts;
        this.manageOverviewService.loadInitData().subscribe((res: IManageInitData) => {
            if (res) this.manageOverviewService.setDrafts(res);
            this.manageOverviewService.drafts.next(res.groupDrafts);
        });
    }

    public updateCurrentPreset() {
        if (!this.isPresetImutable() && this.currentTableConf.hasUnsavedChanges) {
            if (this.isPresetSaveInProgress) this.hasPresetChangesWaitingForSave = true;
            else {
                this.isPresetSaveInProgress = true;
                this.tableConfigurationsApiService.updateConfiguration(this.currentTableConf).subscribe((data: TableConfigurationPresetFE) => {
                    this.currentTableConf.hasUnsavedChanges = false;
                    this.isPresetSaveInProgress = false;

                    if (this.hasPresetChangesWaitingForSave) {
                        this.updateCurrentPreset();
                        this.hasPresetChangesWaitingForSave = false;
                    }
                });
            }
        }
    }

    private isPresetImutable(): boolean {
        return this.currentTableConf.isHunchPredefined || this.currentTableConf.isDefault;
    }

}
