<metric-table #metricTable *ngIf="currentTableConf && drafts && tableDataService && !loading; else dummyTableLoader"
[selectedDataLevel]="selectedDataLevel"
[tableDataService]="tableDataService"
[currentTableConf]="currentTableConf"
[drafts]="drafts"
(emitDeleteDraft)="deleteTableDraft($event)"
(updateTableConf)="updateCurrentPreset()"
></metric-table>

<ng-template #dummyTableLoader>
    <div class="w--100 table-container-borders">
        <empty-table></empty-table>
    </div>
</ng-template>