import { Injectable } from '@angular/core';
import { TableConfigurationsApiService, ManageDisplayMetricConfigurationsApiService, InsightsApiService, TableActionsApiService } from '../../api/services';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CampaignStructureLevel, InsightsQueryResponseObject, InsightsQueryResponseObjectFE } from '../../api/models';
import { get, set } from '../shared/services';
import { UserFormatPreferencesService } from '../shared/services';
import { PlatformEnum, PlatformType } from '../shared';
import { ColumnDisplayConf, TableConfigurationPreset, TableConfigurationPresetFE } from '../metric-table/models';

@Injectable({
    providedIn: 'root'
})
export class ManageOverviewService {
    adAccountIds: string[] = [];
    platform: PlatformType = PlatformEnum.FACEBOOK;
    adAccountIds$ = new BehaviorSubject<string[]>([]);

    dataConfigurations$ = new BehaviorSubject<ColumnDisplayConf[]>(undefined);
    currentDataConfigurations: ColumnDisplayConf[]; //check use

    tableConfigurations$ = new BehaviorSubject<TableConfigurationPresetFE[]>(undefined);

    drafts: BehaviorSubject<InsightsQueryResponseObject[]> = new BehaviorSubject<InsightsQueryResponseObject[]>(null);
    cachedParams: any[] = [];

    private selectedDataLevel: CampaignStructureLevel = CampaignStructureLevel.Campaign;
    private currentTableConf$ = new BehaviorSubject<TableConfigurationPresetFE>(undefined);

    constructor(
        private tableConfigurationsApiService: TableConfigurationsApiService, //
        private dataConfigurationsApiService: ManageDisplayMetricConfigurationsApiService,
        private insightsApiService: InsightsApiService,
        private userFormatPreferencesService: UserFormatPreferencesService,
        private tableActionsApiService: TableActionsApiService
    ) { }

    loadInitData(platform?: PlatformType): Observable<any> {
        this.platform = platform ? platform : PlatformEnum.FACEBOOK;
        set('platform', this.platform);
        let joinObject: any = {
            dataConfigurations: this.dataConfigurationsApiService.loadDataConfigurations(this.platform),
            tableConfigurations: this.tableConfigurationsApiService.loadTableConfigurations(this.platform),
            loadCurrency: this.userFormatPreferencesService.loadCurrencyAndAdAccounts(this.platform),
        };

        if (!platform) {
            joinObject.groupDrafts = this.insightsApiService.getDrafts();
        }
        joinObject.unpublishedHandpickChanges = this.tableActionsApiService.getUnpublishedHandpickChanges(this.platform);

        return forkJoin(joinObject)
            .pipe(
                map((res: any) => {
                    if (res) {
                        this.setDrafts(res);
                        this.setTableConfigurations(res);
                        if (res.unpublishedHandpickChanges) set('unpublishedHandpickChanges', res.unpublishedHandpickChanges);
                    }
                    return res;
                }));
    }

    setDrafts(res: IManageInitData) {
        if (!res.groupDrafts?.length) res.groupDrafts = [];
        else res.groupDrafts = res.groupDrafts.map(item => {
            let retVal = new InsightsQueryResponseObjectFE(item);
            retVal.isDraft = true;
            retVal.effectiveStatus = 'Draft';
            delete retVal['createdTime'];
            delete retVal['startTime'];
            return retVal;
        });
        this.drafts.next(res.groupDrafts);
    }

    private setTableConfigurations(res: IManageInitData) {
        if (res.dataConfigurations?.length) {
            res.dataConfigurations = res.dataConfigurations.map(item => new ColumnDisplayConf(item));
            this.currentDataConfigurations = res.dataConfigurations;
            this.dataConfigurations$.next(res.dataConfigurations);

            if (!res.tableConfigurations || !res.tableConfigurations.length) {
                let defaultPreset = this.tableConfigurationsApiService.convertToFrontEndModel(new TableConfigurationPreset(), this.currentDataConfigurations);
                res.tableConfigurations = [defaultPreset];
            } else {
                res.tableConfigurations = res.tableConfigurations.map(item => this.tableConfigurationsApiService.convertToFrontEndModel(item, res.dataConfigurations));
            }

            this.tableConfigurations$.next(res.tableConfigurations);
            this.currentTableConf$.next(this.getLastUsedTableConfiguration(res.tableConfigurations));
        }
    }



    clearInitData() {
        this.drafts.next(undefined);
        this.tableConfigurations$.next(undefined);
        this.currentTableConf$.next(undefined);
    }


    setCurrentTableConfiguration(preset: TableConfigurationPresetFE) {
        preset.sortPresetColumnsBySortOrder();
        this.currentTableConf$.next(preset);
        window.localStorage.setItem(`groupId-${get('user').accountId}`, preset.id);
    }

    getCurrentTableConfiguration(): Observable<TableConfigurationPresetFE> {
        return this.currentTableConf$.asObservable();
    }

    setCurrentLevel(level: CampaignStructureLevel) {
        this.selectedDataLevel = level;
    }

    getCurrentLevel(): CampaignStructureLevel {
        return this.selectedDataLevel;
    }


    getLastUsedTableConfiguration(tableConfigurations: TableConfigurationPresetFE[]): TableConfigurationPresetFE {
        //--!!!!!!!!!
        let sorted = tableConfigurations.sort((a, b) => {
            if (a.lastUsed > b.lastUsed) {
                return -1;
            }
            if (a.lastUsed < b.lastUsed) {
                return 1;
            }
            return 0;
        });
        let lastSelectedPresetIdForGroup = window.localStorage.getItem(`groupId-${get('user').accountId}`);
        let lastSelectedPreset: TableConfigurationPresetFE;
        if (lastSelectedPresetIdForGroup) {
            lastSelectedPreset = tableConfigurations.find(item => item.id == lastSelectedPresetIdForGroup); // number == string !!!
        }
        return lastSelectedPreset ?? sorted[0];
    }

    setLevelFieldName(value: CampaignStructureLevel): string {
        switch (value) {
            case CampaignStructureLevel.Campaign:
                return 'campaign';
            case CampaignStructureLevel.AdSet:
                return 'adSet';
            case CampaignStructureLevel.Ad:
                return 'ads';
            default:
                //error
                return '';
        }
    }

    savePresetState() { }
}

export interface IManageInitData {
    dataConfigurations: any;
    tableConfigurations: any;
    groupDrafts?: any;
    loadCurrency: any;
}
