import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { get, InteroperabilityService } from '../shared/services';
import { Subscription } from 'rxjs';
import { CampaignStructureLevel } from '../../api/models';
import { TableConfigurationPresetFE } from '../metric-table/models';
import { AdsLevelService } from '../metric-table/table-services/ad-level.service';
import { AdSetLevelService } from '../metric-table/table-services/adset-level.service';
import { CampaignLevelService } from '../metric-table/table-services/campaign-level.service';
import { DatePresetService } from '../metric-table/table-services/date-preset.service';
import { GetTableService } from '../metric-table/table-services/get-table.service';
import { TableMainService } from '../metric-table/table-services/table-main.service';
import { PlatformEnum, PlatformType } from '../shared';
import { platforms } from '../shared/consts';
import { IManageInitData, ManageOverviewService } from './manage-overview.service';

@Component({
    selector: 'manage-overview',
    templateUrl: './manage-overview.component.html',
    styleUrls: ['./manage-overview.component.scss', './reload-manage-overview/reload.component.scss'],
    providers: [TableMainService, DatePresetService, CampaignLevelService, AdSetLevelService, AdsLevelService, GetTableService]
})
export class ManageOverviewComponent implements OnInit, OnDestroy {
    selectedDataLevel: CampaignStructureLevel;
    SelectedLevel = CampaignStructureLevel;
    platform: PlatformType = PlatformEnum.FACEBOOK;
    platformEnum = PlatformEnum;
    platforms: any[] = platforms;
    tableConfigurations: TableConfigurationPresetFE[];
    group: any = {};
    groupData: IManageInitData;
    subscription: Subscription = new Subscription();
    snapchatAccess: boolean;
    multiPlatform: boolean;
    resetTableConfiguration: EventEmitter<any> = new EventEmitter();
    showConnectCampaignModal: boolean;
    showEnrichAdModal: boolean; 
    connectCampaignAllow: boolean;
    enrichAdAllow: boolean;
    showFiltering:boolean;

    constructor(
        private manageOverviewService: ManageOverviewService, //
        private interoperabilityService: InteroperabilityService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.setLevelFromParam();
        this.connectCampaignAllow = JSON.parse(get('connectCampaignAllow')).includes(get('user').teamId) || JSON.parse(get('connectCampaignAllow')).length === 0;
        this.enrichAdAllow = JSON.parse(get('enrichAdAlow')).includes(get('user').teamId) || JSON.parse(get('enrichAdAlow')).length === 0;
    }

    ngOnInit(): void {
        this.reloadOnGroupChange();
        if (this.route.snapshot.queryParams['platform']) { this.platform = this.route.snapshot.queryParams['platform'] };
        this.loadGroupData();
        this.checkPlatformAccess();
        this.showFiltering = true;
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    loadGroupData() {
        this.manageOverviewService.loadInitData(this.platform).subscribe((data: IManageInitData) => {
            this.groupData = data;
        });
    }

    connectToCampaign(): void {
        this.showConnectCampaignModal = true;
    }

    enrichAd():void{
        this.showEnrichAdModal = true
    }

    setLevelFromParam() {
        let param = this.route.snapshot.queryParams.level;
        if (param) this.changeSelectedLevel(parseInt(param));
        else this.changeSelectedLevel(CampaignStructureLevel.Campaign);
    }

    resizedContainer() {
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 200);
    }

    changeSelectedLevel(value: CampaignStructureLevel) {
        this.selectedDataLevel = value;
        this.manageOverviewService.setCurrentLevel(this.selectedDataLevel);
        this.router.navigate(['.'], {
            relativeTo: this.route,
            queryParams: {
                level: `${this.manageOverviewService.getCurrentLevel()}`
            },
            queryParamsHandling: 'merge'
        });
    }

    reloadOnGroupChange() {
        this.subscription.add(this.interoperabilityService.$accountChanged.subscribe((data: any) => {
            this.resetTableConfiguration.emit(true)
            this.manageOverviewService.clearInitData();
            setTimeout(() => {
                this.loadGroupData();
            }, 1000);
        }));
    }

    goToCampaignCreate() {
        location.href = `${get('metaStandardAppUrl')}/meta/standard/setup`;
    }

    goToPlatformManage(platform: PlatformType): void {
        if (platform === this.platform) return;
        this.showFiltering = false;
        this.manageOverviewService.cachedParams.push({
            from: this.route.snapshot.queryParams.from,
            to: this.route.snapshot.queryParams.to
        });
        this.router.navigate(['.'], {
            relativeTo: this.route,
            queryParams: {
                from: this.route.snapshot.queryParams.from,
                to: this.route.snapshot.queryParams.to,
                level: `${this.manageOverviewService.getCurrentLevel()}`,
                platform
            },
            queryParamsHandling: ''
        });
        this.platform = platform;
        this.manageOverviewService.platform = platform;
        this.resetTableConfiguration.emit(true)
        this.manageOverviewService.clearInitData();
        setTimeout(() => {
            this.loadGroupData();
            this.showFiltering = true;
        }, 1000);


    }

    goToSnapchatStandard(): void {
        location.href = `${get('snapchatStandardAppUrl')}/snapchat/standard/setup`;
    }
    goToSnapchatAutomated(): void {
        location.href = `${get('snapchatAutomatedAppUrl')}/snapchat/automated/setup`;
    }

    private checkPlatformAccess(): void {
        const teamId: number = get('user').teamId;
        // this.snapchatAccess = team.snapchat_ads_connection && (team.id === 2 || team.id === 3) ? true : false;
        this.snapchatAccess = get('snapchatEnabled') ? true : false;
        // check which platform client has
        if (this.snapchatAccess) this.multiPlatform = true;
    }
}
