import { Injectable } from '@angular/core';
import { CampaignStructureLevel } from '../../../api/models';
import { AdsLevelService } from './ad-level.service';
import { AdSetLevelService } from './adset-level.service';
import { CampaignLevelService } from './campaign-level.service';

@Injectable()
export class GetTableService {
    constructor(
        private campaignLevelService: CampaignLevelService, //
        private adSetLevelService: AdSetLevelService,
        private adsLevelService: AdsLevelService
    ) { }

    public getCurrentLevel(selectedDataLevel: CampaignStructureLevel): any {
        if (selectedDataLevel) {
            switch (selectedDataLevel) {
                case CampaignStructureLevel.Campaign:
                    return this.campaignLevelService;
                case CampaignStructureLevel.AdSet:
                    return this.adSetLevelService;
                case CampaignStructureLevel.Ad:
                    return this.adsLevelService;
            }
        }
        return null;
    }

    public clearSelectedItemsOnAllLevels(level?: CampaignStructureLevel) {
        if (!level) {
            this.getCurrentLevel(CampaignStructureLevel.Campaign).selectedItemIds.length = 0;
            this.getCurrentLevel(CampaignStructureLevel.AdSet).selectedItemIds.length = 0;
            this.getCurrentLevel(CampaignStructureLevel.Ad).selectedItemIds.length = 0;
            return;
        }
        if (CampaignStructureLevel.AdSet) {
            this.getCurrentLevel(CampaignStructureLevel.AdSet).selectedItemIds.length = 0;
            this.getCurrentLevel(CampaignStructureLevel.Ad).selectedItemIds.length = 0;
        }
    }
}
