export class MessageHub {
    message: Message;
    type: string;
    error?: string;

    public constructor(init?: Partial<MessageHub>) {
        Object.assign(this, init);
    }
}

export interface Message {
    CampaignId: string;
    ObjectId: string;
    ObjectType: string;
    FieldName: string;
    Value: string;
    Status: string;
    Message: string;
    AdAccountId?: string
}

export enum MessageTypeEnum {
    STANDARD_CAMPAIGN = 'StandardCampaign',
    AUTOMATED_CAMPAIGN = 'AutomatedCampaign'
}

export type MessageType = MessageTypeEnum.AUTOMATED_CAMPAIGN | MessageTypeEnum.STANDARD_CAMPAIGN;