import { Component, EventEmitter, HostListener, Input, OnInit, Output, TemplateRef } from '@angular/core';

@Component({
    selector: 'hunch-modal',
    templateUrl: './hunch-modal.component.html',
    styleUrls: ['./hunch-modal.component.scss']
})
export class HunchModalComponent implements OnInit {
    isAppeared: boolean;
    wrapperShow: boolean = true;

    @Input() headerClass: string = '';
    @Input() messageBody: string = '';
    @Input() messageHeader: string = 'Please confirm';
    @Input() confirmBtnText: string = 'Confirm';
    @Input() confirmBtnClass: string = '';
    @Input() cancelBtnText: string = 'Cancel';
    @Input() cancelBtnClass: string = '';
    @Input() customTemplate: TemplateRef<any>;
    @Input() isConfirmDisabled: boolean = false;
    @Input('justify-save-cancel') justifySaveCancel: boolean = true;
    @Input('flex-end') flexEnd: boolean = false;
    @Input() hideButtonGrid: boolean = false;
    @Input() noCancelOnEscape: boolean;
    @Input() isV2: boolean = false;
    @Input() custom: string;

    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        if (!this.noCancelOnEscape) this.closeModal(false);
    }

    @Output() emitCancel = new EventEmitter<any>();
    @Output() emitConfirm = new EventEmitter<any>();
    @Output() emitCustom = new EventEmitter<any>();

    constructor() { }

    ngOnInit(): void {
        setTimeout(() => {
            this.isAppeared = true;
        }, 100);
    }

    closeModal(isConfirmed: boolean) {
        setTimeout(() => {
            if (!isConfirmed) {
                this.wrapperShow = false;
                this.isAppeared = false;
                this.emitCancel.emit();
            }
            else if (this.custom) {
                this.isConfirmDisabled = true;
                this.emitCustom.next(this.custom);
                return;
            }
            else { this.emitConfirm.emit(); this.isAppeared = false; }

        }, 300);
    }
}
