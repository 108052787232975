<ng-container *ngFor="let conf of tableConfigurations; let index = index">
    <div class="w--100 ng-option" role="option" aria-selected="false"
        [ngClass]="{'ng-option-selected': conf.id === currentTableConf.id}"
        (click)="changeSelectedPreset(conf, $event)">
        <div class="w--100 d--flex justify--content_space-between align--items_center">

            <span class="ng-option-label color--grey-6 truncate" style="max-width: 140px;">
                <mat-radio-button class="mr--4" [checked]="conf.id === currentTableConf.id"
                    (change)="currentTableConf.id = conf.id">
                    {{conf.name + ( conf.isDefault || conf.isHunchPredefined ? ' (Default)' : ' ') }}
                </mat-radio-button>
            </span>


            <button *ngIf="!conf.isDefault && !conf.isHunchPredefined; else explain"
            class="btn--solid btn--solid_grey btn--solid_icon transparent-btn"
            (click)="deletePreset(conf, $event)">
                <svg-icon  class="ng-value-img remove-preset"
                svgClass="filter-icon"  name="CloseRemove" alt=""
                style="transform: scale(0.85);"></svg-icon>
            </button>
        </div>
    </div>
</ng-container>

<ng-template #explain>
    <!-- <button class="btn--solid btn--solid_grey btn--solid_icon transparent-btn" title="Cannot delete default nor currently selected presets!">
        <svg-icon  class="ng-value-img remove-preset"
        svgClass="filter-icon"  name="CloseRemove" alt=""
        style="transform: scale(0.85);"></svg-icon>
    </button> -->
</ng-template>
