import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { LevelTabComponent } from './level-tab.component';



@NgModule({
  declarations: [
    LevelTabComponent
  ],
  imports: [
    SharedModule,
    AngularSvgIconModule
  ],
  exports:[
    LevelTabComponent
  ]
})
export class LevelTabModule { }
