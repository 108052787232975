<div class="hunch-angular-module hunch-manage">

    <!-- <app-header [user]="user" [accounts]="accounts" [hunchAppUrl]="hunchAppUrl" [hunchManageAppUrl]="hunchManageAppUrl"
        [hunchCatalogsAppUrl]="hunchCatalogsAppUrl" (accountChanged)="accountChanged($event)"></app-header> -->
    <div id="header-container"></div>
    <ng-container *ngIf="true">
        <div class="app-container" [ngStyle]="{'height': 'calc(100vh - ' + ApplicationHeaderPlaceholderHeight + 'px)'}">

            <div class="app-body">

                <router-outlet></router-outlet>
            </div>
        </div>
    </ng-container>



    <router-outlet></router-outlet>
</div>