import { BillingEventEnum, OldBillingEventEnum } from "../enums";

export const mapBillingEvent: any = {
    [OldBillingEventEnum.AppInstalls]: BillingEventEnum.APP_INSTALLS,
    [OldBillingEventEnum.Clicks]: BillingEventEnum.CLICKS,
    [OldBillingEventEnum.Impressions]: BillingEventEnum.IMPRESSIONS,
    [OldBillingEventEnum.LinkClicks]: BillingEventEnum.LINK_CLICKS,
    [OldBillingEventEnum.OfferClaims]: BillingEventEnum.OFFER_CLAIMS,
    [OldBillingEventEnum.PageLikes]: BillingEventEnum.PAGE_LIKES,
    [OldBillingEventEnum.PostEngagement]: BillingEventEnum.POST_ENGAGEMENT,
    [OldBillingEventEnum.VideoViews]: BillingEventEnum.VIDEO_VIEWS,
    [OldBillingEventEnum.TwoSecondContinuousVideoViews]: BillingEventEnum.TWO_SECOND_CONTINUOUS_VIDEO_VIEWS,
    [OldBillingEventEnum.CompletedVideoViews]: BillingEventEnum.COMPLETED_VIDEO_VIEWS,
    [OldBillingEventEnum.VideoViews15s]: BillingEventEnum.VIDEO_VIEWS_15S,
    [OldBillingEventEnum.None]: BillingEventEnum.NONE,
    [OldBillingEventEnum.Thruplay]: BillingEventEnum.THRUPLAY,
}