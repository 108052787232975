import { Injectable } from '@angular/core';
import { InteroperabilityService } from './interoperability.service';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { get, set } from './global.service';
import { PlatformType } from '../enums';

@Injectable({
    providedIn: 'root'
})
export class UserFormatPreferencesService {
    userPreferences: UserFormatPreferences;
    numberPipeFormatDecimal: string;
    areAllProfilesWithSameCurrency: boolean = true;

    groupAdAccounts: any[];

    currencyByAdAccount = new Map<string, string>();
    platform: PlatformType;

    constructor(private interoperabilityService: InteroperabilityService,
        private httpClient: HttpClient) {
        this.setData();
        this.resetDataOnGroupChange();
    }

    resetDataOnGroupChange() {
        this.interoperabilityService.$accountChanged.subscribe((data: any) => {
            if (data) this.setData(data);
        });
    }

    setData(group?: any) {
        this.userPreferences = new UserFormatPreferences();
        this.numberPipeFormatDecimal = `1.${this.userPreferences.numberOfDecimals}-${this.userPreferences.numberOfDecimals}`;
    }

    loadAdAccounts(accountId: number, platform?: PlatformType): Observable<any[]> {
        let endpointUri = !platform ? `$hunchAppUrl/api/v2/manage/account/${accountId}/facebook-ad-accounts` : '$hunchSnapchatCampaignEditorApi/snapchat/ad-accounts';
        return this.httpClient.get<any[]>(`${endpointUri}`).pipe(
            tap(result => {
                catchError(error => {
                    console.log(error);
                    return throwError('');
                })
            }));
    }

    loadCurrencyAndAdAccounts(platform?: PlatformType): Observable<any[]> {
        this.platform = platform;
        let user = get('user');

        let profiles$ = this.loadAdAccounts(user.accountId, platform);

        return profiles$.pipe(tap(result => {
            if (result) {
                set('adAccountIds', result.map(((aa: any) => aa.adAccountId)));
                this.groupAdAccounts = result;
                let currency = result[0].currency;
                this.areAllProfilesWithSameCurrency = true;
                result.forEach(profile => {
                    if (profile.currency !== currency) this.areAllProfilesWithSameCurrency = false;
                    this.currencyByAdAccount.set(profile.id, profile.currencySymbol ? profile.currencySymbol : '$');
                });
                this.userPreferences.defaultCurrencySymbol = result[0].currencySymbol ? result[0].currencySymbol : '$';
            }
        }));
    }
}

export class UserFormatPreferences {
    constructor() { }

    dateFormat: string = 'MM/dd/yyyy';
    decimalSeparator: string = '.';
    firstDayOfWeek: number = 1;
    numberOfDecimals: number = 2;
    thousandSeparator: string = ',';
    defaultCurrencySymbol: string = '$';

    timeFormat?: string;
    timeZone?: string;
}

